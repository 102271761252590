import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import './i18n/I18nConfig';

const fixRemoveChildIssue = () => {
  // took from: https://github.com/facebook/react/issues/11538
  if (typeof Node === 'function' && Node.prototype) {
    // @ts-ignore
    Node.prototype.originalRemoveChild = Node.prototype.removeChild;
    Node.prototype.removeChild = function (child) {
      if (child.parentNode !== this) {
        if (console) {
          console.error('Cannot remove a child from a different parent', child, this);
        }
        return child;
      }
      // @ts-ignore
      return Node.prototype.originalRemoveChild.apply(this, arguments);
    }

    // @ts-ignore
    Node.prototype.originalInsertBefore = Node.prototype.insertBefore;
    Node.prototype.insertBefore = function (newNode, referenceNode) {
      if (referenceNode && referenceNode.parentNode !== this) {
        if (console) {
          console.error('Cannot insert before a reference node from a different parent', referenceNode, this);
        }
        return newNode;
      }
      // @ts-ignore
      return Node.prototype.originalInsertBefore.apply(this, arguments);
    }
  }
}

fixRemoveChildIssue();

ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
).render(
  <>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </>
);

reportWebVitals(console.log);
