import { useState, useEffect, useContext } from 'react';
import { DataGrid, GridRowId, GridRenderCellParams, deDE, enUS, GridRowParams, GRID_CHECKBOX_SELECTION_COL_DEF, GridColDef } from '@mui/x-data-grid';
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { IconDeleteSmall, IconContainerSmall } from '../../../../components/Icons';
import IconButton from '@mui/material/IconButton';
import { UserContext } from '../../../../contexts/UserContext';
import { useContainersQuery } from '../../../../data/containers';
import { useEmptyingsOverviewQuery } from '../../../../data/emptyings';
import { styles } from '../../../../styles';
import { ContainerRef, Container } from '../../../../types/Container';
import { Emptying, EmptyingStatusI18nKey, EmptyingStatusReason } from '../../../../types/Emptying';
import { CancelEmptyingModalContent, EmptyingCancellingInfo } from './CancelEmptyingModalContent';
import Modal from '../../../../components/Modal';
import { Toast } from '../../../../components/Toast';

type EmptyingsListViewProps = {
    from: Date;
    to: Date;
    visibleMonth: number;
    container?: ContainerRef;
};

const StatusPill = styled.div<{ bg: string }>`
    padding: 2px 8px;
    height: 18px;
    background: ${(props) => props.bg};
    color: ${styles.textColorSecondary900};
    text-align: center;
    display: inline-block;
    border-radius: 100px;
    font-size: 10px;
`;

// RESOLVED: Pass container color from the backend
const ContainerIconWrapper = styled.div<{ color: string }>`
    position: relative;
    height: 34px;

    svg {
        width: 34px;
        height: 34px;

        path {
            fill: ${props => props.color};;
        }
        path:last-child {
            stroke: ${props => props.color};;
        }
    }
`;

const ContainerShortName = styled.span`
    font-family: 'Satoshi Bold';
    color: ${styles.textColorSecondary700};
    position: absolute;
    top: 12px;
    right: 11.5px;
    font-size: 9px;
    min-width: 12px;
    text-align: center;
`;

const IconWrapper = styled.div`
    display: flex;
    align-items: center;

    svg {
        width: 24px;
        height: 24px;
    }
`;

const StyledIconButton = styled(IconButton)`
    &.Mui-disabled svg path {
        stroke: ${styles.neutral300};
    }
`;

type ListRowData = {
    id: string,
    date: Date,
    containerName: string;
    containerId: string;
    containerCode: string;
    status: string;
    emptying: Emptying;
}
const convertEmptyingsToRowData = (emptyings: Emptying[], containers: Container[]): ListRowData[] => {
    return emptyings.map(emptying => {
        const container = containers.find(container => container.id === emptying.containerId);
        return {
            id: emptying.id,
            date: emptying.removalDate.date,
            containerName: container?.displayName || '-',
            containerCode: container?.shortName || '-',
            containerId: container?.awmId || '-',
            color: container?.color || 'black',
            status: EmptyingStatusReason[emptying.statusReason],
            emptying
        }
    }).sort((a, b) => a.date.getTime() > b.date.getTime() ? 1 : -1);
}

const convertToRowData = (ids: GridRowId[], allRows: ListRowData[]) => {
    return ids.map(id => allRows.find(r => r.id === id)).filter(r => r !== undefined) as ListRowData[];
}

const useWindowWidth = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowWidth(window.innerWidth);
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        }
    }, []);

    return windowWidth;
}

export const EmptyingsListView = ({ from, to, visibleMonth, container }: EmptyingsListViewProps) => {
    const userContext = useContext(UserContext);
    const { i18n, t } = useTranslation();
    const emptyingsQuery = useEmptyingsOverviewQuery(from, to, container);
    const containersQuery = useContainersQuery(userContext.accessPermission?.accountRef);
    const [selectedRows, setSelectedRows] = useState<ListRowData[]>([]);
    const windowWidth = useWindowWidth();
    const isMobileView = windowWidth <= 480;
    const [showCancelledToast, setShowCancelledToast] = useState(false);
    const [cancelEmptyingsInfo, setCancelEmptyingsInfo] = useState<EmptyingCancellingInfo | undefined>();
    const onCancelEmptyingModalClosed = (dataChanged: boolean) => {
        if (dataChanged) {
            setShowCancelledToast(true);
            emptyingsQuery.refetch();
        }
        setCancelEmptyingsInfo(undefined);
    }

    const onDeleteSelectedEmptyings = async () => {
        setCancelEmptyingsInfo({
            scheduledEmptyings: selectedRows.map(row => {
                return {
                    emptying: row.emptying,
                    container: containersQuery.data?.find(c => c.id === row.containerId)
                }
            })
        });
    };

    if (emptyingsQuery.isError || containersQuery.isError) { return <>ERROR</> }

    const containers = containersQuery.data || [];
    const emptyings = (emptyingsQuery.data || []).filter(e => e.removalDate.date.getMonth() === visibleMonth);
    const rows = convertEmptyingsToRowData(emptyings, containers);

    const dateFormat = new Intl.DateTimeFormat(i18n.language, { day: '2-digit', month: '2-digit', year: 'numeric' });
    const columns: GridColDef<ListRowData>[] = [
        {
            ...GRID_CHECKBOX_SELECTION_COL_DEF,
            renderCell: (params: GridRenderCellParams<ListRowData>) => (
                params.row.date > new Date() && GRID_CHECKBOX_SELECTION_COL_DEF.renderCell ? GRID_CHECKBOX_SELECTION_COL_DEF.renderCell(params) : <></>
            )
        },
        {
            field: 'date', headerName: 'Datum', sortable: true, flex: 2, renderCell: (params: GridRenderCellParams) => (
                dateFormat.format(params.row.date)
            ),
        },
        { field: 'containerName', headerName: 'Name', flex: 2.5 },
        { field: 'containerId', headerName: 'ID', flex: 2.1 },
        {
            field: 'containerCode',
            headerName: 'Code',
            renderCell: (params: GridRenderCellParams) => (
                <ContainerIconWrapper color={params.row.color}>
                    <IconContainerSmall />
                    <ContainerShortName>{params.row.containerCode}</ContainerShortName>
                </ContainerIconWrapper>
            ),
            flex: 1.4
        },
        {
            field: 'status',
            headerName: 'Status',
            renderCell: (params: GridRenderCellParams<ListRowData>) => {
                let bgColor;
                switch (params.row.emptying.statusReason) {
                    case EmptyingStatusReason.Pending:
                        bgColor = '#DEE9FC';
                        break;
                    case EmptyingStatusReason.Cancelled:
                        bgColor = '#FBD5D5';
                        break;
                    case EmptyingStatusReason.Completed:
                        bgColor = '#E9F6EB';
                        break;
                    case EmptyingStatusReason.Uncompleted:
                        bgColor = '#FBD5D5';
                        break;
                }
                const translationKey = `pills.${EmptyingStatusI18nKey[params.row.emptying.statusReason].toLowerCase()}`;
                return <StatusPill bg={bgColor}>{(t as any)(translationKey)}</StatusPill>;
            },
            flex: 2.5
        },
    ];

    const deleteButtonDisabled = selectedRows.length === 0 ||
        selectedRows.filter(r => r.emptying.statusReason !== EmptyingStatusReason.Pending).length > 0 ||
        selectedRows.filter(r => r.date < new Date()).length > 0 ||
        !userContext.accessPermission?.roles?.payment;

    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: 600 }}>
                <div style={{ display: 'flex', margin: '16px 0 0 5px' }}>
                    <IconWrapper>
                        {/* RESOLVED: hover and disable the delete button doesn't have any effect */}
                        <StyledIconButton disabled={deleteButtonDisabled} onClick={onDeleteSelectedEmptyings}>
                            <IconDeleteSmall />
                        </StyledIconButton>
                        <div style={{ cursor: deleteButtonDisabled ? undefined : 'pointer', color: deleteButtonDisabled ? '#999' : undefined }} onClick={deleteButtonDisabled ? undefined : onDeleteSelectedEmptyings}>{`Leerung${selectedRows.length > 1 ? 'en' : ''} stornieren`}</div>
                    </IconWrapper>
                </div>
                <DataGrid
                    sx={{
                        '&  .MuiCheckbox-root svg': {
                            color: styles.neutral200,
                        },
                        '&  .MuiCheckbox-root.Mui-checked svg': {
                            color: '#33AA47',
                        },
                        '& .MuiDataGrid-row': {
                            borderBottom: `1px solid ${styles.neutral200}`,
                        },
                        '& .MuiDataGrid-columnHeaderTitle': {
                            fontSize: '14px',
                        },
                        '& .MuiDataGrid-cell': {
                            border: 'none',
                            fontSize: '14px',
                        },
                        '& .MuiDataGrid-withBorderColor': {
                            borderColor: `${styles.neutral300}`
                        },
                        '@media (max-width: 834px)': {
                            '& .MuiDataGrid-columnHeaderTitle': {
                                fontSize: '10px !important',
                            },
                            '& .MuiDataGrid-cell': {
                                fontSize: '10px !important',
                            },
                        },
                    }}
                    initialState={{
                        pagination: {
                            paginationModel: { pageSize: 25, page: 0 },
                        },
                    }}
                    className="list-view-grid"
                    style={{ border: 'none' }}
                    loading={emptyingsQuery.isLoading || containersQuery.isLoading}
                    localeText={(i18n.language === 'de' ? deDE : enUS).components.MuiDataGrid.defaultProps.localeText}
                    rows={rows}
                    pageSizeOptions={[10, 25, 100]}
                    paginationMode='client'
                    columns={columns}
                    isRowSelectable={(params: GridRowParams<ListRowData>) => params.row.date > new Date()}
                    checkboxSelection
                    onRowSelectionModelChange={(ids: GridRowId[]) => setSelectedRows(convertToRowData(ids, rows))}
                    disableColumnSelector
                    {...(rows.length < 3 ? { hideFooterPagination: true } : {})}
                    rowHeight={isMobileView ? 44 : 72}
                />
            </div>
            {cancelEmptyingsInfo &&
                <Modal onClose={() => onCancelEmptyingModalClosed(false)}>
                    <CancelEmptyingModalContent onClose={onCancelEmptyingModalClosed} data={cancelEmptyingsInfo} />
                </Modal>
            }
            {showCancelledToast && <Toast message={t("toasts.emptyingCancelled")} type="success" onCloseComplete={() => setShowCancelledToast(false)} />}
        </>
    );
};