import { useCallback } from "react";
import styled from "@emotion/styled";
import { styles } from "../../../../../styles";
import { useTranslation } from "react-i18next";
import Button from '../../../../../components/Button';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { usePostContactFormMutation } from "../../../../../data/contactForms";
import { PostableContactForm } from "../../../../../types/ContactForm";
import { Controller, useForm } from "react-hook-form";
import { IconExclamationMark } from "../../../../../components/Icons";
import { Loader } from "../../../../../components/Loader";


const StyledForm = styled.form`
    max-width: 536px;
`;

const FormRow = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    color: ${styles.textColorSecondary700};

    @media (max-width: 428px) {
        margin-bottom: 0;
    }
`;

const BottomRow = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    margin-top: 48px;

    button {
        width: 156px;
    }
`;

const StyledH3 = styled.h3`
    font-size: 10px;
    line-height: 18px;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    gap: 10px;
`;

const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-right: 12px;

    @media (max-width: 428px) {
        margin-right: 0;
    }

    &:last-child {
        margin-right: 0;
    }
`;
const TwoColRow = styled.div`
    display: flex;
    justify-content: space-between;

    @media (max-width: 428px) {
        flex-direction: column;
    }
`;

type StyledInputProps = {
    hasError?: boolean;
};

const StyledInput = styled.input<StyledInputProps>`
    width: calc(50% - 24px));
    padding: 10px 16px;
    flex: 1;
    box-shadow: ${styles.boxShadowInset};
    background-color: #fff;
    border: 1px solid ${props => props.hasError ? '#ed2b2b' : styles.neutral200};
    border-radius: 4px;
    box-sizing: border-box;
    line-height: 22px;
    font-size: 14px;
    color: ${styles.textColorSecondary700};
    font-family: 'Satoshi Regular';

    &:focus {
        border: 1px solid #3371EA;
        outline: none;
    }

    @media (max-width: 428px) {
        margin-bottom: 24px;
    }
`;


const StyledSelect = styled(Select)(() => ({
    boxShadow: styles.boxShadowInset,
    height: '44px',
    backgroundColor: '#fff',
    borderRadius: '4px',
    flex: '1',
    boxSizing: 'border-box',
    padding: '0',
    fontFamily: 'Satoshi Regular',
    color: styles.textColorSecondary700,
    '@media (max-width: 428px)': {
        marginBottom: '24px'
    },
    '.MuiSelect-icon': {
        color: '#26374A',
    },

    '& .MuiSelect-select': {
        paddingTop: '11px',
        paddingBottom: '11px',
        lineHeight: '22px',
        fontSize: '14px',
        boxShadow: styles.boxShadowInset,
    },
    '& .MuiOutlinedInput-root': {
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#e0e1e0',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#e0e1e0',
        },
    },
    '.MuiOutlinedInput-notchedOutline': {
        borderWidth: '1px',
    },
}));

const StyledTextArea = styled.textarea<StyledInputProps>`
    padding: 10px 16px;
    flex: 1;
    box-shadow: ${styles.boxShadowInset};
    background-color: #fff;
    border: 1px solid ${props => props.hasError ? '#ed2b2b' : styles.neutral200};
    border-radius: 4px;
    box-sizing: border-box;
    line-height: 22px;
    font-size: 14px;
    color: ${styles.textColorSecondary700};
    font-family: 'Satoshi Regular';
    min-height: 88px;
    resize: none;

    &:focus {
        border: 1px solid #3371EA;
        outline: none;
    }

    &:disabled {
        background-color: #f5f5f5;
    }
`;

const StyledMenuItem = styled(MenuItem)`
  color: ${styles.textColorSecondary700};
  font-size: 14px;
  line-height: 22px;
  font-family: 'Satoshi Regular';
`;

const ErrorWrapper = styled.div`
    margin-top: 12px;
    display: flex;
    align-items: center;
    font-size: 10px;
    line-height: 18px;

    svg {
        margin-right: 8px;
    }
`;

const Subheader = styled.p`
    margin-bottom: 24px;
    max-width: 610px;
`;


type ContactFormProps = {
    onSent: () => void;
    defaultValues: PostableContactForm;
    accountName: string;
}


export const ContactForm = ({ onSent, defaultValues, accountName }: ContactFormProps) => {
    const { t } = useTranslation();
    const { register, handleSubmit, control, formState: { errors } } = useForm({
        defaultValues: defaultValues
    });
    const postContactFormMutation = usePostContactFormMutation();

    const onSubmit = useCallback(async (formData: PostableContactForm) => {
        await postContactFormMutation.mutateAsync(formData);
        if (postContactFormMutation.isSuccess) {
            onSent();
        }
    }, [postContactFormMutation, onSent]);

    if (postContactFormMutation.isError) {
        return <p>
            ERROR: {postContactFormMutation.error.message}
        </p>
    }
    if (postContactFormMutation.isLoading) {
        // RESOLVED: make nice
        return <Loader height={"660px"} width={"536px"} messageText={t("pages.help.contact.postingContactForm")} />
    }
    if (postContactFormMutation.isSuccess) {
        onSent();
    }

    return (
        <>
            <Subheader>{t("pages.help.contact.subheader")}</Subheader>
            <StyledForm>
                <FormRow>
                    <TwoColRow>
                        <InputContainer>
                            <StyledH3>{t("pages.help.contact.firstName")}</StyledH3>
                            <StyledInput hasError={Boolean(errors.firstName)} type="text" placeholder={t("pages.help.contact.firstName")} {...register("firstName", { required: 'Mussfeld' })} />
                            {errors.firstName && <ErrorWrapper><IconExclamationMark />{errors.firstName.message}</ErrorWrapper>}
                        </InputContainer>
                        <InputContainer>
                            <StyledH3>{t("pages.help.contact.lastName")}</StyledH3>
                            <StyledInput hasError={Boolean(errors.lastName)} type="text" placeholder={t("pages.help.contact.lastName")} {...register("lastName", { required: 'Mussfeld' })} />
                            {errors.lastName && <ErrorWrapper><IconExclamationMark />{errors.lastName.message}</ErrorWrapper>}
                        </InputContainer>
                    </TwoColRow>
                </FormRow>
                <FormRow>
                    <TwoColRow>
                        <InputContainer>
                            <InputContainer>
                                <StyledH3>{t("pages.help.contact.company")}</StyledH3>
                                <StyledInput hasError={Boolean(errors.companyName)} type="text" placeholder={t("pages.help.contact.company")} {...register("companyName")} />
                                {errors.companyName && <ErrorWrapper><IconExclamationMark />{errors.companyName.message}</ErrorWrapper>}
                            </InputContainer>
                        </InputContainer>
                        <InputContainer>
                            <InputContainer>
                                <StyledH3>{t("pages.help.contact.streetAndNo")}</StyledH3>
                                <StyledInput hasError={Boolean(errors.street)} type="text" placeholder={t("pages.help.contact.streetAndNo")} {...register("street")} />
                                {errors.street && <ErrorWrapper><IconExclamationMark />{errors.street.message}</ErrorWrapper>}
                            </InputContainer>
                        </InputContainer>
                    </TwoColRow>
                </FormRow>
                <FormRow>
                    <TwoColRow>
                        <InputContainer>
                            <StyledH3>{t("pages.help.contact.zipAndCity")}</StyledH3>
                            <StyledInput hasError={Boolean(errors.postalCodeAndCity)} type="text" placeholder={t("pages.help.contact.zipAndCity")} {...register("postalCodeAndCity")} />
                            {errors.postalCodeAndCity && <ErrorWrapper><IconExclamationMark />{errors.postalCodeAndCity.message}</ErrorWrapper>}
                        </InputContainer>
                        <InputContainer>
                            <StyledH3>{t("pages.help.contact.phone")}</StyledH3>
                            <StyledInput hasError={Boolean(errors.phone)} type="text" placeholder={t("pages.help.contact.phone")} {...register("phone")} />
                            {errors.phone && <ErrorWrapper><IconExclamationMark />{errors.phone.message}</ErrorWrapper>}
                        </InputContainer>
                    </TwoColRow>
                </FormRow>
                <FormRow>
                    <StyledH3>{t("pages.help.contact.email")}</StyledH3>
                    <StyledInput hasError={Boolean(errors.email)} type="text" placeholder={t("pages.help.contact.email")} {...register("email", { required: 'Mussfeld' })} />
                    {errors.email && <ErrorWrapper><IconExclamationMark />{errors.email.message}</ErrorWrapper>}
                </FormRow>
                <FormRow>
                    <StyledH3>{t("pages.help.contact.category")}</StyledH3>
                    {<Controller
                        name="category"
                        control={control}
                        rules={{ required: 'Mussfeld' }}
                        render={({ field, fieldState }) => (
                            <><StyledSelect
                                sx={{
                                    '.MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#e0e1e0',
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#3371EA',
                                        borderWidth: '1px'
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#e0e1e0',
                                    },
                                }}
                                {...field}
                                onChange={(e) => {
                                    field.onChange(e.target.value);
                                }}
                            >
                                <StyledMenuItem value="Keine" disabled>{t("pages.help.contact.pleaseChoose")}</StyledMenuItem>
                                <StyledMenuItem value="Portal">{t("pages.help.contact.portal")}</StyledMenuItem>
                                <StyledMenuItem value="Leerung">{t("pages.help.contact.emptying")}</StyledMenuItem>
                                <StyledMenuItem value="Guthaben">{t("pages.help.contact.credit")}</StyledMenuItem>
                                <StyledMenuItem value="Rechnung">{t("pages.help.contact.invoice")}</StyledMenuItem>
                                <StyledMenuItem value="Feedback">{t("pages.help.contact.feedback")}</StyledMenuItem>
                                <StyledMenuItem value="Sonstiges">{t("pages.help.contact.misc")}</StyledMenuItem>
                            </StyledSelect>
                            {fieldState.error && <ErrorWrapper><IconExclamationMark />{fieldState.error.message}</ErrorWrapper>}
                            </>
                        )}
                    />}
                </FormRow>
                <FormRow>
                    <StyledH3>{t("pages.help.contact.yourMessage")}</StyledH3>
                    <StyledTextArea hasError={Boolean(errors.message)} placeholder={t("pages.help.contact.yourMessage")} {...register("message", { required: 'Mussfeld' })} />
                    {errors.message && <ErrorWrapper><IconExclamationMark />{errors.message.message}</ErrorWrapper>}
                </FormRow>
                <BottomRow>
                    <Button disabled={postContactFormMutation.isLoading} onClick={handleSubmit(onSubmit)}>
                        { postContactFormMutation.isLoading && <Loader/> }
                        { !postContactFormMutation.isLoading && t("buttons.send") }
                    </Button>
                </BottomRow>
            </StyledForm>
        </>
    )
}