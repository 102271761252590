import { useContext } from "react";
import styled from "@emotion/styled";
import { UserContext } from "../contexts/UserContext";
import { SelectChangeEvent } from '@mui/material/Select';
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IconUser, IconContainerEmpty, IconUsers } from "./Icons";
import { LoginLogout } from "./LoginLogout";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { styles } from '../styles';
import { ROUTES } from "../skeleton/Routes";


const OptionsContainer = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 10px;
  }
`;

const isLastStyle = `
  position: relative;
  margin-bottom: 0;

  &:before {
    content: "";
    position: absolute;
    top: -8px;
    left: 0;
    width: 100%;
    border-top: 1px solid ${styles.neutral200};
  }
`;

const isPenultimateStyle = `
  margin-bottom: 16px;
`;

const StyledMenuItem = styled(MenuItem, {
	shouldForwardProp: (prop) => prop !== 'isLast' && prop !== 'isPenultimate' && prop !== 'isSelected',
}) <{ isLast: boolean; isPenultimate: boolean; isSelected: boolean }>`
  color: ${styles.textColorSecondary700};
  display: block;
  width: 100%;
  padding: 10px 20px;
  margin-bottom: 8px;
  font-size: 14px;
  font-family: 'Satoshi Regular';

  background-color: ${(props) => (props.isSelected ? "rgba(25, 118, 210, 0.08)" : "transparent")} !important;

  &:hover {
    background-color: rgba(25, 118, 210, 0.12) !important;
  }

  ${props => props.isLast && isLastStyle}
  ${props => props.isPenultimate && isPenultimateStyle}
`;

const StyledUserSettingsChooser = styled.div`
	display: flex;
	align-items: center;
	cursor: pointer;
`;

const User = styled.div`
	display: flex;
	align-items: center;

	svg {
		margin-right: 8px;
	}
`;

export const UserSettingsChooser = () => {
	const { updateSelectedAccount, accessPermission, loggedInUser } = useContext(UserContext);
	const { t } = useTranslation();
	const navigate = useNavigate();

	const options = [
		{
			route: ROUTES.UserProfile,
			component: <OptionsContainer><IconUser />{t("navigation.top.myData")}</OptionsContainer>,
		}
	]
	if (accessPermission?.roles && accessPermission.roles.container) {
		options.push({
			route: ROUTES.ContainerOverview,
			component: <OptionsContainer><IconContainerEmpty />{t("navigation.top.myContainers")}</OptionsContainer>,
		});
	}
	if (accessPermission?.roles && accessPermission.roles.permission) {
		options.push({
			route: ROUTES.Organisation,
			component: <OptionsContainer><IconUsers />{t("navigation.top.myOrganisation")}</OptionsContainer>,
		});
	}
	options.push({
		route: ROUTES.Logout,
		component: <LoginLogout />,
	});

	const location = useLocation();
	const selectedOption = options.find(o => o.route === location.pathname);

	if (!loggedInUser) {
		return <></>;
	}

	const handleChange = (event: SelectChangeEvent<string>) => {
		updateSelectedAccount(event.target.value);
	};

	const user = <User><IconUser />{loggedInUser.contact.firstName} {loggedInUser.contact.lastName}</User>

	return (
		<StyledUserSettingsChooser>
			<FormControl sx={{ m: 1, }} size="small">
				<InputLabel id="select-small-label"></InputLabel>
				<Select
					sx={{
						boxShadow: 'none',
						position: 'relative',
						fontFamily: 'Satoshi Regular, sans-serif',
						fontSize: '14px',
						color: styles.textColorSecondary700,
						'.MuiOutlinedInput-notchedOutline': { border: 'none' },
						'.MuiSelect-select': { display: 'flex' },
						'.MuiSelect-outlined': { border: 'none' },
						'.MuiFormControl-root': { height: '50px' },
						'.MuiSelect-icon': { color: '#26374A' },
					}}
					labelId="select-small-label"
					id="select-small"
					value={selectedOption?.route || options[0].route}
					onChange={handleChange}
					renderValue={() => user}
					MenuProps={{
						disableScrollLock: true,
						PaperProps: {
							style: {
								boxShadow: styles.boxShadowBottomLight,
								border: '1px solid #E0E1E0'
							},
						},
					}}
				>
					{options.map((option, index) => {
						return (
							<StyledMenuItem
								key={option.route} 
								value={option.route}
								onClick={() => navigate(option.route)}
								isLast={index === options.length - 1}
								isPenultimate={index === options.length - 2}
								isSelected={option.route === location.pathname}
							>
								{option.component}
							</StyledMenuItem>
						);
					})}
				</Select>
			</FormControl>
		</StyledUserSettingsChooser>
	);
};