import { useEffect } from 'react';
import ReactDOM from 'react-dom';
import styled from '@emotion/styled';

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(83, 107, 133, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
`;
const ModalContent = styled.div`
  width: 100%;
  max-width: 500px;
  padding: 0 0 24px 24px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 16px 40px -8px rgba(0, 0, 0, 0.12);

  @media (max-width: 480px) {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border-radius: 0;
  }
`;

type ModalProps = {
  onClose: () => void;
  children: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({ children }) => {
  useEffect(() => {
    document.body.classList.add('no-scroll');

    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, []);

  const content = (
    <ModalBackground>
      <ModalContent>
        {children}
      </ModalContent>
    </ModalBackground>
  );

  return ReactDOM.createPortal(content, document.getElementById('modal-root') as HTMLElement);
};

export default Modal;