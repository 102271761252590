export const OrderConfirmedImage = () => (
    <svg width="480" height="295" viewBox="0 0 480 295" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_2089_262263)">
            <rect width="480" height="320" fill="white" />
            <path d="M438.322 261.477C438.322 276.216 349.975 288.129 241 288.129C132.025 288.129 43.6772 276.173 43.6772 261.477C43.6772 246.781 132.025 234.824 241 234.824C349.975 234.824 438.322 246.781 438.322 261.477Z" fill="#F5F5F5" />
            <path d="M390.803 244.331C390.275 243.448 389.11 242.365 388.291 241.746C387.472 241.127 386.352 240.818 385.397 241.209C384.186 241.701 383.631 243.193 383.795 244.486C383.968 245.787 384.586 247.426 385.324 248.509C388.473 253.124 391.386 257.347 396.255 260.778C396.255 255.053 393.716 249.219 390.803 244.331Z" fill="#ADDDB5" />
            <path d="M406.85 245.123C405.758 244.531 403.737 245.369 402.745 246.115C400.779 247.59 399.577 249.137 398.376 251.285C396.856 254.007 396.674 257.838 396.483 260.269C400.333 257.147 404.256 253.934 406.895 249.738C407.36 249 407.778 248.218 407.869 247.353C407.951 246.488 407.615 245.542 406.85 245.123Z" fill="#ADDDB5" />
            <path d="M396.838 263.218C396.729 263.218 396.638 263.145 396.619 263.036C395.709 258.821 394.089 254.826 391.814 251.158C391.75 251.048 391.777 250.912 391.886 250.848C391.996 250.784 392.132 250.812 392.196 250.921C394.499 254.635 396.128 258.676 397.056 262.945C397.084 263.063 397.002 263.19 396.883 263.218C396.865 263.218 396.847 263.218 396.838 263.218Z" fill="#26374A" />
            <path d="M396.328 260.86C396.328 260.86 396.273 260.86 396.255 260.851C396.137 260.806 396.073 260.678 396.119 260.56C397.156 257.711 398.658 255.09 400.597 252.76C400.679 252.659 400.824 252.65 400.915 252.732C401.016 252.814 401.025 252.96 400.943 253.051C399.04 255.335 397.557 257.911 396.537 260.715C396.501 260.806 396.419 260.869 396.319 260.869L396.328 260.86Z" fill="#26374A" />
            <path opacity="0.3" d="M133.376 83.2272L133.645 69.3604L137.285 82.3003L133.376 83.2272Z" fill="black" />
            <path d="M114.383 64.1824C114.383 64.1824 114.685 84.612 117.751 87.0784C120.685 89.4345 168.418 87.0784 168.418 87.0784L174.107 80.6759L127.826 79.5717L126.851 65.4765L114.385 64.1896L114.383 64.1824Z" fill="#FBC8C1" />
            <path d="M137.293 56.5998C135.364 52.1968 131.021 51.4493 129.78 51.4942C126.312 51.6287 129.145 58.1248 127.612 61.2346C124.331 67.6933 124.667 82.1473 124.667 82.1473L141.681 79.359C139.954 70.0447 139.565 62.1316 137.285 56.5924L137.293 56.5998Z" fill="#26374A" />
            <path d="M140.552 239.986L146.667 256.477L152.206 254.69L150.293 237.28L140.552 239.986Z" fill="#FBC8C1" />
            <path d="M146.069 263.414L151.093 263.377C151.093 263.377 152.102 262.831 152.7 262.898C153.298 262.966 155.368 263.347 155.368 263.347L168.705 263.25C168.592 262.689 168.458 262.196 168.308 261.755C168.121 261.216 167.905 260.76 167.665 260.379C166.828 259.034 165.699 258.563 164.473 258.495C162.605 258.391 156.699 254.511 156.699 254.511C156.699 254.511 154.561 253.778 154.427 251.513C154.374 250.661 153.111 249.637 152.513 249.555C151.294 249.39 150.622 250.168 150.45 251.386L150.255 252.807L147.437 252.388C147.437 252.388 144.559 250.968 144.709 252.365C144.791 253.165 145.299 256.724 145.628 259.542C145.703 260.2 145.777 260.82 145.837 261.366C145.979 262.577 146.069 263.414 146.069 263.414Z" fill="#26374A" />
            <path d="M168.697 263.242L155.361 263.339C155.361 263.339 153.29 262.958 152.692 262.891C152.094 262.824 151.085 263.369 151.085 263.369L146.062 263.407C146.062 263.407 145.964 262.577 145.83 261.358L168.293 261.74C168.45 262.181 168.585 262.674 168.69 263.235L168.697 263.242Z" fill="#E0E0E0" />
            <path d="M159.629 255.946C159.57 255.886 159.472 255.879 159.398 255.931C159.069 256.155 158.792 256.462 158.605 256.858C158.508 257.068 158.807 257.262 158.904 257.053C159.069 256.709 159.308 256.432 159.6 256.238C159.697 256.17 159.712 256.036 159.629 255.961V255.946Z" fill="white" />
            <path d="M158.56 255.326C158.501 255.266 158.403 255.259 158.329 255.311C158 255.535 157.723 255.842 157.536 256.238C157.439 256.44 157.738 256.641 157.835 256.432C158 256.088 158.239 255.812 158.53 255.617C158.628 255.55 158.643 255.416 158.56 255.333V255.326Z" fill="white" />
            <path d="M157.462 254.75C157.402 254.69 157.305 254.683 157.23 254.735C156.901 254.96 156.624 255.266 156.437 255.662C156.34 255.872 156.639 256.066 156.736 255.857C156.901 255.513 157.14 255.236 157.432 255.042C157.529 254.975 157.544 254.84 157.462 254.758V254.75Z" fill="white" />
            <path d="M81.1526 228.654L73.2212 244.352L78.3044 247.193L89.7642 233.954L81.1526 228.654Z" fill="#FBC8C1" />
            <path d="M67.7343 248.628L71.1804 252.283C71.1804 252.283 72.2718 252.65 72.6232 253.136C72.9745 253.621 74.1108 255.393 74.1108 255.393L83.2532 265.096C83.5896 264.633 83.8587 264.199 84.0755 263.788C84.3446 263.287 84.5315 262.816 84.6436 262.383C85.0548 260.858 84.6361 259.706 83.8512 258.765C82.6552 257.322 81.4815 250.362 81.4815 250.362C81.4815 250.362 80.5621 248.299 82.1319 246.662C82.7225 246.049 82.6178 244.419 82.2664 243.926C81.5563 242.917 80.5322 242.954 79.523 243.657L78.3493 244.479L76.7347 242.124C76.7347 242.124 75.8227 239.052 74.8957 240.113C74.365 240.719 72.1074 243.515 70.261 245.668C69.8274 246.168 69.4237 246.647 69.0649 247.066C68.2725 247.992 67.7268 248.628 67.7268 248.628H67.7343Z" fill="#26374A" />
            <path d="M83.2533 265.104L74.1109 255.401C74.1109 255.401 72.9821 253.622 72.6233 253.143C72.2645 252.657 71.1805 252.291 71.1805 252.291L67.7344 248.636C67.7344 248.636 68.2801 248 69.0725 247.073L84.0756 263.796C83.8588 264.207 83.5822 264.648 83.2533 265.104Z" fill="#E0E0E0" />
            <path d="M82.4309 253.487C82.4309 253.397 82.3711 253.33 82.2889 253.308C81.9002 253.218 81.489 253.225 81.0779 253.36C80.8611 253.427 80.9209 253.779 81.1377 253.711C81.504 253.599 81.8703 253.584 82.2067 253.666C82.3188 253.689 82.4309 253.614 82.4309 253.494V253.487Z" fill="white" />
            <path d="M82.1543 252.291C82.1543 252.201 82.0945 252.134 82.0123 252.111C81.6236 252.022 81.2124 252.029 80.8013 252.164C80.5845 252.231 80.6443 252.582 80.8611 252.515C81.2274 252.403 81.5937 252.388 81.93 252.47C82.0422 252.493 82.1543 252.418 82.1543 252.298V252.291Z" fill="white" />
            <path d="M81.8328 251.095C81.8328 251.005 81.773 250.938 81.6907 250.916C81.302 250.826 80.8909 250.833 80.4797 250.968C80.263 251.035 80.3228 251.386 80.5395 251.319C80.9058 251.207 81.2721 251.192 81.6085 251.274C81.7206 251.297 81.8328 251.222 81.8328 251.102V251.095Z" fill="white" />
            <path d="M123.71 184.526C123.246 186.014 122.805 187.36 122.417 188.526C118.298 200.442 86.4825 245.675 86.4825 245.675L73.4529 235.785C73.4529 235.785 92.104 196.756 103.28 183.196C103.28 183.196 103.773 163.386 105.171 148.562C109.873 160.957 116.078 172.992 123.71 184.534V184.526Z" fill="#85CC91" />
            <path opacity="0.14" d="M123.71 184.526C123.246 186.014 122.805 187.36 122.417 188.526C118.298 200.442 86.4825 245.675 86.4825 245.675L73.4529 235.785C73.4529 235.785 92.104 196.756 103.28 183.196C103.28 183.196 103.773 163.386 105.171 148.562C109.873 160.957 116.078 172.992 123.71 184.534V184.526Z" fill="black" />
            <path d="M153.32 243.223L139.782 247.282C127.044 211.819 121.445 186.971 121.445 186.971C120.929 186.141 120.421 185.319 119.927 184.511C112.826 172.962 107.055 160.919 102.682 148.517C99.7215 143.546 97.9872 137.925 97.7405 132.146C97.2247 120.171 101.045 112.591 101.045 112.591L105.821 110.176H105.919L135.775 115.529C135.775 115.529 133.368 123.998 130.528 138.74L141.374 180.983L153.32 243.216V243.223Z" fill="#85CC91" />
            <path opacity="0.3" d="M97.6881 126.876V130.18C97.6881 130.18 103.713 131.302 114.612 132.46C125.512 133.619 131.888 131.945 131.888 131.945L132.486 129.134L97.6807 126.876H97.6881Z" fill="black" />
            <path d="M96.9854 62.9465L94.085 126.286C107.802 130.778 122.686 131.234 137.39 128.401C137.39 128.401 138.294 125.276 137.001 123.774C136.134 122.765 134.856 87.0323 134.243 66.9384C134.205 65.2265 136.321 58.0726 134.856 54.1929C133.832 51.4868 129.436 51.4494 128.24 51.3971H111.435C110.314 51.3821 101.441 52.1371 99.7438 53.1388C96.8434 54.8507 96.9107 57.8334 96.9929 62.9465H96.9854Z" fill="#26374A" />
            <path opacity="0.3" d="M95.8342 85.6717L99.3402 85.7988L100.633 72.4626L96.0959 82.9805L95.8342 85.6717Z" fill="black" />
            <path d="M111.062 13.9978C111.435 13.6914 111.966 13.6016 112.437 13.7213C112.908 13.8409 113.319 14.1473 113.611 14.5361L110.613 15.306C110.486 14.8351 110.68 14.3118 111.062 14.0053V13.9978Z" fill="#85CC91" />
            <path d="M125.713 20.9946L126.909 22.7289C126.909 22.7289 130.378 22.0487 129.675 25.3079C130.759 26.0256 131.058 27.4908 130.333 28.5747C130.094 28.9335 129.765 29.2176 129.384 29.397C129.384 29.397 129.175 34.7867 124.42 31.4602C119.666 28.1336 121.385 21.0993 121.385 21.0993L125.713 20.9946Z" fill="#26374A" />
            <path d="M110.381 51.2252C110.381 51.2252 111.047 46.441 108.243 38.1358C108.243 38.1283 108.677 37.9115 108.677 37.9041C108.288 36.7454 107.885 35.6689 107.511 34.742C107.503 34.7121 107.503 34.7046 107.503 34.7046L119.307 41.3128C119.158 44.0115 120.346 49.162 121.019 51.3299C121.019 51.3299 122.641 55.2395 117.363 55.6432C113.506 55.9347 110.381 51.2177 110.381 51.2177V51.2252Z" fill="#FBC8C1" />
            <path opacity="0.2" d="M119.673 45.0879L112.99 43.8844C113.678 45.9177 115.607 48.2799 120.219 48.2725C120.219 48.2725 119.658 45.9925 119.673 45.0879Z" fill="black" />
            <path d="M126.394 21.1066C127.866 23.7081 128.599 29.5389 124.308 28.4998C120.017 27.4682 123.867 16.6363 126.394 21.1066Z" fill="#26374A" />
            <path d="M103.967 28.4625L103.773 31.049C103.773 31.049 99.5346 31.2509 101.658 35.2801C101.658 35.2801 99.1234 39.9971 104.087 39.9223C104.087 39.9223 104.625 44.1086 108.632 41.3427C112.639 38.5768 112.714 31.0416 112.714 31.0416L110.927 23.4391L105.657 26.5638L103.967 28.4625Z" fill="#26374A" />
            <path d="M107.167 29.1278C108.258 36.3864 108.729 40.4978 112.751 43.8842C118.799 48.9749 127.321 44.9382 128.419 37.4778C129.406 30.7649 127.455 20.0302 120.047 17.825C112.751 15.6571 106.076 21.8692 107.167 29.1278Z" fill="#FBC8C1" />
            <path d="M104.827 35.4669C105.575 37.2909 107.1 38.5991 108.61 39.1897C110.882 40.0793 112.28 38.0983 111.876 35.8183C111.51 33.7625 109.88 30.8322 107.496 30.9144C105.141 30.9966 103.975 33.3813 104.827 35.4744V35.4669Z" fill="#FBC8C1" />
            <path d="M117.797 30.5032C117.864 31.2507 118.313 31.8189 118.799 31.7665C119.285 31.7217 119.628 31.0713 119.561 30.3313C119.494 29.5837 119.045 29.0156 118.559 29.0679C118.074 29.1128 117.73 29.7631 117.797 30.5032Z" fill="#26374A" />
            <path d="M125.13 30.3165C125.198 31.0641 125.646 31.6322 126.132 31.5799C126.618 31.535 126.962 30.8847 126.894 30.1446C126.827 29.3971 126.379 28.8289 125.893 28.8813C125.407 28.9261 125.063 29.5765 125.13 30.3165Z" fill="#26374A" />
            <path d="M122.716 29.6065C122.716 29.6065 125.407 33.105 126.297 34.5477C125.34 35.5793 123.516 35.3027 123.516 35.3027L122.716 29.599V29.6065Z" fill="#DA968B" />
            <path d="M115.659 26.9525C115.779 26.9899 115.906 26.96 116.003 26.8703C117.154 25.8013 118.41 26.0853 118.425 26.0853C118.612 26.1302 118.806 26.0181 118.851 25.8237C118.896 25.6368 118.784 25.4499 118.597 25.4051C118.53 25.3901 116.952 25.0313 115.524 26.3619C115.382 26.4965 115.375 26.7133 115.51 26.8553C115.554 26.9002 115.607 26.9301 115.659 26.9525Z" fill="#26374A" />
            <path opacity="0.2" d="M109.836 35.3774C109.746 35.3774 109.671 35.3176 109.656 35.2279C109.537 34.6374 109.26 34.0842 108.864 33.6357C108.602 33.3441 108.184 32.9778 107.653 32.8956C107.174 32.8209 106.681 33.0302 106.487 33.3965C106.434 33.4862 106.322 33.5161 106.233 33.4712C106.143 33.4189 106.113 33.3067 106.158 33.217C106.434 32.7162 107.077 32.4321 107.713 32.5293C108.363 32.6265 108.849 33.0526 109.148 33.3965C109.589 33.8973 109.896 34.5103 110.03 35.1607C110.053 35.2653 109.985 35.3625 109.881 35.3774C109.866 35.3774 109.858 35.3774 109.843 35.3774H109.836Z" fill="black" />
            <path d="M116.369 35.7213L122.805 37.6724C122.454 38.3826 122.095 39.1002 121.572 39.6907C121.049 40.2813 120.368 40.5504 119.576 40.5953C118.447 40.6626 117.363 40.057 116.855 39.0404C116.354 38.0237 116.302 36.8501 116.369 35.7213Z" fill="black" />
            <path d="M122.29 37.5154C122.29 37.5154 121.879 38.3302 121.49 38.4573C121.101 38.5844 116.347 36.9024 116.347 36.9024L116.369 35.7213L122.29 37.5154Z" fill="white" />
            <path d="M120.383 40.4532C120.383 40.4532 120.025 39.3468 118.701 38.8609C117.371 38.3825 116.698 38.674 116.698 38.674C116.698 38.674 117.132 39.9897 118.38 40.4083C119.628 40.8269 120.383 40.4532 120.383 40.4532Z" fill="#DE5753" />
            <path d="M107.145 31.7295C107.145 31.7295 107.302 35.5195 109.798 35.0411C112.295 34.5627 109.888 30.2718 110.89 28.8066C112.168 27.2144 112.564 25.0839 111.936 23.1403C111.099 20.9874 106.292 26.8929 106.292 26.8929L107.145 31.7295Z" fill="#26374A" />
            <path d="M103.923 31.6545C110.396 25.8013 118.866 22.6541 127.582 22.856C127.582 22.856 122.29 10.0805 111.159 14.8797C100.349 19.5444 103.923 31.6545 103.923 31.6545Z" fill="#85CC91" />
            <path d="M128.188 26.504C128.255 26.4816 128.307 26.4442 128.352 26.3919C128.472 26.2424 128.457 26.0181 128.307 25.8985C126.812 24.6651 125.28 25.1584 125.205 25.1659C125.018 25.2257 124.921 25.4201 124.973 25.607C125.033 25.7938 125.227 25.891 125.407 25.8387C125.467 25.8163 126.663 25.4575 127.859 26.4442C127.956 26.519 128.076 26.5414 128.18 26.504H128.188Z" fill="#26374A" />
            <path d="M127.582 22.8559C127.582 22.8559 136.874 15.8365 127.448 14.0349C118.021 12.2334 110.202 27.1842 110.202 27.1842C115.524 24.2687 121.512 22.7737 127.582 22.8559Z" fill="#85CC91" />
            <path d="M127.582 22.8559C127.582 22.8559 136.874 15.8365 127.448 14.0349C118.021 12.2334 110.202 27.1842 110.202 27.1842C115.524 24.2687 121.512 22.7737 127.582 22.8559Z" fill="black" fillOpacity="0.2" />
            <path d="M140.543 89.4255L149.436 89.4256L152.524 86.5861C152.524 86.5861 157.231 86.8107 158.117 87.7768C159.003 88.7429 159.642 91.522 159.642 91.522C159.642 91.522 160.553 93.8516 158.32 94.7036C158.32 94.7036 159.371 96.8142 156.837 97.8521C156.837 97.8521 158.43 99.0636 155.807 99.7412C153.189 100.414 151.313 99.5553 149.868 98.8008C148.423 98.0463 146.916 97.4181 146.916 97.4181L139.974 96.4254L140.543 89.4255Z" fill="#FBC8C1" />
            <path d="M95.0412 73.2845C95.0412 73.2845 95.3435 93.7142 98.4094 96.1806C101.343 98.5367 149.076 96.1806 149.076 96.1806L154.765 89.778L108.485 88.6738L107.509 74.5787L95.0433 73.2917L95.0412 73.2845Z" fill="#FBC8C1" />
            <path d="M99.9857 52.9331C100.099 52.8946 95.0296 55.9779 94.4499 58.1876C93.0676 63.4419 91.9817 71.3867 92.2325 83.7686L110.411 83.5625L107.024 63.9751C103.556 57.2612 99.9885 52.9401 99.9885 52.9401L99.9857 52.9331Z" fill="#26374A" />
            <ellipse cx="340.466" cy="242.323" rx="13.8282" ry="14.8294" fill="#1E2B39" />
            <ellipse cx="338.589" cy="242.823" rx="13.4528" ry="14.3288" fill="#2B3B4D" />
            <ellipse opacity="0.1" cx="338.652" cy="242.886" rx="9.38568" ry="10.0114" fill="white" />
            <ellipse cx="219.453" cy="235.94" rx="13.9534" ry="15.9557" fill="#1E2B39" />
            <path d="M146.62 84.4603L152.001 82.4581L183.287 69.0052L365.119 74.0109V84.4603L353.856 92.5946L349.163 202.657L344.971 222.868L315.5 241.764L285.654 240.263L282.775 241.764L207.377 237.885L204.686 236.008L169.459 234.193L163.628 213.545L159.447 127.572H156.444V113.306L148.247 103.732V93.4706H146.62V84.4603Z" fill="#85CC91" />
            <path d="M331.456 99.2223L146.62 93.5909V84.7683L331.456 88.46L365.182 74.5692V84.7683L331.456 99.2223Z" fill="#85CC91" />
            <path d="M331.456 99.2223L146.62 93.5909V84.7683L331.456 88.46L365.182 74.5692V84.7683L331.456 99.2223Z" fill="white" fillOpacity="0.2" />
            <path opacity="0.1" d="M318.128 219.109L314.874 242.948L344.846 223.176L348.913 202.778L354.044 89.5863L330.767 99.7854H320.381L318.128 219.109Z" fill="black" />
            <path d="M159.447 94.2166L148.122 93.5283V103.79L156.319 113.113V127.755H160.824L159.447 94.2166Z" fill="#85CC91" />
            <path d="M159.447 94.2166L148.122 93.5283V103.79L156.319 113.113V127.755H160.824L159.447 94.2166Z" fill="black" fillOpacity="0.2" />
            <path d="M332.019 109.922V99.2223H320.256L319.442 132.76H323.009L323.885 118.682L332.019 109.922Z" fill="#85CC91" />
            <path d="M332.019 109.922V99.2223H320.256L319.442 132.76H323.009L323.885 118.682L332.019 109.922Z" fill="black" fillOpacity="0.2" />
            <path d="M174.149 105.806L174.544 94.4878L170.354 94.3418L174.149 105.806Z" fill="#85CC91" />
            <path d="M174.149 105.806L174.544 94.4878L170.354 94.3418L174.149 105.806Z" fill="black" fillOpacity="0.2" />
            <path d="M200.601 106.728L200.995 95.4097L196.805 95.2637L200.601 106.728Z" fill="#85CC91" />
            <path d="M200.601 106.728L200.995 95.4097L196.805 95.2637L200.601 106.728Z" fill="black" fillOpacity="0.2" />
            <path d="M227.052 107.65L227.447 96.3316L223.257 96.1855L227.052 107.65Z" fill="#85CC91" />
            <path d="M227.052 107.65L227.447 96.3316L223.257 96.1855L227.052 107.65Z" fill="black" fillOpacity="0.2" />
            <path d="M253.504 108.572L253.898 97.2534L249.709 97.1074L253.504 108.572Z" fill="#85CC91" />
            <path d="M253.504 108.572L253.898 97.2534L249.709 97.1074L253.504 108.572Z" fill="black" fillOpacity="0.2" />
            <path d="M279.956 109.494L280.35 98.1753L276.16 98.0293L279.956 109.494Z" fill="#85CC91" />
            <path d="M279.956 109.494L280.35 98.1753L276.16 98.0293L279.956 109.494Z" fill="black" fillOpacity="0.2" />
            <path d="M306.407 110.416L306.802 99.0972L302.612 98.9512L306.407 110.416Z" fill="#85CC91" />
            <path d="M306.407 110.416L306.802 99.0972L302.612 98.9512L306.407 110.416Z" fill="black" fillOpacity="0.2" />
            <path d="M314.937 234.188L169.021 227.618L170.46 234.126L204.812 236.253L207.878 237.88L282.775 241.384L285.403 240.133L313.748 241.384L314.937 234.188Z" fill="#85CC91" />
            <path d="M314.937 234.188L169.021 227.618L170.46 234.126L204.812 236.253L207.878 237.88L282.775 241.384L285.403 240.133L313.748 241.384L314.937 234.188Z" fill="black" fillOpacity="0.2" />
            <path d="M181.473 62.6181L158.572 72.129L152.752 80.7638V83.5169L331.956 87.4589L362.241 74.8195V72.3167L355.17 66.8104L310.432 64.3701L299.732 60.9913L215.762 59.427L209.254 62.6181H181.473Z" fill="#85CC91" />
            <path d="M181.473 62.6181L158.572 72.129L152.752 80.7638V83.5169L331.956 87.4589L362.241 74.8195V72.3167L355.17 66.8104L310.432 64.3701L299.732 60.9913L215.762 59.427L209.254 62.6181H181.473Z" fill="white" fillOpacity="0.2" />
            <path d="M201.933 78.824L157.883 77.5726V82.4532L331.018 86.0823V81.2017H274.141C250.914 75.5954 216.325 77.2806 201.933 78.824Z" fill="#85CC91" />
            <path d="M201.933 78.824L157.883 77.5726V82.4532L331.018 86.0823V81.2017H274.141C250.914 75.5954 216.325 77.2806 201.933 78.824Z" fill="black" fillOpacity="0.2" />
            <path d="M202.184 53.1073L197.616 62.493V63.6818H210.568V61.3667V53.1073H202.184Z" fill="#85CC91" />
            <path d="M202.184 53.1073L197.616 62.493V63.6818H210.568V61.3667V53.1073H202.184Z" fill="white" fillOpacity="0.2" />
            <path d="M239.414 54.1084C238.266 56.5279 235.897 61.3667 235.597 61.3667H246.171L244.982 54.1084H239.414Z" fill="#85CC91" />
            <path d="M239.414 54.1084C238.266 56.5279 235.897 61.3667 235.597 61.3667H246.171L244.982 54.1084H239.414Z" fill="white" fillOpacity="0.2" />
            <path d="M238.663 55.7979L209.504 54.7968V58.4259L237.349 59.0516L238.663 55.7979Z" fill="#85CC91" />
            <path d="M238.663 55.7979L209.504 54.7968V58.4259L237.349 59.0516L238.663 55.7979Z" fill="white" fillOpacity="0.2" />
            <path d="M310.932 55.9856L307.241 63.6819V65.0584H319.442V55.9856H310.932Z" fill="#85CC91" />
            <path d="M310.932 55.9856L307.241 63.6819V65.0584H319.442V55.9856H310.932Z" fill="white" fillOpacity="0.2" />
            <path d="M346.535 57.1119L343.219 66.2473V67.1233H355.859L354.795 57.1119H346.535Z" fill="#85CC91" />
            <path d="M346.535 57.1119L343.219 66.2473V67.1233H355.859L354.795 57.1119H346.535Z" fill="white" fillOpacity="0.2" />
            <path d="M345.972 58.4259L319.004 57.8002V61.2416L345.972 61.9299V58.4259Z" fill="#85CC91" />
            <path d="M345.972 58.4259L319.004 57.8002V61.2416L345.972 61.9299V58.4259Z" fill="white" fillOpacity="0.2" />
            <ellipse cx="189.92" cy="255.555" rx="15.5802" ry="15.6428" fill="#1E2B39" />
            <ellipse cx="315.688" cy="261.907" rx="15.5802" ry="15.6428" fill="#1E2B39" />
            <ellipse cx="187.792" cy="256.055" rx="15.2048" ry="15.1422" fill="#2B3B4D" />
            <ellipse cx="313.56" cy="262.408" rx="15.2048" ry="15.1422" fill="#2B3B4D" />
            <ellipse opacity="0.1" cx="187.792" cy="256.118" rx="10.5745" ry="10.5745" fill="white" />
            <ellipse opacity="0.1" cx="313.56" cy="262.471" rx="10.5745" ry="10.5745" fill="white" />
            <path d="M174.027 236.783V235.156V234.468L188.293 235.156V237.659H191.672V239.662H188.293L190.921 257.995H183.6L175.779 240.538L171.086 239.662V238.41L174.027 236.783Z" fill="#536B85" />
            <path d="M299.67 243.073V241.071L313.936 241.697V242.448V244.387H317.064V246.39H314.687L317.064 264.848H309.556L300.858 246.39H296.353C295.703 246.39 296.082 245.597 296.353 245.201L298.919 243.073H299.67Z" fill="#536B85" />
            <path d="M335.335 245.076L329.829 232.937L340.091 225.929L342.03 245.076H335.335Z" fill="#536B85" />
            <ellipse cx="237.78" cy="163.754" rx="46.0789" ry="46.0789" fill="#85CC91" />
            <ellipse cx="237.78" cy="163.754" rx="46.0789" ry="46.0789" fill="white" fillOpacity="0.65" />
            <path d="M211.206 167.67L231.48 185.747L261.892 141.512" stroke="#26374A" strokeWidth="9.21578" />
        </g>
        <defs>
            <clipPath id="clip0_2089_262263">
                <rect width="480" height="295" fill="white" />
            </clipPath>
        </defs>
    </svg>
);