import { useQuery } from "@tanstack/react-query";
import { ONE_DAY, convertToErrorMsg, jsonReviverFunction } from "./_base";
import { FaqItem } from "../types/FaqItem";

export const FAQ_QUERY_KEY = 'faq';

export const useFaqsQuery = () => {
    const query = useQuery<FaqItem[] | undefined, Error>({
        queryKey: [FAQ_QUERY_KEY],
        refetchOnWindowFocus: true,
        staleTime: ONE_DAY,
        queryFn: () => getFaqItems()
    })
    return query;
} 

export const getFaqItems = async (): Promise<FaqItem[] | undefined> => {
    const response = await fetch(`/api/help/faq`);
    const responseBody = await response.text();
    if (!response.ok) {
        const errorMsg = convertToErrorMsg(response.status, responseBody);
        throw errorMsg;
    } else {
        const responseJson = JSON.parse(responseBody, jsonReviverFunction);
        const faq = responseJson as FaqItem[];
        return faq;
    }
}
