import { useQuery } from "@tanstack/react-query";
import { ONE_DAY, convertToErrorMsg, jsonReviverFunction } from "./_base";
import { GuideItem } from "../types/GuideItem";

export const GUIDES_QUERY_KEY = 'guides';

export const useGuidesQuery = () => {
    const query = useQuery<GuideItem[] | undefined, Error>({
        queryKey: [GUIDES_QUERY_KEY],
        refetchOnWindowFocus: true,
        staleTime: ONE_DAY,
        queryFn: () => getGuides()
    })
    return query;
} 

export const getGuides = async (): Promise<GuideItem[] | undefined> => {
    const response = await fetch(`/api/help/guides`);
    const responseBody = await response.text();
    if (!response.ok) {
        const errorMsg = convertToErrorMsg(response.status, responseBody);
        throw errorMsg;
    } else {
        const responseJson = JSON.parse(responseBody, jsonReviverFunction);
        const faq = responseJson as GuideItem[];
        return faq;
    }
}
