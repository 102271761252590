import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useContext } from "react";
import { UserContext } from "../contexts/UserContext";
import { AccountRef } from "../types/Account";
import { useTranslation } from "react-i18next";
import { convertToErrorMsg, jsonReviverFunction } from "./_base";
import { PaymentMethod } from "../types/PaymentMethod";
import { HISTORY_LOG_QUERY_KEY } from "./historylog";
import { PaymentTransaction, PaymentTransactionProcess } from "../types/PaymentTransaction";

const PAYMENTS_QUERY_KEY = 'payments';

export type InitiatePaymentTransactionResult = {
    paymentTransactionId: string;
    paymentMethod: PaymentMethod;
    externalPaymentPageUrl?: string
}
export type InitiatePaymentTransactionInputParam = {
    transactionName: string;
    amountInChf: number;
    initiatingProcess: PaymentTransactionProcess;
    paymentMethod: PaymentMethod;
}
type InitiatePaymentTransactionInfo = {
    transactionName: string;
    initiatingProcess: PaymentTransactionProcess;
    amount: number;
    paymentMethod: PaymentMethod;
    language: string;
    redirectionUrlOnTransactionCompletion: string;
}
export type CompletePaymentTransactionInputParam = {
    paymentTransactionId: string;
}

export const getPaymentTransactionDownloadUrl = (transaction: PaymentTransaction) => {
    return transaction.hasDownloadableInvoice || transaction.hasDownloadableReceipt ? `/api/me/accounts/${transaction.accountId}/paymentTransactions/${transaction.id}/doc`: undefined;
}

export const useInvoicesAndReceiptQuery = () => {
    const userContext = useContext(UserContext);
    const accessPermission = userContext.accessPermission;
    const query = useQuery<PaymentTransaction[] | undefined, Error>({
        queryKey: [PAYMENTS_QUERY_KEY, accessPermission ? accessPermission.accountRef.id : '-'],
        refetchOnWindowFocus: true,
        staleTime: 30000,
        retry: 0,
        queryFn: () => getInvoicesAndReceipts(accessPermission?.accountRef.id)
    })
    return query;
}

export const getInvoicesAndReceipts = async (accountId: string | undefined): Promise<PaymentTransaction[] | undefined> => {
    if (!accountId) { return [];}
    const response = await fetch(`/api/me/accounts/${accountId}/invoicesAndReceipts`);
    const responseBody = await response.text();
    if (!response.ok) {
        const errorMsg = convertToErrorMsg(response.status, responseBody);
        throw errorMsg;
    } else {
        const responseJson = JSON.parse(responseBody, jsonReviverFunction);
        const containers = responseJson as PaymentTransaction[];
        return containers;
    }
}

export const usePaymentTransactionQuery = (paymentTransactionId: string | undefined) => {
    const userContext = useContext(UserContext);
    const accessPermission = userContext.accessPermission;
    const query = useQuery<PaymentTransaction | 'unavailable', Error>({
        queryKey: [PAYMENTS_QUERY_KEY, paymentTransactionId],
        refetchOnWindowFocus: false,
        retry: 0,
        queryFn: () => getPaymentTransation(accessPermission?.accountRef.id, paymentTransactionId)
    })
    return query;
}

export const getPaymentTransation = async (accountId: string | undefined, paymentTransactionId: string | undefined ): Promise<PaymentTransaction | 'unavailable'> => {
    if (!accountId) { return 'unavailable';}
    if (!paymentTransactionId) { return 'unavailable';}
    const response = await fetch(`/api/me/accounts/${accountId}/payment-transactions/${paymentTransactionId}`);
    const responseBody = await response.text();
    if (!response.ok) {
        const errorMsg = convertToErrorMsg(response.status, responseBody);
        throw errorMsg;
    } else {
        const responseJson = JSON.parse(responseBody, jsonReviverFunction);
        const transaction = responseJson as PaymentTransaction;
        return transaction;
    }
}


export const useInitPaymentTransactionMutation = () => {
    const queryClient = useQueryClient();
    const { i18n } = useTranslation();
    const userContext = useContext(UserContext);
    const mutation = useMutation<InitiatePaymentTransactionResult | null, Error, InitiatePaymentTransactionInputParam>({
        mutationFn: (initiationInfo: InitiatePaymentTransactionInputParam) => initiatePaymentTransaction(initiationInfo, i18n.language, userContext.accessPermission?.accountRef),
        onSuccess: (result) => {
            queryClient.invalidateQueries({queryKey: [HISTORY_LOG_QUERY_KEY]});
            queryClient.invalidateQueries({queryKey: [PAYMENTS_QUERY_KEY]});
        }
    })
    return mutation;
}

const initiatePaymentTransaction = async (initiationInfo: InitiatePaymentTransactionInputParam, language: string, accountRef?: AccountRef): Promise<InitiatePaymentTransactionResult | null> => {
    if (!accountRef) return null;
    const payload: InitiatePaymentTransactionInfo = {
        language: language,
        initiatingProcess: initiationInfo.initiatingProcess,
        paymentMethod: initiationInfo.paymentMethod,
        amount: initiationInfo.amountInChf,
        transactionName: initiationInfo.transactionName,
        redirectionUrlOnTransactionCompletion: window.location.origin + window.location.pathname,
    };
    const response = await fetch(`/api/me/accounts/${accountRef.id}/payment-transactions`, {
        method: 'post',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(payload)
    });
    const responseBody = await response.text();
    if (!response.ok) {
        const errorMsg = convertToErrorMsg(response.status, responseBody);
        throw errorMsg;
    } else {
        return (JSON.parse(responseBody) as InitiatePaymentTransactionResult);
    }
}

export const useCompletePaymentTransactionMutation = () => {
    const userContext = useContext(UserContext);
    const mutation = useMutation<void, Error, CompletePaymentTransactionInputParam>({
        mutationFn: (completionInfo: CompletePaymentTransactionInputParam) => completePaymentTransaction(completionInfo, userContext.accessPermission?.accountRef),
    })
    return mutation;
}

const completePaymentTransaction = async (completionInfo: CompletePaymentTransactionInputParam, accountRef?: AccountRef): Promise<void> => {
    if (!accountRef) throw new Error("No Account Defined");
    const response = await fetch(`/api/me/accounts/${accountRef.id}/payment-transactions/${completionInfo.paymentTransactionId}/markAsCompleted`, {
        method: 'post',
        headers: {
            "Content-Type": "application/json",
        }
    });
    const responseBody = await response.text();
    if (!response.ok) {
        const errorMsg = convertToErrorMsg(response.status, responseBody);
        throw errorMsg;
    } 
}