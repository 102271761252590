import styled from "@emotion/styled";
import { TextIconButton } from "../../../../components/StyledComponents";
import { IconArrowRight, IconInfoCircleBig, IconQuestionCircleLineLarge } from "../../../../components/Icons";
import { NavLink } from "react-router-dom";
import { LinkboxData } from "./LinkboxData";
import { styles } from "../../../../styles";


type LinkboxProps = {
    item: LinkboxData,
}

const StyledInfobox = styled('div')<LinkboxProps>(({ item }) => ({
    '@media (max-width: 700px)': {
        width: '100%',

        'p': {
            fontSize: '12px'
        },

        'button': {
            fontSize: '12px'
        }
    },

    display: 'flex',
    width: 'calc(50% - 12px)',
    backgroundColor: item.type === 'help' ? '#F1F5FE' : '#FFF',
    borderRadius: '8px',
    padding: '24px 24px 24px 24px',
    marginBottom: '0',
    cursor: 'pointer',
    position: 'relative',
    top: 0,
    right: 0,
    transition: 'all 0.1s ease-in',

    ':hover': {
        boxShadow: styles.boxShadowBottomLight,
        top: '-8px',
    },

    'button': {
        'svg': {
            transition: '.1s ease-in'
        },

        ':hover': {
            'span': {
                borderBottom: `1px solid ${styles.textColorSecondary900}`,
            },
            'svg': {
                transform: 'translateX(50%)',
            }
        }
    },

    'p': {
        marginBottom: '11px',
        maxWidth: '446px'
    },
}));

const InfoboxTextWrapper = styled.div`
    display: flex;

    svg {
        width: 48px;
        margin-right: 12px;
        position: relative;
        top: -2px;
    }
`;

const InfoCircleContainer = styled.div`
    margin-right: 12px;
`;

export const Linkbox: React.FC<LinkboxProps> = ({ item }) => {
    return (
        <StyledInfobox item={item}>
            {item.type === 'help' && <InfoCircleContainer><IconQuestionCircleLineLarge /></InfoCircleContainer>}
            {
                item.onClick.route ?
                <NavLink to={item.onClick.route.target} state={item.onClick.route.state}>
                    <div>
                        <InfoboxTextWrapper>
                            <p>{item.infoText}</p>
                        </InfoboxTextWrapper>
                        <TextIconButton bold svgMargin='0 0 0 8px'><span>{item.buttonText}</span><IconArrowRight /></TextIconButton>
                    </div>
                </NavLink>
                :
                <div onClick={item.onClick.action}>
                    <div>
                        <InfoboxTextWrapper>
                            <p>{item.infoText}</p>
                        </InfoboxTextWrapper>
                        <TextIconButton bold svgMargin='0 0 0 8px'><span>{item.buttonText}</span><IconArrowRight /></TextIconButton>
                    </div>
                </div>
            }
        </StyledInfobox>
    )
}