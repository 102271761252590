import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import Button from "../../../../components/Button";
import { IconContainerFilled } from "../../../../components/Icons";
import { useAccountQuery } from "../../../../data/accounts";
import { useContext } from "react";
import { UserContext } from "../../../../contexts/UserContext";
import { useEmptyingPricesQuery } from "../../../../data/prices";
import { UseQueryResult } from "@tanstack/react-query";
import { Account } from "../../../../types/Account";
import { styles } from "../../../../styles";
import Modal from "../../../../components/Modal";
import { PaymentStep } from "../../../../components/payment/PaymentStep";
import { usePaymentManager } from "../../../../components/payment/usePaymentManager";
import { Loader } from "../../../../components/Loader";
import { PaymentTransactionProcess } from "../../../../types/PaymentTransaction";
import { Toast } from "../../../../components/Toast";
import { usePaymentTransactionQuery } from "../../../../data/payments";
import { StateCode } from "../../../../types/StateCode";
import { PaymentAlmostDoneIllustration } from "../../../../components/payment/PaymentAlmostDoneIllustration";


const StyledSection = styled.section`
    grid-column: span 8;
    color: ${styles.textColorSecondary700};
`;

const StyledH2 = styled.h2`
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 24px;
`;

const CreditBox = styled.div`
    padding: 24px;
    border: 1px solid ${styles.neutral200};
    border-radius: 8px;
    max-width: 536px;
    
    @media (max-width: 800px) {
        max-width: 480px;
	}

    button {
        width: 156px;
        height: 44px;
        margin-bottom: 0;
    }
`;

const TopRow = styled.div`
    font-family: "Satoshi Bold";
    font-weight: 700;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;

    span:first-of-type {
        margin-right: 8px;
    }

    @media (max-width: 480px) {
        justify-content: space-between;
    }
`;

const BottomRow = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    @media (max-width: 480px) {
        flex-direction: column;

        div {
            margin-top: 10px;
            margin-bottom: 26px;
        }

        button {
            width: 100%;
        }
    }

    div {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    
    svg {
        margin-right: 8px;
    }
`

const Currency = styled.span``;

const Amount = styled.span`
    font-size: 18px;
    line-height: 26px;
`;

const calculateAvailableCreditInNofEmptyings = (credit: number, emptyingPrice: number) => {
    return parseInt('' + (credit / emptyingPrice));
}

const CreditAmountInCHF = ({ isLoading, isError, credit }: { isLoading: boolean; isError: boolean; credit: number }) => {
    if (isLoading) {
        return <Loader width={"initial"} />
    }
    if (isError) {
        return <>-</>;
    }
    return (
        <>
            <Amount>{credit}.-</Amount>
        </>
    )
}

const CreditAmountNofEmptyings = ({ accountQuery }: { accountQuery: UseQueryResult<Account | null, Error> }) => {
    const priceConfigsQuery = useEmptyingPricesQuery();
    const { t } = useTranslation();

    if (accountQuery.isLoading || priceConfigsQuery.isLoading) {
        return <Loader width={"50px"} />
    }
    if (accountQuery.isError) {
        console.log("Error in CreditAmountNofEmptyings", accountQuery.error);
        return <>-</>;
    }
    if (priceConfigsQuery.isError || priceConfigsQuery.data === undefined || priceConfigsQuery.data.length === 0) {
        console.log("Error in CreditAmountNofEmptyings", priceConfigsQuery.error);
        return <>-</>;
    }
    const credit = accountQuery.data?.bookableCredit || 0;
    const date = new Date();
    const priceConfig = (priceConfigsQuery.data || []).find(cfg => cfg.endDate.getTime() >= date.getTime() && cfg.startDate.getTime() <= date.getTime())
    const price = priceConfig ? priceConfig.price : undefined;

    return (
        <>
            {credit !== undefined && price !== undefined &&
                <p>{calculateAvailableCreditInNofEmptyings(credit, price)} {t("pages.home.creditSection.creditInfoCard.emptyings")}</p>
            }
        </>
    )
}

export const CreditSection = () => {
    const [isTopUpModalOpened, setIsTopUpModalOpened] = useState(false);
    const [paymentTransactionToBeCompleted, setPaymentTransactionToBeCompleted] = useState<string>();
    const onTopUpModalClosed = () => setIsTopUpModalOpened(false);
    const paymentTransactionQuery = usePaymentTransactionQuery(paymentTransactionToBeCompleted);
    const { accessPermission } = useContext(UserContext);
    const accountQuery = useAccountQuery(accessPermission?.accountRef.id);
    const { t } = useTranslation();
    const [toastInfos, setToastInfos] = useState<{ message: string, type: "success" | "error" | "info" }>();

    const onBeforeLeavingToExternalPayment = (paymentId: string) => {
        console.log("onBeforeLeavingToExternalPayment", paymentId);
    }

    const onAfterExternalPayment = async (paymentId: string, outcome: 'success' | 'failure'): Promise<void> => {
        console.log("onAfterExternalPayment", { paymentId, outcome });
        if (outcome === 'success') {
            setPaymentTransactionToBeCompleted(paymentId);
        }
        if (outcome === 'failure') {
            setToastInfos({ message: t("toasts.topUpFailed"), type: 'info' });
        }
    }

    useEffect(() => {
        let timeoutHandler: any | undefined = 0;
        if (paymentTransactionToBeCompleted) {
            if (paymentTransactionQuery.data !== 'unavailable' && paymentTransactionQuery.data?.completionDate) {
                setPaymentTransactionToBeCompleted(undefined);
                accountQuery.refetch();
                setToastInfos({ message: t("toasts.topUpSucceeded"), type: 'success' });
            } else {
                timeoutHandler = setTimeout(() => {
                    console.log("refetching")
                    paymentTransactionQuery.refetch()
                }, 1000);
            }
        }
        return () => {
            if (timeoutHandler) clearTimeout(timeoutHandler);
        }
    });

    const onAfterNonExternalPayment = (paymentTransactionId: string, paymentMethod: string) => {
        console.log("Non-External Payment", { paymentTransactionId, paymentMethod });
    }

    const externalPaymentMgr = usePaymentManager(onBeforeLeavingToExternalPayment, onAfterExternalPayment, onAfterNonExternalPayment);

    if (!externalPaymentMgr.initialized) return <></>;

    return (
        <>
            <StyledSection>
                <StyledH2>{t("pages.balances.credit")}</StyledH2>
                <CreditBox>
                    <TopRow>
                        <Currency>
                            {t("pages.home.creditSection.creditInfoCard.currency")}
                        </Currency>
                        <CreditAmountInCHF isLoading={accountQuery.isLoading} isError={accountQuery.isError} credit={accountQuery.data?.bookableCredit || 0} />
                    </TopRow>
                    <BottomRow>
                        <div>
                            <IconContainerFilled />
                            <CreditAmountNofEmptyings accountQuery={accountQuery} />
                        </div>
                        <Button disabled={!accessPermission?.roles?.payment || accountQuery.data?.creditProcessing} onClick={() => setIsTopUpModalOpened(!isTopUpModalOpened)}>{t("pages.home.creditSection.creditInfoCard.buttonText")}</Button>
                    </BottomRow>
                </CreditBox>
            </StyledSection>
            {isTopUpModalOpened && !accountQuery.data?.creditProcessing &&
                <Modal onClose={onTopUpModalClosed}>
                    <PaymentStep
                        enableCreditUsage={false}
                        enableInvoice={true}
                        process={PaymentTransactionProcess.TopUp}
                        orderItemName={t('pages.home.creditSection.topUpOrderLineText')}
                        paymentMgr={externalPaymentMgr}
                        onCancel={onTopUpModalClosed}
                        texts={{
                            title: t("pages.home.creditSection.topUpModal.header"),
                            priceFieldLabel: t("pages.home.creditSection.topUpModal.topUpAmount"),
                            paymentMethodFieldLabel: t("pages.home.creditSection.topUpModal.meansOfPayment")
                        }}
                    />
                </Modal>
            }
            {paymentTransactionToBeCompleted &&
                <Modal onClose={() => { }}>
                    <PaymentAlmostDoneIllustration />
                </Modal>
            }
            {toastInfos && <Toast message={toastInfos.message} type={toastInfos.type} onCloseComplete={() => setToastInfos(undefined)} />}
        </>
    )
}