import { useContext } from "react";
import { UserContext } from "../contexts/UserContext";
import styled from "@emotion/styled";
import { styles } from "../styles";
import { IconLogout } from "./Icons";
import { useTranslation } from "react-i18next";

const Button = styled.button`
    flex: 1;
    display: flex;
    align-items: center;
    padding: 0;
    font-size: 0.875rem;
    font-family: 'Satoshi Regular';
    color: ${styles.textColorSecondary700};
    cursor: pointer;
    border: none;
    background: transparent;

    svg {
        margin-right: 10px;
    }
`;

export const LoginLogout = () => {
    const userContext = useContext(UserContext);
    const currentUser = userContext.loggedInUser;
    const { t } = useTranslation();
    return (
        <>
            {!currentUser && <div style={{display: 'flex'}}><Button type="button" value={t('navigation.top.login')} onClick={() => userContext.login()} ><IconLogout/>{t('navigation.top.login')}</Button></div>}
            {currentUser && <div style={{display: 'flex'}}><Button type="button" value={t('navigation.top.logout')} onClick={() => userContext.logout()}><IconLogout/>{t('navigation.top.logout')}</Button></div>}
        </>
    )
}