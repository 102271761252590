import { useContext, useState, useCallback } from "react";
import { UserContext } from "../../contexts/UserContext";
import { InvitationForm } from "./components/invitation-form/InvitationForm";
import { EmployeesList } from "./components/employees-list/EmployeesList";
import Modal from '../../components/Modal';
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { TextIconButton } from "../../components/StyledComponents";
import { IconPlusCircle } from "../../components/Icons";
import { styles } from "../../styles";
import { InvitationSentModalContent } from "./components/invitation-form/InvitationSentModalContent";
import { useContainersQuery } from "../../data/containers";
import { ContainerStatusReason } from "../../types/Container";
import { Tooltip } from "@mui/material";
import { useLocation } from "react-router-dom";


const StyledH1 = styled.h1`
    margin-bottom: 48px;
`;

const StyledTextIconButton = styled(TextIconButton)`
    color: ${props => props.disabled ? styles.neutral200 : "#26374A"};
    svg {
        circle, path {
            stroke: ${props => props.disabled ? styles.neutral200 : "#26374A"};
        }
    }
    cursor: ${props => props.disabled ? "not-allowed" : "pointer"};
`;

export const OrganisationOverviewPage = () => {
    const userContext = useContext(UserContext);
    
    const {state} = useLocation();
    const [isAddingInvitation, setIsAddingInvitation] = useState(state ? state === 'auto-open-new-dlg': false);
    const [isInvitationSuccessful, setIsInvitationSuccessful] = useState(false);
    const containersQuery = useContainersQuery(userContext.accessPermission?.accountRef);
    const { t } = useTranslation();

    const handleInvitationSuccess = useCallback(() => {
        setIsAddingInvitation(false); 
        setIsInvitationSuccessful(true);
    }, []);

    const onInvitationFormClose = useCallback(() => {
        setIsAddingInvitation(false);
    }, []);

    const addingDisabled = isAddingInvitation || !containersQuery.data || containersQuery.data.filter(c => c.statusReason === ContainerStatusReason.Active).length === 0;
    const addingButtonTooltip = !addingDisabled ? t("pages.myOrganization.addEmployees"): isAddingInvitation ? t('pages.myOrganization.creatingInvitation') : t('pages.myOrganization.addingEmployeesNotPossibleDueToNoActiveContainer');
    return (
        <>
            <StyledH1>{t("pages.myOrganization.header")}</StyledH1>
            <EmployeesList />
            {userContext.accessPermission?.roles?.permission &&
                <>
                    <StyledTextIconButton disabled={addingDisabled} onClick={() => setIsAddingInvitation(true)} bold svgMargin='0 4px 0 0'><IconPlusCircle /> 
                        
                            <Tooltip arrow componentsProps={{
                                tooltip: {
                                    sx: {
                                        bgcolor: '#26374A',
                                        '& .MuiTooltip-arrow': {
                                            color: '#26374A !important',
                                        },
                                    },
                                },
                            }} title={addingButtonTooltip}>
                            <span>{t("pages.myOrganization.addEmployees")}</span>
                        </Tooltip>
                    </StyledTextIconButton>
                    
                    {isAddingInvitation && <Modal onClose={onInvitationFormClose}><InvitationForm onClose={onInvitationFormClose} onSuccess={handleInvitationSuccess} /></Modal>}
                    {isInvitationSuccessful && <Modal onClose={() => setIsInvitationSuccessful(false)}><InvitationSentModalContent onClose={() => setIsInvitationSuccessful(false)} /></Modal>}
                </>
            }
        </>
    )
}
