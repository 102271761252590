import React from 'react';
import { NavLink } from "react-router-dom";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { IconArrowLeft } from "./Icons";
import { ROUTES } from "../skeleton/Routes";
import { styles } from "../styles";

const HomeButtonWrapper = styled.div`
	display: flex;
	align-items: center;
	font-family: 'Satoshi Bold';
	color: ${styles.textColorSecondary700};
	cursor: pointer;

	a {
		display: flex;
		align-items: center;
		gap: 8px;
	}
`;

export const HomeButton = () => {
	const { t } = useTranslation();

	return (
		<HomeButtonWrapper>
			<NavLink to={ROUTES.Home}>
				<IconArrowLeft />
				{t("buttons.home")}
			</NavLink>
		</HomeButtonWrapper>
	)
}