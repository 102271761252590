import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { styles } from "../../../styles";
import { IconClose } from "../../../components/Icons";
import Button from '../../../components/Button';
import { TranslationWithBoldedText } from "../../home/components/TranslationWithBoldedText";
import { Todo } from "../../../components/Todo";
import { useEmptyingsOverviewQuery } from "../../../data/emptyings";
import { ONE_DAY } from "../../../data/_base";
import { useDeleteContainertMutation } from "../../../data/containers";
import { Container } from "../../../types/Container";
import { Loader } from "../../../components/Loader";
import { MessageWrapper } from "../../../components/MessageWrapper";
import { useState } from "react";


type DeleteContainerModalProps = {
    container: Container;
    onClose: () => void;
}

const ModalContentWrapper = styled.div`
    padding-right: 24px;
    padding-top: 24px;

    h2 {
        margin-bottom: 36px;
    }

    @media (max-width: 480px) {
        display: flex;
        flex-direction: column;
        align-items: space-between;
        height: 100%;
    }
`;

const ModalHeading = styled.h2`
    margin-bottom: 48px;
    color: ${styles.textColorSecondary700};
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg {
        cursor: pointer;
    }
`;

const BottomRow = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    margin-top: 48px;

    button {
        width: 156px;
    }

    @media (max-width: 480px) {
        margin-top: auto;
        flex-direction: column;

        button {
            width: 100%;
            margin-bottom: 0;
        }
    }
`;


export const DeleteContainerModalContent: React.FC<DeleteContainerModalProps> = ({ container, onClose }) => {
    const { t } = useTranslation();
    const emptyingsQuery = useEmptyingsOverviewQuery(new Date(), new Date(new Date().getTime() + 400 * ONE_DAY), container);
    const deleteContainerMutation = useDeleteContainertMutation();
    const [hasMutationError, setHasMutationError] = useState(false);

    const onDelete = async () => {
        try {
            await deleteContainerMutation.mutateAsync(container);
            onClose();
        } catch {
            setHasMutationError(true);
        }
    }

    const isError = emptyingsQuery.isError || hasMutationError;
    const isLoading = !isError && (emptyingsQuery.isLoading || deleteContainerMutation.isLoading);    
    const hasScheduledEmptyings = emptyingsQuery.data && emptyingsQuery.data.length > 0;

    return (
        <ModalContentWrapper>
            <ModalHeading>
                {t("pages.myContainers.deleteContainersModal.deleteContainer")}
                <div onClick={onClose}>
                    <IconClose />
                </div>
            </ModalHeading>

            {isLoading && !isError && (
                <Loader height={"126px"} />
            )}

            {isError && emptyingsQuery.isError && (
                <MessageWrapper
                    messageText={emptyingsQuery.error.toString()}
                    height={"126px"}
                />
            )}

            {isError && deleteContainerMutation.isError && (
                <MessageWrapper
                    messageText={deleteContainerMutation.error.toString()}
                    height={"126px"}
                />
            )}

            {hasScheduledEmptyings && (
                <MessageWrapper
                    messageText={t("pages.myContainers.deleteContainersModal.scheduledEmptyingFound")}
                    messageType={"userMessage"}
                    height={"126px"}
                />
            )}

            {!isLoading && !isError && !hasScheduledEmptyings && (
                <>
                    <div>
                        <TranslationWithBoldedText textValue={container.displayName} i18nKey="pages.myContainers.deleteContainersModal.doYouReallyWantToDeleteContainer" />
                    </div>

                    <BottomRow>
                        <Button buttonVariant="ghost" onClick={() => onClose()}>
                            {t("buttons.no")}
                        </Button>
                        <Button onClick={onDelete}>
                            {t("buttons.yes")}
                        </Button>
                    </BottomRow>
                </>
            )}
        </ModalContentWrapper>
    );
};