import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { UserContext } from "../contexts/UserContext";
import { AccessPermission } from "../types/AccessPermission";
import { convertToErrorMsg, jsonReviverFunction } from "./_base";
import { HistoryLogItem } from "../types/HistoryLogItem";
import { PagedResultSet } from "../types/PagedResultSet";

export const HISTORY_LOG_QUERY_KEY = "invitations";

export type SortingInfo = {
    field?: 'created' | 'type' | 'amount' | 'bookableCredit';
    type?: 'asc' | 'desc';
}
type HistoryLogResultType = 'credit-mutations' |'blaa';

export const useHistoryLogQuery = (type: HistoryLogResultType, pageSize: number, sortingInfo: SortingInfo, skipToken?: string) => {
    const userContext = useContext(UserContext);
    const accessPermission = userContext.accessPermission;
    const query = useQuery<PagedResultSet<HistoryLogItem> | null, Error>({
        queryKey: [HISTORY_LOG_QUERY_KEY, accessPermission?.accountRef.id, sortingInfo, skipToken],
        refetchOnWindowFocus: true,
        staleTime: 30000,
        retry: false,
        queryFn: () => getHistoryLogItems(type, pageSize, sortingInfo, accessPermission, skipToken)
    })
    return query;
}

const getHistoryLogItems = async (type: HistoryLogResultType, pageSize: number, sortingInfo: SortingInfo, accessPermission?: AccessPermission, skipToken?: string): Promise<PagedResultSet<HistoryLogItem>> => {
    if (!accessPermission) return { skipToken: '', totalCount: 0, rows: []};
    let url = `/api/me/accounts/${accessPermission.accountRef.id}/history-log/${type}?pageSize=${pageSize}&sortField=${sortingInfo.field}&sortType=${sortingInfo.type}`;
    if (skipToken) {
        url = `${url}&skipToken=${skipToken}`
    }
    const response = await fetch(url);
    const responseBody = await response.text();
    if (!response.ok) {
        const errorMsg = convertToErrorMsg(response.status, responseBody);
        throw errorMsg;
    } else {
        const responseJson = JSON.parse(responseBody, jsonReviverFunction);
        const invitations = responseJson as PagedResultSet<HistoryLogItem>;
        return invitations;
    }
}
