import { useContext, useState } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { styles } from "../../../../styles";
import { IconChangeData, IconQuestionCircleLineSmall } from "../../../../components/Icons";
import { TextIconButton } from "../../../../components/StyledComponents";
import Tooltip from '@mui/material/Tooltip';
import Modal from "../../../../components/Modal";
import { RequestDataChangeModalContent } from "./RequestDataChangeModalContent";
import { Toast } from "../../../../components/Toast";
import { useAccountQuery } from "../../../../data/accounts";
import { UserContext } from "../../../../contexts/UserContext";
import { Loader } from "../../../../components/Loader";
import { MessageWrapper } from "../../../../components/MessageWrapper";


const StyledSection = styled.section`
    @media (max-width: 480px) {
        width: 100%;
        min-width: unset;
        margin-bottom: 0;
    }

    width: 50%;
    min-width: 425px;

    h2 {
        margin-bottom: 24px;
        display: flex;
        align-items: center;
        gap: 8px;

        svg {
            cursor: pointer;
        }
    
        @media (max-width: 480px) {
            margin-bottom: 12px;
        }
    }
`;

const IconWrapper = styled.div`
    display: flex;
`;

const FormRow = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    color: ${styles.textColorSecondary700};

    @media (max-width: 480px) {
        margin-bottom: 12px;
    }
`;

const BottomRow = styled.div`
    display: flex;
    justify-content: flex-start;
    gap: 12px;
    margin-top: 48px;
`;

const StyledH3 = styled.h4`
    font-size: 10px;
    line-height: 18px;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    gap: 10px;

    svg {
        cursor: pointer;
    }
`;

const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-right: 12px;

    &:last-child {
        margin-right: 0;
    }
`;

const InputContainerFull = styled(InputContainer)`
    flex-grow: 1;
`;

const MobileContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

type StyledInputProps = {
    hasError?: boolean;
};

const StyledInput = styled.input<StyledInputProps>`
    padding: 10px 16px;
    flex: 1;
    box-shadow: ${styles.boxShadowInset};
    background-color: #fff;
    border: 1px solid ${props => props.hasError ? '#ed2b2b' : styles.neutral200};
    border-radius: 4px;
    box-sizing: border-box;
    line-height: 22px;
    font-size: 14px;
    color: ${styles.textColorSecondary700};
    font-family: 'Satoshi Regular';
    max-height: 44px;

    &:focus {
        border: 1px solid #3371EA;
        outline: none;
    }

    &:disabled {
        background-color: #f5f5f5;
    }
`;

const StyledTextIconButton = styled(TextIconButton)`
    color: ${props => props.disabled ? styles.neutral200 : "#26374A"};
    svg {
        circle, path {
            stroke: ${props => props.disabled ? styles.neutral200 : "#26374A"};
        }
    }
    cursor: ${props => props.disabled ? "not-allowed" : "pointer"};
`;

export const CompanyData = () => {
    const { t } = useTranslation();
    const userContext = useContext(UserContext);
    const accountQuery = useAccountQuery(userContext.accessPermission?.accountRef.id);
    const [isRequestDataChangeModalOpened, setIsRequestDataChangeModalOpened] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const onRequestDataChangeModalCancelled = () => setIsRequestDataChangeModalOpened(false);
    
    const onRequestSent = () => {
        setShowToast(true);
        setIsRequestDataChangeModalOpened(false);
    };

    const handleToastCloseComplete = () => {
        setShowToast(false);
    };

    return (
        <>
            <StyledSection>
                <h2>
                    {t("pages.myData.companyData.header")}
                    <Tooltip arrow componentsProps={{
                        tooltip: {
                            sx: {
                                bgcolor: '#26374A',
                                '& .MuiTooltip-arrow': {
                                    color: '#26374A',
                                },
                            },
                        },
                    }} title={t("pages.myData.companyData.tooltip")}>
                        <IconWrapper>
                            <IconQuestionCircleLineSmall />
                        </IconWrapper>
                    </Tooltip>
                </h2>
                { accountQuery.isLoading && 
                    // RESOLVED: make loading nice
                    <Loader height={"426px"} />
                }
                { accountQuery.isError && 
                    // RESOLVED: make error nice
                    <MessageWrapper messageText={accountQuery.error.message} svgMargin={"0 12px 0 0"} height={"60px"} justifyContent="flex-start" />
                }
                { !accountQuery.isLoading && !accountQuery.isError && accountQuery.data && 
                    <form>
                        <FormRow>
                            <StyledH3>{t("pages.myData.companyData.customerNumber")}</StyledH3>
                            <StyledInput
                                type="text"
                                value={accountQuery.data.number || ''}
                                disabled
                            />
                        </FormRow>
                        <FormRow>
                            <StyledH3>{t("pages.myData.companyData.company")}</StyledH3>
                            <StyledInput
                                type="text"
                                value={accountQuery.data.name || ''}
                                disabled
                            />
                        </FormRow>
                        <FormRow>
                            <MobileContainer>
                                <InputContainerFull>
                                    <StyledH3>{t("pages.myData.companyData.street")}</StyledH3>
                                    <StyledInput
                                        type="text"
                                        value={accountQuery.data.street || ''}
                                        disabled
                                    />
                                </InputContainerFull>
                                <InputContainer style={{ width: '92px' }}>
                                    <StyledH3>{t("pages.myData.companyData.no")}</StyledH3>
                                    <StyledInput
                                        type="text"
                                        value={accountQuery.data.houseNumber || ''}
                                        disabled
                                    />
                                </InputContainer>
                            </MobileContainer>
                        </FormRow>
                        <FormRow>
                            <MobileContainer>
                                <InputContainerFull>
                                    <StyledH3>{t("pages.myData.companyData.location")}</StyledH3>
                                    <StyledInput
                                        type="text"
                                        value={accountQuery.data.city || ''}
                                        disabled
                                    />
                                </InputContainerFull>
                                <InputContainer style={{ width: '92px' }}>
                                    <StyledH3>{t("pages.myData.companyData.postcode")}</StyledH3>
                                    <StyledInput
                                        type="text"
                                        value={accountQuery.data.postalCode || ''}
                                        disabled
                                    />
                                </InputContainer>
                            </MobileContainer>
                        </FormRow>
                        <FormRow>
                            <MobileContainer>
                                <InputContainerFull>
                                    <StyledH3>{t("pages.myData.companyData.email")}</StyledH3>
                                    <StyledInput
                                        type="text"
                                        value={accountQuery.data.email || ''}
                                        disabled
                                    />
                                </InputContainerFull>
                                <InputContainer style={{ width: '40%' }}>
                                    <StyledH3>{t("pages.myData.companyData.phone")}</StyledH3>
                                    <StyledInput
                                        type="text"
                                        value={accountQuery.data.phone || ''}
                                        disabled
                                    />
                                </InputContainer>
                            </MobileContainer>
                        </FormRow>
                        <BottomRow>
                            <StyledTextIconButton onClick={() => setIsRequestDataChangeModalOpened(true)} disabled={false} type="button" bold svgMargin='0 4px 0 0'><IconChangeData /> {t("pages.myData.companyData.requestDataChange")}</StyledTextIconButton>
                        </BottomRow>
                    </form>
                }  
            </StyledSection>
            {isRequestDataChangeModalOpened && accountQuery.data &&
                <Modal onClose={onRequestDataChangeModalCancelled}>
                    <RequestDataChangeModalContent onCancel={onRequestDataChangeModalCancelled} account={accountQuery.data} onRequestSent={onRequestSent} />
                </Modal>
            }
             {showToast && <Toast message={t("toasts.dataChangeRequested")} type="success" onCloseComplete={handleToastCloseComplete} />}
        </>
    )
};