import styled from "@emotion/styled";

type ContainerCardProps = {
    width: number;
    marginRight?: string;
    resizable?: boolean;
}

export const ContainerCard = styled.div<ContainerCardProps>`
    width: ${props => props.resizable ? 'auto' : `${props.width}px`};
    max-width: ${props => props.resizable ? `${props.width}px` : 'auto'};
    margin-right: ${props => props.marginRight ? `${props.marginRight}px` : '24px'};
`;