import styled from '@emotion/styled';
import { useTranslation } from "react-i18next";
import { styles } from '../../styles';
import { GlobalStyles } from '@mui/material';
import SatoshiRegularTtf from '../../fonts/Satoshi-Regular.ttf';
import SatoshiBoldTtf from '../../fonts/Satoshi-Bold.ttf';
import { GenericErrorImage } from './GenericErrorImage';
import Button from '../../components/Button';


const ContentWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin: 124px auto 0;
	max-width: 500px;

    svg {
        width: 100%;
        max-width: 475px;
        height: auto;
    }
`;

const MainSection = styled.section`
    h1 {
        margin-top: 64px;
		font-family: 'Satoshi Bold';
        color: ${styles.textColorSecondary700};

        @media (max-width: 480px) {
            margin-top: 48px;
        }
    }

    p {
        max-width: 500px;
		font-family: 'Satoshi Regular';
        color: ${styles.textColorSecondary700};
		font-weight: 400 !important;
    }
`;

const BottomRow = styled.div`
    display: flex;
    justify-content: flex-end;

	align-self: flex-end;
    gap: 12px;
    margin-top: 48px;
    max-width: 500px;

    button {
        width: 156px;
    }

    @media (max-width: 480px) {
        flex-direction: column;
        align-items: flex-end;
        width: 100%;

        button {
            width: 100%;
            margin-bottom: 0;
        }
    }
`;

type GenericAppErrorPageProps = {
    message: string;
}
export const GenericAppErrorPage = ({ message }: GenericAppErrorPageProps) => {

    const { t } = useTranslation();
    // RESOLVED: design the whole page

    return (
        <>
            <GlobalStyles styles={`
                @font-face {
                    font-family: 'Satoshi Regular';
                    src: url(${SatoshiRegularTtf}) format('truetype');
                    font-weight: 400;
                    font-style: normal;
                }
                
                @font-face {
                    font-family: 'Satoshi Bold';
                    src: url(${SatoshiBoldTtf}) format('truetype');
                    font-weight: 700;
                    font-style: normal;
                }`} 
            />
            <ContentWrapper>
                <GenericErrorImage />
                <MainSection>
                    <h1>{t("pages.genericAppErrorPage.header")}</h1>
                    <p>{t("pages.genericAppErrorPage.report")} {message}</p>
                    <p>{t("pages.genericAppErrorPage.reloadApplication")}</p>
                </MainSection>
                <BottomRow>
                    <Button onClick={() => window.location.reload()}>
                        {t("buttons.refreshPage")}
                    </Button>
                </BottomRow>
            </ ContentWrapper>
        </>
    );
}
