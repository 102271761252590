import React, { useState, useEffect } from 'react';
import styled from "@emotion/styled";
import { IconCircleCheck, IconClose, IconExclamationMark } from './Icons';
import { styles } from '../styles';

type ToastProps = {
    message: string;
    type: "success" | "error" | "info";
    onCloseComplete?: () => void;
};

type ToastWrapperProps = {
    type: "success" | "error" | "info";
    isEntering: boolean;
    isExiting: boolean;
};

const getBackgroundColor = (type: "success" | "error" | "info"): string => {
    switch (type) {
        case 'error':
            return '#FBD5D5';
        case 'info':
            return '#DEE9FC';
        default:
            return '#E9F6EB';
    }
};

const getIconColor = (type: "success" | "error" | "info"): string => {
    switch (type) {
        case 'error':
            return '#ED2B2B';
        case 'info':
            return '#004EE5';
        default:
            return '#33AA47';
    }
};

const ToastWrapper = styled('div') <ToastWrapperProps>`
    position: fixed;
    bottom: 86px;
    right: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 12px;
    width: 312px;
    background-color: ${({ type }) => getBackgroundColor(type)};
    color: ${styles.textColorSecondary900};
    font-family: 'Satoshi Bold';
    border-radius: 4px;
    transition: transform 0.2s ease-out;
    transform: ${({ isEntering, isExiting }) => {
        if (isExiting) return 'translateX(100%)';
        if (isEntering) return 'translateX(0)';
        return 'translateX(100%)';
    }};
    box-shadow: ${styles.boxShadowBottomLight};
    z-index: 9999;

    span {
        display: flex;
    }

    span svg {
        cursor: pointer;
    }

    div {
        display: flex;
        align-items: center;
        gap: 12px;
    }

    div svg {
        path, circle, ellipse {
            stroke: ${({ type }) => getIconColor(type)};
        }
    }
`;

export const Toast: React.FC<ToastProps> = ({ message, type, onCloseComplete }) => {
    const [isEntering, setIsEntering] = useState(false);
    const [isExiting, setIsExiting] = useState(false);

    useEffect(() => {
        const enterTimeoutId = setTimeout(() => {
            setIsEntering(true);
        }, 10);

        const autoCloseTimeoutId = setTimeout(() => {
            handleClose();
        }, 5010);

        return () => {
            clearTimeout(enterTimeoutId);
            clearTimeout(autoCloseTimeoutId);
        };
    }, []);

    const handleClose = () => {
        setIsEntering(false);
        setIsExiting(true);
    };

    useEffect(() => {
        if (isExiting) {
            const exitDuration = 200;
            setTimeout(() => {
                onCloseComplete && onCloseComplete();
            }, exitDuration);
        }
    }, [isExiting, onCloseComplete]);

    return (
        <ToastWrapper type={type} isEntering={isEntering} isExiting={isExiting}>
            <div>
                {type === "success" ? <IconCircleCheck /> : <IconExclamationMark />}
                <p>{message}</p>
            </div>
            <span onClick={handleClose}><IconClose /></span>
        </ToastWrapper>
    );
};