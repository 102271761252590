import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { LoggedInUserInfo } from "../types/LoggedInUserInfo";
import { convertToErrorMsg, jsonReviverFunction } from "./_base";
import { useContext } from "react";
import { UserContext } from "../contexts/UserContext";
import { AccessPermission } from "../types/AccessPermission";
import { ContactAssociation } from "../types/ContactAssociation";
import { Contact, NotificationType } from "../types/Contact";
import { UserRolesInfo } from "../types/UserRolesInfo";

export const CURRENT_USER_QUERY_KEY = 'currentUser';

export const useLoggedInContactQuery = () => {
    const loggedInUserQuery = useQuery<LoggedInUserInfo | null, Error>({
        queryKey: [CURRENT_USER_QUERY_KEY],
        refetchOnWindowFocus: true,
        staleTime: 30000,
        queryFn: getLoggedInContact
    })
    return loggedInUserQuery;
}

const getLoggedInContact = async (): Promise<LoggedInUserInfo | null> => {
    let response = await fetch('/.auth/me');
    const authResponse = await response.json();
    if (!authResponse.clientPrincipal) {
        return null;
    }
    response = await fetch('/api/me');
    const responseBody = await response.text();
    if (!response.ok) {
        const errorMsg = convertToErrorMsg(response.status, responseBody);
        throw errorMsg;
    } else {
        const responseJson = JSON.parse(responseBody, jsonReviverFunction);
        const loggedInUser = responseJson as LoggedInUserInfo;
        if (loggedInUser && loggedInUser.contact) {
            loggedInUser.contact.phone = loggedInUser.contact.phone ? loggedInUser.contact.phone.replace("+41 ", "0"): loggedInUser.contact.phone; // remove switzerland country code because the number must be edited without it.
        }
        return loggedInUser;
    }
}

export const useContactsQuery = () => {
    const userContext = useContext(UserContext);
    const accessPermission = userContext.accessPermission;
    const loggedInUserQuery = useQuery<ContactAssociation[] | null, Error>({
        queryKey: ['contacts', accessPermission?.accountRef.id],
        refetchOnWindowFocus: true,
        staleTime: 30000,
        queryFn: () => getContacts(accessPermission)
    })
    return loggedInUserQuery;
}

const getContacts = async (accessPermission?: AccessPermission): Promise<ContactAssociation[]> => {
    if (!accessPermission) return [];
    const response = await fetch(`/api/accounts/${accessPermission.accountRef.id}/contacts`);
    const responseBody = await response.text();
    if (!response.ok) {
        const errorMsg = convertToErrorMsg(response.status, responseBody);
        throw errorMsg;
    } else {
        const responseJson = JSON.parse(responseBody, jsonReviverFunction);
        const contactAssociations = responseJson as ContactAssociation[];
        return contactAssociations;
    }
}

type UpdateContactInfo = {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    notificationTypes: NotificationType[];
}

export const useUpdateMeMutation = () => {
    const queryClient = useQueryClient()
    const userContext = useContext(UserContext);
    const mutation = useMutation<void, Error, Contact>({
        mutationFn: (contact: Contact) => updateMe(userContext.loggedInUser, contact),
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({queryKey: [CURRENT_USER_QUERY_KEY]});
            queryClient.invalidateQueries({queryKey: ['contacts', userContext.accessPermission?.accountRef.id]});
        }
    })
    return mutation;
}

const updateMe = async (loggedInUserInfo: LoggedInUserInfo | null, updatedContact: Contact): Promise<void> => {
    if (!loggedInUserInfo || loggedInUserInfo.contact.id !== updatedContact.id) {
        throw new Error('Contact Data does not match');
    }
    const body: UpdateContactInfo = updatedContact;
    const response = await fetch(`/api/me`, {
        method: 'Post',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body)
    });
    const responseBody = await response.text();
    if (!response.ok) {
        const errorMsg = convertToErrorMsg(response.status, responseBody);
        throw errorMsg;
    } 
}

export const useContactAssociationRemovalMutation = () => {
    const queryClient = useQueryClient()
    const userContext = useContext(UserContext);
    const mutation = useMutation<boolean, Error, ContactAssociation>({
        mutationFn: (contactAssociation: ContactAssociation) => removeContactAssociation(contactAssociation),
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({queryKey: [CURRENT_USER_QUERY_KEY]});
            queryClient.invalidateQueries({queryKey: ['contacts', userContext.accessPermission?.accountRef.id]});
        }
    })
    return mutation;
}

const removeContactAssociation = async(contactAssociation: ContactAssociation) => {
    const response = await fetch(`/api/me/accounts/${contactAssociation.accountRef.id}/contacts/${contactAssociation.contact.id}`, {
        method: 'delete'
    });
    const responseBody = await response.text();
    if (!response.ok) {
        const errorMsg = convertToErrorMsg(response.status, responseBody);
        throw errorMsg;
    } else {
        return true;
    }
}


export const useContactAssociationUpdateMutation = () => {
    const queryClient = useQueryClient()
    const userContext = useContext(UserContext);
    const mutation = useMutation<boolean, Error, ContactAssociation>({
        mutationFn: (contactAssociation: ContactAssociation) => updateContactAssociation(contactAssociation),
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({queryKey: [CURRENT_USER_QUERY_KEY]});
            queryClient.invalidateQueries({queryKey: ['contacts', userContext.accessPermission?.accountRef.id]});
        }
    })
    return mutation;
}

type UpdateAccessPermissionRequestBody = {
    roles: UserRolesInfo;
    containerIds: string[];
}

const updateContactAssociation = async(contactAssociation: ContactAssociation) => {
    const body: UpdateAccessPermissionRequestBody = {
        roles: contactAssociation.roles || { container: false, payment: false, permission: false},
        containerIds: contactAssociation.containerRefs.map(c => c.id)
    }
    const response = await fetch(`/api/me/accounts/${contactAssociation.accountRef.id}/contacts/${contactAssociation.contact.id}/permissions`, {
        method: 'post',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body)
    });
    const responseBody = await response.text();
    if (!response.ok) {
        const errorMsg = convertToErrorMsg(response.status, responseBody);
        throw errorMsg;
    } else {
        return true;
    }
}