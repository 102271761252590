import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Box, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import styled from '@emotion/styled';
import { IconContainerSmall, IconEditPen, IconQuestionCircleLineSmall } from '../../components/Icons';
import Button from '../../components/Button';
import { styles } from '../../styles'
import Modal from '../../components/Modal';
import Tooltip from '@mui/material/Tooltip';
import { useAccountQuery } from '../../data/accounts';
import { PaymentStep } from '../../components/payment/PaymentStep';
import { PaymentManager } from '../../components/payment/usePaymentManager';
import { EmptyingSchedulingMode, EmptyingsSchedule } from './EmptyingSchedulingPage';
import { PaymentTransactionProcess } from '../../types/PaymentTransaction';
import { TextIconButton } from '../../components/StyledComponents';
import { MessageWrapper } from '../../components/MessageWrapper';


const ContainerWrapper = styled.div<{ color: string }>`
    position: relative;  
    display: flex;
    align-items: center;

    svg   {
        margin-right: 8px;
        width: 36px;
        height: 36px;
        path {
            fill: ${props => props.color};
        }
        path:last-child {
            stroke: ${props => props.color};
        }
    }
`;

const ContainerShortName = styled.span`
    font-family: 'Satoshi Bold';
    color: ${styles.textColorSecondary700};
    position: absolute;
    top: 12px;
    left: 12px;
    font-size: 10px;
`;

type ContainerWithIconProps = {
    containerName: string,
    color: string,
    shortName: string
}

const ContainerWithIcon: React.FC<ContainerWithIconProps> = ({ containerName, color, shortName }) => {
    return (
        <ContainerWrapper color={color}>
            <IconContainerSmall />
            <ContainerShortName>{shortName}</ContainerShortName>
            <span>{containerName}</span>
        </ContainerWrapper>
    );
};

type TableData = {
    container: JSX.Element;
    dates: string[];
    price: number;
}

const MainSection = styled(Box)`
    max-width: 424px;
    width: 100%;

    @media (max-width: 800px) {
        max-width: unset;
    }
`;

const WelcomeSection = styled.section`
    p {
        font-size: 14px;
        max-width: 620px;
    }
`;

const Balance = styled.div`
  display: flex;
  align-items: center;
  font-size: 10px;
  line-height: 18px;
  font-family: 'Satoshi Regular';
  color: ${styles.textColorSecondary700};

  svg {
    margin-left: 8px;
    cursor: pointer;
  }
`;

const TableHeadCell = styled(TableCell)`
  border-bottom: 1px solid ${styles.neutral300};
  padding: 10px 4px;
  font-family: 'Satoshi Regular';
  line-height: 22px;
  color: ${styles.textColorSecondary700};
  height: 44px;

  @media (max-width: 480px) {
    font-size: 12px;
  } 
`;

const TableBodyCell = styled(TableCell)`
  border-bottom: 1.5px solid ${styles.neutral200} !important;
  padding: 18px 4px !important;
  font-family: 'Satoshi Regular';
  color: ${styles.textColorSecondary700};

  @media (max-width: 480px) {
    font-size: 12px;
    } 
`;

const BottomSection = styled.section`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
    margin-bottom: 60px;

    button {
        width: 156px;
        margin-top: 60px;
    }

    @media (max-width: 480px) {
        flex-direction: column;
        margin-top: 48px;
        margin-bottom: 0;
        gap: 12px;

        button {
            width: 100%;
            margin-top: 0;
            margin-bottom: 0;
        }
    }
`;

const PriceWithVat = styled.p`
    font-size: 10px;
    line-height: 18px;

    span {
        font-size: 14px;
    }

    @media (max-width: 480px) {
        font-size: 8px;

        span {
            font-size: 12px;
        }
    } 
`;

const Total = styled.p`
    text-align: right;
    margin-top: 12px;
    font-family: 'Satoshi Bold';
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    color: ${styles.textColorSecondary700};

    span {
        margin-left: 16px;
    }

    @media (max-width: 480px) {
        font-size: 12px;
    } 
`;

const IconWrapper = styled.div`
    display: flex;

    svg {
        cursor: pointer;
    }
`;

const StyledH2 = styled.h2`
    @media (max-width: 480px) {
        font-size: 16px;
    } 
`;

const StyledTextIconButton = styled(TextIconButton)`
    margin-top: 10px;
    color: ${props => props.disabled ? styles.neutral200 : "#26374A"};
    svg {
        circle, path {
            stroke: ${props => props.disabled ? styles.neutral200 : "#26374A"};
        }
    }
    cursor: ${props => props.disabled ? "not-allowed" : "pointer"};
`;

type OrderViewProps = {
    mode: EmptyingSchedulingMode;
    autoOpenPayment: boolean;
    paymentMgr: PaymentManager;
    emptyingSchedules: EmptyingsSchedule[];
    onCancel: () => void;
    onBack: () => void;
}


export const OrderView: React.FC<OrderViewProps> = ({ mode, autoOpenPayment, paymentMgr, emptyingSchedules, onCancel, onBack }) => {
    const { t, i18n } = useTranslation();
    const dateFormatter = useMemo(() => new Intl.DateTimeFormat(i18n.language, { dateStyle: 'long' }), [i18n.language]);
    const accountQuery = useAccountQuery(emptyingSchedules[0].container.accountId);
    const tableData: TableData[] = emptyingSchedules.map(schedule => {
        return {
            container: <ContainerWithIcon containerName={schedule.container.displayName} color={schedule.container.color} shortName={schedule.container.shortName} />,
            dates: schedule.emptyings.map(e => dateFormatter.format(e.date)),
            price: schedule.emptyings.length === 0 ? 0: schedule.emptyings.map(e => e.price).reduce((prev, cur) => prev + cur)
        }
    });
    const [isPaying, setIsPaying] = useState<boolean>(false);

    const calculatePaymentOrderItemName = () => {
        const transactionName = mode === 'single' ?
            (t('payment.transactionNames.payForSingleEmptying') as string).replace('{date}', dateFormatter.format(emptyingSchedules[0].emptyings[0].date)) :
            (t('payment.transactionNames.payForMultipleEmptyings') as string).replace('{nof-emptyings}', `${emptyingSchedules.map(s => s.emptyings.length).reduce((prev, cur) => prev + cur)}`);
        return transactionName;
    }
    const calculateTotalPrice = () => {
        const totalPrice = emptyingSchedules.map(e => e.emptyings.map(e => e.price).reduce((prev, cur) => prev + cur)).reduce((prev, cur) => prev + cur);
        return totalPrice;
    }

    const onCancelProcess = useCallback(() => {
        onCancel();
    }, [onCancel]);

    if (accountQuery.data?.creditProcessing) {
        return <MessageWrapper messageText={t('errors.http.scheduleEmptyings.creditProcessingStillOnGoing')} height={"420px"} />
    }

    return (
        <>
            <WelcomeSection>
                <h1>{t(mode === 'single' ? (autoOpenPayment ? "pages.emptying.oneclick.header": "pages.emptying.onetime.header"): "pages.emptying.series.header")}</h1>
                <p>{t("pages.emptying.onetime.subheaderOrderView")}</p>
            </WelcomeSection>
            <MainSection>
                <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom="20px">
                    <StyledH2>{t("pages.emptying.order.header")}</StyledH2>
                    <Balance>
                        <span>{t("pages.emptying.order.credit")}: {accountQuery.data?.bookableCredit}.- {t("pages.emptying.order.currency")}</span>
                        <Tooltip arrow componentsProps={{
                            tooltip: {
                                sx: {
                                    bgcolor: '#26374A',
                                    '& .MuiTooltip-arrow': {
                                        color: '#26374A',
                                    },
                                },
                            },
                        }} title={t("pages.emptying.order.creditTooltip")}>
                            <IconWrapper>
                                <IconQuestionCircleLineSmall />
                            </IconWrapper>
                        </Tooltip>
                    </Balance>
                </Box>

                <TableContainer>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableHeadCell>{t("pages.emptying.order.container")}</TableHeadCell>
                                { mode === 'single' && <TableHeadCell>{t("pages.emptying.order.date")}</TableHeadCell> }
                                { mode === 'multi' && <TableHeadCell>{t("pages.emptying.order.emptyings")}</TableHeadCell> }
                                <TableHeadCell align="right"><PriceWithVat>{t("pages.emptying.order.priceWithVat")} <span>{t("pages.emptying.order.currency")}</span></PriceWithVat></TableHeadCell>
                            </TableRow>
                            {tableData.map((row, index) => (
                                <TableRow key={index}>
                                    <TableBodyCell>{row.container}</TableBodyCell>
                                    {mode === 'single' && <TableBodyCell>{row.dates[0]}</TableBodyCell>}
                                    {mode === 'multi' && <TableBodyCell>{row.dates.length}</TableBodyCell>}
                                    <TableBodyCell align="right">{row.price}.-</TableBodyCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <Total>{t("pages.emptying.order.total")}<span>{tableData.map(d => d.price).reduce((prev, cur) => prev + cur)}.-</span></Total>
                </TableContainer>

                { !autoOpenPayment && 
                    <StyledTextIconButton onClick={onBack} bold svgMargin='0 4px 0 0'>
                        <IconEditPen /> 
                        <span>{t("pages.emptying.order.edit")}</span>
                    </StyledTextIconButton>
                }
                <BottomSection>
                    <Button onClick={onCancelProcess} buttonVariant="ghost">
                        {t("buttons.abort")}
                    </Button>
                    <Button onClick={() => setIsPaying(true)} >
                        {t("buttons.next")}
                    </Button>
                </BottomSection>

                {isPaying &&
                    <Modal onClose={() => setIsPaying(false)}>
                        <PaymentStep 
                            enableCreditUsage={!!accountQuery.data && !accountQuery.data.creditProcessing && accountQuery.data.bookableCredit >= (tableData.map(row => row.price).reduce((prev, cur) => prev + cur))}
                            enableInvoice={false}
                            paymentMgr={paymentMgr}
                            process={PaymentTransactionProcess.Schedule}
                            orderItemName={calculatePaymentOrderItemName()}
                            totalPrice={calculateTotalPrice()}
                            onCancel={() => setIsPaying(false)}
                            texts={{
                                title: t("pages.emptying.paymentModal.header")
                            }}
                            payWithCreditBtnLabel={t("pages.emptying.paymentModal.payWithCreditBtn")}
                        />
                    </Modal>
                }
            </MainSection>
        </>
    );
};
