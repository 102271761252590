import { useEffectOnce } from "usehooks-ts";
import { useScheduleEmptyingMutation } from "../../data/emptyings";
import { useCallback, useState } from "react";
import { EmptyingsSchedule } from "./EmptyingSchedulingPage";
import { Loader } from "../../components/Loader";
import { MessageWrapper } from "../../components/MessageWrapper";
import { useTranslation } from "react-i18next";
import Modal from '../../components/Modal';
import { useCompletePaymentTransactionMutation } from "../../data/payments";

type BookingViewProps = {
    emptyingSchedules: EmptyingsSchedule[];
    paymentTransactionId: string;
    onCompleted: (totalPrice: number) => void;
}

let isRunning = false;
export const BookingView = ({ emptyingSchedules, paymentTransactionId, onCompleted }: BookingViewProps) => {
    const scheduleEmptyingMutation = useScheduleEmptyingMutation();
    const completePaymentTransactionMutation = useCompletePaymentTransactionMutation();
    const [errorMessage, setErrorMessage] = useState<{main: string, detail: string}>();
    const { t } = useTranslation();

    const scheduleEmptyings = useCallback(async () => {
        let totalPrice = 0;
        let nofSuccessfulEmptyings = 0;
        try {
            for (let i = 0; i < emptyingSchedules.length; i++) {
                const emptyingSchedule = emptyingSchedules[i];
                const result = await scheduleEmptyingMutation.mutateAsync({
                    container: emptyingSchedule.container,
                    dates: emptyingSchedule.emptyings.map(e => e.date),
                    recurrence: emptyingSchedule.recurrence,
                    paymentTransactionId: paymentTransactionId
                });
                totalPrice = totalPrice + result.totalPrice;
                nofSuccessfulEmptyings++;
            }
        } catch (e) {
            let detailMsg = '';
            if (emptyingSchedules.length === 1) {
                detailMsg = "Die Leerung wurde nicht eingeplant. Der Betrag von wurden stattdessen Ihrem Guthaben-Konto gutgeschrieben.";
            }
            else if (nofSuccessfulEmptyings === 0) {
                detailMsg = "Keine Ihrer Leerungen wurde eingeplant. Der Betrag wurde stattdessen Ihrem Guthaben-Konto gutgeschrieben.";
            }
            else {
                detailMsg = `Es konnten nur ${nofSuccessfulEmptyings} von ${emptyingSchedules.length} Leerungsbestellungen eingeplant werden. Dies entspricht einem Betrag von CHF ${totalPrice}.-. Der Restbetrag wurde Ihrem Guthaben-Konto gutgeschrieben.`
            }
            setErrorMessage({main: (e as Error).message, detail: detailMsg});
        } finally {
            await completePaymentTransactionMutation.mutateAsync({paymentTransactionId});
            isRunning = false;
            if (nofSuccessfulEmptyings === emptyingSchedules.length) {
                onCompleted(totalPrice);
            }
        }
    }, [paymentTransactionId, emptyingSchedules, onCompleted, scheduleEmptyingMutation]);

    useEffectOnce(() => {
        if (!isRunning) {
            isRunning = true;
            scheduleEmptyings();
        }
    });

    if (errorMessage) {
        return <MessageWrapper messageText={
            <><div>{errorMessage.main}</div><div>{errorMessage.detail}</div></>} height={"420px"} />
    }

    if (scheduleEmptyingMutation.isLoading) {
        return (
            <Modal onClose={() => { }}>
                <Loader height={"420px"} messageText={t("components.loaders.booking")} />
            </Modal>
        );
    }
    return <></>;
}
