import { Namespace, TFunction } from "i18next";

export type UserRolesInfo = {
    payment: boolean;
    permission: boolean;
    container: boolean;
}
export type EndUserRole = 'admin' | 'staff' | 'viewer';
export const endUserRoleToUserRoleInfo = (endUserRole: EndUserRole): UserRolesInfo => {
    return { 
        container: endUserRole !== 'viewer', 
        payment: endUserRole !== 'viewer', 
        permission: endUserRole === 'admin'
    }
}
export const userRoleInfoToEndUserRole = (userRoleInfo: UserRolesInfo): EndUserRole => {
    let endUserRole: EndUserRole = 'viewer';
    if (userRoleInfo && (userRoleInfo.container || userRoleInfo.payment)) {
        endUserRole = 'staff';
    }
    if (userRoleInfo && userRoleInfo.permission) {
        endUserRole = 'admin';
    }
    return endUserRole;
}
export const endUserRoleToDisplayName = (endUserRole: EndUserRole, t: TFunction<Namespace, undefined, Namespace>): string => {
    if (endUserRole === 'admin') return t('roles.admin');
    if (endUserRole === 'staff') return t('roles.staff');
    if (endUserRole === 'viewer') return t('roles.viewer');
    return '-';
}