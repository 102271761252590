import { Environment } from "./types/Environment";


const loadEnv = (): Environment => {
    const hostname = window.location.hostname.toLowerCase();
    if (hostname === 'localhost') {
        return {
            apiBasePath: "/api",
            name: 'local',
        }
    }
    if (hostname.indexOf('-staging.') > 0) {
        return {
            apiBasePath: "/api",
            name: 'staging',
        }
    }
    return {
        apiBasePath: "/api",
        name: 'production',
    }
}

export const env: Environment = loadEnv();