const KVALogo = () => (

	<svg width="93" height="48" viewBox="0 0 93 48" fill="none" xmlns="http://www.w3.org/2000/svg">
		<mask id="mask0_1726_10392" maskUnits="userSpaceOnUse" x="48" y="0" width="45" height="36">
			<path d="M92.6976 35.4404L69.0332 35.4602L48.3094 0.000488281H92.6976" fill="white" />
		</mask>
		<g mask="url(#mask0_1726_10392)">
			<mask id="mask1_1726_10392" maskUnits="userSpaceOnUse" x="69" y="-9" width="144" height="10">
				<path d="M212.604 -8.42163H69.4324V0.380074H212.604V-8.42163Z" fill="white" />
			</mask>
			<g mask="url(#mask1_1726_10392)">
				<path d="M212.604 -0.299561H69.4319V0.319244H212.604V-0.299561Z" fill="#33AA47" />
			</g>
			<mask id="mask2_1726_10392" maskUnits="userSpaceOnUse" x="69" y="0" width="144" height="10">
				<path d="M212.604 0.379883H69.4324V9.18159H212.604V0.379883Z" fill="white" />
			</mask>
			<g mask="url(#mask2_1726_10392)">
				<path d="M212.604 8.50195H69.4319V8.91593H212.604V8.50195Z" fill="#33AA47" />
				<path d="M212.604 6.74146H69.4319V7.15544H212.604V6.74146Z" fill="#33AA47" />
				<path d="M212.604 4.98145H69.4319V5.39543H212.604V4.98145Z" fill="#33AA47" />
				<path d="M212.604 3.22095H69.4319V3.63493H212.604V3.22095Z" fill="#33AA47" />
				<path d="M212.604 1.46069H69.4319V1.87468H212.604V1.46069Z" fill="#33AA47" />
			</g>
			<mask id="mask3_1726_10392" maskUnits="userSpaceOnUse" x="69" y="9" width="144" height="9">
				<path d="M212.604 9.18164H69.4324V17.9833H212.604V9.18164Z" fill="white" />
			</mask>
			<g mask="url(#mask3_1726_10392)">
				<path d="M212.604 17.3037H69.4319V17.7177H212.604V17.3037Z" fill="#33AA47" />
				<path d="M212.604 15.5435H69.4319V15.9574H212.604V15.5435Z" fill="#33AA47" />
				<path d="M212.604 13.7832H69.4319V14.1972H212.604V13.7832Z" fill="#33AA47" />
				<path d="M212.604 12.0225H69.4319V12.4364H212.604V12.0225Z" fill="#33AA47" />
				<path d="M212.604 10.2625H69.4319V10.6764H212.604V10.2625Z" fill="#33AA47" />
			</g>
			<mask id="mask4_1726_10392" maskUnits="userSpaceOnUse" x="69" y="17" width="144" height="10">
				<path d="M212.604 17.9834H69.4324V26.7851H212.604V17.9834Z" fill="white" />
			</mask>
			<g mask="url(#mask4_1726_10392)">
				<path d="M212.604 26.1057H69.4319V26.5197H212.604V26.1057Z" fill="#33AA47" />
				<path d="M212.604 24.3452H69.4319V24.7592H212.604V24.3452Z" fill="#33AA47" />
				<path d="M212.604 22.585H69.4319V22.9989H212.604V22.585Z" fill="#33AA47" />
				<path d="M212.604 20.8247H69.4319V21.2387H212.604V20.8247Z" fill="#33AA47" />
				<path d="M212.604 19.0642H69.4319V19.4782H212.604V19.0642Z" fill="#33AA47" />
			</g>
			<mask id="mask5_1726_10392" maskUnits="userSpaceOnUse" x="69" y="26" width="144" height="10">
				<path d="M212.604 26.7849H69.4324V35.5866H212.604V26.7849Z" fill="white" />
			</mask>
			<g mask="url(#mask5_1726_10392)">
				<path d="M212.604 34.907H69.4319V35.321H212.604V34.907Z" fill="#33AA47" />
				<path d="M212.604 33.1467H69.4319V33.5607H212.604V33.1467Z" fill="#33AA47" />
				<path d="M212.604 31.3865H69.4319V31.8005H212.604V31.3865Z" fill="#33AA47" />
				<path d="M212.604 29.626H69.4319V30.04H212.604V29.626Z" fill="#33AA47" />
				<path d="M212.604 27.866H69.4319V28.2799H212.604V27.866Z" fill="#33AA47" />
			</g>
			<mask id="mask6_1726_10392" maskUnits="userSpaceOnUse" x="-74" y="-9" width="144" height="10">
				<path d="M69.4323 -8.42163H-73.7393V0.380074H69.4323V-8.42163Z" fill="white" />
			</mask>
			<g mask="url(#mask6_1726_10392)">
				<path d="M69.4319 -0.299561H-73.7395V0.319244H69.4319V-0.299561Z" fill="#33AA47" />
			</g>
			<mask id="mask7_1726_10392" maskUnits="userSpaceOnUse" x="-74" y="0" width="144" height="10">
				<path d="M69.4324 0.379883H-73.7392V9.18159H69.4324V0.379883Z" fill="white" />
			</mask>
			<g mask="url(#mask7_1726_10392)">
				<path d="M69.4323 8.50195H-73.7392V8.91593H69.4323V8.50195Z" fill="#33AA47" />
				<path d="M69.4323 6.74146H-73.7392V7.15544H69.4323V6.74146Z" fill="#33AA47" />
				<path d="M69.4323 4.98145H-73.7392V5.39543H69.4323V4.98145Z" fill="#33AA47" />
				<path d="M69.4319 3.22095H-73.7395V3.63493H69.4319V3.22095Z" fill="#33AA47" />
				<path d="M69.4319 1.46069H-73.7395V1.87468H69.4319V1.46069Z" fill="#33AA47" />
			</g>
			<mask id="mask8_1726_10392" maskUnits="userSpaceOnUse" x="-74" y="9" width="144" height="9">
				<path d="M69.4324 9.18164H-73.7392V17.9833H69.4324V9.18164Z" fill="white" />
			</mask>
			<g mask="url(#mask8_1726_10392)">
				<path d="M69.4323 17.3037H-73.7392V17.7177H69.4323V17.3037Z" fill="#33AA47" />
				<path d="M69.4323 15.5435H-73.7392V15.9574H69.4323V15.5435Z" fill="#33AA47" />
				<path d="M69.4323 13.7832H-73.7392V14.1972H69.4323V13.7832Z" fill="#33AA47" />
				<path d="M69.4319 12.0225H-73.7395V12.4364H69.4319V12.0225Z" fill="#33AA47" />
				<path d="M69.4319 10.2625H-73.7395V10.6764H69.4319V10.2625Z" fill="#33AA47" />
			</g>
			<mask id="mask9_1726_10392" maskUnits="userSpaceOnUse" x="-74" y="17" width="144" height="10">
				<path d="M69.4324 17.9834H-73.7392V26.7851H69.4324V17.9834Z" fill="white" />
			</mask>
			<g mask="url(#mask9_1726_10392)">
				<path d="M69.4323 26.1057H-73.7392V26.5197H69.4323V26.1057Z" fill="#33AA47" />
				<path d="M69.4323 24.3452H-73.7392V24.7592H69.4323V24.3452Z" fill="#33AA47" />
				<path d="M69.4323 22.585H-73.7392V22.9989H69.4323V22.585Z" fill="#33AA47" />
				<path d="M69.4319 20.8247H-73.7395V21.2387H69.4319V20.8247Z" fill="#33AA47" />
				<path d="M69.4319 19.0642H-73.7395V19.4782H69.4319V19.0642Z" fill="#33AA47" />
			</g>
			<mask id="mask10_1726_10392" maskUnits="userSpaceOnUse" x="-74" y="26" width="144" height="10">
				<path d="M69.4324 26.7849H-73.7392V35.5866H69.4324V26.7849Z" fill="white" />
			</mask>
			<g mask="url(#mask10_1726_10392)">
				<path d="M69.4323 34.907H-73.7392V35.321H69.4323V34.907Z" fill="#33AA47" />
				<path d="M69.4323 33.1467H-73.7392V33.5607H69.4323V33.1467Z" fill="#33AA47" />
				<path d="M69.4323 31.3865H-73.7392V31.8005H69.4323V31.3865Z" fill="#33AA47" />
				<path d="M69.4319 29.626H-73.7395V30.04H69.4319V29.626Z" fill="#33AA47" />
				<path d="M69.4319 27.866H-73.7395V28.2799H69.4319V27.866Z" fill="#33AA47" />
			</g>
		</g>
		<path d="M7.93829 18.003L17.8707 35.4567H28.3376L18.5216 17.9547L28.9776 0H18.3944L7.93829 18.003Z" fill="#33AA47" />
		<mask id="mask11_1726_10392" maskUnits="userSpaceOnUse" x="28" y="0" width="42" height="36">
			<path d="M28.2713 35.4562H39.0814L48.4034 18.183L58.3595 35.4749H69.0335L48.3265 0.0251465" fill="white" />
		</mask>
		<g mask="url(#mask11_1726_10392)">
			<mask id="mask12_1726_10392" maskUnits="userSpaceOnUse" x="-74" y="-9" width="144" height="10">
				<path d="M69.4323 -8.42163H-73.7393V0.380074H69.4323V-8.42163Z" fill="white" />
			</mask>
			<g mask="url(#mask12_1726_10392)">
				<path d="M69.4321 -0.506836H-73.7398V0.321129H69.4321V-0.506836Z" fill="#33AA47" />
			</g>
			<mask id="mask13_1726_10392" maskUnits="userSpaceOnUse" x="-74" y="0" width="144" height="10">
				<path d="M69.4324 0.379883H-73.7392V9.18159H69.4324V0.379883Z" fill="white" />
			</mask>
			<g mask="url(#mask13_1726_10392)">
				<path d="M69.4324 8.29492H-73.7394V9.12289H69.4324V8.29492Z" fill="#33AA47" />
				<path d="M69.4324 6.53467H-73.7394V7.36263H69.4324V6.53467Z" fill="#33AA47" />
				<path d="M69.4324 4.77417H-73.7394V5.60213H69.4324V4.77417Z" fill="#33AA47" />
				<path d="M69.4324 3.01416H-73.7394V3.84212H69.4324V3.01416Z" fill="#33AA47" />
				<path d="M69.4322 1.25366H-73.7396V2.08163H69.4322V1.25366Z" fill="#33AA47" />
			</g>
			<mask id="mask14_1726_10392" maskUnits="userSpaceOnUse" x="-74" y="9" width="144" height="9">
				<path d="M69.4324 9.18164H-73.7392V17.9833H69.4324V9.18164Z" fill="white" />
			</mask>
			<g mask="url(#mask14_1726_10392)">
				<path d="M69.4324 17.0969H-73.7394V17.9249H69.4324V17.0969Z" fill="#33AA47" />
				<path d="M69.4324 15.3367H-73.7394V16.1646H69.4324V15.3367Z" fill="#33AA47" />
				<path d="M69.4324 13.5762H-73.7394V14.4041H69.4324V13.5762Z" fill="#33AA47" />
				<path d="M69.4324 11.8157H-73.7394V12.6436H69.4324V11.8157Z" fill="#33AA47" />
				<path d="M69.4322 10.0554H-73.7396V10.8834H69.4322V10.0554Z" fill="#33AA47" />
			</g>
			<mask id="mask15_1726_10392" maskUnits="userSpaceOnUse" x="-74" y="17" width="144" height="10">
				<path d="M69.4324 17.9834H-73.7392V26.7851H69.4324V17.9834Z" fill="white" />
			</mask>
			<g mask="url(#mask15_1726_10392)">
				<path d="M69.4324 25.8987H-73.7394V26.7266H69.4324V25.8987Z" fill="#33AA47" />
				<path d="M69.4324 24.1384H-73.7394V24.9664H69.4324V24.1384Z" fill="#33AA47" />
				<path d="M69.4324 22.3782H-73.7394V23.2061H69.4324V22.3782Z" fill="#33AA47" />
				<path d="M69.4324 20.6177H-73.7394V21.4456H69.4324V20.6177Z" fill="#33AA47" />
				<path d="M69.4322 18.8574H-73.7396V19.6854H69.4322V18.8574Z" fill="#33AA47" />
			</g>
			<mask id="mask16_1726_10392" maskUnits="userSpaceOnUse" x="-74" y="26" width="144" height="10">
				<path d="M69.4324 26.7849H-73.7392V35.5866H69.4324V26.7849Z" fill="white" />
			</mask>
			<g mask="url(#mask16_1726_10392)">
				<path d="M69.4324 34.7H-73.7394V35.5279H69.4324V34.7Z" fill="#33AA47" />
				<path d="M69.4324 32.9399H-73.7394V33.7679H69.4324V32.9399Z" fill="#33AA47" />
				<path d="M69.4324 31.1794H-73.7394V32.0074H69.4324V31.1794Z" fill="#33AA47" />
				<path d="M69.4324 29.4189H-73.7394V30.2469H69.4324V29.4189Z" fill="#33AA47" />
				<path d="M69.4322 27.6587H-73.7396V28.4867H69.4322V27.6587Z" fill="#33AA47" />
			</g>
		</g>
		<path d="M9.32692 35.4646H0V0H0.101569H9.32692V35.4646Z" fill="#33AA47" />
		<mask id="mask17_1726_10392" maskUnits="userSpaceOnUse" x="8" y="0" width="41" height="36">
			<path d="M28.1441 17.5203L37.7452 0.0251418L37.758 0.000488281H48.3413L28.3384 35.4562L8.31482 0.000488281H18.4533L28.1441 17.5203Z" fill="white" />
		</mask>
		<g mask="url(#mask17_1726_10392)">
			<mask id="mask18_1726_10392" maskUnits="userSpaceOnUse" x="-74" y="-9" width="144" height="10">
				<path d="M69.4328 -8.42163H-73.7388V0.380074H69.4328V-8.42163Z" fill="white" />
			</mask>
			<g mask="url(#mask18_1726_10392)">
				<path d="M69.4328 -0.713623H-73.739V0.528422H69.4328V-0.713623Z" fill="#33AA47" />
			</g>
			<mask id="mask19_1726_10392" maskUnits="userSpaceOnUse" x="-74" y="0" width="144" height="10">
				<path d="M69.4329 0.379883H-73.7387V9.18159H69.4329V0.379883Z" fill="white" />
			</mask>
			<g mask="url(#mask19_1726_10392)">
				<path d="M69.4327 8.08789H-73.7392V9.32994H69.4327V8.08789Z" fill="#33AA47" />
				<path d="M69.4327 6.32764H-73.7392V7.56968H69.4327V6.32764Z" fill="#33AA47" />
				<path d="M69.4327 4.56738H-73.7392V5.80943H69.4327V4.56738Z" fill="#33AA47" />
				<path d="M69.4327 2.80688H-73.7392V4.04893H69.4327V2.80688Z" fill="#33AA47" />
				<path d="M69.4326 1.04688H-73.7393V2.28892H69.4326V1.04688Z" fill="#33AA47" />
				<path d="M69.4325 -0.713623H-73.7393V0.528422H69.4325V-0.713623Z" fill="#33AA47" />
			</g>
			<mask id="mask20_1726_10392" maskUnits="userSpaceOnUse" x="-74" y="9" width="144" height="9">
				<path d="M69.4329 9.18164H-73.7387V17.9833H69.4329V9.18164Z" fill="white" />
			</mask>
			<g mask="url(#mask20_1726_10392)">
				<path d="M69.4327 16.8899H-73.7392V18.1319H69.4327V16.8899Z" fill="#33AA47" />
				<path d="M69.4327 15.1294H-73.7392V16.3714H69.4327V15.1294Z" fill="#33AA47" />
				<path d="M69.4327 13.3694H-73.7392V14.6114H69.4327V13.3694Z" fill="#33AA47" />
				<path d="M69.4327 11.6089H-73.7392V12.8509H69.4327V11.6089Z" fill="#33AA47" />
				<path d="M69.4326 9.84839H-73.7393V11.0904H69.4326V9.84839Z" fill="#33AA47" />
				<path d="M69.4325 8.08813H-73.7393V9.33018H69.4325V8.08813Z" fill="#33AA47" />
			</g>
			<mask id="mask21_1726_10392" maskUnits="userSpaceOnUse" x="-74" y="17" width="144" height="10">
				<path d="M69.4329 17.9834H-73.7387V26.7851H69.4329V17.9834Z" fill="white" />
			</mask>
			<g mask="url(#mask21_1726_10392)">
				<path d="M69.4327 25.6914H-73.7392V26.9335H69.4327V25.6914Z" fill="#33AA47" />
				<path d="M69.4327 23.9312H-73.7392V25.1732H69.4327V23.9312Z" fill="#33AA47" />
				<path d="M69.4327 22.1709H-73.7392V23.4129H69.4327V22.1709Z" fill="#33AA47" />
				<path d="M69.4327 20.4104H-73.7392V21.6524H69.4327V20.4104Z" fill="#33AA47" />
				<path d="M69.4326 18.6504H-73.7393V19.8924H69.4326V18.6504Z" fill="#33AA47" />
				<path d="M69.4325 16.8899H-73.7393V18.1319H69.4325V16.8899Z" fill="#33AA47" />
			</g>
			<mask id="mask22_1726_10392" maskUnits="userSpaceOnUse" x="-74" y="26" width="144" height="10">
				<path d="M69.4329 26.7849H-73.7387V35.5866H69.4329V26.7849Z" fill="white" />
			</mask>
			<g mask="url(#mask22_1726_10392)">
				<path d="M69.4327 34.4929H-73.7392V35.735H69.4327V34.4929Z" fill="#33AA47" />
				<path d="M69.4327 32.7324H-73.7392V33.9745H69.4327V32.7324Z" fill="#33AA47" />
				<path d="M69.4327 30.9724H-73.7392V32.2145H69.4327V30.9724Z" fill="#33AA47" />
				<path d="M69.4327 29.2119H-73.7392V30.454H69.4327V29.2119Z" fill="#33AA47" />
				<path d="M69.4326 27.4517H-73.7393V28.6937H69.4326V27.4517Z" fill="#33AA47" />
				<path d="M69.4325 25.6914H-73.7393V26.9335H69.4325V25.6914Z" fill="#33AA47" />
			</g>
		</g>
		<path d="M2.02979 45.9311L2.12496 46.7737H2.16716L2.26233 45.9311L3.17125 40.5603H4.29151L2.86466 47.9316H1.42726L0.000610352 40.5603H1.12087L2.02979 45.9311Z" fill="#33AA47" />
		<path d="M5.53372 40.5603H9.08491V41.5921H6.63288V43.6985H8.73592V44.7408H6.63288V46.8894H9.08491V47.9316H5.53372V40.5603Z" fill="#33AA47" />
		<path d="M13.2427 47.9315L12.0807 44.7407H11.4988V47.9315H10.3998V40.5602H12.3549C12.608 40.5574 12.859 40.606 13.0928 40.703C13.3266 40.8 13.5383 40.9433 13.7151 41.1244C13.8919 41.3055 14.0302 41.5205 14.1216 41.7566C14.213 41.9926 14.2556 42.2447 14.2468 42.4976V42.7927C14.2621 43.1571 14.1688 43.5177 13.9787 43.829C13.7887 44.1402 13.5105 44.388 13.1794 44.5409C13.5493 45.5937 14.0354 46.9207 14.3947 47.9314L13.2427 47.9315ZM11.4988 43.6985H12.3547C12.4611 43.6979 12.5663 43.676 12.6641 43.6341C12.7619 43.5923 12.8503 43.5313 12.9242 43.4547C12.998 43.3782 13.0558 43.2876 13.0941 43.1884C13.1325 43.0891 13.1506 42.9832 13.1474 42.8769V42.4134C13.1506 42.3071 13.1325 42.2012 13.0941 42.102C13.0558 42.0027 12.998 41.9122 12.9242 41.8356C12.8503 41.7591 12.7619 41.6981 12.6641 41.6562C12.5663 41.6143 12.4611 41.5925 12.3547 41.5919H11.4988V43.6985Z" fill="#33AA47" />
		<path d="M18.981 44.225C19.1816 44.4033 19.3419 44.6223 19.4512 44.8674C19.5606 45.1125 19.6165 45.3781 19.6152 45.6465V46.0468C19.6173 46.2991 19.5683 46.5492 19.4712 46.782C19.3741 47.0149 19.2309 47.2257 19.0502 47.4018C18.8695 47.5779 18.6551 47.7156 18.4198 47.8066C18.1845 47.8977 17.9332 47.9402 17.681 47.9316H15.7153V40.5603H17.681C17.9308 40.5547 18.179 40.6001 18.4107 40.6936C18.6424 40.787 18.8526 40.9267 19.0286 41.104C19.2046 41.2813 19.3427 41.4926 19.4344 41.7249C19.5262 41.9573 19.5697 42.2059 19.5623 42.4556V42.7928C19.5696 43.0587 19.5217 43.3233 19.4217 43.5698C19.3217 43.8163 19.1716 44.0394 18.981 44.225ZM16.8145 43.6986H17.6599C17.7676 43.7009 17.8746 43.6811 17.9743 43.6404C18.074 43.5997 18.1643 43.539 18.2396 43.4619C18.3149 43.3849 18.3736 43.2933 18.4121 43.1927C18.4505 43.092 18.4679 42.9846 18.4631 42.877V42.4241C18.4681 42.3159 18.4509 42.2079 18.4127 42.1066C18.3744 42.0053 18.3159 41.9129 18.2407 41.835C18.1655 41.7572 18.0753 41.6954 17.9754 41.6537C17.8755 41.6119 17.7682 41.5909 17.6599 41.5921H16.8142L16.8145 43.6986ZM17.6599 44.7408H16.8142V46.8893H17.6596C17.7711 46.8932 17.8822 46.8745 17.9862 46.8344C18.0903 46.7943 18.1851 46.7335 18.2651 46.6558C18.3451 46.5781 18.4085 46.485 18.4516 46.3821C18.4947 46.2792 18.5165 46.1687 18.5158 46.0572V45.5624C18.5145 45.4519 18.4912 45.3429 18.4473 45.2415C18.4035 45.1402 18.3398 45.0486 18.2601 44.9721C18.1805 44.8956 18.0863 44.8358 17.9833 44.7961C17.8802 44.7564 17.7703 44.7376 17.6599 44.7408Z" fill="#33AA47" />
		<path d="M23.7619 47.9316V45.4465H22.0607V47.9316H20.9614V42.4558C20.9614 42.1997 21.0118 41.9461 21.1098 41.7096C21.2078 41.473 21.3514 41.258 21.5325 41.077C21.7135 40.8959 21.9285 40.7523 22.1651 40.6543C22.4016 40.5563 22.6552 40.5059 22.9113 40.5059C23.1673 40.5059 23.4209 40.5563 23.6575 40.6543C23.894 40.7523 24.109 40.8959 24.29 41.077C24.4711 41.258 24.6147 41.473 24.7127 41.7096C24.8107 41.9461 24.8612 42.1997 24.8612 42.4558V47.9316H23.7619ZM22.0607 44.3932H23.7622V42.3612C23.7517 42.1426 23.6574 41.9365 23.4991 41.7856C23.3407 41.6346 23.1303 41.5504 22.9115 41.5504C22.6927 41.5504 22.4823 41.6346 22.3239 41.7856C22.1655 41.9365 22.0712 42.1426 22.0607 42.3612V44.3932Z" fill="#33AA47" />
		<path d="M30.2708 47.9316H29.1821V44.6463L27.4595 42.2565V47.932H26.3708V40.5603H27.449L29.1821 42.9191V40.5603H30.2708V47.9316Z" fill="#33AA47" />
		<path d="M35.7107 46.0257C35.709 46.2797 35.657 46.5308 35.5575 46.7645C35.458 46.9983 35.3132 47.2099 35.1313 47.3872C34.9494 47.5645 34.7341 47.7039 34.4979 47.7973C34.2617 47.8908 34.0093 47.9364 33.7553 47.9315H31.8108V40.5602H33.7553C34.0096 40.5574 34.262 40.6048 34.4979 40.6996C34.7339 40.7944 34.9488 40.9349 35.1304 41.1129C35.312 41.2909 35.4568 41.5029 35.5563 41.7369C35.6559 41.9709 35.7083 42.2223 35.7107 42.4765V46.0257ZM34.6115 42.3926C34.608 42.2838 34.583 42.1767 34.5381 42.0776C34.4931 41.9784 34.429 41.8891 34.3495 41.8147C34.27 41.7403 34.1766 41.6824 34.0746 41.6442C33.9726 41.606 33.8641 41.5883 33.7553 41.5921H32.9099V46.8892H33.7553C33.8646 46.8948 33.9738 46.8784 34.0766 46.8409C34.1794 46.8033 34.2735 46.7455 34.3534 46.6708C34.4333 46.596 34.4973 46.506 34.5417 46.406C34.586 46.306 34.6098 46.1981 34.6115 46.0887V42.3926Z" fill="#33AA47" />
		<path d="M43.4585 40.5603L41.9579 44.0882C42.5286 45.2777 43.2155 46.7421 43.7757 47.9315H42.5604L41.0701 44.7408H40.5735V47.9315H39.4637V40.5603H40.5735V43.6985H40.9857C41.4188 42.6774 41.8625 41.5712 42.2855 40.5603H43.4585Z" fill="#33AA47" />
		<path d="M46.6276 45.9311L46.7227 46.7737H46.7649L46.86 45.9311L47.7694 40.5603H48.8896L47.4628 47.9315H46.025L44.5983 40.5603H45.7187L46.6276 45.9311Z" fill="#33AA47" />
		<path d="M52.7553 47.9316V45.4465H51.0536V47.9316H49.9547V42.4558C49.9547 41.9386 50.1601 41.4426 50.5258 41.077C50.8915 40.7113 51.3875 40.5059 51.9046 40.5059C52.4218 40.5059 52.9177 40.7113 53.2834 41.077C53.6491 41.4426 53.8545 41.9386 53.8545 42.4558V47.9316H52.7553ZM51.0536 44.3932H52.7553V42.3612C52.7447 42.1426 52.6505 41.9365 52.4921 41.7855C52.3337 41.6345 52.1232 41.5503 51.9044 41.5503C51.6856 41.5503 51.4752 41.6345 51.3167 41.7855C51.1583 41.9365 51.0641 42.1426 51.0536 42.3612V44.3932Z" fill="#33AA47" />
		<path d="M57.1313 40.5603H61.0311V41.5921H59.6361V47.9315H58.5368V41.5922H57.1313V40.5603Z" fill="#33AA47" />
		<path d="M66.1164 47.9316H65.0173V44.7409H63.3155V47.9316H62.2161V40.5603H63.3152V43.6985H65.017V40.5603H66.1162L66.1164 47.9316Z" fill="#33AA47" />
		<path d="M68.7219 46.0994C68.7243 46.3234 68.815 46.5375 68.9743 46.6951C69.1336 46.8527 69.3487 46.9411 69.5728 46.9411C69.7969 46.9411 70.0119 46.8527 70.1712 46.6951C70.3306 46.5375 70.4213 46.3234 70.4237 46.0994V40.5603H71.5228V46.0361C71.5246 46.2934 71.4756 46.5484 71.3784 46.7866C71.2812 47.0248 71.1379 47.2414 70.9567 47.4239C70.7754 47.6064 70.5598 47.7513 70.3224 47.8502C70.0849 47.949 69.8302 47.9999 69.573 47.9999C69.3157 47.9999 69.0611 47.949 68.8236 47.8502C68.5861 47.7513 68.3705 47.6064 68.1893 47.4239C68.0081 47.2414 67.8647 47.0248 67.7676 46.7866C67.6704 46.5484 67.6213 46.2934 67.6231 46.0361V40.5603H68.7219V46.0994Z" fill="#33AA47" />
		<path d="M75.8721 47.9315L74.7095 44.7407H74.1286V47.9315H73.0293V40.5602H74.9843C75.2373 40.5574 75.4884 40.606 75.7221 40.703C75.9559 40.8 76.1676 40.9433 76.3445 41.1244C76.5213 41.3055 76.6596 41.5205 76.751 41.7566C76.8424 41.9926 76.885 42.2447 76.8763 42.4976V42.7927C76.8915 43.1571 76.7982 43.5178 76.6082 43.829C76.4181 44.1403 76.1399 44.3881 75.8088 44.5409C76.1788 45.5937 76.6649 46.9207 77.024 47.9314L75.8721 47.9315ZM74.1286 43.6985H74.9844C75.0907 43.6979 75.1959 43.676 75.2937 43.6341C75.3915 43.5923 75.48 43.5313 75.5539 43.4547C75.6277 43.3782 75.6856 43.2876 75.7239 43.1884C75.7623 43.0891 75.7804 42.9832 75.7772 42.8769V42.4134C75.7804 42.3071 75.7623 42.2012 75.7239 42.1019C75.6856 42.0027 75.6277 41.9122 75.5539 41.8356C75.48 41.7591 75.3915 41.6981 75.2937 41.6562C75.1959 41.6143 75.0907 41.5925 74.9844 41.5919H74.1286V43.6985Z" fill="#33AA47" />
		<path d="M80.0821 44.3933H82.0902V46.0469C82.0902 46.564 81.8848 47.06 81.5191 47.4256C81.1534 47.7913 80.6575 47.9968 80.1403 47.9968C79.6232 47.9968 79.1272 47.7913 78.7615 47.4256C78.3959 47.06 78.1904 46.564 78.1904 46.0469V42.4558C78.1878 42.1995 78.2361 41.9452 78.3324 41.7077C78.4287 41.4701 78.5711 41.254 78.7515 41.0718C78.9318 40.8896 79.1465 40.745 79.3831 40.6463C79.6196 40.5476 79.8734 40.4968 80.1298 40.4968C80.3861 40.4968 80.6399 40.5476 80.8765 40.6463C81.1131 40.745 81.3277 40.8896 81.5081 41.0718C81.6884 41.254 81.8308 41.4701 81.9271 41.7077C82.0234 41.9452 82.0717 42.1995 82.0691 42.4558V42.8981H81.0023V42.3613C80.9903 42.1423 80.8949 41.9362 80.7356 41.7855C80.5764 41.6347 80.3654 41.5507 80.1461 41.5507C79.9268 41.5507 79.7159 41.6347 79.5566 41.7855C79.3974 41.9362 79.302 42.1423 79.29 42.3613V46.1204C79.2975 46.341 79.3904 46.5501 79.5491 46.7035C79.7078 46.8569 79.9199 46.9426 80.1406 46.9426C80.3613 46.9426 80.5734 46.8569 80.7321 46.7035C80.8908 46.5501 80.9837 46.341 80.9913 46.1204V45.4259H80.0824L80.0821 44.3933Z" fill="#33AA47" />
		<path d="M86.2369 47.9316V45.4465H84.5351V47.9316H83.4363V42.4558C83.4363 41.9386 83.6418 41.4426 84.0075 41.077C84.3731 40.7113 84.8691 40.5059 85.3862 40.5059C85.9034 40.5059 86.3993 40.7113 86.765 41.077C87.1307 41.4426 87.3361 41.9386 87.3361 42.4558V47.9316H86.2369ZM84.5351 44.3932H86.2369V42.3612C86.2266 42.1424 86.1325 41.936 85.974 41.7848C85.8156 41.6336 85.605 41.5493 85.386 41.5493C85.167 41.5493 84.9564 41.6336 84.7979 41.7848C84.6395 41.936 84.5454 42.1424 84.5351 42.3612V44.3932Z" fill="#33AA47" />
		<path d="M89.8972 46.0994C89.8996 46.3234 89.9903 46.5374 90.1496 46.695C90.3089 46.8526 90.5239 46.9409 90.7479 46.9409C90.972 46.9409 91.187 46.8526 91.3462 46.695C91.5055 46.5374 91.5962 46.3234 91.5986 46.0994V40.5603H92.6979V46.0361C92.6997 46.2934 92.6506 46.5484 92.5534 46.7866C92.4563 47.0248 92.3129 47.2414 92.1317 47.4239C91.9505 47.6064 91.7349 47.7513 91.4974 47.8502C91.2599 47.949 91.0053 47.9999 90.748 47.9999C90.4908 47.9999 90.2361 47.949 89.9986 47.8502C89.7612 47.7513 89.5456 47.6064 89.3643 47.4239C89.1831 47.2414 89.0398 47.0248 88.9426 46.7866C88.8454 46.5484 88.7963 46.2934 88.7982 46.0361V40.5603H89.8972V46.0994Z" fill="#33AA47" />
	</svg>


);

export default KVALogo;