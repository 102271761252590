import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { Guides } from "./components/guides/Guides";
import { TabbedNavigation } from "../../components/TabbedNavigation";
import { ROUTES } from "../../skeleton/Routes";


// RESOLVED: implementation of whole page

const Header = styled.div`
    margin-bottom: 32px;
`;


export const ManualsPage = () => {
    const { t } = useTranslation();

    return (
        <>
            <Header>
            <h1>{t("pages.help.headers.helpAndContact")}</h1>
            </Header>

            <div>
                <TabbedNavigation nodes={[
                    { label: t("pages.help.tabs.guides"), route: ROUTES.Manuals },
                    { label: t("pages.help.tabs.faq"), route: ROUTES.Faq },
                    { label: t("pages.help.tabs.contact"), route: ROUTES.Contact }
                ]}/>
                <Guides />
            </div>
        </>
    );
};