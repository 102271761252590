import styled from "@emotion/styled";
import { IconCircleCheckSelected, IconContainerMedium, IconInfo } from "../Icons";
import { styles } from '../../styles';
import { Container, ContainerStatusReason } from "../../types/Container";
import { ContainerCard } from "./ContainerCard";
import { useTranslation } from "react-i18next";
import { useEffectOnce } from "usehooks-ts";

type SelectableContainerCardProps = {
    item: Container,
    selected?: boolean;
    onClick?: () => void;
    isLocked?: boolean;
}

const StyledSelectableContainerCard = styled('div') <SelectableContainerCardProps>`
    cursor: ${({ isLocked }) => isLocked ? 'not-allowed' : 'pointer'};
    color: ${styles.textColorSecondary700};
`;

type ContainerContentProps = {
    selected?: boolean;
    isLocked?: boolean;
};

const SelectableContainerContent = styled.div<ContainerContentProps>`
    padding: 12px;
    border: ${({ selected }) => selected ? '1px solid #3371EA' : `1px solid ${styles.neutral200}`};
    border-radius: 8px;
    cursor: ${({ isLocked }) => isLocked ? 'not-allowed' : 'pointer'};
    box-shadow: ${({ selected }) => selected ? 'none' : styles.boxShadowBottomLight};
    position: relative;
    top: 0;
    right: 0;
    transition: all 0.1s ease-in;  
    background-color: ${({ isLocked }) => isLocked ? '#FCFCFC' : 'white'};
    pointer-events: ${({ isLocked }) => isLocked ? 'none' : 'auto'};

    &:hover {
        box-shadow: ${({ selected, unselectable }) => (selected || unselectable) ? 'none' : styles.boxShadowContainerHovered};
        top: ${({ selected, unselectable }) => (selected || unselectable) ? '0px' : '-8px'};        
    }
`;

const TopRow = styled.div`
    display: flex;
    justify-content: flex-end;
    height: 24px;
`;

const ContentRow = styled.div<{ color: string }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    text-align: center;

    svg {
        margin-bottom: 4px;

        path {
            fill: ${props => props.color};;
        }
        path:last-child {
            stroke: ${props => props.color};;
        }
    }
`;

const ContainerName = styled.p`
    font-family: 'Satoshi Bold';
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 22px;

    @media (max-width: 480px) {
        font-size: 12px;
        line-height: 20px;  
    }
`;

const ContainerId = styled.p`
    font-size: 0.625rem;
    line-height: 18px;
    margin-bottom: 25px;

    @media (max-width: 480px) {
        font-size: 12px;
        line-height: 20px;   
    }
`;

const ContainerShortName = styled.span`
    position: absolute;
    top: 50px;
    font-family: 'Satoshi Bold';
    font-size: 11px;
`;

const InfoTextWrapper = styled.div`
    display: flex;
    gap: 12px;
    margin-top: 12px;

    p {
        max-width: 292px;
        font-size: 10px;
        line-height: 18px;
        margin-right: 24px;
    }

    svg {
        min-width: 24px;
        min-height: 24px;
    }
`;

export const SelectableContainerCard: React.FC<SelectableContainerCardProps> = ({ item, selected, onClick }) => {
    const { t } = useTranslation();

    const containerHasRemovalDayAssigned = item.removalArea && item.removalArea.weekdays && item.removalArea.weekdays.length > 0;
    const emptyingAlreadyScheduledOnThisDate = false;

    const isLocked = !containerHasRemovalDayAssigned || emptyingAlreadyScheduledOnThisDate || item.statusReason === ContainerStatusReason.Pending;

    const handleOnClick = () => {
        if (!isLocked && onClick) {
            onClick();
        }
    };

    useEffectOnce(() => {
        if (selected) { handleOnClick() }
    })

    return (
        <StyledSelectableContainerCard item={item} onClick={handleOnClick} isLocked={isLocked}>
            <ContainerCard width={200}>
                <SelectableContainerContent selected={selected && !isLocked} isLocked={isLocked}>
                    <TopRow>
                        {selected && !isLocked && <IconCircleCheckSelected />}
                    </TopRow>
                    <ContentRow color={item.color}>
                        <IconContainerMedium />
                        <ContainerShortName>{item.shortName}</ContainerShortName>
                        <ContainerName>{item.displayName}</ContainerName>
                        <ContainerId>ID-Nr. {item.awmId}</ContainerId>
                    </ContentRow>
                </SelectableContainerContent>
            </ContainerCard>

            {!containerHasRemovalDayAssigned && (
                <InfoTextWrapper>
                    <IconInfo />
                    <p>{t("components.containers.infoTexts.noRemovalDayAssigned")}</p>
                </InfoTextWrapper>
            )}

            {emptyingAlreadyScheduledOnThisDate && (
                <InfoTextWrapper>
                    <IconInfo />
                    <p>{t("components.containers.infoTexts.emptyingScheduledOnThisDate")}</p>
                </InfoTextWrapper>
            )}
        </StyledSelectableContainerCard>
    )
};
