import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import styled from "@emotion/styled";
import { EmptyingsListView } from './EmptyingsListView';
import { EmptyingsCalendar } from './EmptyingsCalendar';
import { useSessionStorage } from 'usehooks-ts';
import { IconCalendarView, IconListView, IconChevronLeft, IconChevronRight } from '../../../../components/Icons';
import { CalendarText } from '../../../../components/StyledComponents';
import { styles } from '../../../../styles';
import { ContainerRef } from '../../../../types/Container';
import {  useTranslation } from 'react-i18next';
import { NumberRange } from '../../../../types/NumberRange';
import { ContainerDropdownSelector } from '../../../../components/containers/ContainerDropdownSelector';

type ButtonSquareSwitchProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  dark?: boolean;
  margin?: string;
  active?: boolean;
}

type ButtonSquareProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  margin?: string;
}

const Wrapper = styled.div`
    height: 748px;
    border: 1px solid ${styles.neutral200};
    border-radius: 8px;
    padding: 24px;
`;

const ButtonSquareSwitch = styled.button<ButtonSquareSwitchProps>`
  width: 40px;
  height: 40px;
  background: ${props => props.active ? styles.textColorSecondary700 : '#fff'};
  color: ${props => props.active ? '#fff' : styles.textColorSecondary700};
  border: 1px solid ${props => props.active ? styles.textColorSecondary700 : styles.neutral200};
  cursor: pointer;
  border-radius: 4px;
  margin:  ${props => props.margin || 0};
  box-shadow: ${props => props.active ? `none` : styles.boxShadowBottomLight};

  rect, path {
    stroke: ${props => props.active ? '#fff' : styles.textColorSecondary700};
  }
`;

const ButtonSquare = styled.button<ButtonSquareProps>`
  width: 40px;
  height: 40px;
  background-color: #fff;
  border: 1px solid ${styles.neutral200};
  cursor: pointer;
  border-radius: 4px;
  margin:  ${props => props.margin || 0};
`;

type ViewSelectorProps = {
  view: string;
  setView: Function;
};

const ViewSelector: React.FC<ViewSelectorProps> = ({ view, setView }) => (
  <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '50%' }}>
    <ButtonSquareSwitch active={view === 'calendar'} onClick={() => setView('calendar')}><IconCalendarView /></ButtonSquareSwitch>
    <ButtonSquareSwitch active={view === 'list'} onClick={() => setView('list')} margin="0 0 0 8px"><IconListView /></ButtonSquareSwitch>
  </Box>
);

type MonthNumber = NumberRange<0, 12>;
type MonthSwitcherProps = {
  month: MonthNumber;
  setMonth: (month: MonthNumber) => void;
};

const MonthSwitcher: React.FC<MonthSwitcherProps> = ({ month, setMonth }) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '50%' }}>
      <ButtonSquare onClick={() => setMonth((month > 0 ? month - 1 : 11) as MonthNumber)}><IconChevronLeft /></ButtonSquare>
      <CalendarText>{t(`dates.months.${month}`)}</CalendarText>
      <ButtonSquare onClick={() => setMonth((month < 11 ? month + 1 : 0) as MonthNumber)}><IconChevronRight /></ButtonSquare>
    </Box>
  );
};

type YearSwitcherProps = {
  year: number;
  setYear: React.Dispatch<React.SetStateAction<number>>;
};

const YearSwitcher: React.FC<YearSwitcherProps> = ({ year, setYear }) => (
  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '50%' }}>
    <ButtonSquare onClick={() => setYear(year - 1)}><IconChevronLeft /></ButtonSquare>
    <CalendarText>{year}</CalendarText>
    <ButtonSquare onClick={() => setYear(year + 1)}><IconChevronRight /></ButtonSquare>
  </Box>
);

const calculateDateRange = (month: number, year: number): { from: Date, to: Date } => {
  const ONE_DAY = 24 * 60 * 60 * 1000;
  const firstDayOfSelectedMonth = new Date(year, month);
  let mondayBeforeFirstDayOfMonth = new Date(firstDayOfSelectedMonth.getTime() - (firstDayOfSelectedMonth.getDay() - 1) * ONE_DAY);
  if (firstDayOfSelectedMonth.getDay() > 5) {
    mondayBeforeFirstDayOfMonth = new Date(mondayBeforeFirstDayOfMonth.getTime() + 7 * ONE_DAY);
  }
  const aDayInNextMonth = new Date(firstDayOfSelectedMonth.getTime() + 40 * ONE_DAY);
  const lastDayOfSelectedMonth = new Date(aDayInNextMonth.getTime() - (aDayInNextMonth.getDate() + 1) * ONE_DAY);
  const fridayAfterLastDayOfMonth = new Date(lastDayOfSelectedMonth.getTime() + (5 - lastDayOfSelectedMonth.getDay()) * ONE_DAY);
  return { from: mondayBeforeFirstDayOfMonth, to: fridayAfterLastDayOfMonth };
}

export const EmptyingsOverview = () => {
  const [viewType, setViewType] = useSessionStorage<'list' | 'calendar'>('emptying-schedule-viewtype', 'calendar');
  const [month, setMonth] = useSessionStorage<MonthNumber>('emptying-schedule-month', new Date().getMonth() as MonthNumber);
  const [year, setYear] = useSessionStorage<number>('emptying-schedule-year', new Date().getFullYear());
  const [container, setContainer] = useSessionStorage<ContainerRef | undefined>('emptying-schedule-container', undefined);
  const [fromTo, setFromTo] = useState(calculateDateRange(month, year));

  const updateMonthWithSupportForSwitchingYear = (updatedMonth: MonthNumber) => {
    if (month === 11 && updatedMonth === 0) {
      setYear(year+1);
    }
    if (month === 0 && updatedMonth === 11) {
      setYear(year-1);
    }
    setMonth(updatedMonth);
  };

  useEffect(() => {
    setFromTo(calculateDateRange(month, year));
  }, [year, month]);

  return (
    <Wrapper>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1rem', gap: '12px' }}>
        <ContainerDropdownSelector initialContainer={container} onChange={setContainer} />
        <ViewSelector view={viewType} setView={setViewType} />
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', gap: '12px' }}>
        <MonthSwitcher month={month} setMonth={updateMonthWithSupportForSwitchingYear} />
        <YearSwitcher year={year} setYear={setYear} />
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1rem' }}>
        {viewType === 'calendar' && <EmptyingsCalendar from={fromTo.from} to={fromTo.to} visibleMonth={month} container={container} />}
        {viewType === 'list' && <EmptyingsListView from={fromTo.from} to={fromTo.to} visibleMonth={month} container={container} />}
      </div>
    </Wrapper>
  );
};