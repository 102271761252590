import styled from '@emotion/styled';
import { useTranslation } from "react-i18next";
import Button from './Button';
import { styles } from "../styles";
import { NavLink } from 'react-router-dom';
import { ROUTES } from '../skeleton/Routes';

const Banner = styled.div`
    display: flex;
    flex-direction: column;
    padding: 16px;
    background-color: #fff;
    box-shadow: ${styles.boxShadowBottomLight};
    margin: 0 24px;
    border-radius: 8px;

    button {
        padding: 0;
    }

    @media (max-width: 962px) {
        flex-direction: row;
        margin: 0;
        border-radius: 0;
        justify-content: center;
        align-items: center;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;

        button {
            font-size: 12px;
            width: 50%;
            max-width: 165px;
            height: 32px;
        }
    }
`;

const BannerText = styled.p`
    margin-bottom: 12px;
    color:  ${styles.textColorSecondary700};
    text-align: center;
    max-width: 168px;

    @media (max-width: 962px) {
        margin-right: 16px;
        width: 50%;
    }
`;

const OneClickBanner = ({ disabled} : {disabled: boolean}) => {
    const { t } = useTranslation();

    return (
        <Banner className="one-click-banner">
            <BannerText>
                {t("navigation.left.bannerText")}
            </BannerText>
            <Button disabled={disabled}>
                <NavLink to={ROUTES.OneClickEmptying} state={{ triggeredByOneClickEmptying: true }}>
                    {t("navigation.left.bannerButtonText")}
                </NavLink>
            </Button>
        </Banner>
    )
}

export default OneClickBanner;
