import styled from "@emotion/styled";
import { CircularProgress } from '@mui/material';
import { styles } from './../styles';


type LoaderProps = {
    height?: string;
    margin?: string;
    svgMargin?: string;
    padding?: string;
    width?: string;
    messageText?: string;
    alignItems?: string;
    justifyContent?:string;
}

const Wrapper = styled.div<{ height?: string, margin?: string, padding?: string, width?: string, svgMargin?: string, alignItems?: string, justifyContent?: string }>`
    width:  ${props => props.width || "100%"};
    height: ${props => props.height || "initial"};
    display: flex;
    align-items: ${props => props.alignItems || "center"};
    justify-content: ${props => props.justifyContent || "center"};
    flex-direction: column;
    margin: ${props => props.margin || 0};
    padding: ${props => props.padding || 0};

    svg {
        margin: ${props => props.svgMargin || 0};
    }
`;

const MessageText = styled.p`
    color: ${styles.textColorSecondary700};
    margin-top: 4px;
`;

export const Loader: React.FC<LoaderProps> = ({ height, margin, padding, width, svgMargin, messageText, justifyContent, alignItems }) => (
    <Wrapper height={height} margin={margin} padding={padding} width={width} svgMargin={svgMargin} justifyContent={justifyContent} alignItems={alignItems}>
        <CircularProgress sx={{ color: '#33AA47'}} size="1.9rem"  />
        {messageText && <MessageText>{messageText}</MessageText>}
    </Wrapper>
)