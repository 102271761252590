import styled from "@emotion/styled";
import { LinksCarousel } from "./LinksCarousel";
import { Linkbox } from "./Linkbox";
import { useTranslation } from "react-i18next";
import { useContext, useState } from "react";
import { LinkboxData } from "./LinkboxData";
import { ROUTES } from "../../../../skeleton/Routes";
import Modal from "../../../../components/Modal";
import { NotificationFormResultInfo, NotificationsForm } from "../../../../components/notifications/NotificationsForm";
import { Toast } from "../../../../components/Toast";
import { UserContext } from "../../../../contexts/UserContext";


const StyledLinksSection = styled.section`
`;

const LinkboxesWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 24px;

    @media (max-width: 480px) {
        display: none;
    },
`;



export const LinksSection = () => {
    const { t } = useTranslation();
    const userContext = useContext(UserContext);
    const [isNotificationsModalOpen, setNotificationsModalOpen] = useState(false);

    const initiallyLoadAvailableLinks = (): LinkboxData[] => {
        const links: LinkboxData[] = [];
        links.push({
            infoText: t('pages.home.helpInfobox.infoText'),
            buttonText: t('pages.home.helpInfobox.buttonText'),
            onClick: {
                route: {
                    target: ROUTES.Manuals
                }
            },
            type: 'help'
        })
        if (userContext.accessPermission?.roles?.container && userContext.accessPermission?.roles?.permission) {
            links.push({
                infoText: t('pages.home.containerInfobox.infoText'),
                buttonText: t('pages.home.containerInfobox.buttonText'),
                onClick: {
                    route: {
                        target: ROUTES.ContainerOverview,
                        state: "auto-open-new-dlg"
                    }
                },
                type: 'register-container'
            });
        }
        if (userContext.accessPermission?.roles?.permission) {
            links.push({
                infoText: t('pages.home.organizationInfobox.infoText'),
                buttonText: t('pages.home.organizationInfobox.buttonText'),
                onClick: {
                    route: {
                        target: ROUTES.Organisation,
                        state: "auto-open-new-dlg"
                    }
                },
                type: 'my-org'
            });
        }
        links.push({
            infoText: t('pages.home.notificationsInfobox.infoText'),
            buttonText: t('pages.home.notificationsInfobox.buttonText'),
            onClick: {
                action: () => setNotificationsModalOpen(true)
            },
            type: 'notifications'
        })
        return links;
    }

    const [infoboxData] = useState<LinkboxData[]>(initiallyLoadAvailableLinks);

    const [toastInfo, setToastInfo] = useState<NotificationFormResultInfo>();
    const onNotificationsFormClosed = (resultInfo?: NotificationFormResultInfo) => {
        setNotificationsModalOpen(false);
        if (resultInfo && resultInfo.type !== 'cancel') {
            setToastInfo(resultInfo);
        }
    }

    const handleToastCloseComplete = () => {
        setToastInfo(undefined);
    };

    return (
        <>
            <StyledLinksSection>
                <LinksCarousel data={infoboxData} />
                <LinkboxesWrapper>
                    {
                        infoboxData.map(item => (
                            <Linkbox key={item.buttonText} item={item} />
                        ))
                    }
                </LinkboxesWrapper>
            </StyledLinksSection>
            {isNotificationsModalOpen && <Modal onClose={onNotificationsFormClosed}><NotificationsForm onClose={onNotificationsFormClosed} /></Modal>}
            {toastInfo && toastInfo.type !== 'cancel' && <Toast message={toastInfo.message} type={toastInfo.type} onCloseComplete={handleToastCloseComplete} />}
        </>
    );
};