import styled from "@emotion/styled";
import { styles } from "../../../../styles";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { Controller, useForm } from "react-hook-form";
import { CardStyledContainersList } from "../../../../components/containers/CardStyledContainersList";
import { UserContext } from "../../../../contexts/UserContext";
import { useInvitationUpdateMutation } from "../../../../data/invitations";
import { EndUserRole, endUserRoleToUserRoleInfo, userRoleInfoToEndUserRole } from "../../../../types/UserRolesInfo";
import { Container } from "../../../../types/Container";
import Button from '../../../../components/Button';
import Select from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';
import { IconClose, IconExclamationMark, IconQuestionCircleLineSmall } from "../../../../components/Icons";
import { EmployeeListItem } from "../employees-list/Helpers";
import { Invitation } from "../../../../types/Invitation";
import { MenuItem } from "@mui/material";
import { useContactAssociationUpdateMutation } from "../../../../data/contacts";
import { ContactAssociation } from "../../../../types/ContactAssociation";
import { useContainersQuery } from "../../../../data/containers";
import { MessageWrapper } from "../../../../components/MessageWrapper";
import { Loader } from "../../../../components/Loader";
import { Salutation } from "../../../../types/Contact";


const Wrapper = styled.div`
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    max-height: 80vh;

    @media (max-width: 480px) {
        max-height: 100vh;
        height: 100%;
    }
`;

const ContentWrapper = styled.div`
    overflow-y: auto;
    position: relative;
    max-height: 80vh;
    padding-right: 16px;

    @media (max-width: 480px) {
        max-height: 100vh;
        height: 100%;
    }s
`;

const FormHeading = styled.h2`
    color: ${styles.textColorSecondary700};
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #fff;
    padding-top: 24px;
    padding-bottom: 24px;
    padding-right: 24px;
    border-radius: 8px;
    margin-bottom: 0;

    svg {
        cursor: pointer;
    }
`;

const FormRow = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    color: ${styles.textColorSecondary700};
`;

const BottomRow = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    margin-top: 48px;
    margin-bottom: 68px;

    button {
        width: 156px;
    }

    @media (max-width: 480px) {
        margin-bottom: 0;
        flex-direction: column;
        align-items: flex-end;
        gap: 12px;
        width: 100%;

        button {
            width: 100%;
            margin-bottom: 0;
        }
    }
`;

const StyledH3 = styled.h3`
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 8px;
`;

const StyledH4 = styled.h4`
    font-size: 10px;
    line-height: 18px;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    gap: 10px;

    svg {
        cursor: pointer;
    }
`;

const IconWrapper = styled.div`
    display: flex;
`;


const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-right: 12px;

    &:last-child {
        margin-right: 0;
    }
`;

const NameContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

type StyledInputProps = {
    hasError?: boolean;
};

const StyledInput = styled.input<StyledInputProps>`
    width: calc(50% - 24px));
    padding: 10px 16px;
    flex: 1;
    box-shadow: ${styles.boxShadowInset};
    background-color: #fff;
    border: 1px solid ${props => props.hasError ? '#ed2b2b' : styles.neutral200};
    border-radius: 4px;
    box-sizing: border-box;
    line-height: 22px;
    font-size: 14px;
    color: ${styles.textColorSecondary700};
    font-family: 'Satoshi Regular';

    &:focus {
        border: 1px solid #3371EA;
        outline: none;
    }

    &:disabled {
        background-color: #f5f5f5;
    }
`;

const StyledSelect = styled(Select)(() => ({
    boxShadow: styles.boxShadowInset,
    height: '44px',
    backgroundColor: '#fff',
    borderRadius: '4px',
    flex: '1',
    boxSizing: 'border-box',
    padding: '0',
    fontFamily: 'Satoshi Regular',
    color: styles.textColorSecondary700,

    '.MuiSelect-icon': {
        color: '#26374A',
    },

    '& .MuiSelect-select': {
        paddingTop: '11px',
        paddingBottom: '11px',
        lineHeight: '22px',
        fontSize: '14px',
        boxShadow: styles.boxShadowInset,
    },
    '& .MuiOutlinedInput-root': {
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#e0e1e0',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#e0e1e0',
        },
    },
    '.MuiOutlinedInput-notchedOutline': {
        borderWidth: '1px',
    },
}));

const ErrorWrapper = styled.div`
    margin-top: 12px;
    display: flex;
    align-items: center;
    font-size: 10px;
    line-height: 18px;

    svg {
        margin-right: 8px;
    }
`;
/*
const StyledFormControlLabel = styled(FormControlLabel)`
    height: 28px;
    input {
        height: 28px;
        width: 28px;
        box-sizing: border-box;
    }

    span {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        font-family: 'Satoshi Regular';
        font-size: 14px;
    }
`;

const StyledCheckbox = styled(Checkbox)`
  &.MuiCheckbox-root {
    color: #E0E1E0;
    
    &.Mui-checked {
      color: #33AA47;
    }
  }
`;

*/

type EditEmployeeModalContentProps = {
    employeeToEdit: EmployeeListItem;
    onClose: () => void;
}
type FormData = {
    salutation: '' | 'mr' | 'mrs';
    firstName: string, lastName: string;
    role: '' | EndUserRole;
    containers: Container[];
    sendInvitationRedeemedNotification: boolean;
}
export const EditEmployeeModalContent = (props: EditEmployeeModalContentProps) => {
    const userContext = useContext(UserContext);
    const containersQuery = useContainersQuery(userContext.accessPermission?.accountRef);
    if (containersQuery.data) {
        return <InternalEditEmployeeModalContent {...props} containers={containersQuery.data}  />
    }
    return null;
}
type InternalEditEmployeeModalContentProps = {
    employeeToEdit: EmployeeListItem;
    onClose: () => void;
    containers: Container[];
}
const InternalEditEmployeeModalContent = ({ employeeToEdit, containers, onClose }: InternalEditEmployeeModalContentProps) => {

    const { register, handleSubmit, watch, control, formState: { errors } } = useForm<FormData>({
        defaultValues: {
            containers: employeeToEdit.containers.filter(cRef => containers.find(c => c.id === cRef.id)).map(cRef => containers.filter(c => c.id === cRef.id)[0]),
            firstName: employeeToEdit.firstName,
            lastName: employeeToEdit.lastName,
            salutation: (employeeToEdit.salutation === Salutation.Misses) ? 'mrs': 'mr',
            role: userRoleInfoToEndUserRole(employeeToEdit.typedObject.roles || { container: false, payment: false, permission: false }),
            sendInvitationRedeemedNotification: employeeToEdit.type === 'invitation' && (employeeToEdit.typedObject as Invitation).sendInvitationRedeemedNotification
        }
    });

    const invitationUpdateMutation = useInvitationUpdateMutation();
    const contactAssociationUpdateMutation = useContactAssociationUpdateMutation();
    const { t } = useTranslation();

    const onSubmit = (async (formData: FormData) => {
        if (employeeToEdit.type === 'invitation') {
            const updatedInvitation: Invitation = {...employeeToEdit.typedObject as Invitation};
            updatedInvitation.containers = formData.containers ? formData.containers.map(c => { return {id: c.id}}): [];
            updatedInvitation.roles = formData.role === '' ? { container: false, payment: false, permission: false}: endUserRoleToUserRoleInfo(formData.role);
            updatedInvitation.salutation = formData.salutation === 'mrs' ? Salutation.Misses: Salutation.Mister;
            updatedInvitation.firstName = formData.firstName;
            updatedInvitation.lastName = formData.lastName;
            updatedInvitation.sendInvitationRedeemedNotification = formData.sendInvitationRedeemedNotification;
            await invitationUpdateMutation.mutateAsync(updatedInvitation);
        }
        if (employeeToEdit.type === 'contact') {
            const updatedContactAssociation: ContactAssociation = {...employeeToEdit.typedObject as ContactAssociation};
            updatedContactAssociation.containerRefs = formData.containers ? formData.containers.map(c => { return {id: c.id}}): [];
            updatedContactAssociation.roles = formData.role === '' ? { container: false, payment: false, permission: false}: endUserRoleToUserRoleInfo(formData.role);
            await contactAssociationUpdateMutation.mutateAsync(updatedContactAssociation);
        }
        onClose();
    });

    const noErrorsOrLoaders = (
        !invitationUpdateMutation.isError && 
        !contactAssociationUpdateMutation.isError && 
        !invitationUpdateMutation.isLoading && 
        !contactAssociationUpdateMutation.isLoading
    );

    
    const enableSubmit = watch('firstName') !== '' && watch('lastName') !== '' && watch('containers').length > 0;

    return (
        <Wrapper>          
        <form>
            <FormHeading>{t("pages.myOrganization.editEmployees")}<div onClick={onClose}><IconClose /></div></FormHeading>
            {noErrorsOrLoaders && <ContentWrapper>

            <FormRow style={{marginTop: '24px'}}>
                <StyledH3>{t("pages.myOrganization.invitationForm.personalDetails")}</StyledH3>
                <StyledH4>{t("pages.myOrganization.invitationForm.salutation")}</StyledH4>
                <Controller
                    name="salutation"
                    control={control}
                    rules={{ required: false }}
                    render={({ field }) => (
                        <StyledSelect
                            disabled={employeeToEdit.type === 'contact'}
                            sx={{
                                marginBottom: '12px',
                                '.MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#e0e1e0 !important'
                                },
                                '&.Mui-disabled': {
                                    backgroundColor: '#f5f5f5',
                                },
                            }}
                            {...field}
                            onChange={(e) => {
                                field.onChange(e.target.value);
                            }}
                        >
                            <MenuItem value="mr">{t("pages.myOrganization.invitationForm.mr")}</MenuItem>
                            <MenuItem value="mrs">{t("pages.myOrganization.invitationForm.mrs")}</MenuItem>
                        </StyledSelect>
                    )}
                /> 
                <NameContainer>
                    <InputContainer>
                        <StyledH4>{t("pages.myOrganization.invitationForm.firstName")}</StyledH4>
                        { employeeToEdit.type === 'contact' && 
                            <StyledInput disabled type="text" value={employeeToEdit.firstName} />
                        }
                        { employeeToEdit.type === 'invitation' && 
                            <>
                                <StyledInput hasError={Boolean(errors.firstName)} type="text" placeholder={t("pages.myOrganization.invitationForm.firstName")} {...register("firstName", { required: t('errors.requiredField') })} />
                                {errors.firstName && <ErrorWrapper><IconExclamationMark /> {errors.firstName.message}</ErrorWrapper>}
                            </>
                        }
                    </InputContainer>
                    <InputContainer>
                        <StyledH4>{t("pages.myOrganization.invitationForm.surname")}</StyledH4>
                        { employeeToEdit.type === 'contact' && 
                            <StyledInput disabled type="text" value={employeeToEdit.lastName} />
                        }
                        { employeeToEdit.type === 'invitation' && 
                            <>
                                <StyledInput hasError={Boolean(errors.lastName)} type="text" placeholder={t("pages.myOrganization.invitationForm.surname")} {...register("lastName", { required: t('errors.requiredField') })} />
                                {errors.lastName && <ErrorWrapper><IconExclamationMark />{errors.lastName.message}</ErrorWrapper>}
                            </>
                        }
                    </InputContainer>
                </NameContainer>
            </FormRow>
            <FormRow>
                <StyledH4>{t("pages.myOrganization.invitationForm.email")}</StyledH4>
                <StyledInput disabled type="text" value={employeeToEdit.email} />
            </FormRow>

            <FormRow>
                <StyledH4>
                    {t("pages.myOrganization.invitationForm.role")}
                    <Tooltip arrow componentsProps={{
                        tooltip: {
                            sx: {
                                bgcolor: '#26374A',
                                '& .MuiTooltip-arrow': {
                                    color: '#26374A',
                                },
                            },
                        },
                    }} title={t("pages.myOrganization.invitationForm.roleTooltip")}>
                        <IconWrapper>
                            <IconQuestionCircleLineSmall />
                        </IconWrapper>
                    </Tooltip>
                </StyledH4>
                {<Controller
                    name="role"
                    control={control}
                    defaultValue={'viewer'}
                    rules={{ required: true }}
                    render={({ field }) => (
                        <StyledSelect
                        sx={{
                            '.MuiOutlinedInput-notchedOutline': {
                                borderColor: '#e0e1e0',
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#3371EA',
                                    borderWidth: '1px'
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#e0e1e0',
                                },
                            }}
                            {...field}
                            onChange={(e) => {
                                field.onChange(e.target.value);
                            }}
                        >
                            <MenuItem value="admin">Administrator</MenuItem>
                            <MenuItem value="staff">Staff</MenuItem>
                            <MenuItem value="viewer">Staff (View Only)</MenuItem>
                        </StyledSelect>
                    )}
                /> }
            </FormRow>

            <Controller
                control={control}
                name="containers"
                rules={{ required: { value: true, message: 'At least one container must be selected' } }}
                render={({ field: { onChange } }) => (
                    <CardStyledContainersList
                        cardWidth={200} selectionMode="multi"
                        defaultSelection={employeeToEdit.containers.map(c => c.id)}
                        onChange={onChange} 
                        header={t("pages.myOrganization.invitationForm.containerAssignment")}
                    />
                )} />
            {errors.containers && <span>{errors.containers.message}</span>}

            <BottomRow>
                    { !invitationUpdateMutation.isLoading && !contactAssociationUpdateMutation.isLoading && 
                        <Button buttonVariant="ghost" onClick={() => onClose()}>
                            {t("buttons.abort")}
                        </Button>
                    }
                    <Button disabled={!enableSubmit || invitationUpdateMutation.isLoading || contactAssociationUpdateMutation.isLoading} onClick={handleSubmit(onSubmit)}>
                        { (invitationUpdateMutation.isLoading || contactAssociationUpdateMutation.isLoading) && <Loader/> }
                        { !invitationUpdateMutation.isLoading && !contactAssociationUpdateMutation.isLoading && t("buttons.save") }
                    </Button>
                </BottomRow>
            </ContentWrapper>}
            
            {invitationUpdateMutation.isError && (
                <MessageWrapper messageText={invitationUpdateMutation.error.message} height={"114px"} alignItems={"flex-start"} margin={"24px 0 0"} />
            )}
       
            {contactAssociationUpdateMutation.isError && (
                <MessageWrapper messageText={contactAssociationUpdateMutation.error.message} height={"114px"} alignItems={"flex-start"} margin={"24px 0 0"} />
            )}

            {(invitationUpdateMutation.isLoading || contactAssociationUpdateMutation.isLoading) && (
                <Loader messageText={t("pages.myOrganization.updatingEmployee")} height={"114px"} justifyContent={"flex-start"} margin={"24px 0 0"}/>
            )}
        </form>
        </Wrapper>
    )
}