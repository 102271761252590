import { Button, ButtonProps as MuiButtonProps } from '@mui/material';
import React from 'react';
import styled from '@emotion/styled';
import { styles } from '../styles';

type ButtonVariant = 'primary' | 'ghost';

type CustomButtonProps = MuiButtonProps & {
  buttonVariant?: ButtonVariant;
};

const StyledButton = styled(Button, { 
  shouldForwardProp: (prop) => prop !== 'buttonVariant' 
})<{ buttonVariant: ButtonVariant }>`
  width: 100%;
  font-family: 'Satoshi Bold', sans-serif;
  font-weight: 700;
  margin-bottom: 12px;
  padding: 11px 24px;
  border-radius: 4px;
  cursor: pointer;
  text-transform: none;

  ${({ buttonVariant }) => {
    switch (buttonVariant) {
      case 'primary':
        return `
          background-color: #33AA47;
          color: #fff;
          border: 1.5px solid #33AA47;

          &:hover,
          &:active {
            background-color: #298839;
            border-color: #298839;
          }
        
          &:disabled {
            background-color: ${styles.neutral200};
            border-color: ${styles.neutral200}; 
            color: #FFF;
          }
        `;
      case 'ghost':
        return `
          background: #fff;
          color: #33AA47;
          border: 1.5px solid #33AA47;

          &:hover,
          &:active {
            background-color: #FFF;
            border-color: #298839;
            color: #298839;
          }
        
          &:disabled {
            background-color: #FFF;
            border-color: #FFF;
            color: ${styles.neutral200};
          }
        `;
      default:
        return '';
    }
  }}
`;

const CustomButton: React.FC<CustomButtonProps> = ({ buttonVariant = 'primary', ...props }) => {
  return <StyledButton buttonVariant={buttonVariant} {...props} />;
};

export default CustomButton;