import { useContext } from "react";
import styled from "@emotion/styled"
import { useTranslation } from "react-i18next";
import { UserContext } from "../../contexts/UserContext";
import { styles } from "../../styles";
import Button from '../../components/Button';
import { IconCar, IconCircleCheck, IconEditPen, IconNotificationsOn } from "../../components/Icons";
import { useMemo } from "react";
import { EmptyingSchedulingMode, EmptyingsSchedule } from "./EmptyingSchedulingPage";
import { OrderConfirmedImage } from "./components/OrderConfirmedImage";
import { Todo } from "../../components/Todo";


const Wrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-column-gap: 24px;
`;

const WelcomeSection = styled.section`
    grid-column: span 5;

    @media (max-width: 480px) {
		grid-column: span 8;
	}
`;

const ImageWrapper = styled.div`
    grid-column: span 5;
    margin: 0 auto 48px;
    text-align: center;
    color: ${styles.textColorSecondary700};
    font-size: 16px;
    line-height: 20px;
    font-family: 'Satoshi Bold';

    svg {
        width: 100%;
    }

    p {
        margin-top: 4px;
    }

    @media (max-width: 480px) {
		grid-column: span 8;
	}
`;

const ConfirmationCard = styled.div`
    grid-column: span 5;
    margin-bottom: 128px;
    padding: 24px 16px;
    box-shadow: ${styles.boxShadowBottomLight};
    border-radius: 4px;
    border: 1px solid ${styles.neutral200};
    color: ${styles.textColorSecondary700};

    h2 {
        margin-bottom: 32px;
    }

    @media (max-width: 480px) {
		grid-column: span 8;
	}
`;

const ConfirmationCardRow = styled.div`
    margin-bottom: 16px;
    display: flex;

    span {
        font-family: 'Satoshi Bold';
    }

    svg {
        min-width: 24px;
        margin-right: 12px;
        circle, 
        path {
            stroke: #26374A;
        }
    }
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 32px;
    
    button {
        max-width: 156px;
        margin-bottom: 0;
    }
`;

type ConfirmationViewProps = {
    mode: EmptyingSchedulingMode;
    autoOpenPayment: boolean;
    emptyingSchedules: EmptyingsSchedule[];
    onCompleted: () => void;
}

export const ConfirmationView = ({ mode, autoOpenPayment, emptyingSchedules, onCompleted }: ConfirmationViewProps) => {
    const { i18n, t } = useTranslation();
    const dateFormatter = useMemo(() => new Intl.DateTimeFormat(i18n.language, { dateStyle: 'long' }), [i18n.language]);

    const distinctContainerNames: string[] = [];
    emptyingSchedules.forEach(e => {
        const containerName = e.container.displayName;
        if (distinctContainerNames.indexOf(containerName) < 0) {
            distinctContainerNames.push(containerName);
        }
    });
    let containerNamesText = '';
    distinctContainerNames.forEach((name, index) => {
        if (index === 0) { containerNamesText = name }
        else {
            const joiner = index === distinctContainerNames.length - 1 ? ` ${i18n.language === 'de' ? 'und' : 'and'} ` : ', ';
            containerNamesText = containerNamesText + joiner + name;
        }
    })
    const nextEmptyingInfo: { date: Date | undefined, containers: string[] } = {
        date: undefined,
        containers: []
    }
    emptyingSchedules.forEach(s => {

        const first = s.emptyings.map(e => e.date).find(d => d);
        if (first && (!nextEmptyingInfo.date || first.getTime() <= nextEmptyingInfo.date.getTime())) {
            nextEmptyingInfo.date = first;
            if (first.getTime() < nextEmptyingInfo.date.getTime()) {
                nextEmptyingInfo.containers = [];
            }
            nextEmptyingInfo.containers.push(s.container.displayName)
        }
    })
    const dateFormat = new Intl.DateTimeFormat(i18n.language, { dateStyle: 'long' });

    const userContext = useContext(UserContext);
    const currentUser = userContext.loggedInUser;
    const notificationsAreNotSet = currentUser?.contact.notificationTypes ? currentUser?.contact.notificationTypes.length === 0 : true;

    return (
        <Wrapper>
            <WelcomeSection>
                <h1>{t(mode === 'single' ? (autoOpenPayment ? "pages.emptying.oneclick.header": "pages.emptying.onetime.header"): "pages.emptying.series.header")}</h1>
            </WelcomeSection>

            <ImageWrapper>
                <OrderConfirmedImage />
                <p>{t("pages.emptying.orderConfirmation.lidOnIt")}</p>
            </ImageWrapper>

            <ConfirmationCard>
                <h2>{t("pages.emptying.orderConfirmation.header")}</h2>
                {mode === 'single' &&
                    <ConfirmationCardRow>
                        <IconCircleCheck />
                        <p>{t("pages.emptying.orderConfirmation.oneTimeEmptyingFor")}
                            <span>{emptyingSchedules[0].container.displayName}</span> {t("pages.emptying.orderConfirmation.hasBeenOrdered")}
                            {t("pages.emptying.orderConfirmation.itWillTakePlaceOn")}
                            <span>{dateFormatter.format(emptyingSchedules[0].emptyings[0].date)}.</span></p>
                    </ConfirmationCardRow>
                }
                {mode === 'multi' &&
                    <>
                        <ConfirmationCardRow>
                            <IconCircleCheck />
                            <p>{t("pages.emptying.orderConfirmation.serialEmptyingFor")} <span>{containerNamesText}</span> {t("pages.emptying.orderConfirmation.hasBeenOrdered")}</p>
                        </ConfirmationCardRow>
                        {nextEmptyingInfo.date &&
                            <ConfirmationCardRow>
                                <IconCar />
                                <p>{t("pages.emptying.orderConfirmation.nextEmptyingWillTakePlaceOn")} <span>{dateFormat.format(nextEmptyingInfo.date)}</span> ({nextEmptyingInfo.containers.join(', ')}). {t("pages.emptying.orderConfirmation.emptyingOverviewInfo")}</p>
                            </ConfirmationCardRow>
                        }
                    </>
                }
                <ConfirmationCardRow>
                    <IconEditPen />
                    <p>{t("pages.emptying.orderConfirmation.cancelingInformation")}</p>
                </ConfirmationCardRow>
                {notificationsAreNotSet && (
                    <ConfirmationCardRow>
                        <IconNotificationsOn />
                        <p>{t("pages.emptying.orderConfirmation.notificationsSettingsReminder")}</p>
                    </ConfirmationCardRow>
                )}
                <ButtonWrapper>
                    <Button onClick={onCompleted}>{t("buttons.goToHome")}</Button>
                </ButtonWrapper>
            </ConfirmationCard>
        </Wrapper>
    );
};


