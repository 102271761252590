import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { useContext } from "react";
import { UserContext } from "../../../../contexts/UserContext";


const StyledWelcomeSection = styled.section`
    p {
        max-width: 526px;
        font-size: 14px;
    }
`;

export const WelcomeSection = () => {
    const { t } = useTranslation();
    const userContext = useContext(UserContext);
    
    return (
        <StyledWelcomeSection>
            <h1>{t("pages.home.welcome.header")}</h1>
        </StyledWelcomeSection>
    )
};