import { useState, useMemo, useContext, useEffect } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { styles } from "../../../../styles";
import { TextIconButton } from "../../../../components/StyledComponents";
import { IconChangePassword, IconExclamationMark, IconSwissFlag } from "../../../../components/Icons";
import Button from '../../../../components/Button';
import { Toast } from "../../../../components/Toast";
import { useUpdateMeMutation } from "../../../../data/contacts";
import { Controller, useForm } from "react-hook-form";
import { Contact, Salutation } from "../../../../types/Contact";
import { UserContext } from "../../../../contexts/UserContext";
import { MenuItem, Select } from "@mui/material";
import { Loader } from "../../../../components/Loader";


const StyledSection = styled.section`
    @media (max-width: 480px) {
        width: 100%;
        min-width: unset;
        margin-bottom: 0;
    }

    width: 50%;
    min-width: 425px;

    h2 {
        margin-bottom: 24px;

        @media (max-width: 480px) {
            margin-bottom: 12px;
        }
    }
`;

const FormRow = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    color: ${styles.textColorSecondary700};

    @media (max-width: 480px) {
        margin-bottom: 12px;
    }
`;

const StyledH3 = styled.h4`
    font-size: 10px;
    line-height: 18px;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    gap: 10px;

    svg {
        cursor: pointer;
    }
`;

const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-right: 12px;

    &:last-child {
        margin-right: 0;
    }
`;

const InputContainerFull = styled(InputContainer)`
    flex-grow: 1;
`;

const MobileContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

type StyledInputProps = {
    hasError?: boolean;
};

const StyledInput = styled.input<StyledInputProps>`
    padding: 10px 16px;
    flex: 1;
    box-shadow: ${styles.boxShadowInset};
    background-color: #fff;
    border: 1px solid ${props => props.hasError ? '#ed2b2b' : styles.neutral200};
    border-radius: 4px;
    box-sizing: border-box;
    line-height: 22px;
    font-size: 14px;
    color: ${styles.textColorSecondary700};
    font-family: 'Satoshi Regular';
    max-height: 44px;

    &:focus {
        border: 1px solid #3371EA;
        outline: none;
    }
`;

const FlagInputContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
    padding: 10px 12px;
    box-shadow: ${styles.boxShadowInset};
    background-color: #f5f5f5;
    border: 1px solid ${styles.neutral200};
    border-radius: 4px;
    box-sizing: border-box;
    line-height: 22px;
    font-size: 14px;
    color: ${styles.textColorNeutral600};
    font-family: 'Satoshi Regular';
    max-height: 44px;
    width: 92px;
    pointer-events: none;
`;

const ChangePasswordRow = styled.div`
    display: flex;
    justify-content: flex-start;
    gap: 12px;
    margin-top: 48px;
`;

const StyledTextIconButton = styled(TextIconButton)`
    color: ${props => props.disabled ? styles.neutral200 : "#26374A"};
    svg {
        circle, path {
            stroke: ${props => props.disabled ? styles.neutral200 : "#26374A"};
        }
    }
    cursor: ${props => props.disabled ? "not-allowed" : "pointer"};
`;

const BottomSection = styled.section`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
    margin-bottom: 60px;

    button {
        width: 162px;
        margin-top: 64px;
    }

    @media (max-width: 480px) {
        flex-direction: column;
        align-items: flex-end;
        margin-top: 48px;
        margin-bottom: 0px;

        button {
            width: 100%;
            margin-top: 0;
            margin-bottom: 0;
        }
    }
`;


const StyledSelect = styled(Select)(() => ({
    boxShadow: styles.boxShadowInset,
    backgroundColor: '#fff',
    borderRadius: '4px',
    flex: '1',
    boxSizing: 'border-box',
    padding: '0',
    fontFamily: 'Satoshi Regular',
    color: styles.textColorSecondary700,

    '.MuiSelect-icon': {
        color: '#26374A',
    },

    '& .MuiSelect-select': {
        paddingTop: '11px',
        paddingBottom: '11px',
        lineHeight: '22px',
        fontSize: '14px',
        boxShadow: styles.boxShadowInset,
    },
    '& .MuiOutlinedInput-root': {
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#e0e1e0',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#e0e1e0',
        },
    },
    '.MuiOutlinedInput-notchedOutline': {
        borderWidth: '1px',
    },
}));

const ErrorWrapper = styled.div`
    margin-top: 12px;
    display: flex;
    align-items: center;
    font-size: 10px;
    line-height: 18px;

    svg {
        margin-right: 8px;
    }
`;

type ToastInfo = {
    type: "success" | "error" | "info",
    message: string;
}

export const PersonalDetails = ({ contact }: { contact: Contact }) => {
    const { t } = useTranslation();
    const userContext = useContext(UserContext);
    const updateMeMutation = useUpdateMeMutation();
    const { register, control, watch, setValue, handleSubmit, formState: { errors }, reset } = useForm<Contact>({
        defaultValues: contact
    });
    const [toastInfo, setToastInfo] = useState<ToastInfo>();
    const { lastName, firstName, salutation, email, phone } = watch();

    const comparableContact = useMemo(() => ({
        salutation: contact.salutation,
        lastName: contact.lastName,
        firstName: contact.firstName,
        email: contact.email,
        phone: contact.phone
    }), [contact]);

    const hasDataChanged = JSON.stringify({ salutation, lastName, firstName, email, phone }) !== JSON.stringify(comparableContact);

    const handleToastCloseComplete = () => {
        setToastInfo(undefined);
    };

    useEffect(() => {
        setValue('salutation', contact.salutation);
        setValue('phone', contact.phone);
        setValue('email', contact.email);
        setValue('firstName', contact.firstName);
        setValue('lastName', contact.lastName);
    }, [contact, setValue]);

    const onSave = async (updatedContact: Contact) => {
        try {
            await updateMeMutation.mutateAsync(updatedContact);
            setToastInfo({ type: 'success', message: t("toasts.saved") });
        } catch (e) {
            setToastInfo({ type: 'error', message: t("toasts.error") + ` - ${updateMeMutation.error} | ${JSON.stringify(e)}` });
        }
    }

    const onChangePassword = () => {
        userContext.changePwd();
    }

    return (
        <>
            <StyledSection>
                <h2>{t("pages.myData.personalDetails.header")}</h2>
                <form>
                    <FormRow>
                        <StyledH3>{t("pages.myData.personalDetails.salutation")}</StyledH3>
                        <Controller
                            name="salutation"
                            control={control}
                            rules={{ required: false }}
                            render={({ field }) => (
                                <StyledSelect
                                    sx={{
                                        '.MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#e0e1e0 !important'
                                        },
                                        '&.Mui-disabled': {
                                            backgroundColor: '#f5f5f5',
                                        },
                                    }}
                                    {...field}
                                    onChange={(e) => {
                                        field.onChange(e.target.value);
                                    }}
                                >
                                    <MenuItem value={Salutation.Mister}>{t("pages.myData.personalDetails.salutationOptions.mr")}</MenuItem>
                                    <MenuItem value={Salutation.Misses}>{t("pages.myData.personalDetails.salutationOptions.mrs")}</MenuItem>
                                </StyledSelect>
                            )}
                        />
                        {errors.salutation && <ErrorWrapper><IconExclamationMark />{errors.salutation.message}</ErrorWrapper>}
                    </FormRow>
                    <FormRow>
                        <StyledH3>{t("pages.myData.personalDetails.firstName")}</StyledH3>
                        <StyledInput hasError={Boolean(errors.firstName)} type="text" placeholder={t("pages.myData.personalDetails.firstName")} {...register("firstName", { required: t('errors.requiredField') })} />
                        {errors.firstName && <ErrorWrapper><IconExclamationMark /> {errors.firstName.message}</ErrorWrapper>}
                    </FormRow>
                    <FormRow>
                        <StyledH3>{t("pages.myData.personalDetails.surname")}</StyledH3>
                        <StyledInput hasError={Boolean(errors.lastName)} type="text" placeholder={t("pages.myData.personalDetails.surname")} {...register("lastName", { required: t('errors.requiredField') })} />
                        {errors.lastName && <ErrorWrapper><IconExclamationMark />{errors.lastName.message}</ErrorWrapper>}
                    </FormRow>
                    <FormRow>
                        <StyledH3>{t("pages.myData.personalDetails.loginName")}</StyledH3>
                        <StyledInput style={{ backgroundColor: '#f5f5f5' }} disabled readOnly type="login" placeholder={t("pages.myData.personalDetails.loginName")} {...register("loginName")} />
                    </FormRow>
                    <FormRow>
                        <StyledH3>{t("pages.myData.personalDetails.email")}</StyledH3>
                        <StyledInput hasError={Boolean(errors.email)} type="email" placeholder={t("pages.myData.personalDetails.email")} {...register("email", { required: t('errors.requiredField') })} />
                        {errors.email && <ErrorWrapper><IconExclamationMark />{errors.email.message}</ErrorWrapper>}
                    </FormRow>
                    <MobileContainer>
                        <InputContainer>
                            <StyledH3>{t("pages.myData.personalDetails.mobilePhone")}</StyledH3>
                            <FlagInputContainer>
                                <IconSwissFlag />
                                <span>+41</span>
                            </FlagInputContainer>
                        </InputContainer>
                        <InputContainerFull>
                            <StyledInput hasError={Boolean(errors.phone)} type="tel" {...register("phone", { required: t('errors.requiredField') })} />
                            {errors.phone && <ErrorWrapper><IconExclamationMark />{errors.phone.message}</ErrorWrapper>}
                        </InputContainerFull>
                    </MobileContainer>
                    <ChangePasswordRow>
                        <StyledTextIconButton type="button" onClick={onChangePassword} bold svgMargin='0 4px 0 0'><IconChangePassword /> {t("buttons.changePassword")}</StyledTextIconButton>
                    </ChangePasswordRow>
                    <BottomSection>
                        {hasDataChanged && !updateMeMutation.isLoading &&
                            <Button buttonVariant="ghost" onClick={() => reset(contact)}>
                                {t("buttons.resetToDefault")}
                            </Button>
                        }
                        <Button disabled={!hasDataChanged || updateMeMutation.isLoading} onClick={handleSubmit(onSave)}>
                            {updateMeMutation.isLoading && <Loader />}
                            {!updateMeMutation.isLoading && t("buttons.save")}
                        </Button>
                    </BottomSection>
                </form>
            </StyledSection>
            {toastInfo && <Toast message={toastInfo.message} type={toastInfo.type} onCloseComplete={handleToastCloseComplete} />}
        </>
    )
};