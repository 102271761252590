import styled from "@emotion/styled";
import { t } from "i18next";
import { IconExclamationMark } from "../../components/Icons";

type EmptyingScheduleSummaryProps = {
    hasConfiguredSchedules: boolean;
    nofEmptyings: number;
    totalCost: number;
}


const WarningText = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 8px;

    p {
        text-align: left;
    }
`;

export const EmptyingScheduleSummary = ({ hasConfiguredSchedules, nofEmptyings, totalCost }: EmptyingScheduleSummaryProps) => {

    return (
        <p style={{ textAlign: 'right', marginTop: '48px' }}>
            {nofEmptyings > 0 &&
                <>
                    Anzahl Leerungen: {nofEmptyings} /&nbsp;
                    {t("pages.emptying.series.modalPlanSerialEmptying.costs")}
                    &nbsp;
                    {totalCost}
                    ,- {t("pages.emptying.series.modalPlanSerialEmptying.currency")}
                </>
            }
            {hasConfiguredSchedules && nofEmptyings === 0 &&
                <WarningText>
                    <IconExclamationMark />
                    <p>Während dem ausgewählten Zeitraum kann keine zusätzliche Leerung eingeplant werden.</p>
                </WarningText>
            }
        </p>
    )
}