export const ContainerWasCheckedImage = () => (
    <svg width="360" height="240" viewBox="0 0 360 240" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="360" height="240" fill="white" />
        <path d="M348 206.25C348 217.449 271.774 226.5 177.75 226.5C83.7264 226.5 7.5 217.416 7.5 206.25C7.5 195.084 83.7264 186 177.75 186C271.774 186 348 195.084 348 206.25Z" fill="#F5F5F5" />
        <path d="M295.395 39.9766C290.243 41.0552 284.752 61.7539 284.752 61.7539L289.353 66.7173L297.583 51.6309L295.395 39.9766Z" fill="#26374A" />
        <path opacity="0.2" d="M297.583 51.6384L297.41 51.9628L290.53 64.5751L289.353 66.7249L284.752 61.7539C284.752 61.7539 290.236 41.0703 295.388 39.9766L297.583 51.6384Z" fill="white" />
        <path opacity="0.1" d="M297.41 51.9401L290.53 64.5524C290.53 64.3412 290.53 64.1149 290.53 63.866C291.149 54.271 296.497 49.2095 296.497 49.2095C296.94 50.0671 297.248 50.9883 297.41 51.9401Z" fill="#10242E" />
        <path d="M333.745 92.7718C333.745 92.7718 317.957 88.5777 298.812 94.733C298.812 94.733 295.734 54.6332 296.104 49.1191C296.474 43.6049 325.651 45.2267 325.651 45.2267C325.651 45.2267 326.405 61.2787 328.284 70.9567C330.162 80.6347 334.695 90.9086 333.745 92.7718Z" fill="#26374A" />
        <path opacity="0.2" d="M333.745 92.7718C333.745 92.7718 317.957 88.5777 298.812 94.733C298.812 94.733 295.734 54.6332 296.104 49.1191C296.474 43.6049 325.651 45.2267 325.651 45.2267C325.651 45.2267 326.405 61.2787 328.284 70.9567C330.162 80.6347 334.695 90.9086 333.745 92.7718Z" fill="#10242E" />
        <path d="M289.564 197.004L294.716 198.46L295.946 190.442L296.602 186.157L291.45 184.694L290.575 190.442L289.564 197.004Z" fill="#FBC8C1" />
        <path d="M319.188 197.427L324.498 198.128L324.551 190.442L324.588 185.667L319.263 184.973L319.233 190.442L319.188 197.427Z" fill="#FBC8C1" />
        <path opacity="0.2" d="M319.263 184.973L319.232 190.442H324.55L324.588 185.667L319.263 184.973Z" fill="#10242E" />
        <path opacity="0.2" d="M290.575 190.442H295.946L296.602 186.157L291.45 184.694L290.575 190.442Z" fill="#10242E" />
        <path d="M289.633 196.197L295.479 196.039C295.585 196.037 295.689 196.071 295.774 196.135C295.859 196.199 295.92 196.29 295.947 196.393L297.176 201.108C297.21 201.222 297.216 201.344 297.193 201.461C297.171 201.579 297.12 201.689 297.047 201.784C296.973 201.878 296.878 201.954 296.769 202.004C296.661 202.054 296.542 202.078 296.422 202.073C294.31 202.073 291.255 202.073 288.6 202.126C285.499 202.209 283.531 202.058 279.887 202.126C277.692 202.186 276.998 199.984 277.911 199.765C282.075 198.739 284.79 198.943 288.336 196.619C288.721 196.361 289.17 196.215 289.633 196.197Z" fill="#26374A" />
        <path d="M289.127 196.951C288.267 197.147 286.744 197.404 286.11 196.899C286.019 196.826 285.948 196.732 285.902 196.626C285.856 196.519 285.837 196.403 285.846 196.288C285.847 196.219 285.865 196.152 285.9 196.092C285.934 196.033 285.983 195.983 286.042 195.948C286.683 195.563 288.871 196.597 289.12 196.702C289.145 196.712 289.166 196.73 289.18 196.754C289.193 196.777 289.199 196.804 289.195 196.831C289.196 196.855 289.19 196.879 289.178 196.9C289.166 196.922 289.148 196.939 289.127 196.951ZM286.223 196.144H286.178C286.153 196.156 286.132 196.176 286.119 196.2C286.105 196.225 286.1 196.252 286.103 196.28C286.095 196.355 286.106 196.431 286.135 196.501C286.164 196.571 286.21 196.632 286.268 196.68C286.608 196.966 287.49 196.989 288.667 196.763C287.822 196.408 286.638 195.993 286.223 196.144Z" fill="#9DD6A7" />
        <path d="M289.127 196.951H289.036C288.38 196.68 287.053 195.556 287.136 194.952C287.136 194.809 287.241 194.635 287.596 194.59C287.721 194.572 287.849 194.58 287.971 194.614C288.093 194.647 288.207 194.706 288.305 194.786C288.812 195.351 289.129 196.061 289.21 196.815C289.213 196.838 289.21 196.862 289.2 196.883C289.191 196.904 289.176 196.923 289.157 196.936L289.127 196.951ZM287.498 194.869C287.392 194.907 287.384 194.967 287.384 194.982C287.339 195.352 288.244 196.235 288.893 196.612C288.813 196.005 288.55 195.436 288.139 194.982C288.067 194.923 287.983 194.88 287.894 194.855C287.804 194.831 287.71 194.825 287.618 194.839C287.577 194.844 287.536 194.854 287.498 194.869Z" fill="#9DD6A7" />
        <path d="M319.413 196.197L325.267 196.039C325.373 196.037 325.477 196.071 325.562 196.135C325.647 196.199 325.707 196.29 325.734 196.393L326.964 201.108C326.998 201.222 327.003 201.344 326.981 201.461C326.958 201.579 326.908 201.689 326.834 201.784C326.761 201.878 326.666 201.954 326.557 202.004C326.448 202.054 326.329 202.078 326.21 202.073C324.098 202.073 321.043 202.073 318.395 202.126C315.287 202.209 313.318 202.058 309.682 202.126C307.48 202.186 306.793 199.984 307.706 199.765C311.862 198.739 314.578 198.943 318.123 196.619C318.507 196.364 318.953 196.217 319.413 196.197Z" fill="#26374A" />
        <path d="M318.9 196.951C318.048 197.147 316.517 197.404 315.883 196.899C315.795 196.824 315.726 196.73 315.68 196.624C315.634 196.518 315.613 196.403 315.619 196.288C315.62 196.218 315.639 196.15 315.675 196.09C315.711 196.031 315.762 195.982 315.823 195.948C316.456 195.563 318.651 196.597 318.9 196.702C318.923 196.714 318.942 196.733 318.954 196.756C318.966 196.779 318.971 196.805 318.968 196.831C318.97 196.855 318.964 196.88 318.952 196.901C318.94 196.922 318.922 196.94 318.9 196.951ZM316.004 196.144H315.959C315.934 196.156 315.913 196.176 315.899 196.2C315.886 196.225 315.88 196.252 315.883 196.28C315.875 196.355 315.886 196.431 315.915 196.501C315.944 196.571 315.99 196.632 316.049 196.68C316.388 196.966 317.279 196.989 318.448 196.763C317.588 196.408 316.396 195.993 316.004 196.144Z" fill="#9DD6A7" />
        <path d="M318.9 196.951H318.802C318.146 196.68 316.826 195.556 316.902 194.952C316.902 194.809 317.015 194.635 317.362 194.59C317.487 194.571 317.615 194.579 317.737 194.613C317.859 194.647 317.973 194.706 318.071 194.786C318.578 195.351 318.895 196.061 318.976 196.815C318.98 196.838 318.977 196.861 318.97 196.882C318.962 196.903 318.948 196.922 318.931 196.936L318.9 196.951ZM317.264 194.869C317.237 194.877 317.212 194.892 317.192 194.911C317.173 194.931 317.158 194.956 317.15 194.982C317.105 195.352 318.01 196.235 318.659 196.612C318.579 196.005 318.316 195.436 317.905 194.982C317.832 194.923 317.747 194.88 317.656 194.855C317.565 194.831 317.47 194.825 317.377 194.839L317.264 194.869Z" fill="#9DD6A7" />
        <path d="M330.139 80.6421C332.953 92.847 328.125 188.805 328.125 188.805H318.794L314.804 108.846L300.11 188.805L290.138 188.05C296.655 126.098 302.418 89.1131 302.418 89.1131V84.4363L330.139 80.6421Z" fill="#26374A" />
        <path d="M325.116 35.4582C324.825 41.292 324.974 47.1395 325.561 52.951C325.945 57.0092 326.489 60.8487 327.069 64.3111C328.412 72.4351 329.936 78.515 330.147 80.6422L302.411 84.4666C292.295 56.187 296.104 41.176 296.104 41.176L302.463 37.5552C302.463 37.5552 303.391 37.3063 303.866 37.1931C307.757 36.4286 311.689 35.8871 315.641 35.5713C316.396 35.5034 317.15 35.4506 317.904 35.4205C321.676 35.247 325.116 35.4582 325.116 35.4582Z" fill="#9DD6A7" />
        <g opacity="0.7">
            <path d="M325.116 35.4583C324.825 41.2921 324.974 47.1396 325.561 52.9511C325.945 57.0093 326.489 60.8488 327.069 64.3112C328.412 72.4352 329.936 78.5151 330.147 80.6423L302.411 84.4667C292.295 56.1871 296.104 41.1761 296.104 41.1761L302.463 37.5553C302.463 37.5553 303.391 37.3064 303.866 37.1933C307.757 36.4287 311.689 35.8872 315.641 35.5714C316.396 35.5036 317.15 35.4508 317.904 35.4206C321.676 35.2471 325.116 35.4583 325.116 35.4583Z" fill="white" />
        </g>
        <path d="M314.676 35.4357C314.676 35.4357 308.317 40.4595 306.379 40.4368C305.036 40.4368 303.09 38.2267 303.09 38.2267C303.912 37.8401 304.631 37.2635 305.187 36.5445C306.514 34.7266 305.745 32.5164 304.666 30.5099L310.241 22.7555C310.35 24.6451 310.602 26.5238 310.995 28.3752C311.614 31.3774 312.745 34.3268 314.676 35.4357Z" fill="#FBC8C1" />
        <path opacity="0.2" d="M310.987 28.3524C310.406 31.7469 308.92 35.6543 305.209 36.5368C306.537 34.7189 305.767 32.5087 304.688 30.5022L310.263 22.7478C310.363 24.6316 310.605 26.5052 310.987 28.3524Z" fill="#10242E" />
        <path d="M294.347 19.6474C294.121 21.7445 295.275 25.7348 297.772 23.8641C300.269 21.9934 294.739 16.0342 294.347 19.6474Z" fill="#26374A" />
        <path d="M309.109 19.4665C310.429 24.4827 311.462 27.3491 309.795 30.6682C307.298 35.6618 300.457 35.3601 297.658 30.8266C295.139 26.7457 293.404 19.2326 297.59 15.7401C298.518 14.9643 299.627 14.4365 300.814 14.2059C302.002 13.9754 303.228 14.0498 304.379 14.422C305.529 14.7943 306.567 15.4523 307.394 16.3345C308.221 17.2167 308.811 18.2943 309.109 19.4665Z" fill="#FBC8C1" />
        <path d="M304.998 17.4223L303.866 11.7498C303.866 11.7498 309.81 10.0903 310.165 13.2585C313.838 14.2466 314.034 21.9181 310.052 25.3277C307.2 23.8643 305.835 16.9396 305.835 16.9396C305.556 17.0829 305.284 17.2489 304.998 17.4223Z" fill="#26374A" />
        <path d="M312.36 22.9742C312.365 23.6176 312.243 24.2557 311.999 24.8514C311.756 25.4471 311.396 25.9884 310.942 26.4441C309.72 27.6585 308.249 26.7609 307.879 25.1693C307.547 23.736 307.781 21.3976 309.388 20.764C309.733 20.6501 310.1 20.6208 310.459 20.6787C310.817 20.7367 311.157 20.8801 311.448 21.0968C311.74 21.3136 311.975 21.5974 312.133 21.9241C312.292 22.2509 312.37 22.6111 312.36 22.9742Z" fill="#FBC8C1" />
        <path d="M302.064 23.2305C302.199 23.6303 302.064 23.9848 301.83 24.1055C301.596 24.2262 301.241 23.932 301.113 23.5322C300.985 23.1324 301.113 22.7402 301.347 22.6572C301.581 22.5742 301.935 22.8307 302.064 23.2305Z" fill="#26374A" />
        <path d="M299.046 24.4225C298.937 25.7635 298.646 27.0832 298.179 28.345C298.517 28.4409 298.872 28.465 299.22 28.4156C299.568 28.3662 299.902 28.2445 300.2 28.0583L299.046 24.4225Z" fill="#DA968B" />
        <path d="M297.537 24.6788C297.673 25.0786 297.537 25.4709 297.304 25.5614C297.07 25.6519 296.715 25.3879 296.587 24.9881C296.459 24.5883 296.587 24.1885 296.821 24.1056C297.055 24.0226 297.394 24.279 297.537 24.6788Z" fill="#26374A" />
        <path d="M302.818 28.9032C302.652 28.9827 302.481 29.0508 302.305 29.1069C302.29 29.1115 302.274 29.113 302.258 29.1113C302.242 29.1096 302.227 29.1047 302.213 29.097C302.198 29.0893 302.186 29.0788 302.176 29.0663C302.166 29.0537 302.159 29.0393 302.155 29.0239C302.145 28.9928 302.147 28.9592 302.161 28.9298C302.175 28.9003 302.2 28.8774 302.23 28.8655C302.732 28.7193 303.193 28.4557 303.573 28.0963C303.954 27.7369 304.243 27.2921 304.417 26.7986C304.422 26.7832 304.429 26.7688 304.439 26.7562C304.449 26.7437 304.461 26.7332 304.476 26.7255C304.49 26.7178 304.505 26.7129 304.521 26.7112C304.537 26.7096 304.553 26.7111 304.568 26.7157C304.584 26.7192 304.6 26.7259 304.613 26.7355C304.626 26.7451 304.638 26.7574 304.646 26.7715C304.655 26.7856 304.66 26.8014 304.662 26.8177C304.665 26.8341 304.663 26.8507 304.659 26.8665C304.509 27.3144 304.268 27.7264 303.951 28.0769C303.634 28.4273 303.249 28.7086 302.818 28.9032Z" fill="#26374A" />
        <path d="M302.991 21.322C302.953 21.3438 302.91 21.3553 302.867 21.3553C302.823 21.3553 302.78 21.3438 302.742 21.322C302.491 21.1372 302.199 21.0138 301.892 20.9614C301.584 20.909 301.268 20.9292 300.969 21.0202C300.939 21.0345 300.906 21.0424 300.872 21.0435C300.838 21.0446 300.805 21.0388 300.774 21.0265C300.742 21.0143 300.714 20.9958 300.69 20.9721C300.666 20.9485 300.647 20.9203 300.634 20.8891C300.621 20.8579 300.615 20.8245 300.615 20.7908C300.616 20.7571 300.623 20.7239 300.637 20.6931C300.651 20.6623 300.671 20.6347 300.695 20.6118C300.72 20.589 300.749 20.5714 300.781 20.5601C301.159 20.4335 301.563 20.4003 301.957 20.4633C302.351 20.5264 302.724 20.6838 303.044 20.9222C303.071 20.9397 303.094 20.9625 303.112 20.9892C303.131 21.0159 303.143 21.0459 303.15 21.0776C303.156 21.1092 303.156 21.1418 303.149 21.1734C303.143 21.205 303.13 21.235 303.112 21.2616C303.078 21.2925 303.036 21.3133 302.991 21.322Z" fill="#26374A" />
        <path d="M295.049 23.7284C295.004 23.7399 294.957 23.7399 294.913 23.7284C294.848 23.7173 294.79 23.6826 294.75 23.631C294.709 23.5795 294.689 23.5147 294.694 23.4493C294.741 23.0602 294.88 22.6877 295.099 22.3626C295.318 22.0374 295.611 21.7687 295.954 21.5786C296.014 21.5523 296.082 21.5502 296.144 21.5727C296.206 21.5952 296.256 21.6405 296.286 21.6993C296.314 21.7583 296.319 21.8264 296.297 21.8885C296.276 21.9507 296.231 22.002 296.173 22.0312C295.896 22.1805 295.661 22.3968 295.49 22.6604C295.318 22.924 295.216 23.2264 295.192 23.5398C295.184 23.5802 295.167 23.6183 295.143 23.6511C295.118 23.6838 295.085 23.7103 295.049 23.7284Z" fill="#26374A" />
        <path d="M305.836 16.9395C305.836 16.9395 296.497 22.1443 292.409 19.7003C288.32 17.2563 292.409 15.1744 292.409 15.1744C292.409 15.1744 291.119 16.6076 292.333 17.3242C292.333 17.3242 292.605 13.3037 296.105 12.0439C295.836 12.3843 295.647 12.7802 295.55 13.203C295.454 13.6257 295.453 14.0647 295.547 14.4879C297.438 13.0261 299.607 11.9637 301.921 11.365C305.934 10.1958 316.736 10.9577 305.836 16.9395Z" fill="#26374A" />
        <path d="M306.394 40.4367C306.394 40.4367 307.947 41.8473 309.72 43.2127C311.847 41.7945 316.788 38.0908 316.192 35.2847C315.777 34.5304 313.56 34.2362 313.56 34.2362C313.56 34.2362 311.576 37.5703 306.394 40.4367Z" fill="#9DD6A7" />
        <path d="M306.393 40.4367C305.384 41.4683 304.528 42.6393 303.851 43.9141C302.048 43.0316 302.26 37.6608 302.803 37.0045C303.622 36.4069 304.58 36.0278 305.586 35.9032C305.341 36.6674 305.286 37.48 305.426 38.2702C305.567 39.0605 305.899 39.8043 306.393 40.4367Z" fill="#9DD6A7" />
        <g opacity="0.5">
            <path d="M306.394 40.4367C306.394 40.4367 307.947 41.8473 309.72 43.2127C311.847 41.7945 316.788 38.0908 316.192 35.2847C315.777 34.5304 313.56 34.2362 313.56 34.2362C313.56 34.2362 311.576 37.5703 306.394 40.4367Z" fill="white" />
            <path d="M306.393 40.4367C305.384 41.4683 304.528 42.6393 303.851 43.9141C302.048 43.0316 302.26 37.6608 302.803 37.0045C303.622 36.4069 304.58 36.0278 305.586 35.9032C305.341 36.6674 305.286 37.48 305.426 38.2702C305.567 39.0605 305.899 39.8043 306.393 40.4367Z" fill="white" />
        </g>
        <path d="M316.192 35.2847C316.495 42.1848 317.401 49.0452 318.9 55.7872C320.952 64.9296 324.535 75.1657 330.796 82.0376C330.796 82.0376 333.715 88.736 332.765 94.6047C332.765 94.6047 334.673 93.8503 335.028 92.7415C335.382 91.6326 329.861 78.3565 328.518 68.2637C327.175 58.1708 326.413 35.2545 326.413 35.2545C324.12 34.9288 321.8 34.8403 319.489 34.9905C318.508 35.0433 317.392 35.1489 316.192 35.2847Z" fill="#26374A" />
        <path opacity="0.2" d="M335.057 92.7641C334.673 93.8503 332.794 94.6348 332.794 94.6348C333.745 88.7587 330.833 82.0678 330.833 82.0678C330.433 81.6303 330.079 81.1777 329.664 80.7176C326.149 76.3877 323.554 70.9868 321.638 65.4199C320.556 62.2704 319.654 59.0618 318.938 55.8098C318.855 55.4553 318.787 55.1083 318.704 54.7613C317.367 48.436 316.54 42.0137 316.229 35.5562C316.229 35.3978 316.229 35.3148 316.229 35.3148C317.436 35.179 318.553 35.0961 319.571 35.0433C321.88 34.8924 324.198 34.9834 326.488 35.3148C326.488 35.3148 326.669 40.6403 327.001 47.2784C327.001 47.384 327.001 47.4745 327.001 47.5801C327.099 49.4886 327.212 51.4875 327.326 53.5091C327.552 57.2807 327.816 61.0523 328.133 64.3035C328.268 65.7744 328.419 67.1397 328.57 68.324C329.89 78.3867 335.442 91.693 335.057 92.7641Z" fill="white" />
        <path d="M316.192 35.2847C316.495 42.1849 317.401 49.0452 318.9 55.7872L322.566 41.1534L318.689 40.7837L322.566 38.5208L319.549 35.0056C318.508 35.0433 317.392 35.1489 316.192 35.2847Z" fill="#26374A" />
        <path d="M298.616 96.1435L299.612 96.8601C298.67 84.9464 298.383 72.99 298.752 61.0448C298.752 60.0567 298.82 59.1062 298.858 58.201C299.612 40.8893 302.282 37.7438 302.456 37.5552L299.944 38.4076L295.418 39.9765C294.053 54.7462 298.616 96.1435 298.616 96.1435Z" fill="#26374A" />
        <path opacity="0.2" d="M298.616 96.1435L299.612 96.8601C298.67 84.9464 298.383 72.99 298.752 61.0448C298.752 60.0567 298.82 59.1062 298.858 58.201C299.612 40.8893 302.282 37.7438 302.456 37.5552L299.944 38.4076L295.418 39.9765C294.053 54.7462 298.616 96.1435 298.616 96.1435Z" fill="white" />
        <path d="M299.756 43.6199L296.882 45.4152C296.882 45.4152 297.636 53.8863 298.858 58.201C299.613 40.8893 302.283 37.7438 302.456 37.5552L299.944 38.4076C298.564 39.686 297.409 41.188 296.527 42.8505L299.756 43.6199Z" fill="#26374A" />
        <path opacity="0.2" d="M325.637 47.799C325.807 49.7837 326.371 51.7146 327.296 53.479C327.183 51.465 327.07 49.4585 326.972 47.55C326.514 47.535 326.058 47.62 325.637 47.799Z" fill="#10242E" />
        <path opacity="0.2" d="M311.673 125.849L314.804 108.831L314.2 99.8323C314.2 99.8323 309.916 115.447 311.673 125.849Z" fill="#10242E" />
        <path opacity="0.1" d="M328.11 64.2732C327.778 64.3989 327.446 64.512 327.114 64.6126C325.323 65.1234 323.471 65.3948 321.608 65.4197C320.334 65.454 319.06 65.3834 317.799 65.2085C304.274 63.1492 303.866 60.3733 303.866 60.3733C303.866 60.3733 312.337 59.8076 316.358 57.356C317.328 56.6826 318.118 55.7812 318.658 54.731C321.208 50.3483 322.566 42.8202 322.566 42.8202C322.566 42.8202 323.607 43.748 325.04 45.2114C325.628 45.8073 326.277 46.4937 326.948 47.2481C326.948 47.3537 326.948 47.4442 326.948 47.5498C327.047 49.4582 327.16 51.4572 327.273 53.4788C327.529 57.2504 327.793 61.0447 328.11 64.2732Z" fill="#10242E" />
        <path d="M303.059 55.9379L303.21 59.3701C302.078 62.7947 297.93 60.5846 297.93 60.5846L295.501 60.3205C295.076 60.2487 294.691 60.0278 294.414 59.6973C294.138 59.3668 293.988 58.9486 293.992 58.5177L293.864 56.119C293.81 55.6283 293.949 55.1359 294.252 54.7459C294.554 54.3559 294.997 54.099 295.486 54.0295L297.613 53.9012C298.233 53.8634 298.854 53.9828 299.416 54.2482L303.059 55.9379Z" fill="#FBC8C1" />
        <path d="M326.451 35.2847C334.545 37.5476 327.847 62.4403 324.882 63.255C319.262 64.824 302.456 61.7463 302.456 61.7463V55.1837C302.456 55.1837 318.636 56.7678 320.002 56.1643C321.767 55.41 322.619 45.981 323.019 41.5455C323.082 40.3108 323.424 39.1064 324.018 38.0223C324.613 36.9382 325.444 36.0023 326.451 35.2847Z" fill="#26374A" />
        <path opacity="0.2" d="M326.451 35.2847C334.545 37.5476 327.847 62.4403 324.882 63.255C319.262 64.824 302.456 61.7463 302.456 61.7463V55.1837C302.456 55.1837 318.636 56.7678 320.002 56.1643C321.767 55.41 322.619 45.981 323.019 41.5455C323.082 40.3108 323.424 39.1064 324.018 38.0223C324.613 36.9382 325.444 36.0023 326.451 35.2847Z" fill="white" />
        <path d="M295.048 69.7421L281.885 70.949C281.625 70.9609 281.367 70.8909 281.147 70.7489C280.928 70.6069 280.759 70.3999 280.663 70.157L273.61 51.1254C273.566 51.0106 273.55 50.8866 273.565 50.7643C273.579 50.642 273.623 50.5251 273.693 50.4239C273.745 50.345 273.813 50.278 273.892 50.2273C273.971 50.1765 274.06 50.1433 274.154 50.1297L287.339 48.9228C287.6 48.9137 287.858 48.9857 288.077 49.1288C288.296 49.272 288.465 49.4793 288.561 49.7224L295.607 68.7539C295.662 68.8534 295.692 68.9656 295.691 69.0796C295.691 69.1937 295.662 69.3058 295.606 69.4051C295.549 69.5044 295.469 69.5876 295.371 69.6466C295.274 69.7056 295.162 69.7385 295.048 69.7421Z" fill="#26374A" />
        <path d="M275.662 61.5728L277.073 64.6505C279.524 67.6301 281.915 65.352 281.915 65.352L283.771 64.9522C284.405 64.8089 284.578 64.0847 284.194 63.2248L283.205 61.075C283.014 60.6111 282.704 60.2054 282.307 59.8984C281.91 59.5914 281.44 59.3937 280.942 59.325L279.162 59.3702C278.689 59.363 278.227 59.5143 277.85 59.8002L275.662 61.5728Z" fill="#FBC8C1" />
        <path d="M277.309 49.157L283.573 48.6233L284.206 49.9741L277.837 50.5295L277.309 49.157Z" fill="#1E2B39" />
        <path d="M281.424 48.9811L281.349 47.8636L278.348 48.066L279.119 49.3108L281.424 48.9811Z" fill="#1E2B39" />
        <ellipse cx="247.711" cy="188.498" rx="11.09" ry="11.8929" fill="#1E2B39" />
        <ellipse cx="246.206" cy="188.899" rx="10.789" ry="11.4915" fill="#2B3B4D" />
        <ellipse opacity="0.1" cx="246.256" cy="188.95" rx="7.52718" ry="8.02899" fill="white" />
        <ellipse cx="150.661" cy="183.379" rx="11.1904" ry="12.7962" fill="#1E2B39" />
        <path d="M92.25 61.8948L96.5656 60.289L121.656 49.5L267.483 53.5145V61.8948L258.45 68.4183L254.687 156.687L251.324 172.896L227.689 188.05L203.753 186.846L201.444 188.05L140.976 184.939L138.818 183.434L110.566 181.978L105.89 165.419L102.537 96.4696H100.128V85.0283L93.5547 77.3506V69.1209H92.25V61.8948Z" fill="#85CC91" />
        <path d="M240.485 73.7337L92.25 69.2174V62.1418L240.485 65.1025L267.533 53.9623V62.1418L240.485 73.7337Z" fill="#85CC91" />
        <path d="M240.485 73.7337L92.25 69.2174V62.1418L240.485 65.1025L267.533 53.9623V62.1418L240.485 73.7337Z" fill="white" fillOpacity="0.2" />
        <path opacity="0.1" d="M229.797 169.881L227.188 189L251.224 173.143L254.486 156.784L258.601 66.0059L239.934 74.1854H231.603L229.797 169.881Z" fill="black" />
        <path d="M102.537 69.719L93.4541 69.167V77.3967L100.028 84.8737V96.6161H103.641L102.537 69.719Z" fill="#85CC91" />
        <path d="M102.537 69.719L93.4541 69.167V77.3967L100.028 84.8737V96.6161H103.641L102.537 69.719Z" fill="black" fillOpacity="0.2" />
        <path d="M240.937 82.3146V73.7336H231.503L230.851 100.631H233.711L234.413 89.34L240.937 82.3146Z" fill="#85CC91" />
        <path d="M240.937 82.3146V73.7336H231.503L230.851 100.631H233.711L234.413 89.34L240.937 82.3146Z" fill="black" fillOpacity="0.2" />
        <path d="M114.328 79.014L114.644 69.9367L111.284 69.8196L114.328 79.014Z" fill="#85CC91" />
        <path d="M114.328 79.014L114.644 69.9367L111.284 69.8196L114.328 79.014Z" fill="black" fillOpacity="0.2" />
        <path d="M135.542 79.7534L135.858 70.6761L132.498 70.559L135.542 79.7534Z" fill="#85CC91" />
        <path d="M135.542 79.7534L135.858 70.6761L132.498 70.559L135.542 79.7534Z" fill="black" fillOpacity="0.2" />
        <path d="M156.756 80.4927L157.072 71.4154L153.712 71.2983L156.756 80.4927Z" fill="#85CC91" />
        <path d="M156.756 80.4927L157.072 71.4154L153.712 71.2983L156.756 80.4927Z" fill="black" fillOpacity="0.2" />
        <path d="M177.97 81.2317L178.286 72.1545L174.926 72.0374L177.97 81.2317Z" fill="#85CC91" />
        <path d="M177.97 81.2317L178.286 72.1545L174.926 72.0374L177.97 81.2317Z" fill="black" fillOpacity="0.2" />
        <path d="M199.183 81.9711L199.5 72.8938L196.14 72.7767L199.183 81.9711Z" fill="#85CC91" />
        <path d="M199.183 81.9711L199.5 72.8938L196.14 72.7767L199.183 81.9711Z" fill="black" fillOpacity="0.2" />
        <path d="M220.396 82.7105L220.713 73.6332L217.353 73.5161L220.396 82.7105Z" fill="#85CC91" />
        <path d="M220.396 82.7105L220.713 73.6332L217.353 73.5161L220.396 82.7105Z" fill="black" fillOpacity="0.2" />
        <path d="M227.237 181.975L110.215 176.706L111.369 181.924L138.918 183.631L141.377 184.935L201.444 187.745L203.552 186.742L226.284 187.745L227.237 181.975Z" fill="#85CC91" />
        <path d="M227.237 181.975L110.215 176.706L111.369 181.924L138.918 183.631L141.377 184.935L201.444 187.745L203.552 186.742L226.284 187.745L227.237 181.975Z" fill="black" fillOpacity="0.2" />
        <path d="M120.201 44.3776L101.835 52.0051L97.168 58.9302V61.1381L240.887 64.2995L265.175 54.1629V52.1557L259.504 47.7397L223.625 45.7827L215.044 43.0729L147.7 41.8184L142.482 44.3776H120.201Z" fill="#85CC91" />
        <path d="M120.201 44.3776L101.835 52.0051L97.168 58.9302V61.1381L240.887 64.2995L265.175 54.1629V52.1557L259.504 47.7397L223.625 45.7827L215.044 43.0729L147.7 41.8184L142.482 44.3776H120.201Z" fill="white" fillOpacity="0.2" />
        <path d="M136.61 57.3747L101.282 56.3711V60.2852L240.134 63.1957V59.2816H194.519C175.892 54.7853 148.151 56.1369 136.61 57.3747Z" fill="#85CC91" />
        <path d="M136.61 57.3747L101.282 56.3711V60.2852L240.134 63.1957V59.2816H194.519C175.892 54.7853 148.151 56.1369 136.61 57.3747Z" fill="black" fillOpacity="0.2" />
        <path d="M136.811 36.75L133.147 44.2772V45.2306H143.535V43.3739V36.75H136.811Z" fill="#85CC91" />
        <path d="M136.811 36.75L133.147 44.2772V45.2306H143.535V43.3739V36.75H136.811Z" fill="white" fillOpacity="0.2" />
        <path d="M166.669 37.5529C165.749 39.4932 163.848 43.3739 163.607 43.3739H172.088L171.135 37.5529H166.669Z" fill="#85CC91" />
        <path d="M166.669 37.5529C165.749 39.4932 163.848 43.3739 163.607 43.3739H172.088L171.135 37.5529H166.669Z" fill="white" fillOpacity="0.2" />
        <path d="M166.066 38.9079L142.682 38.105V41.0155L165.012 41.5173L166.066 38.9079Z" fill="#85CC91" />
        <path d="M166.066 38.9079L142.682 38.105V41.0155L165.012 41.5173L166.066 38.9079Z" fill="white" fillOpacity="0.2" />
        <path d="M224.026 39.0582L221.065 45.2305V46.3345H230.851V39.0582H224.026Z" fill="#85CC91" />
        <path d="M224.026 39.0582L221.065 45.2305V46.3345H230.851V39.0582H224.026Z" fill="white" fillOpacity="0.2" />
        <path d="M252.579 39.9615L249.92 47.2879V47.9905H260.056L259.203 39.9615H252.579Z" fill="#85CC91" />
        <path d="M252.579 39.9615L249.92 47.2879V47.9905H260.056L259.203 39.9615H252.579Z" fill="white" fillOpacity="0.2" />
        <path d="M252.127 41.0154L230.499 40.5135V43.2735L252.127 43.8255V41.0154Z" fill="#85CC91" />
        <path d="M252.127 41.0154L230.499 40.5135V43.2735L252.127 43.8255V41.0154Z" fill="white" fillOpacity="0.2" />
        <ellipse cx="126.976" cy="199.11" rx="12.4951" ry="12.5453" fill="#1E2B39" />
        <ellipse cx="227.84" cy="204.205" rx="12.4951" ry="12.5453" fill="#1E2B39" />
        <ellipse cx="125.269" cy="199.511" rx="12.194" ry="12.1439" fill="#2B3B4D" />
        <ellipse cx="226.133" cy="204.606" rx="12.194" ry="12.1439" fill="#2B3B4D" />
        <ellipse opacity="0.1" cx="125.27" cy="199.562" rx="8.48063" ry="8.48062" fill="white" />
        <ellipse opacity="0.1" cx="226.134" cy="204.657" rx="8.48063" ry="8.48062" fill="white" />
        <path d="M114.23 184.055V182.751V182.199L125.671 182.751V184.758H128.381V186.364H125.671L127.779 201.067H121.907L115.635 187.066L111.871 186.364V185.36L114.23 184.055Z" fill="#536B85" />
        <path d="M214.994 189.1V187.495L226.435 187.996V188.598V190.154H228.944V191.76H227.037L228.944 206.563H222.922L215.947 191.76H212.334C211.812 191.76 212.117 191.124 212.334 190.806L214.391 189.1H214.994Z" fill="#536B85" />
        <path d="M243.597 190.706L239.181 180.971L247.41 175.351L248.966 190.706H243.597Z" fill="#536B85" />
        <path d="M95.7916 90.5046L95.196 89.0479C95.0857 88.7773 94.9117 88.5373 94.689 88.3481C94.4662 88.159 94.2012 88.0263 93.9163 87.9613L91.2282 87.3255C90.6969 87.2017 90.1437 87.2065 89.6145 87.3395C89.0854 87.4725 88.5957 87.7298 88.186 88.0901L87.6709 88.5408C88.4757 91.0277 91.2202 93.0961 91.2202 93.0961L91.9284 93.7802C92.0699 93.9235 92.2385 94.0373 92.4243 94.115C92.6101 94.1927 92.8095 94.2327 93.0109 94.2327C93.2123 94.2327 93.4117 94.1927 93.5975 94.115C93.7833 94.0373 93.9519 93.9235 94.0934 93.7802L95.4535 92.3879C95.6861 92.1404 95.8419 91.8307 95.9019 91.4964C95.9619 91.1621 95.9236 90.8175 95.7916 90.5046Z" fill="#FBC8C1" />
        <path d="M63.6378 63.0117C67.517 68.8789 71.1951 74.8667 74.7926 80.911L73.3761 79.7681C76.201 80.9351 79.0099 82.1826 81.8107 83.4059C84.2251 84.5327 87.7664 86.1343 90.1406 87.3657C90.4652 87.5578 90.712 87.8578 90.8379 88.2134C90.9639 88.569 90.961 88.9575 90.8297 89.3111C90.6984 89.6647 90.4471 89.961 90.1196 90.1482C89.7921 90.3354 89.4093 90.4017 89.038 90.3355C83.1708 88.8868 77.1025 87.0437 71.3721 85.1765C70.7757 84.9785 70.2702 84.5729 69.9476 84.0337C65.9235 78.2551 62.004 72.4281 58.2615 66.4724C57.9159 65.7641 57.8422 64.9536 58.0543 64.1945C58.2664 63.4354 58.7496 62.7805 59.4124 62.3539C60.0751 61.9273 60.8713 61.7586 61.65 61.8799C62.4288 62.0011 63.1361 62.4038 63.6378 63.0117Z" fill="#FBC8C1" />
        <path d="M70.7531 72.7985L63.558 76.1063L62.7532 75.3015L59.6707 72.219L59.2441 71.7924L62.0369 57.9414C67.0429 61.0883 70.7531 72.7985 70.7531 72.7985Z" fill="#26374A" />
        <path opacity="0.2" d="M62.7534 75.3178L59.6709 72.2353C60.2598 70.0641 61.4612 68.1084 63.1316 66.6016C63.7345 69.493 63.6045 72.4894 62.7534 75.3178Z" fill="#10242E" />
        <path d="M40.959 59.4788C39.8564 63.2776 39.4621 66.5773 38.6894 69.3057C37.3212 74.1346 34.0215 86.6818 32.3555 89.6355L54.4318 90.722C57.9247 84.7824 63.5585 70.3842 64.3633 68.4848C64.9854 67.0233 65.2374 65.4309 65.097 63.8488C64.9566 62.2666 64.4282 60.7435 63.5585 59.4144C63.1501 58.8408 62.64 58.3471 62.0535 57.9577C61.1842 57.3862 60.2667 56.7987 59.3653 56.2676C56.4495 54.6789 53.2223 53.745 49.9087 53.5311C43.8725 53.5231 41.8282 56.4688 40.959 59.4788Z" fill="#26374A" />
        <path d="M72.2817 202.938L75.8712 201.957C74.5513 183.872 71.0021 162.053 67.992 145.691C67.5172 143.124 67.0584 140.701 66.6238 138.448C66.4065 137.378 66.2134 136.339 66.0122 135.349C65.0544 130.448 64.2496 126.569 63.7506 124.178C63.3804 122.432 63.1711 121.466 63.1711 121.466L50.2939 125.209L51.3644 128.428L54.9136 139.148L55.316 140.38L57.6581 147.462L61.642 159.534C62.1812 177.989 72.2817 202.938 72.2817 202.938Z" fill="#FBC8C1" />
        <path d="M41.0227 202.061L44.7249 201.707C46.6645 182.094 46.7369 157.675 46.4794 140.71C46.4311 138.078 46.3828 135.623 46.3345 133.394C46.3345 133.281 46.3345 133.177 46.3345 133.064C46.2299 128.645 46.1172 125.136 46.0367 122.915L45.924 120.219L32.5801 121.708L33.3849 127.261L34.5841 135.422L34.8255 137.032L35.6304 142.593L37.8597 157.498C35.3004 175.752 41.0227 202.061 41.0227 202.061Z" fill="#FBC8C1" />
        <path opacity="0.2" d="M34.584 135.422L46.3102 133.394C46.3102 133.281 46.3102 133.176 46.3102 133.064C46.2056 128.645 46.0929 125.136 46.0124 122.915L33.3848 127.261L34.584 135.422Z" fill="#10242E" />
        <path opacity="0.2" d="M65.0384 130.44L52.7005 132.532L51.3564 128.452L63.7507 124.17C64.0646 125.691 64.5153 127.824 65.0384 130.44Z" fill="#10242E" />
        <path d="M33.0712 86.4806C33.0712 86.4806 25.0713 105.177 32.5481 132.283L47.1234 130.617V116.645L50.2784 130.11L64.8939 127.172C60.403 105.177 56.3145 95.1968 56.3145 95.1968L55.5821 87.7442L33.0712 86.4806Z" fill="#9DD6A7" />
        <path d="M40.1934 91.4945L52.2335 91.9854V88.2591L40.1934 87.736V91.4945Z" fill="#26374A" />
        <path d="M39.1313 87.6958L33.0871 87.4382L32.5479 91.1887L39.1313 91.4543V87.6958Z" fill="#26374A" />
        <path d="M53.1592 88.2915V92.0259L55.8634 92.1386V88.4122L53.1592 88.2915Z" fill="#26374A" />
        <path d="M51.1235 88.5329C50.8383 88.2411 50.4528 88.0685 50.0451 88.0501L47.2041 87.9293C47.0392 87.9123 46.8727 87.9303 46.7153 87.9822C46.5579 88.0341 46.4133 88.1187 46.2909 88.2304C46.1686 88.3421 46.0712 88.4785 46.0053 88.6305C45.9393 88.7825 45.9063 88.9467 45.9083 89.1124V90.8508C45.9178 91.2463 46.0826 91.6222 46.367 91.8971C46.6229 92.1599 46.9608 92.3274 47.3248 92.372H47.4535L50.2946 92.4846C50.4588 92.5005 50.6245 92.4816 50.7808 92.4291C50.9372 92.3767 51.0808 92.2919 51.2023 92.1803C51.3238 92.0687 51.4204 91.9328 51.4859 91.7814C51.5513 91.63 51.5842 91.4665 51.5823 91.3015V89.5712C51.5674 89.1792 51.4033 88.8078 51.1235 88.5329ZM50.2302 91.8005L47.3892 91.6879C47.1898 91.6795 47.0017 91.5928 46.866 91.4464C46.7959 91.3817 46.7397 91.3034 46.701 91.2162C46.6622 91.129 46.6417 91.0348 46.6407 90.9394V89.201C46.6403 89.1209 46.6567 89.0417 46.6889 88.9684C46.7211 88.8952 46.7683 88.8295 46.8274 88.7756C46.8866 88.7217 46.9565 88.6809 47.0324 88.6557C47.1084 88.6306 47.1888 88.6216 47.2684 88.6295L50.1095 88.7503H50.1739C50.3482 88.7757 50.5094 88.8577 50.6326 88.9836C50.7017 89.0487 50.7566 89.1274 50.7939 89.2146C50.8313 89.3018 50.8504 89.3958 50.8499 89.4907V89.7563C50.8154 89.7493 50.7798 89.7493 50.7453 89.7563L49.9405 89.716C49.8805 89.7099 49.8198 89.7171 49.7629 89.7371C49.706 89.757 49.6542 89.7893 49.6112 89.8315C49.5681 89.8738 49.5349 89.925 49.514 89.9816C49.493 90.0381 49.4847 90.0986 49.4898 90.1587C49.5078 90.288 49.5695 90.4072 49.6647 90.4965C49.7599 90.5858 49.8827 90.6398 50.0129 90.6496L50.8177 90.6899V91.2613C50.8146 91.3353 50.7969 91.408 50.7657 91.4751C50.7345 91.5423 50.6903 91.6027 50.6358 91.6528C50.5812 91.7028 50.5173 91.7417 50.4477 91.767C50.3781 91.7924 50.3042 91.8038 50.2302 91.8005Z" fill="white" />
        <path d="M39.252 201.305C39.252 200.846 44.9823 201.208 44.9823 201.208C44.9823 201.208 48.4994 204.782 51.9119 205.586C55.3243 206.391 53.425 208.444 49.602 208.444H40.1534C37.4411 208.444 39.1393 206.134 39.252 201.305Z" fill="#26374A" />
        <path d="M47.9848 202.673C47.1606 202.568 46.3603 202.323 45.6186 201.948C45.5992 201.94 45.5826 201.926 45.5703 201.908C45.5586 201.872 45.5586 201.832 45.5703 201.796C45.5703 201.731 46.0773 200.403 46.85 200.081C46.9661 200.028 47.0924 200 47.2202 200C47.348 200 47.4743 200.028 47.5904 200.081C47.848 200.202 47.9767 200.363 47.9687 200.564C47.9606 200.765 47.5421 201.168 47.0753 201.369C47.5071 201.298 47.9501 201.348 48.355 201.514C48.4303 201.553 48.4964 201.608 48.5491 201.674C48.6018 201.741 48.6399 201.818 48.6608 201.9C48.7034 202.004 48.7131 202.118 48.6887 202.228C48.6642 202.337 48.6067 202.436 48.524 202.512C48.3695 202.629 48.178 202.686 47.9848 202.673ZM46.1417 201.868C46.9466 202.222 48.0331 202.536 48.2987 202.319C48.2987 202.319 48.3952 202.238 48.2987 202.013C48.2729 201.928 48.2152 201.855 48.1377 201.812C47.4809 201.645 46.7913 201.659 46.1417 201.852V201.868ZM47.1639 200.258C47.0837 200.26 47.0045 200.276 46.9305 200.307C46.4765 200.612 46.1324 201.055 45.9486 201.57C46.8017 201.248 47.6146 200.765 47.6307 200.548C47.6307 200.468 47.5099 200.395 47.4294 200.355C47.3478 200.309 47.2572 200.282 47.1639 200.274V200.258Z" fill="#9DD6A7" />
        <path d="M71.2603 201.305C71.2603 200.846 76.9907 201.208 76.9907 201.208C76.9907 201.208 80.5078 204.782 83.9283 205.586C87.3488 206.391 85.4333 208.444 81.6184 208.444H72.1698C69.4495 208.444 71.1477 206.134 71.2603 201.305Z" fill="#26374A" />
        <path d="M80.0003 202.673C79.16 202.571 78.3434 202.326 77.5858 201.948L77.5375 201.908C77.5295 201.891 77.5254 201.871 77.5254 201.852C77.5254 201.832 77.5295 201.813 77.5375 201.796C77.5375 201.731 78.0365 200.403 78.8091 200.081C78.9252 200.028 79.0516 200 79.1794 200C79.3072 200 79.4335 200.028 79.5496 200.081C79.8152 200.202 79.9359 200.363 79.9279 200.564C79.9198 200.765 79.5013 201.168 79.0345 201.369C79.4689 201.299 79.9142 201.349 80.3222 201.514C80.3975 201.553 80.4637 201.608 80.5164 201.674C80.5691 201.741 80.6071 201.818 80.6281 201.9C80.6663 202.004 80.6737 202.118 80.6495 202.226C80.6252 202.335 80.5703 202.434 80.4912 202.512C80.3497 202.618 80.1772 202.675 80.0003 202.673ZM78.1492 201.868C78.9943 202.222 80.0405 202.536 80.3142 202.319C80.3142 202.319 80.4108 202.238 80.3142 202.013C80.3026 201.97 80.2824 201.93 80.2547 201.895C80.227 201.861 80.1924 201.832 80.1532 201.812C79.4939 201.643 78.8012 201.657 78.1492 201.852V201.868ZM79.1794 200.258C79.0991 200.258 79.0196 200.275 78.946 200.307C78.4876 200.607 78.1421 201.052 77.9641 201.57C78.7689 201.248 79.6301 200.765 79.6462 200.548C79.6462 200.468 79.5174 200.395 79.4369 200.355C79.3578 200.31 79.27 200.283 79.1794 200.274V200.258Z" fill="#9DD6A7" />
        <path opacity="0.2" d="M47.123 116.645C49.618 111.495 51.3564 107.261 51.3564 107.261C51.3564 107.261 51.9037 120.291 50.278 130.11L47.123 116.645Z" fill="#10242E" />
        <path d="M96.0435 80.7554L98.5996 89.1473L112.874 84.7997L110.318 76.4077L96.0435 80.7554Z" fill="#26374A" />
        <path opacity="0.1" d="M96.0435 80.7554L98.5996 89.1473L112.874 84.7997L110.318 76.4077L96.0435 80.7554Z" fill="white" />
        <path opacity="0.3" d="M112.287 82.8905L98.0049 87.2405L98.5888 89.1576L112.87 84.8075L112.287 82.8905Z" fill="#10242E" />
        <path opacity="0.2" d="M138.663 99.0456C153.712 98.3675 165.361 85.6185 164.683 70.57C164.005 55.5214 151.256 43.8718 136.208 44.5499C121.159 45.228 109.51 57.977 110.188 73.0255C110.866 88.0741 123.615 99.7237 138.663 99.0456Z" fill="#9DD6A7" />
        <g clipPath="url(#clip0_2086_70621)">
            <rect x="110.643" y="45.9297" width="53.9232" height="53.9232" rx="26.9616" fill="white" />
            <path d="M78.5566 39.4523L87.7512 36.0311L141.208 13.0447L451.898 21.5978V39.4523L432.654 53.351L424.635 241.412L417.472 275.945L367.116 308.233L316.118 305.667L311.2 308.233L182.37 301.604L177.772 298.397L117.58 295.296L107.617 260.015L100.474 113.116H95.342V88.7394L81.3364 72.3816V54.8478H78.5566V39.4523Z" fill="#85CC91" />
            <path d="M394.271 64.363L78.4492 54.7408V39.666L394.271 45.9739L451.898 22.2391V39.666L394.271 64.363Z" fill="#85CC91" />
            <path d="M394.271 64.363L78.4492 54.7408V39.666L394.271 45.9739L451.898 22.2391V39.666L394.271 64.363Z" fill="white" fillOpacity="0.2" />
            <path d="M125.487 75.613L126.161 56.2734L119.002 56.0239L125.487 75.613Z" fill="#85CC91" />
            <path d="M125.487 75.613L126.161 56.2734L119.002 56.0239L125.487 75.613Z" fill="black" fillOpacity="0.2" />
            <path d="M170.684 77.1881L171.358 57.8485L164.199 57.599L170.684 77.1881Z" fill="#85CC91" />
            <path d="M170.684 77.1881L171.358 57.8485L164.199 57.599L170.684 77.1881Z" fill="black" fillOpacity="0.2" />
        </g>
        <path opacity="0.3" d="M138.663 99.0456C153.712 98.3675 165.361 85.6185 164.683 70.57C164.005 55.5214 151.256 43.8718 136.208 44.5499C121.159 45.228 109.51 57.977 110.188 73.0255C110.866 88.0741 123.615 99.7237 138.663 99.0456Z" fill="white" />
        <path d="M129.417 45.9496C123.875 47.6367 118.958 50.9293 115.288 55.4108C111.618 59.8924 109.359 65.3617 108.797 71.127C108.235 76.8923 109.396 82.6948 112.132 87.8006C114.868 92.9064 119.057 97.0862 124.168 99.8114C129.28 102.537 135.085 103.685 140.849 103.111C146.613 102.537 152.077 100.266 156.551 96.5867C161.025 92.907 164.307 87.9833 165.983 82.4382C167.658 76.8931 167.651 70.9757 165.964 65.4344C163.701 58.0043 158.579 51.7774 151.725 48.1234C144.871 44.4693 136.847 43.6874 129.417 45.9496ZM130.431 49.2735C135.317 47.7835 140.535 47.7762 145.425 49.2526C150.314 50.729 154.657 53.6227 157.902 57.5675C161.147 61.5124 163.148 66.3311 163.654 71.414C164.16 76.497 163.146 81.6156 160.742 86.1223C158.337 90.6291 154.65 94.3214 150.147 96.732C145.644 99.1427 140.526 100.163 135.443 99.6649C130.359 99.1665 125.538 97.1713 121.588 93.9319C117.639 90.6925 114.739 86.3544 113.256 81.4665C111.269 74.9199 111.962 67.8525 115.182 61.8164C118.403 55.7804 123.887 51.2692 130.431 49.2735Z" fill="#26374A" />
        <path opacity="0.1" d="M129.417 45.9496C123.875 47.6367 118.958 50.9293 115.288 55.4108C111.618 59.8924 109.359 65.3617 108.797 71.127C108.235 76.8923 109.396 82.6948 112.132 87.8006C114.868 92.9064 119.057 97.0862 124.168 99.8114C129.28 102.537 135.085 103.685 140.849 103.111C146.613 102.537 152.077 100.266 156.551 96.5867C161.025 92.907 164.307 87.9833 165.983 82.4382C167.658 76.8931 167.651 70.9757 165.964 65.4344C163.701 58.0043 158.579 51.7774 151.725 48.1234C144.871 44.4693 136.847 43.6874 129.417 45.9496ZM130.431 49.2735C135.317 47.7835 140.535 47.7762 145.425 49.2526C150.314 50.729 154.657 53.6227 157.902 57.5675C161.147 61.5124 163.148 66.3311 163.654 71.414C164.16 76.497 163.146 81.6156 160.742 86.1223C158.337 90.6291 154.65 94.3214 150.147 96.732C145.644 99.1427 140.526 100.163 135.443 99.6649C130.359 99.1665 125.538 97.1713 121.588 93.9319C117.639 90.6925 114.739 86.3544 113.256 81.4665C111.269 74.9199 111.962 67.8525 115.182 61.8164C118.403 55.7804 123.887 51.2692 130.431 49.2735Z" fill="white" />
        <path d="M128.644 43.4062C123.102 45.0939 118.186 48.387 114.516 52.8691C110.846 57.3512 108.587 62.821 108.026 68.5866C107.465 74.3523 108.627 80.1548 111.364 85.2604C114.1 90.366 118.29 94.5452 123.402 97.2697C128.514 99.9941 134.32 101.141 140.084 100.566C145.848 99.991 151.312 97.7194 155.786 94.0386C160.259 90.3577 163.54 85.4331 165.214 79.8874C166.888 74.3417 166.88 68.4241 165.191 62.8829C162.926 55.4544 157.804 49.2294 150.95 45.577C144.097 41.9245 136.073 41.1437 128.644 43.4062ZM129.65 46.7221C134.535 45.2306 139.754 45.2218 144.644 46.6969C149.535 48.172 153.878 51.0647 157.124 55.0089C160.37 58.9532 162.373 63.7717 162.88 68.8547C163.387 73.9377 162.374 79.0568 159.971 83.5642C157.567 88.0717 153.88 91.7648 149.377 94.1763C144.874 96.5878 139.757 97.6093 134.673 97.1116C129.589 96.6139 124.767 94.6193 120.817 91.3802C116.867 88.141 113.967 83.803 112.483 78.915C110.496 72.3695 111.188 65.3031 114.407 59.2672C117.625 53.2313 123.108 48.7194 129.65 46.7221Z" fill="#26374A" />
        <path opacity="0.3" d="M128.644 43.4062C123.102 45.0939 118.186 48.387 114.516 52.8691C110.846 57.3512 108.587 62.821 108.026 68.5866C107.465 74.3523 108.627 80.1548 111.364 85.2604C114.1 90.366 118.29 94.5452 123.402 97.2697C128.514 99.9941 134.32 101.141 140.084 100.566C145.848 99.991 151.312 97.7194 155.786 94.0386C160.259 90.3577 163.54 85.4331 165.214 79.8874C166.888 74.3417 166.88 68.4241 165.191 62.8829C162.926 55.4544 157.804 49.2294 150.95 45.577C144.097 41.9245 136.073 41.1437 128.644 43.4062ZM129.65 46.7221C134.535 45.2306 139.754 45.2218 144.644 46.6969C149.535 48.172 153.878 51.0647 157.124 55.0089C160.37 58.9532 162.373 63.7717 162.88 68.8547C163.387 73.9377 162.374 79.0568 159.971 83.5642C157.567 88.0717 153.88 91.7648 149.377 94.1763C144.874 96.5878 139.757 97.6093 134.673 97.1116C129.589 96.6139 124.767 94.6193 120.817 91.3802C116.867 88.141 113.967 83.803 112.483 78.915C110.496 72.3695 111.188 65.3031 114.407 59.2672C117.625 53.2313 123.108 48.7194 129.65 46.7221Z" fill="white" />
        <path d="M50.1985 99.3094C50.6501 100.794 51.6728 102.038 53.0417 102.769C54.4107 103.499 56.0137 103.656 57.4983 103.205L99.5664 90.3919C99.8088 90.3177 100.034 90.1961 100.229 90.0342C100.424 89.8723 100.585 89.6732 100.702 89.4486C100.82 89.2241 100.892 88.9784 100.914 88.7258C100.936 88.4733 100.907 88.2189 100.83 87.9775L98.5282 80.4283C98.3813 79.9452 98.0489 79.5402 97.6038 79.3018C97.1588 79.0634 96.6374 79.0112 96.1539 79.1566L54.0858 91.9694C53.3476 92.1945 52.6611 92.5632 52.0658 93.0544C51.4705 93.5456 50.9781 94.1496 50.6169 94.8316C50.2556 95.5136 50.0327 96.2603 49.961 97.0288C49.8892 97.7972 49.9699 98.5723 50.1985 99.3094Z" fill="#26374A" />
        <path opacity="0.3" d="M50.1985 99.3094C50.6501 100.794 51.6728 102.038 53.0417 102.769C54.4107 103.499 56.0137 103.656 57.4983 103.205L99.5664 90.3919C99.8088 90.3177 100.034 90.1961 100.229 90.0342C100.424 89.8723 100.585 89.6732 100.702 89.4486C100.82 89.2241 100.892 88.9784 100.914 88.7258C100.936 88.4733 100.907 88.2189 100.83 87.9775L98.5282 80.4283C98.3813 79.9452 98.0489 79.5402 97.6038 79.3018C97.1588 79.0634 96.6374 79.0112 96.1539 79.1566L54.0858 91.9694C53.3476 92.1945 52.6611 92.5632 52.0658 93.0544C51.4705 93.5456 50.9781 94.1496 50.6169 94.8316C50.2556 95.5136 50.0327 96.2603 49.961 97.0288C49.8892 97.7972 49.9699 98.5723 50.1985 99.3094Z" fill="white" />
        <path opacity="0.3" d="M49.9883 98.3275C50.0223 98.6614 50.0925 98.9906 50.1975 99.3094C50.421 100.044 50.7872 100.728 51.2752 101.321C51.7632 101.914 52.3634 102.406 53.0414 102.767C53.7194 103.128 54.4619 103.352 55.2265 103.425C55.9911 103.499 56.7628 103.421 57.4973 103.197L99.5654 90.3839C99.809 90.3116 100.036 90.1911 100.232 90.0296C100.428 89.8681 100.59 89.6689 100.708 89.4438C100.825 89.2187 100.897 88.9722 100.918 88.7189C100.939 88.4657 100.908 88.2108 100.829 87.9694L100.25 86.062C100.328 86.304 100.357 86.5591 100.335 86.8124C100.313 87.0657 100.241 87.312 100.122 87.537C100.004 87.7619 99.8413 87.9609 99.6448 88.1223C99.4484 88.2836 99.2216 88.404 98.9779 88.4765L56.9178 101.289C55.599 101.693 54.18 101.617 52.9118 101.075C51.6436 100.533 50.608 99.5596 49.9883 98.3275Z" fill="#10242E" />
        <path opacity="0.3" d="M54.4475 95.0034C54.1245 95.1668 53.8548 95.4191 53.6704 95.7306C53.4859 96.0421 53.3943 96.3998 53.4063 96.7616C53.4184 97.1235 53.5336 97.4743 53.7384 97.7729C53.9431 98.0714 54.229 98.3052 54.5622 98.4467C54.8954 98.5882 55.2621 98.6315 55.6191 98.5716C55.9762 98.5117 56.3086 98.351 56.5774 98.1084C56.8461 97.8658 57.0399 97.5515 57.136 97.2024C57.232 96.8534 57.2263 96.4842 57.1196 96.1383C56.9124 95.6361 56.516 95.2357 56.0161 95.0233C55.5161 94.811 54.9527 94.8039 54.4475 95.0034Z" fill="#10242E" />
        <path d="M61.4729 54.352C60.9301 54.7547 60.4311 55.2133 59.984 55.7202C58.9056 56.9998 58.4549 58.6095 59.9035 60.7503C59.9035 60.7503 60.3301 62.2473 57.2718 62.9314C55.308 55.8972 52.8613 54.2876 52.8613 54.2876C54.3261 54.2071 55.8392 52.8953 57.1752 51.2615C58.0086 50.2229 58.762 49.1225 59.4287 47.9697C59.7506 47.4305 60.0323 46.9154 60.2335 46.4486L60.483 48.0583L61.4729 54.352Z" fill="#FBC8C1" />
        <path opacity="0.2" d="M61.4735 54.3521C60.9307 54.7548 60.4317 55.2134 59.9846 55.7203C58.6593 54.5082 57.6884 52.9587 57.1758 51.2374C58.0092 50.1988 58.7626 49.0984 59.4293 47.9457C59.7915 47.9457 60.1536 48.0101 60.5239 48.0584L61.4735 54.3521Z" fill="#10242E" />
        <path d="M62.8903 35.2857C62.8903 35.2857 66.842 30.7384 62.0855 26.9235C57.329 23.1087 48.0091 24.831 46.2868 33.4426C44.5645 42.0542 46.9387 45.692 46.9387 45.692C46.9387 45.692 46.9387 40.2112 48.5483 38.6096C48.2951 39.9985 48.2951 41.4218 48.5483 42.8108C49.0232 44.4204 48.5483 43.3902 48.5483 43.3902C48.5483 43.3902 50.0051 36.517 57.0231 38.4889C64.0412 40.4607 62.8903 35.2857 62.8903 35.2857Z" fill="#26374A" />
        <path d="M66.7292 35.9778C71.9364 37.5874 72.7654 40.8711 71.5098 46.3519C69.9404 53.209 66.9948 57.5229 60.9184 54.1024C52.6689 49.4586 58.0773 33.3621 66.7292 35.9778Z" fill="#FBC8C1" />
        <path d="M68.2254 45.0321C68.4037 45.9387 68.693 46.8199 69.0865 47.6558C68.8679 47.8235 68.6074 47.9279 68.3335 47.9577C68.0596 47.9875 67.7828 47.9415 67.5332 47.8248L68.2254 45.0321Z" fill="#DA968B" />
        <path d="M65.1674 43.6396C65.0466 44.0662 64.7167 44.3479 64.4269 44.2674C64.1372 44.1869 64.0084 43.7765 64.1291 43.3419C64.2499 42.9072 64.5798 42.6336 64.8696 42.7141C65.1593 42.7946 65.2881 43.205 65.1674 43.6396Z" fill="#26374A" />
        <path d="M64.7412 42.6981L65.7794 42.5935C65.7794 42.5935 65.0953 43.2535 64.7412 42.6981Z" fill="#26374A" />
        <path d="M70.5521 45.0481C70.4314 45.4746 70.1014 45.7563 69.8117 45.6759C69.5219 45.5954 69.3932 45.1849 69.5139 44.7503C69.6346 44.3157 69.9646 44.0421 70.2463 44.1225C70.528 44.203 70.6729 44.6296 70.5521 45.0481Z" fill="#26374A" />
        <path d="M70.125 44.1066L71.1632 44.002C71.1632 44.002 70.4791 44.6297 70.125 44.1066Z" fill="#26374A" />
        <path d="M63.7103 40.348C63.6739 40.348 63.6381 40.3392 63.6059 40.3224C63.5736 40.3056 63.5459 40.2812 63.5252 40.2514C63.4938 40.2007 63.483 40.14 63.495 40.0817C63.507 40.0233 63.5408 39.9717 63.5896 39.9375C64.0029 39.7086 64.4636 39.5786 64.9355 39.5577C65.4075 39.5367 65.8779 39.6254 66.3099 39.8168C66.3368 39.8288 66.3611 39.8463 66.3811 39.868C66.4011 39.8897 66.4165 39.9153 66.4263 39.9432C66.436 39.971 66.4401 40.0006 66.438 40.0301C66.436 40.0596 66.428 40.0883 66.4145 40.1146C66.3899 40.169 66.3447 40.2114 66.2889 40.2325C66.2331 40.2536 66.1711 40.2517 66.1167 40.2272C65.7556 40.0679 65.3631 39.9924 64.9686 40.0064C64.5742 40.0203 64.188 40.1233 63.8391 40.3077C63.8008 40.3331 63.7562 40.3471 63.7103 40.348Z" fill="#26374A" />
        <path d="M72.0085 42.2151C71.9753 42.2148 71.9427 42.2066 71.9133 42.1912C71.8839 42.1758 71.8586 42.1537 71.8395 42.1266C71.6391 41.8409 71.3762 41.6046 71.0708 41.4359C70.7653 41.2671 70.4254 41.1703 70.0769 41.1527C70.0172 41.1527 69.9598 41.129 69.9176 41.0867C69.8753 41.0445 69.8516 40.9871 69.8516 40.9274C69.8516 40.8676 69.8753 40.8103 69.9176 40.768C69.9598 40.7258 70.0172 40.702 70.0769 40.702C70.4966 40.7144 70.9077 40.8241 71.2778 41.0224C71.6479 41.2208 71.9669 41.5023 72.2097 41.8449C72.2309 41.8652 72.2477 41.8895 72.2592 41.9165C72.2708 41.9435 72.2767 41.9725 72.2767 42.0018C72.2767 42.0311 72.2708 42.0602 72.2592 42.0871C72.2477 42.1141 72.2309 42.1385 72.2097 42.1588C72.1851 42.1865 72.153 42.2065 72.1174 42.2165C72.0817 42.2265 72.0439 42.226 72.0085 42.2151Z" fill="#26374A" />
        <path d="M58.359 43.02C58.359 43.02 62.0934 39.4868 62.7373 36.0663C62.7373 36.0663 74.3428 35.0039 71.6547 45.6356C71.6547 45.6356 75.5018 36.6377 66.6085 33.7001C57.7152 30.7625 53.7313 43.8731 57.2001 49.1849C57.2001 49.1849 56.0331 45.2413 58.359 43.02Z" fill="#26374A" />
        <path d="M66.327 50.175C65.6381 50.1838 64.9691 49.9449 64.4417 49.5017C63.9143 49.0585 63.5637 48.4406 63.4537 47.7605C63.4492 47.7314 63.4507 47.7016 63.4581 47.673C63.4654 47.6444 63.4786 47.6176 63.4966 47.5943C63.5147 47.5709 63.5374 47.5515 63.5632 47.5372C63.589 47.5229 63.6175 47.514 63.6469 47.5111C63.7055 47.5042 63.7644 47.5206 63.8111 47.5567C63.8577 47.5928 63.8884 47.6458 63.8964 47.7042C63.9837 48.28 64.2761 48.8047 64.7198 49.1819C65.1635 49.559 65.7286 49.7631 66.3109 49.7565C66.3706 49.7565 66.4279 49.7803 66.4702 49.8225C66.5125 49.8648 66.5362 49.9221 66.5362 49.9819C66.5362 50.0416 66.5125 50.0989 66.4702 50.1412C66.4279 50.1835 66.3706 50.2072 66.3109 50.2072L66.327 50.175Z" fill="#26374A" />
        <path d="M58.874 44.3801C58.9991 43.7629 58.8781 43.1213 58.5368 42.592C58.1955 42.0628 57.6609 41.6878 57.0471 41.5471C55.3811 41.2252 53.6024 44.3077 57.369 46.3278C57.9243 46.6175 58.4072 46.1507 58.874 44.3801Z" fill="#FBC8C1" />
        <path opacity="0.2" d="M41.8271 61.5956C41.8271 61.5956 39.7989 72.9919 46.656 78.8912C53.5131 84.7906 53.4648 67.672 51.8874 62.0463C50.6801 57.7405 41.8271 61.5956 41.8271 61.5956Z" fill="#10242E" />
        <path d="M67.5825 99.76L67.6308 98.1906C67.6404 97.8988 67.5796 97.609 67.4535 97.3456C67.3274 97.0823 67.1398 96.8531 66.9065 96.6776L64.7093 95.0035C64.2758 94.6743 63.7698 94.4536 63.2335 94.3598C62.6973 94.266 62.1464 94.3018 61.6268 94.4643L60.9669 94.6655C60.7093 97.2651 62.3512 100.299 62.3512 100.299L62.7214 101.209C62.7928 101.397 62.9007 101.57 63.039 101.716C63.1773 101.863 63.3432 101.981 63.5272 102.063C63.7112 102.145 63.9097 102.19 64.1111 102.196C64.3126 102.201 64.5132 102.166 64.7012 102.094L66.5121 101.378C66.8298 101.243 67.1008 101.018 67.2912 100.73C67.4816 100.443 67.5829 100.105 67.5825 99.76Z" fill="#FBC8C1" />
        <path d="M49.4174 61.531C50.5764 68.4685 51.4939 75.4383 52.3229 82.4564L51.518 80.8467C53.6267 83.0734 55.7031 85.3296 57.7474 87.6153C59.526 89.6435 62.0773 92.5328 63.7353 94.6173C63.9508 94.9248 64.0519 95.298 64.0213 95.6723C63.9906 96.0466 63.8301 96.3984 63.5674 96.6668C63.3048 96.9352 62.9565 97.1033 62.583 97.142C62.2094 97.1807 61.8341 97.0876 61.522 96.8788C56.7574 93.1686 51.9687 89.0157 47.4859 84.9755C47.0395 84.5481 46.7551 83.9793 46.681 83.3658C45.3692 76.4605 44.1459 69.5309 43.164 62.5692C43.1456 61.7877 43.4123 61.0262 43.9141 60.4269C44.416 59.8276 45.1188 59.4313 45.8913 59.3121C46.6639 59.1929 47.4534 59.3588 48.1126 59.7789C48.7718 60.1991 49.2556 60.8447 49.4738 61.5954L49.4174 61.531Z" fill="#FBC8C1" />
        <path d="M47.8881 54.7383C43.864 55.181 40.5642 56.9275 42.3107 69.3781L52.1698 68.871C52.1698 68.871 52.6446 54.2071 47.8881 54.7383Z" fill="#26374A" />
        <path d="M94.6346 181.277C95.0795 180.533 96.0613 179.62 96.7516 179.099C97.4419 178.577 98.3854 178.316 99.1908 178.646C100.211 179.061 100.679 180.318 100.541 181.408C100.395 182.505 99.8735 183.885 99.2522 184.798C96.5982 188.687 94.1437 192.246 90.04 195.138C90.04 190.313 92.1801 185.396 94.6346 181.277Z" fill="#ADDDB5" />
        <path d="M81.1189 181.945C82.0394 181.446 83.7422 182.152 84.5783 182.781C86.2351 184.023 87.2475 185.327 88.26 187.138C89.541 189.431 89.6944 192.66 89.8555 194.708C86.6109 192.077 83.305 189.37 81.0806 185.834C80.6894 185.212 80.3365 184.553 80.2598 183.824C80.1908 183.095 80.4746 182.298 81.1189 181.945Z" fill="#ADDDB5" />
        <path d="M90.1212 197.23C90.2133 197.23 90.29 197.168 90.3053 197.076C91.0723 193.525 92.4377 190.158 94.3553 187.066C94.409 186.974 94.3859 186.859 94.2939 186.806C94.2019 186.752 94.0868 186.775 94.0331 186.867C92.0925 189.997 90.7195 193.402 89.9371 197C89.9141 197.099 89.9832 197.207 90.0829 197.23C90.0982 197.23 90.1135 197.23 90.1212 197.23Z" fill="#26374A" />
        <path d="M89.9802 195.214C89.9802 195.214 90.0263 195.214 90.0493 195.207C90.149 195.168 90.2027 195.061 90.1643 194.961C89.2976 192.56 88.0243 190.351 86.3905 188.388C86.3215 188.303 86.1987 188.296 86.122 188.365C86.0377 188.434 86.03 188.556 86.099 188.633C87.7021 190.558 88.9524 192.729 89.8115 195.092C89.8422 195.168 89.9112 195.222 89.9879 195.222L89.9802 195.214Z" fill="#26374A" />
        <defs>
            <clipPath id="clip0_2086_70621">
                <rect x="110.643" y="45.9297" width="53.9232" height="53.9232" rx="26.9616" fill="white" />
            </clipPath>
        </defs>
    </svg>
)