import { useState, useEffect, useCallback, useContext } from 'react';
import { DataGrid, GridRenderCellParams, deDE, enUS  } from '@mui/x-data-grid';
import { IconButton } from '@mui/material';
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { styles } from "../../../styles";
import { IconDeleteSmall, IconEditPen, IconPlusCircle } from '../../../components/Icons';
import { TextIconButton } from "../../../components/StyledComponents";
import Modal from '../../../components/Modal';
import { AddContainerFormModal } from './AddContainerFormModal';
import { EditContainerFormModal } from './EditContainerFormModal';
import { DeleteContainerModalContent } from './DeleteContainerModalContent';
import { ContainerCheckedModalContent } from './ContainerCheckedModalContent';
import { UserContext } from '../../../contexts/UserContext';
import { useContainersQuery } from '../../../data/containers';
import { useLocation } from 'react-router-dom';
import { Container, ContainerStatusReason } from '../../../types/Container';
import { useAccountQuery } from '../../../data/accounts';
import { Loader } from '../../../components/Loader';
import { MessageWrapper } from '../../../components/MessageWrapper';


const StyledSection = styled.section`
    grid-column: span 8;
`;

const StyledH2 = styled.h2`
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 24px;

    @media (max-width: 480px) {
        margin-bottom: 12px;
    }
`;

const StatusPill = styled.div<{ bg: string }>`
    padding-right: 8px;
    padding-left: 8px;
    height: 18px;
    line-height: 18px;
    background: ${(props) => props.bg};
    color: ${styles.textColorSecondary900};
    text-align: center;
    display: inline-block;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    font-size: 10px;
`;

const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 4px;

    width: 100%;
    justify-content: flex-end;

    @media (min-width: 962px) and (max-width: 1060px) {
        width: initial;
    }
`;

const Separator = styled.div`
  height: 20px;
  width: 1px;
  background-color: ${styles.neutral300};
`;

const BottomRow = styled.div`
    display: flex;
    justify-content: flex-start;
    gap: 12px;
`;

const StyledTextIconButton = styled(TextIconButton)`
    color: ${props => props.disabled ? styles.neutral200 : "#26374A"};
    svg {
        circle, path {
            stroke: ${props => props.disabled ? styles.neutral200 : "#26374A"};
        }
    }
    cursor: ${props => props.disabled ? "not-allowed" : "pointer"};
`;

const ColorIndicator = styled.div<{ color: string }>`
    width: 18px;
    height: 18px;
    background-color: ${props => props.color};
    border-radius: 50%;
`;

const MobileRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 12px;

    > div {
        width: 25%;
    }
`;

const MobileHeaderRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    border-bottom: 1px solid ${styles.neutral300};

    div {
        width: 25%;
    }
`;

const MobileHeader = styled.div`
    color: ${styles.textColorSecondary700};
    margin-bottom: 12px;
    margin-top: 12px;
`;

const MobileData = styled.div`
    margin-bottom: 8px;
    font-size: 12px;
    margin-top: 20px;
`;

const IconsRow = styled.div`
    display: flex;
    justify-content: flex-end;
    border-bottom: 1px solid ${styles.neutral200};
    margin-bottom: 14px;
`;

const useWindowWidth = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowWidth(window.innerWidth);
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        }
    }, []);

    return windowWidth;
}

export const OverviewSection: React.FC = () => {
    const userContext = useContext(UserContext);
    const accountQuery = useAccountQuery(userContext.accessPermission?.accountRef.id);
    const containersQuery = useContainersQuery(userContext.accessPermission?.accountRef);

    const windowWidth = useWindowWidth();
    const isTabletView = windowWidth <= 834 && windowWidth > 480;
    const isMobileView = windowWidth <= 480;
    const isDesktopView = !isMobileView && !isTabletView;

    const { t, i18n } = useTranslation();

    const {state} = useLocation();

    const [isAddingContainer, setIsAddingContainer] = useState(state ? state === 'auto-open-new-dlg': false);
    const [containerInEdit, setContainerInEdit] = useState(state ? state.preselectedContainer: undefined);
    const [containerToDelete, setContainerToDelete] = useState<Container>();
    const [wasAddingSuccessful, setWasAddingSuccessful] = useState(false);

    const IconsWrapper = ({container}: {container: Container}) => {
        return (
            <IconWrapper>
                <IconButton onClick={() => setContainerInEdit(container)}>
                    <IconEditPen />
                </IconButton>

                <Separator />

                <IconButton onClick={() => setContainerToDelete(container)}>
                    <IconDeleteSmall />
                </IconButton>
            </IconWrapper>
        );
    };

    const onAddContainerFormClose = useCallback((success: boolean) => {
        setIsAddingContainer(false);
        setWasAddingSuccessful(success);
    }, []);

    const onEditContainerFormClose = useCallback(() => {
        setContainerInEdit(undefined);
    }, []);

    const onDeleteContainerFormClose = useCallback(() => {
        setContainerToDelete(undefined);
    }, []);

    const columns: {
        field: string;
        headerName: string;
        sortable?: boolean;
        flex?: number;
        renderCell?: (params: GridRenderCellParams) => JSX.Element;
    }[] = [];
    columns.push({ field: 'displayName', headerName: t("pages.myContainers.name"), flex: 2 });
    columns.push({ field: 'awmId', headerName: t("pages.myContainers.id"), flex: 1.3 });
    columns.push({ field: 'shortName', headerName: t("pages.myContainers.code"), flex: 0.8 });
    if (isDesktopView) {
        columns.push({ field: 'street', headerName: t("pages.myContainers.street"), flex: 2 });
        columns.push({ field: 'houseNumber', headerName: t("pages.myContainers.number"), sortable: true, flex: 0.8 });
        columns.push({ field: 'city', headerName: t("pages.myContainers.location"), sortable: true, flex: 2 });
        columns.push({
            field: 'color',
            headerName: t("pages.myContainers.color"),
            renderCell: (params: GridRenderCellParams<Container>) => (
                <ColorIndicator color={params.row.color} />
            ),
            flex: 0.8
        })
    }
    columns.push({
        field: 'statusReason',
        headerName: t("pages.myContainers.status"),
        renderCell: (params: GridRenderCellParams<Container>) => (
            <StatusPill bg={params.row.statusReason === ContainerStatusReason.Active ? '#E9F6EB' : '#DEE9FC'}>
                { params.row.statusReason === ContainerStatusReason.Active && t(`containerStatus.active`)}
                { params.row.statusReason === ContainerStatusReason.Pending && t(`containerStatus.pending`)}
            </StatusPill>
        ),
        flex: 2
    });
    if (userContext.accessPermission?.roles?.container && 
    columns.push({
        field: 'file',
        headerName: '',
        renderCell: (params: GridRenderCellParams<Container>) => (
            <IconsWrapper container={params.row}/>
        ),
        flex: 1.8
    })
    )

    if (containersQuery.isError) return (
        <MessageWrapper messageText={containersQuery.error.message} height={"420px"} margin={"0 0 48px 0"} />
    );

    const renderMobileView = () => {
        if (containersQuery.isLoading) return <Loader height={"420px"} margin={"0 0 48px 0"} />;

        return (
            <div>
                <MobileHeaderRow>
                    <MobileHeader>{t("pages.myContainers.name")}</MobileHeader>
                    <MobileHeader>{t("pages.myContainers.id")}</MobileHeader>
                    <MobileHeader style={{ maxWidth: 50 }}>{t("pages.myContainers.code")}</MobileHeader>
                    <MobileHeader style={{ maxWidth: 86 }}>{t("pages.myContainers.status")}</MobileHeader>
                </MobileHeaderRow>
                {containersQuery.data ? containersQuery.data.map((container) => (
                    <>
                        <MobileRow key={container.id}>
                            <MobileData>{container.displayName}</MobileData>
                            <MobileData>{container.awmId}</MobileData>
                            <MobileData style={{ maxWidth: 50 }}>{container.shortName}</MobileData>
                            <MobileData style={{ maxWidth: 86 }}>
                            <StatusPill bg={container.statusReason === ContainerStatusReason.Active ? '#E9F6EB' : '#DEE9FC'}>
                                { container.statusReason === ContainerStatusReason.Active && t(`containerStatus.active`)}
                                { container.statusReason === ContainerStatusReason.Pending && t(`containerStatus.pending`)}
                            </StatusPill>
                            </MobileData>
                        </MobileRow>
                        { userContext.accessPermission?.roles?.container && 
                            <IconsRow><IconsWrapper container={container} /></IconsRow>
                        }
                    </>
                )) : <MessageWrapper messageText={t("components.tables.noEntriesAvailable") as string} messageType="textOnly" height={"320px"} />}
            </div>
        )
    };

    const renderTabletAndDesktopView = () => {
        if (containersQuery.isLoading) return <Loader height={"420px"} margin={"0 0 48px 0"} />;

        return (
            <DataGrid
                sx={{
                    '& .MuiDataGrid-row': {
                        borderBottom: `1px solid ${styles.neutral200}`,
                    },
                    '& .MuiDataGrid-cell': {
                        border: 'none'
                    },
                    '& .MuiDataGrid-withBorderColor': {
                        borderColor: `${styles.neutral300}`
                    },
                    '.MuiDataGrid-footerContainer.MuiDataGrid-withBorderColor': {
                        minHeight: '48px'
                    }
                }}
                initialState={{
                    pagination: {
                        paginationModel: { pageSize: 10, page: 0 },
                    },
                }}
                localeText={(i18n.language === 'de' ? deDE : enUS).components.MuiDataGrid.defaultProps.localeText}
                className="table-grid"
                style={{ border: 'none' }}
                rows={containersQuery.data || []}
                columns={columns}
                disableColumnSelector
                hideFooterSelectedRowCount={true}
                rowHeight={72}
            />
        )
    }

    return (
        <>
            <StyledSection>
                <StyledH2>{t("pages.balances.overview")}</StyledH2>
                {/* RESOLVED: Add a message 'Keine Einträge vorhanden' when there are no rows to show. This Todo must be done on every list based component, for mobile as well as for desktop.*/}
                {isMobileView ? renderMobileView() : renderTabletAndDesktopView()}

                { userContext.accessPermission?.roles?.container && 
                <BottomRow>
                    <StyledTextIconButton onClick={() => setIsAddingContainer(true)} bold svgMargin='0 4px 0 0'><IconPlusCircle /> {t("buttons.addContainers")}</StyledTextIconButton>
                </BottomRow>
                }
            </StyledSection>
            {isAddingContainer && accountQuery.data && <Modal onClose={() => onAddContainerFormClose(false)}><AddContainerFormModal account={accountQuery.data} onClose={onAddContainerFormClose} /></Modal>}
            {containerInEdit && 
                <Modal onClose={onEditContainerFormClose}>
                    <EditContainerFormModal container={containerInEdit} onClose={onEditContainerFormClose} />
                </Modal>
            }
            {containerToDelete && 
                <Modal onClose={onDeleteContainerFormClose}>
                    <DeleteContainerModalContent container={containerToDelete} onClose={onDeleteContainerFormClose} />
                </Modal>}
            {wasAddingSuccessful &&
                <Modal onClose={() => { setWasAddingSuccessful(false); }}>
                    <ContainerCheckedModalContent onClose={() => { setWasAddingSuccessful(false); }} />
                </Modal>
            }
        </>
    );
};