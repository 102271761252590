import { Namespace, TFunction } from "i18next";
import { ContactAssociation } from "../../../../types/ContactAssociation";
import { ContainerRef } from "../../../../types/Container";
import { Invitation } from "../../../../types/Invitation";
import { endUserRoleToDisplayName, userRoleInfoToEndUserRole } from "../../../../types/UserRolesInfo";
import { Salutation } from "../../../../types/Contact";

export type EmployeeListItem = {
    type: 'invitation' | 'contact';
    typedObject: Invitation | ContactAssociation;
    id: string;
    firstName: string;
    lastName: string;
    salutation: Salutation;
    email: string;
    contactId?: string;
    created: Date;
    roleName: string;
    containers: ContainerRef[];
}
export const convertContactAssociationsToListItems = (contactAssociations: ContactAssociation[] | null,  t: TFunction<Namespace, undefined, Namespace>): EmployeeListItem[] => {
    if (!contactAssociations) return [];
    return contactAssociations.map(c => { return {
        type: 'contact',
        typedObject: c,
        id: c.id,
        salutation: c.contact.salutation,
        firstName: c.contact.firstName,
        lastName: c.contact.lastName,
        contactId: c.contact.id,
        email: c.contact.email,
        roleName: endUserRoleToDisplayName(userRoleInfoToEndUserRole(c.roles || {container: false, payment: false, permission: false}), t),
        created: c.contact.created,
        containers: c.containerRefs
    }});
}
export const convertInvitationsToListItems = (invitations: Invitation[] | null, t: TFunction<Namespace, undefined, Namespace>): EmployeeListItem[] => {
    if (!invitations) return [];
    return invitations.map(i => { return {
        type: 'invitation',
        typedObject: i,
        id: i.id,
        salutation: i.salutation,
        firstName: i.firstName,
        lastName: i.lastName,
        email: i.email,
        roleName: endUserRoleToDisplayName(userRoleInfoToEndUserRole(i.roles), t),
        created: i.created,
        containers: i.containers
    }});
}