import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import Button from "../../../../components/Button";
import { IconContainerFilled, IconInfo } from "../../../../components/Icons";
import { useAccountQuery } from "../../../../data/accounts";
import { useContext } from "react";
import { UserContext } from "../../../../contexts/UserContext";
import { useEmptyingPricesQuery } from "../../../../data/prices";
import { UseQueryResult } from "@tanstack/react-query";
import { Account } from "../../../../types/Account";
import Modal from "../../../../components/Modal";
import { PaymentStep } from "../../../../components/payment/PaymentStep";
import { usePaymentManager } from "../../../../components/payment/usePaymentManager";
import { styles } from "../../../../styles";
import { Loader } from "../../../../components/Loader";
import { PaymentTransactionProcess } from "../../../../types/PaymentTransaction";
import { Toast } from "../../../../components/Toast";
import { NonExternalPaymentMethod } from "../../../../types/PaymentMethod";
import { usePaymentTransactionQuery } from "../../../../data/payments";
import { StateCode } from "../../../../types/StateCode";
import { PaymentAlmostDoneIllustration } from "../../../../components/payment/PaymentAlmostDoneIllustration";

const CreditIntro = styled.div`
    p {
        margin-bottom: 24px;
    };
`;

const CreditBox = styled.div`
    padding: 24px;
    border: 1px solid #E0E1E0;
    border-radius: 8px;

    @media (max-width: 700px) {
        max-width: 379.5px;
    };
`;

const CreditInfoTextContainer = styled.div`
    display: flex;
    font-size: 10px;
    line-height: 18px;
    color: ${styles.textColorSecondary700};
    svg {
        flex-shrink: 0;
        margin-right: 8px;
    }
`;

const TopRow = styled.div`
    div:first-of-type {
        font-family: "Satoshi Bold";
        font-weight: 700;
        display: flex;
        justify-content: space-between;
        margin-bottom: 9px;
    }

    div + div {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 25px;
        
        svg {
            margin-right: 8px;
        }
    }
`;

const Currency = styled.span`
    font-size: 14px;
    
    @media (max-width: 480px) {
        font-size: 12px;
        line-height: 20px;
    }
`;

const Amount = styled.span`
    font-size: 18px;
        
    @media (max-width: 480px) {
        font-size: 16px;
        line-height: 24px;
    }
`;

const NofEmptyings = styled.p`
    font-size: 14px;
    line-height: 22px;

    @media (max-width: 480px) {
        font-size: 12px;
        line-height: 20px;
    }
`;

const calculateAvailableCreditInNofEmptyings = (credit: number, emptyingPrice: number) => {
    return parseInt('' + (credit / emptyingPrice));
}
const CreditAmountInCHF = ({ accountQuery }: { accountQuery: UseQueryResult<Account | null, Error> }) => {
    if (accountQuery.isLoading) {
        return <Loader width={"initial"}/>
    }
    if (accountQuery.isError) {
        console.log("Error in CreditAmountNofEmptyings", accountQuery.error);
        return <>-</>;
    }
    const credit = accountQuery.data?.bookableCredit || 0;
    return (
        <>
            <Amount>{credit}.-</Amount>
        </>
    )
};

const CreditAmountNofEmptyings = ({ accountQuery }: { accountQuery: UseQueryResult<Account | null, Error> }) => {
    const priceConfigsQuery = useEmptyingPricesQuery();
    const { t } = useTranslation();
    if (accountQuery.isLoading) {
        return <Loader width={"99px !important"} height={"22px"} margin={"0 0 0 0 !important"}/>
    }
    if (accountQuery.isError) {
        console.log("Error in CreditAmountNofEmptyings", accountQuery.error);
        return <>-</>;
    }
    const credit = accountQuery.data?.bookableCredit || 0;
    const date = new Date();
    const priceConfig = (priceConfigsQuery.data || []).find(cfg => cfg.endDate.getTime() >= date.getTime() && cfg.startDate.getTime() <= date.getTime())
    const price = priceConfig ? priceConfig.price: undefined;
    return (
        <>
            {credit !== undefined && priceConfigsQuery.data && price &&
                <NofEmptyings>{calculateAvailableCreditInNofEmptyings(credit, price)} {t("pages.home.creditSection.creditInfoCard.emptyings")}</NofEmptyings>
            }
        </>
    )
};

export const CreditSection = () => {
    const [isTopUpModalOpened, setIsTopUpModalOpened] = useState(false);
    const [paymentTransactionToBeCompleted, setPaymentTransactionToBeCompleted] = useState<string>();
    const onTopUpModalClosed = () => setIsTopUpModalOpened(false);
    const paymentTransactionQuery = usePaymentTransactionQuery(paymentTransactionToBeCompleted);
    const { accessPermission } = useContext(UserContext);
    const { t } = useTranslation();
    const accountQuery = useAccountQuery(accessPermission?.accountRef.id);
    const [toastInfos, setToastInfos] = useState<{message: string, type: "success" | "error" | "info"}>();

    const onBeforeLeavingToExternalPayment = useCallback((paymentId: string) => {
        console.log("onBeforeLeavingToExternalPayment", paymentId);
    }, []);

    const onAfterExternalPayment = useCallback(async (paymentId: string, outcome: 'success' | 'failure'): Promise<void> => {
        console.log("onAfterExternalPayment", {paymentId, outcome})
        if (outcome === 'success') {
            setPaymentTransactionToBeCompleted(paymentId);
        }
        if (outcome === 'failure') {
            setToastInfos({message: t("toasts.topUpFailed"), type: 'info'});
        }
    }, []);

    useEffect(() => {
        let timeoutHandler: any | undefined = 0;
        if (paymentTransactionToBeCompleted) {
            if (paymentTransactionQuery.data !== 'unavailable' && paymentTransactionQuery.data?.completionDate) {
                setPaymentTransactionToBeCompleted(undefined);
                accountQuery.refetch();
                setToastInfos({ message: t("toasts.topUpSucceeded"), type: 'success' });
            } else {
                timeoutHandler = setTimeout(() => {
                    console.log("refetching")
                    paymentTransactionQuery.refetch()
                }, 1000);
            }
        }
        return () => {
            if (timeoutHandler) clearTimeout(timeoutHandler);
        }
    });

    const onAfterNonExternalPayment = (paymentTransactionId: string, paymentMethod: NonExternalPaymentMethod) => {
        console.log("Non-External Payment", {paymentTransactionId, paymentMethod});
    }

    const externalPaymentMgr = usePaymentManager(onBeforeLeavingToExternalPayment, onAfterExternalPayment, onAfterNonExternalPayment);

    if (!externalPaymentMgr.initialized) return <></>;
    
    return (
        <>            
            <CreditIntro>
                <h2>{t("pages.home.creditSection.header")}</h2>
            </CreditIntro>
            <CreditBox>
                <TopRow>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <Currency>
                            {t("pages.home.creditSection.creditInfoCard.currency")}
                        </Currency>
                        <CreditAmountInCHF accountQuery={accountQuery} />
                    </div>
                    <div>
                        <IconContainerFilled />
                        <CreditAmountNofEmptyings accountQuery={accountQuery} />
                    </div>
                </TopRow>
                <Button disabled={!accessPermission?.roles?.payment || accountQuery.data?.creditProcessing} onClick={() => setIsTopUpModalOpened(!isTopUpModalOpened)}>{t('pages.home.creditSection.creditInfoCard.buttonText')}</Button>
                <CreditInfoTextContainer>
                    <IconInfo />
                    <div>{t('pages.home.creditSection.creditInfoCard.infoText')}</div>
                </CreditInfoTextContainer>
            </CreditBox>
            {isTopUpModalOpened && !accountQuery.data?.creditProcessing &&
                <Modal onClose={onTopUpModalClosed}>
                    <PaymentStep 
                            enableCreditUsage={false}
                            enableInvoice={true}
                            process={PaymentTransactionProcess.TopUp}
                            orderItemName={t('pages.home.creditSection.topUpOrderLineText')}
                            paymentMgr={externalPaymentMgr}
                            onCancel={onTopUpModalClosed} 
                            texts= {{
                                title: t("pages.home.creditSection.topUpModal.header"),
                                priceFieldLabel: t("pages.home.creditSection.topUpModal.topUpAmount"),
                                paymentMethodFieldLabel: t("pages.home.creditSection.topUpModal.meansOfPayment")
                            }}
                        />
                </Modal>
            }
            {paymentTransactionToBeCompleted &&
                <Modal onClose={() => { }}>
                    <div style={{margin: '20px 20px 0 0'}}>
                        <PaymentAlmostDoneIllustration />
                    </div>
                </Modal>
            }
            { toastInfos && <Toast message={toastInfos.message} type={toastInfos.type} onCloseComplete={() => setToastInfos(undefined)} />}
        </>
    );
};