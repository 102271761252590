import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { styles } from '../../../../styles';
import { AccordionSection } from "./components/AccordionSection";
import { Todo } from "../../../../components/Todo";
import { useFaqsQuery } from "../../../../data/faq";
import { Loader } from "../../../../components/Loader";

const PageWrapper = styled.div`
    color: ${styles.textColorSecondary700};
`;

const StyledH2 = styled.h2`
    margin-bottom: 24px;
`;

export const Faq = () => {
    const { t } = useTranslation();
    const faqQuery = useFaqsQuery();

    if (faqQuery.isLoading) return (
        // RESOLVED: insert loading spinner
        <Loader height={"200px"} />
    ) 
    if (faqQuery.isError) {
        return <>ERROR: {faqQuery.error.toString()}</>
    }
    if (!faqQuery.data) return null;
    return (
        <PageWrapper>
            <StyledH2>{t("pages.help.faq")}</StyledH2>
            <AccordionSection data={faqQuery.data} />
        </PageWrapper>
    )
}