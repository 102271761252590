import { useContext, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { UserContext } from "../../contexts/UserContext";
import { useInvitationRedemptionMutation } from "../../data/invitations";
import { ROUTES } from "../../skeleton/Routes";
import { useEffectOnce } from "usehooks-ts";
import { MessageWrapper } from "../../components/MessageWrapper";
import { Loader } from "../../components/Loader";

export const INVITATION_SESSION_STORAGE_KEY = 'kva-invitation-code';

export const InvitationRedemptionPage = () => {
    const navigate = useNavigate();
    const userContext = useContext(UserContext);
    const { code } = useParams();
    const redeemMutation = useInvitationRedemptionMutation();
    const { t } = useTranslation();

    const redeem = useCallback(async () => {
        sessionStorage.removeItem(INVITATION_SESSION_STORAGE_KEY);
        if (userContext.loggedInUser && code) {
            redeemMutation.mutateAsync(code);
        }
    }, [redeemMutation, navigate, userContext.loggedInUser, code]);

    useEffectOnce(() => {
        const timeout = setTimeout(() => {
            redeem();
        }, 100);
        return () => { clearTimeout(timeout) }
    });

    useEffect(() => {
        const timeoutHandler = setTimeout(() => {
            navigate(ROUTES.Home);
        }, 3000);
        if (!redeemMutation.isSuccess) {
            clearTimeout(timeoutHandler);
        }
        return () => { clearTimeout(timeoutHandler)}
    }, [redeemMutation.isSuccess, navigate])

    if (!userContext.loggedInUser) {
        if (code) {
            // store code in session storage - it can occur, that during B2C login, the invitation code gets lost. for those situations, we 
            // store the code and check after retur
            // Nach dem Login in Azure B2C wird eigentlich auf die gleiche Seite zurück weiter geleitet, von der der Login gestartetet wurde.
            // D.h. eigentlich dieser URL .
            // In bestimmten Fällen kann es jedoch vorkommen, dass dass Azure B2C den Pfad "vergisst" und dann nur auf die Startseite des KVA Portals
            // leitet - in diesen Fällen kann dann die Einladung nicht angenommen werden. 
            // Um diesen Eventualfall abzudecken, wird beim Laden des Portals geprüft, ob ein Code im sessionStorage vorhanden ist - wen ja, wird auf 
            // diese Invitation Redemption Seite weiter geleitet.
            // zu dieser Situation kommt es offenbar immer dann, wenn auf Seite B2C ein Fehler angezeigt wird und der Benutzer sich dann anschliessend 
            // doch noch einloggt - B2C scheint nach einem Fehler eine neue Login-Session zu starten. Ein Beispiel ist: Benutzer ist deaktiviert, wird 
            // aber eingeladen. Beim Login kommt dann die Meldung, der Benutzer sei deaktiviert. Support aktiviert den Benutzer, der sich dann korrekt
            // einloggen kann. Der Redirect zurück zum KVA Portal enthält dann aber leider den Einladungscode nicht mehr. 
            sessionStorage.setItem(INVITATION_SESSION_STORAGE_KEY, code);
        }
        userContext.login({fromInvitationLink: true});
        return <></>
    }

    if (redeemMutation.isError) {
        return <MessageWrapper messageText={
            redeemMutation.error.name 
            ? <><div style={{fontWeight: 'bold'}}>{redeemMutation.error.name}</div><div style={{marginTop: '10px'}} dangerouslySetInnerHTML={{__html: redeemMutation.error.message}}/></>
            : redeemMutation.error.message} height={"100%"} />
    }
    if (redeemMutation.isLoading) {
        return <Loader height={"100%"} messageText={t("pages.invitationRedemption.isLoading")} />
    }
    if (redeemMutation.isSuccess) {
        return <>
                <MessageWrapper 
                    messageText={t("pages.invitationRedemption.isSuccess")} 
                    messageType={"successMessage"} 
                    height={"100%"} 
                />
            </>
    }
    return <></>
}