export const ROUTES = {
    Home: '/',
    InvitationRedemption: '/invitation/:code',
    SingleEmptying: '/emptyings/schedule/single',
    OneClickEmptying: '/emptyings/schedule/one-click',
    MultiEmptying: '/emptyings/schedule/multi',
    EmptyingEditing: '/emptyings/:id',
    CreditOverview: '/transactions/credit-overview',
    Payments: '/transactions/payments',
    EmptyingConfirmations: '/transactions/emptying-confirmations',
    TopUp: '/top-up',
    ContainerRegistration: '/containers/register',
    ContainerOverview: '/containers',
    Organisation: '/organisation',
    Notifications: '/notifications',
    UserProfile: '/profile/user',
    AccountProfile: '/profile/account',
    Logout: '/.auth/logout',
    Manuals: '/infos/manuals',
    Faq: '/infos/faq',
    Contact: '/infos/contact',
    ImprintInfos: '/infos/imprint',
    TermsAndConditions: '/info/terms-conditions',
    Unauthenticated: '/errorpage/401'
}