import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { IconClose } from "../../../components/Icons";
import Button from '../../../components/Button';
import { styles } from "../../../styles";
import { TranslationWithBoldedText } from "../../home/components/TranslationWithBoldedText";
import { EmptyingsSchedule } from "../EmptyingSchedulingPage";

type CancelModalProps = {
    onClose: (cancelledSchedule?: EmptyingsSchedule) => void;
    plannedSchedule: EmptyingsSchedule;
}

const ModalContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding-right: 24px;
    padding-top: 24px;

    h2 {
        margin-bottom: 36px;
    }

    p {
        color: ${styles.textColorSecondary700};
        max-width: 300px;
    }
`;

const ModalHeading = styled.h2`
    margin-bottom: 48px;
    color: ${styles.textColorSecondary700};
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg {
        cursor: pointer;
    }
`;

const BottomRow = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    margin-top: 48px;

    button {
        width: 156px;
        margin-bottom: 0;
    }

    @media (max-width: 480px) {
        flex-direction: column;
        margin-top: 48px;
        margin-bottom: 0;
        gap: 12px;

        button {
            width: 100%;
            margin-top: 0;
            margin-bottom: 0;
        }
    }
`;


export const CancelSerialEmptyingModalContent: React.FC<CancelModalProps> = ({ plannedSchedule, onClose }) => {
    const { t } = useTranslation();

    return (
        <ModalContentWrapper>
            <div>
                <ModalHeading>{t("pages.emptying.series.modalCancelSerialEmptying.header")} <div onClick={() => onClose()}><IconClose /></div></ModalHeading>
                <p>
                    <TranslationWithBoldedText textValue={plannedSchedule.container.displayName} i18nKey="pages.emptying.series.modalCancelSerialEmptying.confirmDeleteText" />
                </p>        
            </div>

            <BottomRow>
                <Button buttonVariant="ghost" onClick={() => onClose()}>
                    {t("buttons.abort")}
                </Button>
                <Button onClick={() => onClose(plannedSchedule)}>
                    {t("buttons.delete")}
                </Button>
            </BottomRow>
        </ModalContentWrapper>
    );
};
