import Modal from "../../components/Modal";
import styled from "@emotion/styled";
import { styles } from "../../styles";
import { IconClose } from "../../components/Icons";
import type {
    unstable_Blocker as Blocker
} from "react-router-dom";
import Button from '../../components/Button';
import { useTranslation } from "react-i18next";

const Wrapper = styled.div`
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    max-height: 80vh;

    @media (max-width: 480px) {
        max-height: 100vh;
        height: 100%;
    }
`;


const Heading = styled.h2`
    color: ${styles.textColorSecondary700};
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #fff;
    padding-top: 24px;
    padding-bottom: 24px;
    padding-right: 24px;
    border-radius: 8px;
    margin-bottom: 0;

    svg {
        cursor: pointer;
    }
`;


const ContentWrapper = styled.div`
    overflow-y: auto;
    position: relative;
    max-height: 80vh;
    padding-right: 16px;

    @media (max-width: 480px) {
        max-height: 100vh;
        height: 100%;
    }
`;

const BottomRow = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    margin-top: 48px;

    button {
        width: 156px;
    }

    @media (max-width: 480px) {
        margin-bottom: 0;
        flex-direction: column;
        align-items: flex-end;
        gap: 12px;
        width: 100%;

        button {
            width: 100%;
            margin-bottom: 0;
        }
    }
`;


export const LeavingConfirmation = ({ blocker }: { blocker: Blocker }) => {
    const { t } = useTranslation();
    const onStay = () => blocker.reset?.();
    if (blocker.state === "blocked") {
        return (
            <Modal onClose={onStay}>
                <Wrapper>
                    <Heading>{t('pages.emptying.dirtyFormMessage.title')}<div onClick={onStay}><IconClose /></div></Heading>
                    <ContentWrapper>
                        <p >
                            {t('pages.emptying.dirtyFormMessage.text')}
                        </p>
                        <BottomRow>
                            <Button buttonVariant="ghost" onClick={onStay}>
                                {t('pages.emptying.dirtyFormMessage.labels.stay')}
                            </Button>
                            <Button onClick={() => blocker.proceed?.()}>
                                {t('pages.emptying.dirtyFormMessage.labels.proceed')}
                            </Button>
                        </BottomRow>
                    </ContentWrapper>
                </Wrapper>
            </Modal>
        );
    }
    return null;
}
