import styled from "@emotion/styled";
import ContainerCarousel from "./ContainerCarousel";
import { useContainersQuery } from "../../data/containers";
import { UserContext } from "../../contexts/UserContext";
import { useContext } from "react";
import { Container, ContainerStatusReason } from "../../types/Container";
import { IconExclamationMark } from "../Icons";

type ContainerCardsProps = {
    cardWidth: number;
    selectionMode: 'none' | 'single' | 'multi';
    defaultSelection?: string[];
    onChange?: (selectedContainers: Container[]) => void;
    header?: string;
};

const StyledContainerCards = styled.div`
    display: flex;
`;

const ErrorWrapper = styled.div`
    display: flex;
    align-items: center;
    font-size: 10px;
    line-height: 18px;

    svg {
        margin-right: 8px;
    }
`;
export const CardStyledContainersList = ({ cardWidth, selectionMode, defaultSelection, onChange, header }: ContainerCardsProps) => {
    const userContext = useContext(UserContext);
    const containersQuery = useContainersQuery(userContext.accessPermission?.accountRef);


    if (containersQuery.isError) {
        return <ErrorWrapper><IconExclamationMark />Error: {containersQuery.error.message}</ErrorWrapper>;
    }

    const containers: Container[] = containersQuery.data || [];
    const activeContainers = containers.filter(c => c.statusReason === ContainerStatusReason.Active);
    const selectedByDefault = activeContainers.length === 1
        ? [activeContainers[0]]
        : defaultSelection
            ? activeContainers.filter(c => defaultSelection.indexOf(c.id) >= 0)
            : [];

    return (
        <StyledContainerCards>
            {/* RESOLVED: Handle error and loading state. Loading state handled in ContainerCarousel component */}
            <ContainerCarousel isLoading={containersQuery.isLoading} containers={containers} cardWidth={cardWidth} selectionMode={selectionMode} selectedByDefault={selectedByDefault} onChange={onChange} header={header} />
        </StyledContainerCards>
    );
};