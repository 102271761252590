import { useQuery } from "@tanstack/react-query";
import { ONE_DAY, convertToErrorMsg, jsonReviverFunction } from "./_base";
import { RemovalArea } from "../types/RemovalArea";

export const useRemovalDatesQuery = (from: Date, to: Date, removalArea?: RemovalArea) => {
    const query = useQuery<Date[], Error>({
        queryKey: ['removalDates', removalArea?.id || '-', from.toDateString(), to.toDateString()],
        refetchOnWindowFocus: true,
        staleTime: ONE_DAY,
        queryFn: () => getRemovalDates(from, to, removalArea)
    })
    return query;
}

// TODO: Ensure that only dates are retourned which are not booked yet!
const getRemovalDates = async (from: Date, to: Date, removalArea?: RemovalArea): Promise<Date[]> => {
    if (!removalArea) return [];
    const dateFormat = new Intl.DateTimeFormat('en');
    const fromParam = dateFormat.format(from);
    const toParam = dateFormat.format(to);
    const response = await fetch(`/api/removalAreas/${removalArea.id}/emptying-dates?from=${fromParam}&to=${toParam}`);
    const responseBody = await response.text();
    if (!response.ok) {
        const errorMsg = convertToErrorMsg(response.status, responseBody);
        throw errorMsg;
    } else {
        const responseJson = JSON.parse(responseBody, jsonReviverFunction);
        const removalDates = responseJson as Date[];
        return removalDates;
    }
}
