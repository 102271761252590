import styled from "@emotion/styled";

const Container = styled.div`
    h2 {
        margin-top: 60px;
    }
    p {
        margin-top: 30px;
    }
	a:hover {
        text-decoration: underline;
    }
}`
 

export const TermsAndConditionsPage = () => {
    
    return <Container>
            <h1>Allgemeine Geschäftsbedingungen</h1>
            <p>Der Verband KVA Thurgau bietet die Leerung von Gewerbecontainer für Gewerbekunden im Einzugsgebiet an. Für die Leerung entfallen Gebühren. Mit der Bestellung einer Containerleerung via dem Portal e-container.kvatg.ch erkennt der Besteller die Geltung dieser AGBs an und bestätigt, die AGBs gelesen und verstanden zu haben. Die AGBs sind integrierter Bestandteil des Vertrages zwischen dem Besteller und dem Verband KVA Thurgau.</p>
            <p>
                <b>§ 1 Anbieter</b><br/>
                Als Anbieter dieser AGB gilt die öffentlich-rechtliche Körperschaft:<br/>
                Verband KVA Thurgau<br/>
                Rüteliholzstrasse 5<br/>
                8570 Weinfelden<br/>
            </p>
            <p>
                <b>§ 2 Geltungsbereich</b><br/>
                Diese Allgemeinen Geschäftsbedingungen gelten für Bestellungen von Containerleerungen, welche der Besteller über das Portal e-container.kvatg.ch abschliesst.<br/>
            </p>
            <p>
                <b>§ 3 Vertragsabschluss</b><br/>
                Als Besteller kommen Gewerbekunden im Verbandsgebiet der KVA Thurgau in Frage. Nach der Registrierung im Portal, prüft der Verband KVA Thurgau, ob die Voraussetzungen erfüllt sind. Nach erfolgreicher Prüfung wird der Besteller als Benutzer des Portals zugelassen. Mit Bestellung der Containerleerung kommt der Vertrag zwischen dem Besteller und dem Verband KVA Thurgau zustande. <br/>
            </p>
            <p>
                <b>§ 4 Zahlung</b><br/>
                Die gesetzliche Mehrwertsteuer ist im angegebenen Preis inbegriffen. Bestellern steht die Zahlung via Rechnung, TWINT, PostFinance oder Kreditkarte zur Verfügung. Leerungen müssen im Voraus, spätestens zum Bestellzeitpunkt, bezahlt werden.<br/>
            </p>
            <p>
                <b>§ 5 Leerung</b><br/>
                Die Leerung des Containers erfolgt an dem bestellten Leerungstag. Die Bestellung kann bis 22 Uhr am Vortag kostenlos annulliert werden.<br/>
            </p>
            <p>
                <b>§ 6 Gewährleistung</b><br/>
                Die gesetzlichen Gewährleistungsregelungen gelten.<br/>
            </p>
            <p>
                <b>§ 7 Anwendbares Recht und Gerichtsstand</b><br/>
                Die AGBs und der Vertrag zwischen dem Besteller und dem Verband KVA Thurgau unterstehen dem schweizerischen Recht. <br/>
                Gerichtsstand ist am Sitz des Verbands KVA Thurgau<br/>
            </p>
        </Container>
}