import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { styles } from "../../../styles";
import { IconClose, IconQuestionCircleLineSmall, IconExclamationMark } from "../../../components/Icons";
import Button from '../../../components/Button';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { ColorSelect } from "./ColorSelect";
import { Controller, useForm } from "react-hook-form";
import { useRegisterContainertMutation } from "../../../data/containers";
import { Container } from "../../../types/Container";
import { Account } from "../../../types/Account";
import { ImageUpload } from "./ImageUpload";
import { useEffect, useState } from "react";
import { Loader } from "../../../components/Loader";


type AddContainerFormModalProps = {
    account: Account;
    onClose: (success: boolean) => void;
}


const Wrapper = styled.div`
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    max-height: 80vh;

    @media (max-width: 480px) {
        max-height: 100vh;
        height: 100%;
    }
`;

const ContentWrapper = styled.div`
    overflow-y: auto;
    position: relative;
    max-height: 80vh;
    padding-right: 16px;

    @media (max-width: 480px) {
        max-height: 100vh;
        height: 100%;
    }
`;

const Legend = styled.legend`
    line-height: 24px;
    margin-bottom: 8px;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
    height: 32px;
    input {
        height: 28px;
        width: 28px;
        box-sizing: border-box;
    }

    span {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        font-family: 'Satoshi Regular';
        font-size: 14px;
    }
`;


const ModalHeading = styled.h2`
    color: ${styles.textColorSecondary700};
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #fff;
    padding-top: 24px;
    padding-bottom: 24px;
    padding-right: 24px;
    border-radius: 8px;
    margin-bottom: 0;

    svg {
        cursor: pointer;
    }
`;

const FormRow = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
    color: ${styles.textColorSecondary700};
`;

const BottomRow = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    margin-top: 48px;
    margin-bottom: 68px;

    button {
        width: 156px;
    }

    @media (max-width: 480px) {
        margin-bottom: 0;
        flex-direction: column;
        align-items: flex-end;
        gap: 12px;
        width: 100%;

        button {
            width: 100%;
            margin-bottom: 0;
        }
    }
`;

const StyledH3 = styled.h4`
    font-size: 10px;
    line-height: 18px;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    gap: 8px;

    svg {
        cursor: pointer;
    }
`;

const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-right: 12px;

    &:last-child {
        margin-right: 0;
    }
`;

const InputContainerFull = styled(InputContainer)`
    flex-grow: 1;
`;

const TwoColumnsFormRow = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
`;

type StyledInputProps = {
    hasError?: boolean;
};

const StyledInput = styled.input<StyledInputProps>`
    padding: 10px 16px;
    flex: 1;
    box-shadow: ${styles.boxShadowInset};
    background-color: #fff;
    border: 1px solid ${props => props.hasError ? '#ed2b2b' : styles.neutral200};
    border-radius: 4px;
    box-sizing: border-box;
    line-height: 22px;
    font-size: 14px;
    color: ${styles.textColorSecondary700};
    font-family: 'Satoshi Regular';
    max-height: 44px;

    &:focus {
        border: 1px solid #3371EA;
        outline: none;
    }

    &:disabled {
        background-color: #f5f5f5;
    }
`;

const StyledTextArea = styled.textarea`
    padding: 10px 16px;
    flex: 1;
    box-shadow: ${styles.boxShadowInset};
    background-color: #fff;
    border: 1px solid ${styles.neutral200};
    border-radius: 4px;
    box-sizing: border-box;
    line-height: 22px;
    font-size: 14px;
    color: ${styles.textColorSecondary700};
    font-family: 'Satoshi Regular';
    min-height: 88px;
    resize: none;

    &:focus {
        border: 1px solid #3371EA;
        outline: none;
    }

    &:disabled {
        background-color: #f5f5f5;
    }
`;

const GreenRadio = styled(Radio)`
  &.MuiRadio-root {
    color: #E0E1E0;

    &.Mui-checked {
      color: #33AA47;
    }
  }
`;

const IconWrapper = styled.div`
    display: flex;
`;

const ErrorWrapper = styled.div`
    margin-top: 12px;
    display: flex;
    align-items: center;
    font-size: 10px;
    line-height: 18px;

    svg {
        margin-right: 8px;
    }
`;

export const AddContainerFormModal: React.FC<AddContainerFormModalProps> = ({ account, onClose }) => {
    const { t } = useTranslation();
    const [useAddressFromAccount, setUseAddressFromAccount] = useState(true);

    const registerContainerMutation = useRegisterContainertMutation();
    const { register, handleSubmit, watch, control, setValue, formState: { errors } } = useForm<Container>({
        defaultValues: {
            street: account.street,
            houseNumber: account.houseNumber,
            city: account.city,
            postalCode: account.postalCode
        }
    });

    const onSave = async (newContainer: Container) => {
        try {
            await registerContainerMutation.mutateAsync(newContainer);
            onClose(true);
        } catch {
            onClose(false);
        }
    }

    useEffect(() => {
        console.log("Update Address Fields")
        setValue('street', useAddressFromAccount ? account.street : '');
        setValue('houseNumber', useAddressFromAccount ? account.houseNumber : '');
        setValue('city', useAddressFromAccount ? account.city : '');
        setValue('postalCode', useAddressFromAccount ? account.postalCode : '');
    }, [account, setValue, useAddressFromAccount]);

    const enableSubmit = watch('displayName') !== '' && watch('shortName') !== '' && watch('color') !== '';

    return (
        <>
            <Wrapper>
                <form>
                    <ModalHeading>{t("pages.myContainers.containerAddOrEditForm.registerContainer")} <div onClick={() => onClose(false)}><IconClose /></div></ModalHeading>
                    <ContentWrapper>
                        <FormRow style={{ marginTop: '24px' }}>
                            <FormControl component="fieldset">
                                <Legend>{t("pages.myContainers.containerAddOrEditForm.sameLocationText")}</Legend>
                                <RadioGroup style={{ marginBottom: 10 }}>
                                    <StyledFormControlLabel checked={useAddressFromAccount} onChange={() => setUseAddressFromAccount(true)} control={<GreenRadio />} label={t("pages.myContainers.containerAddOrEditForm.yes")} />
                                    <StyledFormControlLabel checked={!useAddressFromAccount} onChange={() => setUseAddressFromAccount(false)} control={<GreenRadio />} label={t("pages.myContainers.containerAddOrEditForm.no")} />
                                </RadioGroup>
                            </FormControl>
                        </FormRow>
                        <TwoColumnsFormRow>
                            <InputContainerFull>
                                <StyledH3>{t("pages.myContainers.containerAddOrEditForm.containerName")}</StyledH3>
                                <StyledInput hasError={Boolean(errors.displayName)} type="text" placeholder={t("pages.myContainers.containerAddOrEditForm.containerNamePlaceholder")}
                                    {...register("displayName", { required: t('errors.requiredField') })} />
                                {errors.displayName && <ErrorWrapper><IconExclamationMark />{errors.displayName.message}</ErrorWrapper>}
                            </InputContainerFull>
                            <InputContainer style={{ width: '156px' }}>
                                <StyledH3>
                                    {t("pages.myContainers.containerAddOrEditForm.containerId")}
                                    <Tooltip arrow componentsProps={{
                                        tooltip: {
                                            sx: {
                                                bgcolor: '#26374A',
                                                '& .MuiTooltip-arrow': {
                                                    color: '#26374A',
                                                },
                                            },
                                        },
                                    }} title={t("pages.myContainers.containerAddOrEditForm.containerIdTooltip")}>
                                        <IconWrapper>
                                            <IconQuestionCircleLineSmall />
                                        </IconWrapper>
                                    </Tooltip>
                                </StyledH3>
                                <StyledInput
                                    type="text"
                                    readOnly
                                    disabled
                                />
                                {errors.displayName && <div style={{ height: '24px' }}></div>}
                            </InputContainer>
                        </TwoColumnsFormRow>
                        <TwoColumnsFormRow style={{ justifyContent: 'flex-start' }}>
                            <InputContainer style={{ width: '156px' }}>
                                <StyledH3>
                                    {t("pages.myContainers.containerAddOrEditForm.containerCode")}
                                    <Tooltip arrow componentsProps={{
                                        tooltip: {
                                            sx: {
                                                bgcolor: '#26374A',
                                                '& .MuiTooltip-arrow': {
                                                    color: '#26374A',
                                                },
                                            },
                                        },
                                    }} title={t("pages.myContainers.containerAddOrEditForm.containerCodeTooltip")}>
                                        <IconWrapper>
                                            <IconQuestionCircleLineSmall />
                                        </IconWrapper>
                                    </Tooltip>
                                </StyledH3>
                                <StyledInput maxLength={2} hasError={Boolean(errors.shortName)} type="text" placeholder={t("pages.myContainers.containerAddOrEditForm.containerCodePlaceholder")}
                                    {...register("shortName", { required: t('errors.requiredField') })} />
                                {errors.shortName && <ErrorWrapper><IconExclamationMark />{errors.shortName.message}</ErrorWrapper>}
                                {!errors.shortName && errors.color && <div style={{ height: '24px' }}></div>}
                            </InputContainer>
                            <InputContainer style={{ width: '156px' }}>
                                <StyledH3>
                                    {t("pages.myContainers.containerAddOrEditForm.color")}
                                    <Tooltip arrow componentsProps={{
                                        tooltip: {
                                            sx: {
                                                bgcolor: '#26374A',
                                                '& .MuiTooltip-arrow': {
                                                    color: '#26374A',
                                                },
                                            },
                                        },
                                    }} title={t("pages.myContainers.containerAddOrEditForm.colorTooltip")}>
                                        <IconWrapper>
                                            <IconQuestionCircleLineSmall />
                                        </IconWrapper>
                                    </Tooltip>
                                </StyledH3>
                                <Controller
                                    control={control}
                                    name="color"
                                    rules={{ required: { value: true, message: 'Required Value' } }}
                                    render={({ field: { onChange } }) => (
                                        <ColorSelect value="" onChange={onChange} />
                                    )} />
                                {errors.color && <ErrorWrapper><IconExclamationMark />{errors.color.message}</ErrorWrapper>}
                                {!errors.color && errors.shortName && <div style={{ height: '24px' }}></div>}
                                {/* RESOLVED: as long as there is no color selected, the dropdown field is too little high */}
                            </InputContainer>
                        </TwoColumnsFormRow>
                        <TwoColumnsFormRow>
                            <InputContainerFull>
                                <StyledH3>{t("pages.myContainers.containerAddOrEditForm.street")}</StyledH3>
                                <StyledInput hasError={Boolean(errors.street)} type="text" disabled={useAddressFromAccount} placeholder={t("pages.myContainers.containerAddOrEditForm.street")}
                                    {...register("street", { required: t('errors.requiredField') })} />
                                {errors.street && <ErrorWrapper><IconExclamationMark />{errors.street.message}</ErrorWrapper>}
                            </InputContainerFull>
                            <InputContainer style={{ width: '156px' }}>
                                <StyledH3>{t("pages.myContainers.containerAddOrEditForm.number")}</StyledH3>
                                <StyledInput hasError={Boolean(errors.houseNumber)} type="text" disabled={useAddressFromAccount} placeholder={t("pages.myContainers.containerAddOrEditForm.number")}
                                    {...register("houseNumber", { required: t('errors.requiredField') })} />
                                {errors.houseNumber && <ErrorWrapper><IconExclamationMark />{errors.houseNumber.message}</ErrorWrapper>}
                            </InputContainer>
                        </TwoColumnsFormRow>
                        <TwoColumnsFormRow>
                            <InputContainerFull>
                                <StyledH3>{t("pages.myContainers.containerAddOrEditForm.location")}</StyledH3>
                                <StyledInput hasError={Boolean(errors.city)} type="text" disabled={useAddressFromAccount} placeholder={t("pages.myContainers.containerAddOrEditForm.location")}
                                    {...register("city", { required: t('errors.requiredField') })} />
                                {errors.city && <ErrorWrapper><IconExclamationMark />{errors.city.message}</ErrorWrapper>}
                            </InputContainerFull>
                            <InputContainer style={{ width: '156px' }}>
                                <StyledH3>{t("pages.myContainers.containerAddOrEditForm.postcode")}</StyledH3>
                                <StyledInput maxLength={4} hasError={Boolean(errors.postalCode)} type="number" disabled={useAddressFromAccount} placeholder={t("pages.myContainers.containerAddOrEditForm.postcode")}
                                    {...register("postalCode", { required: t('errors.requiredField') })} />
                                {errors.postalCode && <ErrorWrapper><IconExclamationMark />{errors.postalCode.message}</ErrorWrapper>}
                            </InputContainer>
                        </TwoColumnsFormRow>
                        <FormRow>
                            <StyledH3>{t("pages.myContainers.containerAddOrEditForm.photo")}</StyledH3>
                            <Controller
                                control={control}
                                name="pictureBase64Encoded"
                                render={({ field: { onChange } }) => (
                                    <ImageUpload defaultValue="" onChange={onChange} />
                                )} />
                        </FormRow>
                        <FormRow>
                            <StyledH3>{t("pages.myContainers.containerAddOrEditForm.comments")}</StyledH3>
                            <StyledTextArea placeholder={t("pages.myContainers.containerAddOrEditForm.comments")}
                                {...register("remarks", {})} />
                            {errors.remarks && <ErrorWrapper><IconExclamationMark />{errors.remarks.message}</ErrorWrapper>}
                        </FormRow>
                        <BottomRow>
                            {!registerContainerMutation.isLoading &&
                                <Button buttonVariant="ghost" onClick={() => onClose(false)}>
                                    {t("buttons.abort")}
                                </Button>
                            }
                            <Button disabled={!enableSubmit || registerContainerMutation.isLoading} onClick={handleSubmit(onSave)}>
                                {registerContainerMutation.isLoading && <Loader />}
                                {!registerContainerMutation.isLoading && t("buttons.register")}
                            </Button>
                        </BottomRow>
                    </ContentWrapper>
                </form>
            </Wrapper>
        </>
    );
};