import React from "react";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { styles } from "../styles";

type ButtonProps = {
	resolvedLanguage: string;
	languageKey: string;
};

const Button = styled.button<ButtonProps>`
	border: none;
	background: transparent;
	color: ${styles.textColorSecondary900};
	font-size: 14px;
	cursor: pointer;
	padding: 0;
	font-family: ${props => props.resolvedLanguage === props.languageKey ? 'Satoshi Bold' : 'Satoshi Regular'};;

	@media (max-width: 480px) {
		font-size: 12px;
		line-height: 22px;
	}
`;

const VerticalLine = styled.div`
	height: 1rem;
	width: 1px;
	background-color:  ${styles.neutral300};
	margin: 0 8px;
`;

export const LanguageSwitcher = () => {
	const { t, i18n } = useTranslation();
	const languages = [
		{ key: 'de', label: t('navigation.top.languageChooserLabels.de') },
		{ key: 'en', label: t('navigation.top.languageChooserLabels.en') }
	];

	return (
		<div style={{ display: 'flex', justifyContent:'center', alignItems: 'center', height: '22px' }}>
			{languages.map((language, index) => (
				<React.Fragment key={language.key}>
					<Button
						resolvedLanguage={i18n.language}
						languageKey={language.key}
						onClick={() => i18n.changeLanguage(language.key)}
					>
						{language.label}
					</Button>
					{index < languages.length - 1 && <VerticalLine />}
				</React.Fragment>
			))}
		</div>
	)
}