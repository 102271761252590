import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { styles } from "../../../styles";
import { IconClose, IconQuestionCircleLineSmall, IconInfo, IconExclamationMark } from "../../../components/Icons";
import Button from '../../../components/Button';
import Tooltip from '@mui/material/Tooltip';
import { ColorSelect } from "./ColorSelect";
import { Container } from "../../../types/Container";
import { Controller, useForm } from "react-hook-form";
import { useUpdateContainertMutation } from "../../../data/containers";
import { Loader } from "../../../components/Loader";


type EditContainerFormModalProps = {
    container: Container;
    onClose: () => void;
}

const Wrapper = styled.div`
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    max-height: 80vh;

    @media (max-width: 480px) {
        max-height: 100vh;
        height: 100%;
    }
`;

const ContentWrapper = styled.div`
    overflow-y: auto;
    position: relative;
    max-height: 80vh;
    padding-right: 24px;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: 480px) {
        max-height: 100vh;
        height: 100%;
    }
`;

const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
`;

const ModalHeading = styled.h2`
    color: ${styles.textColorSecondary700};
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #fff;
    padding-top: 24px;
    padding-bottom: 24px;
    padding-right: 24px;
    border-radius: 8px;
    margin-bottom: 0;

    svg {
        cursor: pointer;
    }
`;

const BottomRow = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    margin-top: 48px;
    // margin-bottom: 68px;

    button {
        width: 156px;
    }

    @media (max-width: 480px) {
        margin-bottom: 0;
        flex-direction: column;
        align-items: flex-end;
        gap: 12px;
        width: 100%;

        button {
            width: 100%;
            margin-bottom: 0;
        }
    }
`;

const StyledH3 = styled.h4`
    font-size: 10px;
    line-height: 18px;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    gap: 8px;

    svg {
        cursor: pointer;
    }
`;

const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-right: 12px;

    &:last-child {
        margin-right: 0;
    }
`;

const InputContainerFull = styled(InputContainer)`
    flex-grow: 1;
`;

const TwoColumnsFormRow = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
`;

type StyledInputProps = {
    hasError?: boolean;
};

const StyledInput = styled.input<StyledInputProps>`
    padding: 10px 16px;
    flex: 1;
    box-shadow: ${styles.boxShadowInset};
    background-color: #fff;
    border: 1px solid ${props => props.hasError ? '#ed2b2b' : styles.neutral200};
    border-radius: 4px;
    box-sizing: border-box;
    line-height: 22px;
    font-size: 14px;
    color: ${styles.textColorSecondary700};
    font-family: 'Satoshi Regular';
    max-height: 44px;

    &:focus {
        border: 1px solid #3371EA;
        outline: none;
    }

    &:disabled {
        background-color: #f5f5f5;
    }
`;

const WarningText = styled.div`
    display: flex;
    align-items: flex-start;
    align-items: center;
    gap: 8px;
    margin-bottom: 12px;

    p {
        text-align: left;
        font-size: 10px;
        line-height: 18px;
    }
`;

const IconWrapper = styled.div`
    display: flex;
`;

const ErrorWrapper = styled.div`
    margin-top: 12px;
    display: flex;
    align-items: center;
    font-size: 10px;
    line-height: 18px;

    svg {
        margin-right: 8px;
    }
`;

export const EditContainerFormModal: React.FC<EditContainerFormModalProps> = ({ container, onClose }) => {
    const { t } = useTranslation();
    const updateContainerMutation = useUpdateContainertMutation();
    const { register, handleSubmit, watch, control, formState: { errors } } = useForm<Container>({
        defaultValues: container
    });
    const onSave = async (updatedContainer: Container) => {
        await updateContainerMutation.mutateAsync({
            id: container.id, 
            displayName: updatedContainer.displayName, 
            shortName: updatedContainer.shortName, 
            color: updatedContainer.color
        });
        onClose();
    }
    const enableSubmit = watch('displayName') !== '' && watch('shortName') !== '' && watch('color') !== '';

    return (
        <Wrapper>
            <StyledForm>
                <ModalHeading>{t("pages.myContainers.containerAddOrEditForm.editContainer")} <div onClick={onClose}><IconClose /></div></ModalHeading>
                <ContentWrapper>
                <div style={{ marginTop: '24px' }}>
                <TwoColumnsFormRow>
                    <InputContainerFull>
                        <StyledH3>{t("pages.myContainers.containerAddOrEditForm.containerName")}</StyledH3>
                        <StyledInput hasError={Boolean(errors.displayName)} type="text" placeholder={t("pages.myContainers.containerAddOrEditForm.containerNamePlaceholder")} 
                        {...register("displayName", { required: t('errors.requiredField') })} />
                        {errors.displayName && <ErrorWrapper><IconExclamationMark />{errors.displayName.message}</ErrorWrapper>}
                    </InputContainerFull>
                    <InputContainer style={{ width: '156px' }}>
                        <StyledH3>
                            {t("pages.myContainers.containerAddOrEditForm.containerId")}
                            <Tooltip arrow componentsProps={{
                                tooltip: {
                                    sx: {
                                        bgcolor: '#26374A',
                                        '& .MuiTooltip-arrow': {
                                            color: '#26374A',
                                        },
                                    },
                                },
                            }} title={t("pages.myContainers.containerAddOrEditForm.containerIdTooltip")}>
                                <IconWrapper>
                                    <IconQuestionCircleLineSmall />
                                </IconWrapper>
                            </Tooltip>
                        </StyledH3>
                        <StyledInput
                            type="text"
                            value={container.awmId}
                            disabled
                        />
                        { errors.displayName && <div style={{height:'24px'}}></div> }
                    </InputContainer>
                </TwoColumnsFormRow>
                <TwoColumnsFormRow style={{ justifyContent: 'flex-start' }}>
                    <InputContainer style={{ width: '156px' }}>
                        <StyledH3>
                            {t("pages.myContainers.containerAddOrEditForm.containerCode")}
                            <Tooltip arrow componentsProps={{
                                tooltip: {
                                    sx: {
                                        bgcolor: '#26374A',
                                        '& .MuiTooltip-arrow': {
                                            color: '#26374A',
                                        },
                                    },
                                },
                            }} title={t("pages.myContainers.containerAddOrEditForm.containerCodeTooltip")}>
                                <IconWrapper>
                                    <IconQuestionCircleLineSmall />
                                </IconWrapper>
                            </Tooltip>
                        </StyledH3>
                        <StyledInput maxLength={2} hasError={Boolean(errors.shortName)} type="text" placeholder={t("pages.myContainers.containerAddOrEditForm.containerCodePlaceholder")} 
                        {...register("shortName", { required: t('errors.requiredField') })} />
                        {errors.shortName && <ErrorWrapper><IconExclamationMark />{errors.shortName.message}</ErrorWrapper>}
                        {!errors.shortName && errors.color && <div style={{height:'24px'}}></div> }
                    </InputContainer>
                    <InputContainer style={{ width: '156px' }}>
                        <StyledH3>
                            {t("pages.myContainers.containerAddOrEditForm.color")}
                            <Tooltip arrow componentsProps={{
                                tooltip: {
                                    sx: {
                                        bgcolor: '#26374A',
                                        '& .MuiTooltip-arrow': {
                                            color: '#26374A',
                                        },
                                    },
                                },
                            }} title={t("pages.myContainers.containerAddOrEditForm.colorTooltip")}>
                                <IconWrapper>
                                    <IconQuestionCircleLineSmall />
                                </IconWrapper>
                            </Tooltip>
                        </StyledH3>
                        <Controller
                            control={control}
                            name="color"
                            rules={{ required: { value: true, message: 'Required Value' } }}
                            render={({ field: { onChange } }) => (
                                <ColorSelect value={container.color} onChange={onChange} />
                            )} />
                            {errors.color && <ErrorWrapper><IconExclamationMark />{errors.color.message}</ErrorWrapper>}
                            {!errors.color && errors.shortName && <div style={{height:'24px'}}></div> }
                    </InputContainer>
                </TwoColumnsFormRow>
                <TwoColumnsFormRow>
                    <InputContainerFull>
                        <StyledH3>{t("pages.myContainers.containerAddOrEditForm.street")}</StyledH3>
                        <StyledInput
                            type="text"
                            value={container.street}
                            disabled
                        />
                    </InputContainerFull>
                    <InputContainer style={{ width: '156px' }}>
                        <StyledH3>{t("pages.myContainers.containerAddOrEditForm.number")}</StyledH3>
                        <StyledInput
                            type="text"
                            value={container.houseNumber}
                            disabled
                        />
                    </InputContainer>
                </TwoColumnsFormRow>
                <TwoColumnsFormRow>
                    <InputContainerFull>
                        <StyledH3>{t("pages.myContainers.containerAddOrEditForm.location")}</StyledH3>
                        <StyledInput
                            type="text"
                            value={container.city}
                            disabled
                        />
                    </InputContainerFull>
                    <InputContainer style={{ width: '156px' }}>
                        <StyledH3>{t("pages.myContainers.containerAddOrEditForm.postcode")}</StyledH3>
                        <StyledInput
                            type="text"
                            value={container.postalCode}
                            disabled
                        />
                    </InputContainer>
                </TwoColumnsFormRow>
                <WarningText>
                        <IconInfo />
                        <p>{t("pages.myContainers.containerAddOrEditForm.addressChangeTooltip")}</p>
                </WarningText>
                </div>
                <BottomRow>
                    { !updateContainerMutation.isLoading && 
                        <Button buttonVariant="ghost" onClick={() => onClose()}>
                            {t("buttons.abort")}
                        </Button>
                    }
                    <Button disabled={!enableSubmit || updateContainerMutation.isLoading} onClick={handleSubmit(onSave)}>
                        { updateContainerMutation.isLoading && <Loader/> }
                        { !updateContainerMutation.isLoading && t("buttons.save") }
                    </Button>
                </BottomRow>
                </ContentWrapper>
            </StyledForm>
        </Wrapper>
    );
};