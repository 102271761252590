import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styles } from "../../../../../styles";
import { FaqItem } from '../../../../../types/FaqItem';


const Accordion = styled(MuiAccordion)(() => ({
    margin: '0 0 24px 0',
    border: '1px solid #E0E1E0',
    borderRadius: '4px',
    backgroundColor: '#fff',
    boxShadow: styles.boxShadowInset,
    color: styles.textColorSecondary700,
    '&.MuiAccordion-root': {
        '&.Mui-expanded': {
            margin: '0 0 24px 0',
        },
    },
    '&.MuiPaper-root.MuiAccordion-root:before': {
        content: 'none',
    },
}));

const AccordionSummary = styled(MuiAccordionSummary)(() => ({
    borderRadius: '4px',
    backgroundColor: '#fff',
    boxShadow: styles.boxShadowInset,
    fontFamily: "Satoshi Bold, sans-serif",
    minHeight: '48px',
    '& .MuiAccordionSummary-content': {
        margin: '12px 0 !important',
    },
    '&.MuiAccordionSummary-root': {
        '&.Mui-expanded': {
            backgroundColor: 'white',
            minHeight: '48px',
        },
    },
    '& .MuiSvgIcon-root': {
        color: styles.textColorSecondary700,
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(() => ({
    paddingTop: '12px',
    borderBottom: 'none',
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',
    fontFamily: "Satoshi Regular, sans-serif",
    '& img': {
        maxWidth: '100%',
        maxHeight: '100%',
        display: 'block',
    },
}));

type AccordionProps = {
    data: FaqItem[];
}

export const AccordionSection: React.FC<AccordionProps> = ({ data }) => (
    <section>
        {data.map(item => (
            <Accordion key={item.id}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <p>{item.question}</p>
                </AccordionSummary>
                <AccordionDetails>
                    <p dangerouslySetInnerHTML={{__html: item.answer}}/>
                </AccordionDetails>
            </Accordion>
        ))}
    </section>
);
