import { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { TableCell, Tooltip, TableContainer, Table, TableBody, TableRow } from "@mui/material";
import { IconContainerSmall, IconDeleteSmall, IconEditPen, IconQuestionCircleLineSmall } from "../../../components/Icons";
import { styles } from "../../../styles";
import { useTranslation } from "react-i18next";
import { RecurrenceDefinition, RecurrenceInterval } from "../../../types/Emptying";
import { EmptyingsSchedule } from "../EmptyingSchedulingPage";
import { Namespace, TFunction } from "i18next";
import { IconButton } from '@mui/material';
import Modal from "../../../components/Modal";
import { EditSerialEmptyingModalContent } from "./EditSerialEmptyingModalContent";
import { CancelSerialEmptyingModalContent } from "./CancelSerialEmptyingModalContent";


const SerialPlanning = styled.div`
`;

const StyledTableRow = styled(TableRow)`
  height: 72px;
`;

const TableHeadCell = styled(TableCell)`
  border-bottom: 1px solid ${styles.neutral300} !important;
  padding: 16px 4px !important;
  font-family: 'Satoshi Regular';
  line-height: 22px;
  color: ${styles.textColorSecondary700};
`;

const TableBodyCell = styled(TableCell)`
  border-bottom: 1.5px solid ${styles.neutral200} !important;
  padding: 10px 4px;
  font-family: 'Satoshi Regular';
  color: ${styles.textColorSecondary700};
`;

const HeadingWrapper = styled.div`
    h2 {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
    }

    svg {
        margin-left: 8px;
        cursor: pointer;
    }
`;

const IconWrapper = styled.div`
    display: flex;
`;

const IconsWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 4px;
`;

const Separator = styled.div`
  height: 20px;
  width: 1px;
  background-color: ${styles.neutral300};
`;

const MobileRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 12px;

    > div {
        width: 25%;
    }
`;

const ActionsMobileRow = styled(MobileRow)`
    border-bottom: 1px solid ${styles.neutral200};
`;

const MobileHeaderRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    border-bottom: 1px solid ${styles.neutral300};

    div {
        width: 25%;
    }
`;

const MobileHeader = styled.div`
    color: ${styles.textColorSecondary700};
    margin-bottom: 8px;
`;

const MobileData = styled.div`
    margin-bottom: 8px;
`;

const ContainerIconWrapper = styled.div<{ color: string }>`
    position: relative;
    height: 48px;
    cursor: pointer;

    svg {
        path {
            fill: ${props => props.color};;
        }
        path:last-child {
            stroke: ${props => props.color};;
        }
    }
`;

const ContainerShortName = styled.span`
    font-family: 'Satoshi Bold';
    color: ${styles.textColorSecondary700};
    position: absolute;
    top: 15px;
    right: 13px;
    font-size: 9px;
    width: 14px;
    text-align: center;

    @media (max-width: 480px) {
        top: 11px;
    }
`;

const ContainerCellWrapper = styled.div`
    display: flex;
    align-items: center;
`;

type StyledIconsWrapperProps = {
    plannedSchedule: EmptyingsSchedule;
    onDelete: (plannedSchedule: EmptyingsSchedule) => void;
    onEdit: (plannedSchedule: EmptyingsSchedule) => void;
};

const StyledIconsWrapper: React.FC<StyledIconsWrapperProps> = ({ plannedSchedule, onEdit, onDelete }) => {
    const handleEdit = () => {
        onEdit(plannedSchedule);
    }

    const handleDelete = () => {
        onDelete(plannedSchedule);
    }
    return (
        <IconsWrapper>
            <IconButton onClick={handleEdit}>
                <IconEditPen />
            </IconButton>

            <Separator />

            <IconButton onClick={handleDelete}>
                <IconDeleteSmall />
            </IconButton >
        </IconsWrapper >
    );
};

const useWindowWidth = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowWidth(window.innerWidth);
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        }
    }, []);

    return windowWidth;
}

type SeriesListProps = {
    plannedSchedules: EmptyingsSchedule[];
    onUpdate: (plannedSchedules: EmptyingsSchedule[]) => void;
}

type WeekdayIndexInI18nList = 0 | 1 | 2 | 3 | 4;

const calcDayName = (t: TFunction<Namespace, undefined, Namespace>, weekday: number) => {
    return (weekday <= 0 || weekday > 5) ? '-' : t(`dates.workingDays.long.${weekday - 1 as WeekdayIndexInI18nList}`);
}

const recurrenceToText = (t: TFunction<Namespace, undefined, Namespace>, dateFormat: Intl.DateTimeFormat, recurrence?: RecurrenceDefinition) => {
    if (!recurrence) return '-';
    const weekdayText = recurrence.weekdays.map(weekday => calcDayName(t, weekday)).join(', ');
    const intervalText = recurrence.interval === RecurrenceInterval.BiWeekly
        ? t('pages.emptying.series.modalPlanSerialEmptying.biweekly')
        : (recurrence.interval === RecurrenceInterval.FourWeekly)
            ? t('pages.emptying.series.modalPlanSerialEmptying.fourweekly')
            : t('pages.emptying.series.modalPlanSerialEmptying.weekly');
    return `${weekdayText}, ${intervalText}, ${dateFormat.format(recurrence.startDate)} - ${dateFormat.format(recurrence.endDate)}`
}

export const SeriesPlannedSchedulesList = ({ plannedSchedules, onUpdate }: SeriesListProps) => {
    const windowWidth = useWindowWidth();
    const isMobileView = windowWidth <= 480;
    const { t, i18n } = useTranslation();
    const dateFormat = new Intl.DateTimeFormat(i18n.language, { dateStyle: 'short' });
    const [scheduleToCancel, setScheduleToCancel] = useState<EmptyingsSchedule>();
    const [scheduleInEdit, setScheduleInEdit] = useState<EmptyingsSchedule>();

    const [editedPlannedSchedules, setEditedPlannedSchedules] = useState<{ original: EmptyingsSchedule, edited: EmptyingsSchedule}[]>([]);
    
    const cancelModal = () => {
        setScheduleInEdit(undefined);
        setScheduleToCancel(undefined);
    }

    const onCancelSerialEmptyingModalClosed = (cancelledEmptyingSchedule?: EmptyingsSchedule) => {
        if (cancelledEmptyingSchedule) {
            const updatedPlannedSchedules = plannedSchedules.filter(p => p !== scheduleToCancel);
            onUpdate(updatedPlannedSchedules);
        }
        cancelModal();
    }
    const onEditSerialEmptyingModalClosed = (originalEmptyingSchedule: EmptyingsSchedule, updatedEmptyingSchedule?: EmptyingsSchedule) => {
        if (updatedEmptyingSchedule) {
            const updatedEditedPlannedSchedules = editedPlannedSchedules.filter(e => e.original !== originalEmptyingSchedule);
            updatedEditedPlannedSchedules.push( { original: originalEmptyingSchedule, edited: updatedEmptyingSchedule});
            const updatedPlannedSchedules = plannedSchedules.map(p => p === scheduleInEdit ? updatedEmptyingSchedule: p);
            onUpdate(updatedPlannedSchedules);
            setEditedPlannedSchedules(updatedEditedPlannedSchedules);
        }
        cancelModal();
    }

    const renderMobileView = () => {
        return (
            <div>
                <MobileHeaderRow>
                    <MobileHeader style={{width: '120px'}}>{t("pages.emptying.series.serialPlanning.container")}</MobileHeader>
                    <MobileHeader style={{width: '120px'}}>{t("pages.emptying.series.serialPlanning.seriesRule")}</MobileHeader>
                    <MobileHeader style={{maxWidth: '40px'}}>#</MobileHeader>
                </MobileHeaderRow>
                {plannedSchedules.filter(plannedSchedule => plannedSchedule.emptyings.length > 0).map((plannedSchedule, index) => (
                    <div key={`${index}`}>
                        <MobileRow>
                            <MobileData style={{width: '120px'}}>
                                <ContainerCellWrapper>
                                    <ContainerIconWrapper color={plannedSchedule.container.color}>
                                        <IconContainerSmall />
                                        <ContainerShortName>{plannedSchedule.container.shortName}</ContainerShortName>
                                    </ContainerIconWrapper>
                                    <p>{plannedSchedule.container.displayName}</p>
                                </ContainerCellWrapper>
                            </MobileData>
                            <MobileData style={{width: '120px'}}>{recurrenceToText(t, dateFormat, plannedSchedule.recurrence)}</MobileData>
                            <MobileData style={{maxWidth: '40px'}}>{plannedSchedule.emptyings.length}</MobileData>
                        </MobileRow>
                        <ActionsMobileRow>
                            <MobileData></MobileData>
                            <MobileData></MobileData>
                            <MobileData></MobileData>
                            <MobileData>
                                <StyledIconsWrapper plannedSchedule={plannedSchedule} onDelete={setScheduleToCancel} onEdit={setScheduleInEdit} />
                            </MobileData>
                        </ActionsMobileRow>
                    </div>
                ))}

                {(!plannedSchedules || plannedSchedules.length === 0) &&
                    <div>
                        <MobileRow>
                            <MobileData>-</MobileData>
                            <MobileData>-</MobileData>
                            <MobileData>-</MobileData>
                        </MobileRow>
                        <MobileRow>
                            <MobileData></MobileData>
                            <MobileData></MobileData>
                            <MobileData></MobileData>
                        </MobileRow>
                    </div>
                }
            </div>
        )
    }

    const renderDesktopView = () => (
        <TableContainer>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableHeadCell>{t("pages.emptying.series.serialPlanning.container")}</TableHeadCell>
                        <TableHeadCell>{t("pages.emptying.series.serialPlanning.seriesRule")}</TableHeadCell>
                        <TableHeadCell>{t("pages.emptying.series.serialPlanning.emptyingsNumber")}</TableHeadCell>
                        <TableHeadCell></TableHeadCell>
                    </TableRow>
                    {plannedSchedules.filter(plannedSchedule => plannedSchedule.emptyings.length > 0).map((plannedSchedule, index) => (
                        <StyledTableRow key={`${index}`}>
                            <TableBodyCell>
                                <ContainerCellWrapper>
                                    <ContainerIconWrapper color={plannedSchedule.container.color}>
                                        <IconContainerSmall />
                                        <ContainerShortName>{plannedSchedule.container.shortName}</ContainerShortName>
                                    </ContainerIconWrapper>
                                    <p>{plannedSchedule.container.displayName}</p>
                                </ContainerCellWrapper>
                            </TableBodyCell>
                            <TableBodyCell>{recurrenceToText(t, dateFormat, plannedSchedule.recurrence)}</TableBodyCell>
                            <TableBodyCell>{plannedSchedule.emptyings.length}</TableBodyCell>
                            <TableBodyCell align="right"> 
                                <StyledIconsWrapper plannedSchedule={plannedSchedule} onDelete={setScheduleToCancel} onEdit={setScheduleInEdit} />
                            </TableBodyCell>
                        </StyledTableRow>
                    )
                    )}
                    {(!plannedSchedules || plannedSchedules.length === 0) &&
                        <StyledTableRow>
                            <TableBodyCell>-</TableBodyCell>
                            <TableBodyCell>-</TableBodyCell>
                            <TableBodyCell>-</TableBodyCell>
                            <TableBodyCell align="right"></TableBodyCell>
                        </StyledTableRow>
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )

    return (
        <SerialPlanning>
            <HeadingWrapper>
                <h2>
                    {t("pages.emptying.series.serialPlanning.header")}
                    <Tooltip arrow componentsProps={{
                        tooltip: {
                            sx: {
                                bgcolor: '#26374A',
                                '& .MuiTooltip-arrow': {
                                    color: '#26374A',
                                },
                            },
                        },
                    }} title={t("pages.emptying.series.serialPlanning.tooltip")}>
                        <IconWrapper>
                            <IconQuestionCircleLineSmall />
                        </IconWrapper>
                    </Tooltip>
                </h2>
            </HeadingWrapper>

            {isMobileView ? renderMobileView() : renderDesktopView()}
            {scheduleToCancel && (
                <Modal onClose={cancelModal}>
                    <CancelSerialEmptyingModalContent onClose={onCancelSerialEmptyingModalClosed} plannedSchedule={scheduleToCancel}/>
                </Modal>
            )}
            {scheduleInEdit && (
                <Modal onClose={cancelModal}>
                    <EditSerialEmptyingModalContent 
                        onClose={onEditSerialEmptyingModalClosed} 
                        originalPlannedSchedule={(editedPlannedSchedules.find(e => e.edited === scheduleInEdit)?.original  || scheduleInEdit)} 
                        editedPlannedSchedule={scheduleInEdit}/>
                </Modal>
            )}
        </SerialPlanning>
    )
}