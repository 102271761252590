export type Contact = {
    id: string;
    salutation: Salutation;
    firstName: string;
    lastName: string;
    loginName: string;
    email: string;
    phone: string;
    created: Date;
    notificationTypes: NotificationType[];
}

export enum Salutation {
    Mister = 125160000,
    Misses = 125160001
}

export enum NotificationType {
    EmailOrderConfirmation = 1,
    EmailEmptyingReminder = 2,
    EmailEmptyingConfirmation = 3,
    SmsEmptyingReminder = 4
}
