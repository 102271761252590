import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { ROUTES } from "../../skeleton/Routes";
import { CompanyData } from "./components/company-data/CompanyData";
import { TabbedNavigation } from "../../components/TabbedNavigation";
import { UserContext } from "../../contexts/UserContext";
import { useContext } from "react";

const StyledH1 = styled.h1`
    margin-bottom: 48px;

    @media (max-width: 480px) {
        margin-bottom: 12px;
    }
`;

export const AccountProfilePage = () => {
    const { accessPermission } = useContext(UserContext);
    const { t } = useTranslation();

    const nodes = [
        { label: t("pages.myData.personalDetails.header"), route: ROUTES.UserProfile }
    ]
    if (accessPermission) {
        nodes.push({ label: t("pages.myData.companyData.header"), route: ROUTES.AccountProfile });
    }
    return (
        <>
            <StyledH1>{t("pages.myData.header")}</StyledH1>
            <div>
                <TabbedNavigation nodes={nodes} />
                <CompanyData />
            </div>
        </>
    )
};