import styled from "@emotion/styled";
import { IconContainerEmpty, IconPlusCircle } from "../Icons";
import { useTranslation } from "react-i18next";
import { styles } from '../../styles';
import { Container } from "../../types/Container";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../skeleton/Routes";
import { useContext } from "react";
import { UserContext } from "../../contexts/UserContext";


const ContainerSubMenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  border: 1px solid ${styles.neutral200};
  border-radius: 4px;
  box-shadow: ${styles.boxShadowContainerHovered};
  position: absolute;
  top: 32px;
  right: -12px;
  z-index: 1;
`;

const ContainerSubMenuItem = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
  cursor: pointer;
  padding: 10px 25px;
  color: ${styles.textColorSecondary700};

  &:hover {
    background: #F5F5F5;
  }

  &:last-child {
    margin-bottom: 24px;
  }

  &:nth-child(1) {
    margin-top: 24px;
  }

  svg {
    margin-right: 10px;
    width: 24px;
    height: 24px;
  }
`;

export const ContainerSubMenu = ({ container }: { container: Container }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { accessPermission } = useContext(UserContext);

  const onChangeContainerData = () => {
    navigate(ROUTES.ContainerOverview, { state: { preselectedContainer: container } });
  }

  const onOrderOneTimeEmptying = () => {
    navigate(ROUTES.SingleEmptying, { state: { preselectedContainer: container } });
  }

  return (
    <ContainerSubMenuWrapper>
      { accessPermission?.roles?.container && 
        <ContainerSubMenuItem onClick={onChangeContainerData}>
          <IconContainerEmpty />
          <p>{t("components.containers.submenu.changeContainerData")}</p>
        </ContainerSubMenuItem>
      }
      { accessPermission?.roles?.payment && 
        <ContainerSubMenuItem onClick={onOrderOneTimeEmptying}>
          <IconPlusCircle />
          <p>{t("components.containers.submenu.orderOneTimeEmptying")}</p>
        </ContainerSubMenuItem>
      }
    </ContainerSubMenuWrapper>
  );
};