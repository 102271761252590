import styled from "@emotion/styled";
import { styles } from "../../../../styles";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import { CardStyledContainersList } from "../../../../components/containers/CardStyledContainersList";
import { CreateOrUpdateInvitationInfo, useInvitationCreationMutation } from "../../../../data/invitations";
import { EndUserRole, endUserRoleToUserRoleInfo } from "../../../../types/UserRolesInfo";
import { Container } from "../../../../types/Container";
import Button from '../../../../components/Button';
import Select from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';
import { IconClose, IconExclamationMark, IconQuestionCircleLineSmall } from "../../../../components/Icons";
import { MenuItem } from "@mui/material";
import { MessageWrapper } from "../../../../components/MessageWrapper";
import { Loader } from "../../../../components/Loader";
import { Salutation } from "../../../../types/Contact";


const Wrapper = styled.div`
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    max-height: 80vh;

    @media (max-width: 480px) {
        max-height: 100vh;
        height: 100%;
    }
`;

const ContentWrapper = styled.div`
    overflow-y: auto;
    position: relative;
    max-height: 80vh;
    padding-right: 16px;

    @media (max-width: 480px) {
        max-height: 100vh;
        height: 100%;
    }
`;

const FormHeading = styled.h2`
    margin-bottom: 48px;
    color: ${styles.textColorSecondary700};
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #fff;
    padding-top: 24px;
    padding-bottom: 24px;
    padding-right: 24px;
    border-radius: 8px;
    margin-bottom: 0;

    svg {
        cursor: pointer;
    }
`;

const FormRow = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
    color: ${styles.textColorSecondary700};
`;

const BottomRow = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    margin-top: 48px;
    margin-bottom: 68px;

    button {
        width: 156px;
    }

    @media (max-width: 480px) {
        margin-bottom: 0;
        flex-direction: column;
        align-items: flex-end;
        gap: 12px;
        width: 100%;

        button {
            width: 100%;
            margin-bottom: 0;
        }
    }
`;

const StyledH3 = styled.h3`
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 8px;
`;

const StyledH4 = styled.h4`
    font-size: 10px;
    line-height: 18px;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    gap: 10px;

    svg {
        cursor: pointer;
    }
`;

const IconWrapper = styled.div`
    display: flex;
`;


const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-right: 12px;

    &:last-child {
        margin-right: 0;
    }
`;

const NameContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

type StyledInputProps = {
    hasError?: boolean;
};

const StyledInput = styled.input<StyledInputProps>`
    width: calc(50% - 24px));
    padding: 10px 16px;
    flex: 1;
    box-shadow: ${styles.boxShadowInset};
    background-color: #fff;
    border: 1px solid ${props => props.hasError ? '#ed2b2b' : styles.neutral200};
    border-radius: 4px;
    box-sizing: border-box;
    line-height: 22px;
    font-size: 14px;
    color: ${styles.textColorSecondary700};
    font-family: 'Satoshi Regular';

    &:focus {
        border: 1px solid #3371EA;
        outline: none;
    }
`;

const StyledSelect = styled(Select)(() => ({
    boxShadow: styles.boxShadowInset,
    height: '44px',
    backgroundColor: '#fff',
    borderRadius: '4px',
    flex: '1',
    boxSizing: 'border-box',
    padding: '0',
    fontFamily: 'Satoshi Regular',
    color: styles.textColorSecondary700,

    '.MuiSelect-icon': {
        color: '#26374A',
    },

    '& .MuiSelect-select': {
        paddingTop: '11px',
        paddingBottom: '11px',
        lineHeight: '22px',
        fontSize: '14px',
        boxShadow: styles.boxShadowInset,
    },
    '& .MuiOutlinedInput-root': {
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#e0e1e0',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#e0e1e0',
        },
    },
    '.MuiOutlinedInput-notchedOutline': {
        borderWidth: '1px',
    },
}));

const ErrorWrapper = styled.div`
    margin-top: 12px;
    display: flex;
    align-items: center;
    font-size: 10px;
    line-height: 18px;

    svg {
        margin-right: 8px;
    }
`;

type InvitationFormProps = {
    onClose: () => void;
    onSuccess: () => void;
}
type FormData = {
    firstName: string, lastName: string; email: string;
    salutation: '' | Salutation;
    role: '' | EndUserRole;
    containers: Container[];
    sendInvitationRedeemedNotification: boolean;
}
const formDefaultValues: FormData = {
    firstName: '', lastName: '', email: '',
    role: '', salutation: '',
    sendInvitationRedeemedNotification: false,
    containers: []
}
export const InvitationForm = ({ onClose, onSuccess }: InvitationFormProps) => {
    const { register, handleSubmit, watch, control, formState: { errors } } = useForm({
        defaultValues: formDefaultValues
    });
    const invitationMutation = useInvitationCreationMutation();
    const { t } = useTranslation();

    const onSubmit = useCallback(async (formData: FormData) => {
        const invitationRequest: CreateOrUpdateInvitationInfo = {
            ...formData,
            salutation: formData.salutation || Salutation.Mister,
            roles: endUserRoleToUserRoleInfo(formData.role as EndUserRole),
            containerIds: formData.containers ? formData.containers.map(c => c.id) : []
        }
        await invitationMutation.mutateAsync(invitationRequest);
        if (invitationMutation.isSuccess) {
            onSuccess();
        }
    }, [invitationMutation, onSuccess]);


    if (invitationMutation.isSuccess) {
        onSuccess();
    }

    const enableSubmit = watch('salutation') !== '' && watch('role') !== '' && watch('firstName') !== '' && watch('lastName') !== '' && watch('email') !== '' && watch('containers').length > 0;

    return (
        <Wrapper>
            <form>
                <FormHeading>{t("pages.myOrganization.invitationForm.header")}<div onClick={onClose}><IconClose /></div></FormHeading>
                {!invitationMutation.isError && !invitationMutation.isLoading && <ContentWrapper>

                    <FormRow style={{ marginTop: '24px' }}>
                        <StyledH3>{t("pages.myOrganization.invitationForm.personalDetails")}</StyledH3>
                        <StyledH4>{t("pages.myOrganization.invitationForm.salutation")}</StyledH4>
                        <Controller
                            name="salutation"
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => (
                                <StyledSelect
                                    placeholder={t("pages.myOrganization.invitationForm.salutation")}
                                    sx={{
                                        marginBottom: '12px',
                                        '.MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#e0e1e0',
                                        },
                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#3371EA',
                                            borderWidth: '1px'
                                        },
                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#e0e1e0',
                                        },
                                    }}
                                    {...field}
                                    onChange={(e) => {
                                        field.onChange(e.target.value);
                                    }}
                                >
                                    <MenuItem value={Salutation.Mister}>{t("pages.myOrganization.invitationForm.mr")}</MenuItem>
                                    <MenuItem value={Salutation.Misses}>{t("pages.myOrganization.invitationForm.mrs")}</MenuItem>
                                </StyledSelect>
                            )}
                        />
                        <NameContainer>
                            <InputContainer>
                                <StyledH4>{t("pages.myOrganization.invitationForm.firstName")}</StyledH4>
                                <StyledInput hasError={Boolean(errors.firstName)} type="text" placeholder={t("pages.myOrganization.invitationForm.firstName")} {...register("firstName", { required: t('errors.requiredField') })} />
                                {errors.firstName && <ErrorWrapper><IconExclamationMark /> {errors.firstName.message}</ErrorWrapper>}
                            </InputContainer>
                            <InputContainer>
                                <StyledH4>{t("pages.myOrganization.invitationForm.surname")}</StyledH4>
                                <StyledInput hasError={Boolean(errors.lastName)} type="text" placeholder={t("pages.myOrganization.invitationForm.surname")} {...register("lastName", { required: t('errors.requiredField') })} />
                                {errors.lastName && <ErrorWrapper><IconExclamationMark />{errors.lastName.message}</ErrorWrapper>}
                            </InputContainer>
                        </NameContainer>
                    </FormRow>
                    <FormRow>
                        <StyledH4>{t("pages.myOrganization.invitationForm.email")}</StyledH4>
                        <StyledInput
                            hasError={Boolean(errors.email)}
                            type="text"
                            placeholder={t("pages.myOrganization.invitationForm.email")}
                            {...register("email", { required: t('errors.requiredField') })} />
                        {errors.email && <ErrorWrapper><IconExclamationMark />{errors.email.message}</ErrorWrapper>}
                    </FormRow>

                    <FormRow>
                        <StyledH4>
                            {t("pages.myOrganization.invitationForm.role")}
                            <Tooltip arrow componentsProps={{
                                tooltip: {
                                    sx: {
                                        bgcolor: '#26374A',
                                        '& .MuiTooltip-arrow': {
                                            color: '#26374A',
                                        },
                                    },
                                },
                            }} title={t("pages.myOrganization.invitationForm.roleTooltip")}>
                                <IconWrapper>
                                    <IconQuestionCircleLineSmall />
                                    {/* RESOLVED: wrong tooltip text */}
                                </IconWrapper>
                            </Tooltip>
                        </StyledH4>
                        {<Controller
                            name="role"
                            control={control}
                            defaultValue={'viewer'}
                            rules={{ required: true }}
                            render={({ field }) => (
                                <StyledSelect
                                    sx={{
                                        '.MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#e0e1e0',
                                        },
                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#3371EA',
                                            borderWidth: '1px'
                                        },
                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#e0e1e0',
                                        },
                                    }}
                                    {...field}
                                    onChange={(e) => {
                                        field.onChange(e.target.value);
                                    }}
                                >
                                    <MenuItem value="admin">{t("roles.admin")}</MenuItem>
                                    <MenuItem value="staff">{t("roles.staff")}</MenuItem>
                                    <MenuItem value="viewer">{t("roles.viewer")}</MenuItem>
                                </StyledSelect>
                            )}
                        />}
                    </FormRow>

                    <Controller
                        control={control}
                        name="containers"
                        rules={{ required: { value: true, message: t("pages.myOrganization.invitationForm.atLeastOneContainer") } }}
                        render={({ field: { onChange } }) => (
                            <CardStyledContainersList
                                cardWidth={200} selectionMode="multi"
                                defaultSelection={[]}
                                onChange={onChange}
                                header={t("pages.myOrganization.invitationForm.containerAssignment")}
                            />
                        )} />
                    {errors.containers && <span>{errors.containers.message}</span>}

                    <BottomRow>
                        {!invitationMutation.isLoading &&
                            <Button buttonVariant="ghost" onClick={() => onClose()}>
                                {t("buttons.abort")}
                            </Button>
                        }
                        <Button disabled={!enableSubmit || invitationMutation.isLoading} onClick={handleSubmit(onSubmit)}>
                            {invitationMutation.isLoading && <Loader />}
                            {!invitationMutation.isLoading && t("buttons.invite")}
                        </Button>
                    </BottomRow>
                </ContentWrapper>}

                {/* RESOLVED: Handle error and loading state */}
                {invitationMutation.isError && (
                    <MessageWrapper messageText={invitationMutation.error.message} height={"114px"} alignItems={"flex-start"} margin={"24px 0 0"} />
                )}

                {invitationMutation.isLoading && (
                    <Loader messageText={t("pages.myOrganization.invitationForm.creatingInvitation")} height={"114px"} justifyContent={"flex-start"} margin={"24px 0 0"} />
                )}
            </form>
        </Wrapper>
    )
}