import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Emptying, EmptyingStatusReason, RecurrenceDefinition } from "../types/Emptying";
import { Container, ContainerRef } from "../types/Container";
import { ONE_MINUTE, convertToErrorMsg, jsonReviverFunction } from "./_base";
import { UserContext } from "../contexts/UserContext";
import { useContext } from "react";
import { AccessPermission } from "../types/AccessPermission";
import { ACCOUNT_BY_ID_QUERY_KEY } from "./accounts";
import { HISTORY_LOG_QUERY_KEY } from "./historylog";

const EMPTYINGS_QUERY_KEY = 'emptyings';

export const useScheduledAndLatestInPastEmptyingsQuery = (container: Container) => {
    const userContext = useContext(UserContext);
    const accessPermission = userContext.accessPermission;
    const today = new Date(new Date().toDateString());
    const query = useQuery<{scheduled: Emptying[], latest: Emptying | undefined}, Error>({
        queryKey: [EMPTYINGS_QUERY_KEY, accessPermission ? accessPermission.accountRef.id : '-', container.id, "scheduled-and-latest-in-past", today.toDateString()],
        refetchOnWindowFocus: true,
        staleTime: 30000,
        retry: 0,
        queryFn: () => getLatestCompletedAndScheduledEmptyings(accessPermission, container, today)
    })
    return query;
}

export const useEmptyingsOverviewQuery = (from: Date, to: Date, containerRef?: ContainerRef) => {
    return _useEmptyingsQuery(from, to, 'overview', containerRef);
}
export const useEmptyingConfirmationsQuery = (from: Date, to: Date, containerRef?: ContainerRef) => {
    return _useEmptyingsQuery(from, to, 'confirmations', containerRef);
}

const _useEmptyingsQuery = (from: Date, to: Date, type: 'overview' | 'confirmations', containerRef?: ContainerRef) => {
    const userContext = useContext(UserContext);
    const accessPermission = userContext.accessPermission;
    const query = useQuery<Emptying[] | undefined, Error>({
        queryKey: [EMPTYINGS_QUERY_KEY, accessPermission ? accessPermission.accountRef.id : '-', containerRef ? containerRef.id: '-', from.toDateString(), to.toDateString()],
        refetchOnWindowFocus: true,
        staleTime: 30000,
        retry: 0,
        queryFn: () => getEmptyings({type, from, to}, accessPermission, containerRef)
    })
    return query;
}

const getLatestCompletedAndScheduledEmptyings = async (accessPermission: AccessPermission | undefined, container: Container, currentDate: Date): Promise<{scheduled: Emptying[], latest: Emptying | undefined}> => {
    const result: {scheduled: Emptying[], latest: Emptying | undefined} = {
        scheduled: [],
        latest: undefined
    }
    const emptyings = await getEmptyings({type: 'latestCompletedAndSchedules'}, accessPermission, container);
    if (emptyings) {
        result.scheduled = emptyings.filter(e => e.removalDate.date >= currentDate && e.statusReason === EmptyingStatusReason.Pending);
        const pastEmptyings = emptyings.filter(e => new Date(e.removalDate.date.toDateString()) <= currentDate && e.statusReason !== EmptyingStatusReason.Pending).sort((e1, e2) => e1.removalDate.date > e2.removalDate.date ? 1: -1);
        result.latest = (pastEmptyings && pastEmptyings.length > 0) ? pastEmptyings[pastEmptyings.length - 1]: result.latest;
    }
    return result;
}

export const getEmptyingDownloadUrl = (emptying: Emptying, container: Container) => {
    return `/api/me/accounts/${container.accountId}/emptyings/${emptying.id}/doc`
}

const getEmptyings = async (params: {type: 'overview' | 'confirmations', from: Date, to: Date} | { type: 'latestCompletedAndSchedules'}, accessPermission?: AccessPermission, containerRef?: ContainerRef): Promise<Emptying[] | undefined> => {
    if (!accessPermission) return [];
    if (containerRef && !accessPermission.containerRefs.find(c => c.id === containerRef.id)) return [];
    
    const dateFormat = new Intl.DateTimeFormat('en');
    let urlEnding = '';
    if (params.type === 'overview' || params.type === 'confirmations') {
        const fromParam = dateFormat.format(params.from);
        const toParam = dateFormat.format(params.to);
        urlEnding = `?from=${fromParam}&to=${toParam}`;
    } 
    let serviceUrl = `/api/me/accounts/${accessPermission.accountRef.id}`;
    if (containerRef) {
        serviceUrl = `${serviceUrl}/containers/${containerRef.id}`;
    }
    serviceUrl = `${serviceUrl}/emptyings/${params.type}${urlEnding}`;
    const response = await fetch(serviceUrl);
    const responseBody = await response.text();
    if (!response.ok) {
        const errorMsg = convertToErrorMsg(response.status, responseBody);
        throw errorMsg;
    } else {
        const responseJson = JSON.parse(responseBody, jsonReviverFunction);
        const emptyings = responseJson as Emptying[];
        return emptyings;
    }
}

type ScheduleEmptyingsRequestInfo = {
    container: Container;
    dates: Date[];
    recurrence?: RecurrenceDefinition;
    paymentTransactionId: string;
}
type EmptyingSchedulingResult = {
    totalPrice: number;
}
export const useScheduleEmptyingMutation = () => {
    const queryClient = useQueryClient()
    const userContext = useContext(UserContext);
    const mutation = useMutation<EmptyingSchedulingResult, Error, ScheduleEmptyingsRequestInfo>({
        mutationFn: (requestInfo: ScheduleEmptyingsRequestInfo) => scheduleEmptyings(requestInfo),
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({queryKey: [ACCOUNT_BY_ID_QUERY_KEY, userContext.accessPermission?.accountRef.id]});
            queryClient.invalidateQueries({queryKey: [EMPTYINGS_QUERY_KEY, userContext.accessPermission?.accountRef.id, variables.container.id]});
            queryClient.invalidateQueries({queryKey: [EMPTYINGS_QUERY_KEY, userContext.accessPermission?.accountRef.id, '-']});
            queryClient.invalidateQueries({queryKey: [EMPTYINGS_QUERY_KEY, '-']});
            queryClient.invalidateQueries({queryKey: [HISTORY_LOG_QUERY_KEY]});
        }
    })
    return mutation;
}

const scheduleEmptyings = async (requestInfo: ScheduleEmptyingsRequestInfo): Promise<EmptyingSchedulingResult> => {
    const container = requestInfo.container;
    requestInfo.dates = requestInfo.dates.map(d => new Date(d.getTime() - d.getTimezoneOffset() * ONE_MINUTE));
    const body = {...requestInfo, container: undefined};
    const response = await fetch(`/api/me/accounts/${container.accountId}/containers/${container.id}/emptyings`, {
        method: 'post',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body)
    });
    const responseBody = await response.text();
    if (!response.ok) {
        const errorMsg = convertToErrorMsg(response.status, responseBody);
        throw errorMsg;
    } else {
        const result = JSON.parse(responseBody, jsonReviverFunction) as EmptyingSchedulingResult;
        return result;
    }
}


export const useCancelEmptyingMutation = () => {
    const queryClient = useQueryClient()
    const userContext = useContext(UserContext);
    const mutation = useMutation<void, Error, Emptying>({
        mutationFn: (emptying: Emptying) => cancelEmptyings(emptying, userContext.accessPermission),
        onSuccess: (data, emptying) => {
            queryClient.invalidateQueries({queryKey: [ACCOUNT_BY_ID_QUERY_KEY, userContext.accessPermission?.accountRef.id]});
            queryClient.invalidateQueries({queryKey: [EMPTYINGS_QUERY_KEY, userContext.accessPermission?.accountRef.id, emptying.containerId]});
            queryClient.invalidateQueries({queryKey: [EMPTYINGS_QUERY_KEY, userContext.accessPermission?.accountRef.id, '-']});
            queryClient.invalidateQueries({queryKey: [EMPTYINGS_QUERY_KEY, '-']});
            queryClient.invalidateQueries({queryKey: [HISTORY_LOG_QUERY_KEY]});
        }
    })
    return mutation;
}

const cancelEmptyings = async (emptying: Emptying, accessPermission?: AccessPermission): Promise<void> => {
    const response = await fetch(`/api/me/accounts/${accessPermission?.accountRef.id}/containers/${emptying.containerId}/emptyings/${emptying.id}`, {
        method: 'delete'
    });
    const responseBody = await response.text();
    if (!response.ok) {
        const errorMsg = convertToErrorMsg(response.status, responseBody);
        throw errorMsg;
    } 
}