import React, { useEffect } from 'react';
import { Select, MenuItem, ListItemIcon, FormControl, SelectChangeEvent } from '@mui/material';
import { useTranslation } from "react-i18next";
import { styles } from "../../../styles";
import styled from "@emotion/styled";

const StyledSelect = styled(Select)(() => ({
    boxShadow: styles.boxShadowInset,
    boxSizing: 'border-box',
    height: '44px important',
    backgroundColor: '#fff',
    borderRadius: '4px',
    flex: '1',
    padding: '0',
    fontFamily: 'Satoshi Regular',
    color: styles.textColorSecondary900,

    '.MuiSelect-icon': {
        color: '#26374A',
    },

    '& .MuiSelect-select': {
        paddingLeft: '8px',
        lineHeight: '22px',
        fontSize: '14px',
        boxShadow: styles.boxShadowInset,
        boxSizing: 'border-box',
        maxHeight: '44px',
    },
    '& .MuiSelect-select > div': {
        height: '100%',
        marginTop: '-5px',
    },
    '& .MuiOutlinedInput-root': {
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: styles.neutral200,
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: styles.neutral200,
        },
    },
    '.MuiOutlinedInput-notchedOutline': {
        borderWidth: '1px',
    },
    '.MuiListItemIcon-root': {
        minWidth: '34px',
    },
}));

const ListItemWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 32px;
    padding-left: 7px;
    padding-right: 12px;
    width: 100%;
    color: ${styles.textColorSecondary900};
    font-family: 'Satoshi Regular';
    font-size: 14px;
`;

const ColorIndicator = styled.div<{ color: string }>`
    width: 18px;
    height: 18px;
    background-color: ${props => props.color};
    border-radius: 50%;
`;

const StyledMenuItem = styled(MenuItem)(() => ({
    paddingLeft: '8px',
    paddingRight: '8px',
    margin: '8px 0'
}));

type ColorSelectProps = {
    value: string;
    onChange: (value: string) => void;
}
export const ColorSelect = ({value, onChange}: ColorSelectProps) => {
    const [color, setColor] = React.useState<string>(value);
    const { t } = useTranslation();

    const handleChange = (event: SelectChangeEvent<unknown>, child: React.ReactNode) => {
        setColor(event.target.value as string);
    };

    useEffect(() => {
        onChange(color);
    }, [onChange, color]);

    return (
        <FormControl fullWidth>
            <StyledSelect
                sx={{
                    '.MuiOutlinedInput-notchedOutline': {
                        borderColor: styles.neutral200,
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#3371EA',
                        borderWidth: '1px'
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: styles.neutral200,
                    },
                }}
                labelId="select-small-label"
                id="select-small"
                value={color}
                label=""
                onChange={handleChange}
            >
                <StyledMenuItem value={'#6695EF'}>
                    <ListItemWrapper>
                        <ListItemIcon>
                            <ColorIndicator color={'#6695EF'} />
                        </ListItemIcon>
                        {t("pages.myContainers.containerAddOrEditForm.colors.blue")}
                    </ListItemWrapper>
                </StyledMenuItem>
                <StyledMenuItem value={'#85CC91'}>
                    <ListItemWrapper>
                        <ListItemIcon>
                            <ColorIndicator color={'#85CC91'} />
                        </ListItemIcon>
                        {t("pages.myContainers.containerAddOrEditForm.colors.green")}
                    </ListItemWrapper>
                </StyledMenuItem>
                <StyledMenuItem value={'#FFB979'}>
                    <ListItemWrapper>
                        <ListItemIcon>
                            <ColorIndicator color={'#FFB979'} />
                        </ListItemIcon>
                        {t("pages.myContainers.containerAddOrEditForm.colors.orange")}
                    </ListItemWrapper>
                </StyledMenuItem>
                <StyledMenuItem value={'#F48080'}>
                    <ListItemWrapper>
                        <ListItemIcon>
                            <ColorIndicator color={'#F48080'} />
                        </ListItemIcon>
                        {t("pages.myContainers.containerAddOrEditForm.colors.red")}
                    </ListItemWrapper>
                </StyledMenuItem>
                <StyledMenuItem value={"#D07AF9"}>
                    <ListItemWrapper>
                        <ListItemIcon>
                            <ColorIndicator color={'#D07AF9'} />
                        </ListItemIcon>
                        {t("pages.myContainers.containerAddOrEditForm.colors.purple")}
                    </ListItemWrapper>
                </StyledMenuItem>
            </StyledSelect>
        </FormControl>
    );
}

