import { useContext } from "react"
import { UserContext } from "../../contexts/UserContext"
import { styles } from "../../styles";
import { Error401Image } from "./Error401Image";
import styled from "@emotion/styled";
import Button from '../../components/Button';
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { ROUTES } from '../../skeleton/Routes';
import { MessageWrapper } from "../../components/MessageWrapper";

const ContentWrapper = styled.div`
    svg {
        width: 100%;
        max-width: 475px;
        height: auto;
    }
`;

const MainSection = styled.section`
    h1 {
        margin-top: 64px;
        @media (max-width: 480px) {
            margin-top: 48px;
        }
    }

    p {
        max-width: 500px;
        color: ${styles.textColorSecondary700};
    }
`;

const BottomRow = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    margin-top: 48px;
    max-width: 500px;

    button {
        width: 156px;
    }

    @media (max-width: 480px) {
        flex-direction: column;
        align-items: flex-end;
        width: 100%;

        button {
            width: 100%;
            margin-bottom: 0;
        }
    }
`;


export const UnauthenticatedPage = ({ mode }: { mode?: 'loggedOut' }) => {
    const userContext = useContext(UserContext);
    const { t } = useTranslation();

    const mustBeRedirectedToLogin = !userContext.loggedInUser || mode === 'loggedOut';
    if (mustBeRedirectedToLogin) {
        setTimeout(() => {
            // in rare cases the auto redirect to login page does not work - so make sure that after some time it really goes away from this page.
            userContext.login();
        }, 3000);
    }
    return (
        <div>
            {mustBeRedirectedToLogin &&
                <MessageWrapper messageType="userMessage" messageText={t("pages.unauthenticatedPage.youAreNotLoggedIn")} height="460px" svgMargin="12px" />
            }
            {userContext.loggedInUser && mode !== 'loggedOut' &&
                <ContentWrapper>
                    <Error401Image />
                    <MainSection>
                        <h1>{t("pages.unauthenticatedPage.noPermissionsHeader")}</h1>
                        <p>{t("pages.unauthenticatedPage.noPermissionsParagraph")}</p>
                    </MainSection>
                    <BottomRow>
                        <Button>
                            <NavLink to={ROUTES.Home}>
                                {t("buttons.home")}
                            </NavLink>
                        </Button>
                    </BottomRow>
                </ContentWrapper>
            }
        </div>
    )
}