import { useState, useEffect } from 'react';
import { DataGrid, GridColDef, GridRenderCellParams, deDE, enUS } from '@mui/x-data-grid';
import { IconButton, Tooltip } from '@mui/material';
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { IconPdfDownload, IconPdfView } from '../../../../components/Icons';
import { styles } from '../../../../styles';
import { getPaymentTransactionDownloadUrl, useInvoicesAndReceiptQuery } from '../../../../data/payments';
import { PaymentTransaction } from '../../../../types/PaymentTransaction';
import { StateCode } from '../../../../types/StateCode';
import { Loader } from '../../../../components/Loader';
import { MessageWrapper } from '../../../../components/MessageWrapper';

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(8, 1fr);
`;

const StyledSection = styled.section`
    grid-column: span 8;
`;

const StyledH2 = styled.h2`
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 24px;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Separator = styled.div`
  height: 20px;
  width: 1px;
  background-color: ${styles.neutral300};
`;

const MobileRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 12px;

    > div {
        width: 25%;
    }
`;

const MobileHeaderRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    border-bottom: 1px solid ${styles.neutral300};

    div {
        width: 25%;
    }
`;

const MobileHeader = styled.div`
    color: ${styles.textColorSecondary700};
    margin-bottom: 8px;
`;

const MobileData = styled.div`
    margin-bottom: 8px;
`;

const IconsRow = styled.div`
    display: flex;
    justify-content: flex-end;
    border-bottom: 1px solid ${styles.neutral200};
    margin-bottom: 24px;
`;

const StatusPill = styled.div<{ bg: string }>`
    padding-right: 8px;
    padding-left: 8px;
    height: 18px;
    line-height: 18px;
    background: ${(props) => props.bg};
    color: ${styles.textColorSecondary900};
    text-align: center;
    display: inline-block;
    border-radius: 100px;
    font-size: 10px;
`;


type IconsWrapperProps = {
    pdfUrl?: string;
    fileName?: string;
    downloadNotReadyMessage: string;
}

const IconsWrapper: React.FC<IconsWrapperProps> = ({ pdfUrl, fileName, downloadNotReadyMessage }) => {
    return (
        <IconWrapper>
            {pdfUrl &&
                <><a href={`${pdfUrl}/view`} target='_blank' rel="noreferrer">
                    <IconButton>
                        <IconPdfView />
                    </IconButton>
                </a>
                    <Separator />

                    <a href={`${pdfUrl}/download`} download={fileName} >
                        <IconButton>
                            <IconPdfDownload />
                        </IconButton>
                    </a>
                </>
            }
            {!pdfUrl &&
                <>
                    <Tooltip title={downloadNotReadyMessage} style={{ cursor: 'help' }} >
                        <span>
                            <IconButton disabled>
                                <IconPdfView disabled />
                            </IconButton>
                        </span>
                    </Tooltip>
                    <Separator />
                    <Tooltip title={downloadNotReadyMessage} style={{ cursor: 'help' }} >
                        <span>
                            <IconButton disabled>
                                <IconPdfDownload disabled />
                            </IconButton>
                        </span>
                    </Tooltip>
                </>
            }

        </IconWrapper>
    );
};

const useWindowWidth = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowWidth(window.innerWidth);
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        }
    }, []);

    return windowWidth;
}

type PaymentRow = {
    id: string;
    date: string;
    time: string;
    amount: string | number;
    paymentMethod: string;
    paymentNumber?: string;
    downloadUrl?: string;
    downloadFileName?: string;
    statusI18nKey: string;
    statusPillBackground: string;
    issuer: string;
}

const convertPaymentTransactionsToTableRows = (i18nLanguage: string, paymentTransactions: PaymentTransaction[]): PaymentRow[] => {
    const dateFormat = new Intl.DateTimeFormat(i18nLanguage, { dateStyle: 'medium' });
    const timeFormat = new Intl.DateTimeFormat(i18nLanguage, { timeStyle: 'short' });
    return paymentTransactions.map(p => {
        const tableRow: PaymentRow = {
            id: p.id,
            issuer: p.contact ? `${p.contact.firstName} ${p.contact.lastName}` : '-',
            amount: p.amount === parseInt(`${p.amount}`) ? `${p.amount}.—` : p.amount,
            date: dateFormat.format(p.creationDate),
            time: timeFormat.format(p.creationDate),
            downloadUrl: getPaymentTransactionDownloadUrl(p),
            downloadFileName: `payment-${p.id}`,
            paymentMethod: p.paymentMethod,
            paymentNumber: p.invoiceNumber || p.receiptNumber,
            statusI18nKey: p.completionDate ? "Bezahlt": "Unbezahlt",
            statusPillBackground: p.completionDate ? '#E9F6EB': '#DEE9FC'
        }
        return tableRow;
    })
}

export const Payments: React.FC = () => {
    const windowWidth = useWindowWidth();
    const isTabletView = windowWidth <= 834 && windowWidth > 480;
    const isMobileView = windowWidth <= 480;
    const isDesktopView = !isTabletView && !isMobileView;

    const paymentsQuery = useInvoicesAndReceiptQuery();

    const { t, i18n } = useTranslation();

    const columns: GridColDef[] = [];
    columns.push({ field: 'date', headerName: t("pages.balances.date"), sortable: false, disableColumnMenu: true, flex: 1 });
    columns.push({ field: 'time', headerName: t("pages.balances.time"), sortable: false, disableColumnMenu: true, flex: 1 });

    if (isDesktopView) {
        columns.push({ field: 'issuer', headerName: t("pages.balances.issuer"), sortable: false, disableColumnMenu: true, flex: 2.2 });
    }
    if (isDesktopView) {
        columns.push({ field: 'paymentNumber', headerName: t("pages.balances.invoiceNumber"), sortable: false, disableColumnMenu: true, flex: 1.5 });
    }
    columns.push({
        field: 'statusI18nKey',
        headerName: t("pages.balances.status"),
        renderCell: (params: GridRenderCellParams<PaymentRow>) => {
            return <StatusPill bg={params.row.statusPillBackground} aria-details={params.row.id}>{(t as any)(params.row.statusI18nKey)}</StatusPill>;
        },
        disableColumnMenu: true, sortable: false,
        flex: 1.5
    });
    columns.push({ field: 'amount', headerName: t("pages.balances.amountChf"), sortable: false, disableColumnMenu: true, flex: 1.3, align: 'left' });
    //columns.push({ field: 'paymentMethod', headerName: t("pages.balances.paymentMethod"), sortable: false, disableColumnMenu: true, flex: 1.6, align: 'left' });
    
    columns.push({
        field: 'downloadUrl',
        headerName: t("pages.balances.file"),
        sortable: false, disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<PaymentRow>) => (
            <IconsWrapper pdfUrl={params.row.downloadUrl} fileName={params.row.downloadFileName} downloadNotReadyMessage={t('pages.balances.documentNotReadyYet')} />
        ),
        flex: 1
    });

    const payments = convertPaymentTransactionsToTableRows(i18n.language, paymentsQuery.data || []);
    const renderMobileView = () => {
        return (
            <div>
                <MobileHeaderRow>
                    <MobileHeader>{t("pages.balances.date")}</MobileHeader>
                    <MobileHeader>{t("pages.balances.amountChf")}</MobileHeader>
                    <MobileHeader>{t("pages.balances.status")}</MobileHeader>
                </MobileHeaderRow>
                {payments ? payments.map(payment => (
                    <>
                        <MobileRow key={payment.id}>
                            <MobileData>{payment.date}</MobileData>
                            <MobileData>{payment.amount}</MobileData>
                            <MobileData style={{ maxWidth: 86 }}><StatusPill bg={payment.statusPillBackground}>{(t as any)(payment.statusI18nKey)}</StatusPill></MobileData>
                        </MobileRow>
                        <IconsRow><IconsWrapper pdfUrl={payment.downloadUrl} fileName={payment.downloadFileName} downloadNotReadyMessage={t('pages.balances.documentNotReadyYet')}/></IconsRow>
                    </>
                )) : <MessageWrapper messageText={t("components.tables.noEntriesAvailable") as string} messageType="textOnly" height={"420px"} />}
            </div>
        )
    }

    const renderTabletAndDesktopView = () => {
        return (
            <DataGrid
                sx={{
                    '& .MuiDataGrid-row': {
                        borderBottom: `1px solid ${styles.neutral200}`,
                    },
                    '& .MuiDataGrid-cell': {
                        border: 'none'
                    },
                    '& .MuiDataGrid-withBorderColor': {
                        borderColor: `${styles.neutral300}`
                    },
                }}
                initialState={{
                    pagination: {
                        paginationModel: { pageSize: 10, page: 0 },
                    },
                }}
                localeText={(i18n.language === 'de' ? deDE : enUS).components.MuiDataGrid.defaultProps.localeText}
                className="table-grid"
                style={{ border: 'none' }}
                rows={payments}
                columns={columns}
                disableColumnSelector
                hideFooterSelectedRowCount={true}
                rowHeight={72}
            />
        )
    }

    return (
        <Wrapper>
            <StyledSection>
                <StyledH2>{t("pages.balances.overview")}</StyledH2>
                {paymentsQuery.isLoading &&
                    <Loader height={"420px"} messageText={"Loading..."} />
                }
                {paymentsQuery.isError &&
                    <MessageWrapper messageText={paymentsQuery.error?.message} height={"420px"} alignItems="flex-start" />
                }
                {!paymentsQuery.isLoading && !paymentsQuery.isError &&
                    <>{isMobileView ? renderMobileView() : renderTabletAndDesktopView()}</>
                }
            </StyledSection>
        </Wrapper>
    );
};