import { useQuery } from "@tanstack/react-query";
import { PriceConfig } from "../types/PriceConfig";
import { ONE_DAY, convertToErrorMsg, jsonReviverFunction } from "./_base";

export const useEmptyingPricesQuery = () => {
    const query = useQuery<PriceConfig[], Error>({
        queryKey: ['emptyingPrices'],
        refetchOnWindowFocus: true,
        staleTime: ONE_DAY,
        queryFn: () => getEmptyingPrices()
    })
    return query;
}

const getEmptyingPrices = async (): Promise<PriceConfig[]> => {
    const response = await fetch(`/api/emptying-prices`);
    const responseBody = await response.text();
    if (!response.ok) {
        const errorMsg = convertToErrorMsg(response.status, responseBody);
        throw errorMsg;
    } else {
        const responseJson = JSON.parse(responseBody, jsonReviverFunction);
        const priceConfigs = responseJson as PriceConfig[];
        return priceConfigs;
    }
}
