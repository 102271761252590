import styled from "@emotion/styled";
import { LinksSection } from "./components/links-section/LinksSection";
import { ContainerSection } from "./components/container-section/ContainerSection";
import { WelcomeSection } from "./components/welcome-section/WelcomeSection";
import { useTranslation } from "react-i18next";
import { styles } from '../../styles';
import { CreditSection } from "./components/credit-section/CreditSection";
import { EmptyingsOverview } from "./components/empyings-overview/EmptyingsOverview";
import { useContainersQuery } from "../../data/containers";
import { UserContext } from "../../contexts/UserContext";
import { useContext } from "react";
import { Container } from "../../types/Container";
import DummyContainer from "../../components/containers/DummyContainer";
import { NonSelectableContainerCard } from "../../components/containers/NonSelectableContainerCard";
import { HashLink } from "../../components/HashLink";
import { useEffectOnce } from "usehooks-ts";
import { INVITATION_SESSION_STORAGE_KEY } from "../invitation-redemption/InvitationRedemptionPage";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../skeleton/Routes";


const StyledEmptyingsScheduleAndCreditSection = styled.section`
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: 24px;

    @media (max-width: 480px) {
        row-gap: 48px;
        margin-bottom: 0;
    }

    h2  {
        margin-bottom: 24px;
        color:  ${styles.textColorSecondary700};
    }
`;


const EmptyingsScheduleSection = styled.div`
    grid-column: span 5;

    @media (max-width: 700px) {
        grid-column: span 8;
    };
`;

const StyledCreditSection = styled.div`
    @media (max-width: 700px) {
        grid-column: span 8;
        grid-row: 1;
    };

    grid-column: span 3;
`;

const OneContainerCardSection = styled.section`
    margin-bottom: 48px;
`;

export const HomePage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const userContext = useContext(UserContext);
    const containersQuery = useContainersQuery(userContext.accessPermission?.accountRef);
    const containers: Container[] = containersQuery.data || [];

    useEffectOnce(() => {
        const invitationCode = sessionStorage.getItem(INVITATION_SESSION_STORAGE_KEY);
        if (invitationCode) {
            console.log("Invitation Code detected - redirecting to Invitation Redemption Page");
            navigate(ROUTES.InvitationRedemption.replace(':code', invitationCode));
        }
    });

    return (
        <>
            <WelcomeSection />
            { (userContext.accessPermission?.roles?.payment || userContext.accessPermission?.roles?.container || userContext.accessPermission?.roles?.permission) &&
                <LinksSection />
            }
            {containers && containers.length > 1 && <ContainerSection />}                
            <StyledEmptyingsScheduleAndCreditSection>
                <EmptyingsScheduleSection>
                    <HashLink name="emptyings"/>
                    <h2>{ t("pages.home.emptyingsOverviewSection.header") }</h2>
                    <EmptyingsOverview />
                </EmptyingsScheduleSection>
                <StyledCreditSection>
                    {containers && containers.length <= 1 && userContext.accessPermission?.roles?.container && (
                    <OneContainerCardSection>
                        <h2>{t("components.containers.header")}</h2>
                        {containers.length === 0 && <DummyContainer />}
                        {containers.length === 1 && <NonSelectableContainerCard item={containers[0]} cardWidth={379.5} resizable marginRight={'0'}/>}
                    </OneContainerCardSection>)}

                    <CreditSection />
                </StyledCreditSection>
            </StyledEmptyingsScheduleAndCreditSection>
        </>
    )
}