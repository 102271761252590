import { useTranslation } from "react-i18next";
import styled from '@emotion/styled';
import { FormControl, FormControlLabel, Checkbox } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { IconClose, IconContainerLarge, IconQuestionCircleLineSmall } from "../../../components/Icons";
import Button from '../../../components/Button';
import { styles } from '../../../styles';
import { Container } from "../../../types/Container";
import { useEffect, useState } from "react";
import { RecurrenceDefinition, RecurrenceInterval } from "../../../types/Emptying";
import { EmptyingsSchedule } from "../EmptyingSchedulingPage";
import { useEmptyingPricesQuery } from "../../../data/prices";
import { DayOfWeek } from "../../../types/RemovalArea";
import { WeekdayChooser } from "./WeekdayChooser";
import { IntervalChooser } from "./IntervalChooser";
import { DatePeriod, DatePeriodsChooser } from "./DatePeriodsChooser";
import { EmptyingScheduleSummary } from "../EmptyingScheduleSummary";
import { ONE_DAY } from "../../../data/_base";

type SeriesPlannerToolProps = {
    container: Container;
    onCancel: () => void;
    onSaveSchedules: (schedules: EmptyingsSchedule[]) => void;
    existingSchedules: EmptyingsSchedule[];
}

const SeriesPlannerToolWrapper = styled.div`
    max-height: 80vh;
    overflow-y: hidden;

    @media (max-width: 480px) {
        max-height: 100vh;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
`;

const ContentWrapper = styled.div`
    overflow-y: auto;
    position: relative;
    max-height: 80vh;
    padding-right: 24px;

    @media (max-width: 480px) {
        max-height: 100vh;
        height: 100%;
    }
`;

const ModalHeading = styled.h2`
    color: ${styles.textColorSecondary700};
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #fff;
    padding-top: 24px;
    padding-bottom: 24px;
    padding-right: 24px;
    border-radius: 8px;
    margin-bottom: 0;

    svg {
        cursor: pointer;
    }
`;

const ContainerInfo = styled.div<{ color: string }>`
    width: calc(50% - 12px);
    min-width: 208px;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: 12px;
    padding: 16px 24px;
    border: 1px solid ${styles.neutral200};
    border-radius: 8px;

    svg {
        width: 48px;
        height: 48px;
        path {
            fill: ${props => props.color};
        }
        path:last-child {
            stroke: ${props => props.color};
        }
    }

    h4 {
        font-size: 14px;
        line-height: 22px;
    }

    p {
        font-size: 10px;
        line-height: 18px;
    }

    @media (max-width: 480px) {
        h4,
        p {
            font-size: 12px;
        }
    }
`;

const Row = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    color: ${styles.textColorSecondary700};
`;


const StyledH3 = styled.h3`
    display: flex;
    gap: 10px;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 8px;

    svg {
        cursor: pointer;
    }
`;

const BottomRow = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    margin-top: 12px;
    margin-bottom: 68px;

    button {
        width: 156px;
    }

    @media (max-width: 480px) {
        flex-direction: column;
        margin-top: 12px;
        margin-bottom: 0;
        gap: 12px;

        button {
            width: 100%;
            margin-top: 0;
            margin-bottom: 0;
        }
    }
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
    height: 28px;
    input {
        height: 28px;
        width: 28px;
        box-sizing: border-box;
    }

    span {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        font-family: 'Satoshi Regular';
        font-size: 14px;
    }
`;

const ContainerShortName = styled.span`
    font-family: 'Satoshi Bold';
    color: ${styles.textColorSecondary700};
    position: absolute;
    top: 15px;
    right: 13px;
    width: 22px;
    text-align: center;
`;

const IconWrapper = styled.div`
    display: flex;
`;

const OpacityWrapper = styled.div<{ isDimmed?: boolean }>`
    opacity: ${({ isDimmed }) => (isDimmed ? 0.4 : 1)};
`;

export const StyledCheckbox = styled(Checkbox)`
  &.MuiCheckbox-root {
    color: #E0E1E0;
    
    &.Mui-checked {
      color: #33AA47;
    }
  }
`;

const shortenTo12Chars = (s: string) => {
    return (s && s.length > 12 ? s.substring(0, 12) + "..." : s);
}

export const SeriesPlannerTool: React.FC<SeriesPlannerToolProps> = ({ container, onCancel, onSaveSchedules, existingSchedules }) => {
    const priceConfigsQuery = useEmptyingPricesQuery();
    const { t } = useTranslation();

    const existingSchedulesForSelectedContainer = existingSchedules.filter(e => e.container.id === container.id);
    const [selectedWeekdays, setSelectedWeekdays] = useState<DayOfWeek[]>(container.removalArea?.weekdays.length === 1 ? [container.removalArea.weekdays[0]]: []);
    const [selectedInterval, setSelectedInterval] = useState<RecurrenceInterval>();
    const [datePeriods, setDatePeriods] = useState<DatePeriod[]>([]);
    const [useSettingsFromPreviousContainer, setUseSettingsFromPreviousContainer] = useState(false);
    const [isReloading, setIsReloading] = useState(false);

    useEffect(() => {
        if (existingSchedules.length > 0) {
            const existingSchedulesForSelectedContainer = existingSchedules.filter(e => e.container.id === container.id);
            const schedulesToLoad = useSettingsFromPreviousContainer && existingSchedulesForSelectedContainer.length === 0
                ? existingSchedules.filter(s => s.container.id === existingSchedules[existingSchedules.length - 1].container.id)
                : existingSchedulesForSelectedContainer;
            const recurrences = schedulesToLoad.map(s => s.recurrence).filter(r => r !== undefined) as RecurrenceDefinition[];
            if (recurrences.length > 0) {
                setSelectedInterval(recurrences[0].interval);
                setSelectedWeekdays(recurrences[0].weekdays);
                setDatePeriods(recurrences.map((r, i) => {
                    const datePeriod: DatePeriod = {
                        id: i,
                        startDate: r.startDate,
                        endDate: r.endDate,
                        schedulableDates: [],
                        type: r.type
                    }
                    return datePeriod;
                }))
                setIsReloading(true);
            }
        }
    }, [useSettingsFromPreviousContainer, existingSchedules, container])

    useEffect(() => {
        if (isReloading) {
            setTimeout(() => { setIsReloading(false)}, 10);
        }
    }, [isReloading]);

    const buildEmptyingSchedules = () => {
        if (!selectedInterval) return [];
        return datePeriods.map(datePeriod => {
            const minDaysBetweenSchedules = selectedInterval === RecurrenceInterval.BiWeekly ? 14 : selectedInterval === RecurrenceInterval.FourWeekly ? 28 : 7;

            let dateToSchedule: Date[] = [];
            selectedWeekdays.forEach(weekday => {
                const first = datePeriod.schedulableDates.find(d => d.getDay() === weekday);
                if (first) {
                    dateToSchedule.push(first);
                    let minNextDate = new Date(first.getTime() + minDaysBetweenSchedules * ONE_DAY);
                    for (let i = 0; i < datePeriod.schedulableDates.length; i++) {
                        const date = datePeriod.schedulableDates[i];
                        if (minNextDate.getTime() <= date.getTime()) {
                            minNextDate = new Date(minNextDate.getTime() + minDaysBetweenSchedules * ONE_DAY);
                            const alreadyAdded = dateToSchedule.find(d => d.getTime() === date.getTime()) !== undefined;
                            const isCorrectWeekday = date.getDay() === weekday;
                            const isExceptionalOneWeekdayLater = selectedWeekdays.find(w => w === date.getDay()) === undefined && date.getDay() === weekday + 1;
                            if (!alreadyAdded && (isCorrectWeekday || isExceptionalOneWeekdayLater)) {
                                dateToSchedule.push(date);
                            }
                        }
                    }
                }
            })

            const schedule: EmptyingsSchedule = {
                container: container,
                emptyings: dateToSchedule.map(date => {
                    const priceConfig = (priceConfigsQuery.data || []).find(cfg => cfg.endDate.getTime() >= date.getTime() && cfg.startDate.getTime() <= date.getTime())
                    return priceConfig ? {
                        date: date,
                        price: priceConfig.price
                    } : undefined;
                }).filter(e => e !== undefined) as { date: Date, price: number }[],
                recurrence: {
                    startDate: datePeriod.startDate,
                    endDate: datePeriod.endDate,
                    interval: selectedInterval,
                    weekdays: selectedWeekdays,
                    type: datePeriod.type
                }
            }
            return schedule;
        });
    }

    const onSave = () => {
        const emptyingSchedules = buildEmptyingSchedules();
        onSaveSchedules(emptyingSchedules);
    }

    const emptyingSchedules = buildEmptyingSchedules();

    const sumUp = (prev: number, next: number) => (prev || 0) + next;
       
    const schedulesWithEmptyings = emptyingSchedules.filter(s => s.emptyings.length > 0);
    const nofEmptyings = schedulesWithEmptyings.length > 0 ? Math.floor(schedulesWithEmptyings.map(s => s.emptyings.length).reduce(sumUp) * 100) / 100 : 0;
    console.log("schedulesWithEmptyings", schedulesWithEmptyings)
    const totalCost = schedulesWithEmptyings.length > 0 ? Math.floor(schedulesWithEmptyings.map(s => s.emptyings.map(e => e.price).reduce(sumUp)).reduce(sumUp) * 100) / 100 : 0;

    if (existingSchedulesForSelectedContainer.length > 0 && datePeriods.length === 0) return null;
    if (isReloading) return null;

    return (
        <SeriesPlannerToolWrapper>
            <ModalHeading>{t("pages.emptying.series.modalPlanSerialEmptying.header")}<div onClick={onCancel}><IconClose /></div></ModalHeading>
            <ContentWrapper>
                <Row style={{ marginTop: '24px' }}>
                    <StyledH3>{t("pages.emptying.series.modalPlanSerialEmptying.container")}</StyledH3>
                    <ContainerInfo color={container.color}>
                        <div style={{ position: 'relative' }}>
                            <IconContainerLarge />
                            <ContainerShortName>{container.shortName}</ContainerShortName>
                        </div>
                        <div>
                            <h4>{shortenTo12Chars(container.displayName)}</h4>
                            <p>ID-Nr. {container.awmId}</p>
                        </div>
                    </ContainerInfo>
                </Row>
                {existingSchedules.length > 0 && existingSchedulesForSelectedContainer.length === 0 &&
                    <Row>
                        <StyledFormControlLabel control={<StyledCheckbox checked={useSettingsFromPreviousContainer} onChange={() => setUseSettingsFromPreviousContainer(!useSettingsFromPreviousContainer)} />} label={t("pages.emptying.series.modalPlanSerialEmptying.inheritInputFromPreviousContainer")} />
                    </Row>
                }
                {container.removalArea &&
                    <>
                        <OpacityWrapper isDimmed={useSettingsFromPreviousContainer}>
                            <Row>
                                <StyledH3>
                                    {t("pages.emptying.series.modalPlanSerialEmptying.weekday")}
                                    <Tooltip arrow componentsProps={{
                                        tooltip: {
                                            sx: {
                                                bgcolor: '#26374A',
                                                '& .MuiTooltip-arrow': {
                                                    color: '#26374A',
                                                },
                                            },
                                        },
                                    }} title={t("pages.emptying.series.modalPlanSerialEmptying.weekdayTooltip")}>
                                        <IconWrapper>
                                            <IconQuestionCircleLineSmall />
                                        </IconWrapper>
                                    </Tooltip>
                                </StyledH3>

                                <WeekdayChooser removalArea={container.removalArea} onChange={setSelectedWeekdays} values={selectedWeekdays} useSettingsFromPreviousContainer={useSettingsFromPreviousContainer} />
                            </Row>
                            <Row>
                                <StyledH3>{t("pages.emptying.series.modalPlanSerialEmptying.rhythm")}</StyledH3>
                                <FormControl component="fieldset">
                                    <IntervalChooser onChange={setSelectedInterval} value={selectedInterval} useSettingsFromPreviousContainer={useSettingsFromPreviousContainer} />
                                </FormControl>
                            </Row>
                            <Row style={{ marginBottom: 0 }}>
                                <StyledH3>
                                    {t("pages.emptying.series.modalPlanSerialEmptying.period")}
                                    <Tooltip arrow componentsProps={{
                                        tooltip: {
                                            sx: {
                                                bgcolor: '#26374A',
                                                '& .MuiTooltip-arrow': {
                                                    color: '#26374A',
                                                },
                                            },
                                        },
                                    }} title={t("pages.emptying.series.modalPlanSerialEmptying.periodTooltip")}>
                                        <IconWrapper>
                                            <IconQuestionCircleLineSmall />
                                        </IconWrapper>
                                    </Tooltip>
                                </StyledH3>
                                {container &&
                                    <DatePeriodsChooser useSettingsFromPreviousContainer={useSettingsFromPreviousContainer} onChange={setDatePeriods} initialValue={datePeriods} container={container} />
                                }
                            </Row>
                        </OpacityWrapper>
                        <div>
                            <EmptyingScheduleSummary hasConfiguredSchedules={schedulesWithEmptyings.length > 0} nofEmptyings={nofEmptyings} totalCost={totalCost} />
                            <BottomRow>
                                <Button buttonVariant="ghost" onClick={onCancel}>
                                    {t("buttons.abort")}
                                </Button>
                                <Button onClick={onSave} disabled={datePeriods.length === 0 || !selectedInterval || selectedWeekdays.length === 0 || nofEmptyings === 0}>
                                    {t("buttons.plan")}
                                </Button>
                            </BottomRow>
                        </div>
                    </>
                }
            </ContentWrapper>
        </SeriesPlannerToolWrapper>
    )
}