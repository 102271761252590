import styled from "@emotion/styled";
import { IconCircleCheck, IconExclamationMark, IconInfo } from "./Icons";
import { styles } from "../styles";


type MessageWrapperProps = {
    messageText: string | undefined | JSX.Element,
    height?: string;
    margin?: string;
    padding?: string;
    width?: string;
    messageType?: 'errorMessage' | 'userMessage' | 'successMessage' | 'textOnly';
    justifyContent?: string;
    svgMargin?: string;
    fontSize?: string;
    alignItems?: string;
    lineHeight?: string;
    textMargin?: string;
}

type StyledMessageWrapperProps = {
    height?: string,
    margin?: string, 
    padding?: string, 
    width?: string, 
    justifyContent?: string, 
    alignItems?: string,
    lineHeight?: string, 
}

const StyledErrorMessageWrapper = styled.div<StyledMessageWrapperProps>`
    width:  ${props => props.width || "100%"};
    height: ${props => props.height || "initial"};
    display: flex;
    align-items: ${props => props.alignItems || "center"};
    justify-content: ${props => props.justifyContent || "center"};
    margin: ${props => props.margin || 0};
    padding: ${props => props.padding || 0};
`;

const MessageTextContainer = styled.div<{ svgMargin?: string, fontSize?: string, messageType?: string, lineHeight?: string, textMargin?: string }>`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    color: ${styles.textColorSecondary700};
    font-size: ${props => props.fontSize || "14px"};
    line-height: ${props => props.lineHeight || "22px"};

    svg {
        min-width: 24px;
        margin: ${props => props.svgMargin || "12px 12px 12px 0"};

        path, circle {
            stroke: ${props => props.messageType === 'successMessage' ? '#33AA47' : "auto"};
        }
    }

    span {
        margin-top: 10px;
        margin: ${props => props.textMargin || "initial"};
    }
`;

export const MessageWrapper: React.FC<MessageWrapperProps> = ({
    messageText,
    height,
    margin,
    padding,
    width,
    messageType = 'errorMessage',
    justifyContent,
    alignItems,
    svgMargin,
    fontSize,
    lineHeight,
    textMargin
}) => {
    return (
        <StyledErrorMessageWrapper height={height} margin={margin} padding={padding} width={width} justifyContent={justifyContent} alignItems={alignItems} lineHeight={lineHeight}>
           <MessageTextContainer svgMargin={svgMargin} fontSize={fontSize} messageType={messageType} lineHeight={lineHeight} textMargin={textMargin}>
                {
                    messageType === 'errorMessage' ? <IconExclamationMark />
                        : messageType === 'successMessage' ? <IconCircleCheck />
                            : messageType === 'userMessage' ? <IconInfo />
                                : null
                }
                <span style={{display: 'inline-block', margin: '12px'}}>
                    {messageType === 'errorMessage'
                        ? (typeof messageText === 'string'
                            ? `${messageText || 'Unknown Error'}`
                            : messageText || 'Unknown Error')
                        : messageText}
                </span>
            </MessageTextContainer>
        </StyledErrorMessageWrapper>
    )
}