import { useMutation, useQuery } from "@tanstack/react-query";
import { Account, AccountDataChangeRequest, AccountRef } from "../types/Account";
import { convertToErrorMsg, jsonReviverFunction } from "./_base";
import { useContext } from "react";
import { UserContext } from "../contexts/UserContext";


export const ACCOUNT_BY_ID_QUERY_KEY = "accountById";

export const useAccountQuery = (accountId: string | undefined) => {
    const query = useQuery<Account | null, Error>({
        queryKey: [ACCOUNT_BY_ID_QUERY_KEY, accountId],
        refetchOnWindowFocus: true,
        staleTime: 30000,
        queryFn: () => getAccountById(accountId)
    })
    return query;
} 

const getAccountById = async (accountId: string |undefined): Promise<Account | null> => {
    if (!accountId) { return null;}
    const response = await fetch(`/api/me/accounts/${accountId}`);
    const responseBody = await response.text();
    if (!response.ok) {
        const errorMsg = convertToErrorMsg(response.status, responseBody);
        throw errorMsg;
    } else {
        const responseJson = JSON.parse(responseBody, jsonReviverFunction);
        const account = responseJson as Account;
        return account;
    }
}


export const usePostAccountDataChangeRequestMutation = () => {
    const userContext = useContext(UserContext);
    const mutation = useMutation<void, Error, AccountDataChangeRequest>({
        mutationFn: (changeRequest: AccountDataChangeRequest) => postChangeRequest(changeRequest, userContext.accessPermission?.accountRef),
    })
    return mutation;
}

const postChangeRequest = async (changeRequest: AccountDataChangeRequest, accountRef?: AccountRef): Promise<void> => {
    if (!accountRef) return;
    const response = await fetch(`/api/me/accounts/${accountRef.id}/data-change`, {
        method: 'post',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(changeRequest)
    });
    const responseBody = await response.text();
    if (!response.ok) {
        const errorMsg = convertToErrorMsg(response.status, responseBody);
        throw errorMsg;
    } 
}