import { useMutation } from "@tanstack/react-query";
import { convertToErrorMsg } from "./_base";
import { AccountRef } from "../types/Account";
import { useContext } from "react";
import { UserContext } from "../contexts/UserContext";
import { PostableContactForm } from "../types/ContactForm";

export const usePostContactFormMutation = () => {
    const userContext = useContext(UserContext);
    const mutation = useMutation<void, Error, PostableContactForm>({
        mutationFn: (contactForm: PostableContactForm) => postContactForm(contactForm, userContext.accessPermission?.accountRef),
    })
    return mutation;
}

const postContactForm = async (contactForm: PostableContactForm, accountRef?: AccountRef): Promise<void> => {
    if (!accountRef) return;
    const response = await fetch(`/api/me/accounts/${accountRef.id}/contact-forms`, {
        method: 'post',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(contactForm)
    });
    const responseBody = await response.text();
    if (!response.ok) {
        const errorMsg = convertToErrorMsg(response.status, responseBody);
        throw errorMsg;
    } 
}