import { Contact } from "./Contact";
import { PaymentMethod } from "./PaymentMethod";
import { StateCode } from "./StateCode";

export type PaymentTransaction = {
    id: string;
    displayName: string;
    accountId: string;
    contactId: string;
    amount: number;
    paymentMethod: PaymentMethod;
    completionDate?: Date;
    creationDate: Date;
    invoiceNumber?: string;
    receiptNumber?: string;
    stateCode: StateCode;
    contact: Contact;
    hasDownloadableInvoice: boolean;
    hasDownloadableReceipt: boolean;
}

export enum PaymentTransactionProcess {
    Schedule = 125160000,
    TopUp = 125160001
}