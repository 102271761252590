import { useState, useEffect, useContext } from 'react';
import { DataGrid, GridColDef, GridRenderCellParams, deDE, enUS  } from '@mui/x-data-grid';
import { IconButton } from '@mui/material';
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { styles } from "../../../../styles";
import { IconPdfDownload, IconPdfView } from '../../../../components/Icons';
import { getEmptyingDownloadUrl, useEmptyingConfirmationsQuery } from '../../../../data/emptyings';
import { ONE_YEAR } from '../../../../data/_base';
import { useContainersQuery } from '../../../../data/containers';
import { UserContext } from '../../../../contexts/UserContext';
import { Emptying, EmptyingStatusI18nKey, EmptyingStatusReason } from '../../../../types/Emptying';
import { Container, ContainerRef } from '../../../../types/Container';
import { ContainerDropdownSelector } from '../../../../components/containers/ContainerDropdownSelector';
import { Loader } from '../../../../components/Loader';
import { MessageWrapper } from '../../../../components/MessageWrapper';

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(8, 1fr);
`;

const StyledSection = styled.section`
    grid-column: span 8;
`;

const StyledH2 = styled.h2`
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 32px;
`;

const StatusPill = styled.div<{ bg: string }>`
    padding-right: 8px;
    padding-left: 8px;
    height: 18px;
    line-height: 18px;
    background: ${(props) => props.bg};
    color: ${styles.textColorSecondary900};
    text-align: center;
    display: inline-block;
    border-radius: 100px;
    font-size: 10px;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Separator = styled.div`
  height: 20px;
  width: 1px;
  background-color: ${styles.neutral300};
`;

const MobileRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 12px;

    > div {
        width: 25%;
    }
`;

const MobileHeaderRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    border-bottom: 1px solid ${styles.neutral300};

    div {
        width: 25%;
    }
`;

const MobileHeader = styled.div`
    color: ${styles.textColorSecondary700};
    margin-bottom: 8px;
`;

const MobileData = styled.div`
    margin-bottom: 8px;
`;

const IconsRow = styled.div`
    display: flex;
    justify-content: flex-end;
    border-bottom: 1px solid ${styles.neutral200};
    margin-bottom: 24px;
`;


type IconsWrapperProps = {
    pdfUrl: string;
    fileName: string;
}

const IconsWrapper: React.FC<IconsWrapperProps> = ({ pdfUrl, fileName }) => {
    return (
        <IconWrapper>
            <a href={pdfUrl} target='_blank' rel="noreferrer">
                <IconButton>
                    <IconPdfView />
                </IconButton>
            </a>

            <Separator />

            <a href={pdfUrl} download={fileName} >
                <IconButton>
                    <IconPdfDownload />
                </IconButton>
            </a>

        </IconWrapper>
    );
};

const useWindowWidth = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowWidth(window.innerWidth);
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        }
    }, []);

    return windowWidth;
}

type EmptyingConfirmation = {
    id: string;
    date: string;
    time: string;
    containerName: string;
    containerCode: string;
    awmContainerId: string;
    statusI18nKey: string;
    statusPillBackground: string;
    price: string | number;
    downloadUrl?: string;
    downloadFileName?: string;
}

const convertEmptyingsToConfirmations = (i18nLanguage: string, emptyings: Emptying[], containers: Container[]): EmptyingConfirmation[] => {
    const dateFormat = new Intl.DateTimeFormat(i18nLanguage, { dateStyle: 'medium' });
    const timeFormat = new Intl.DateTimeFormat(i18nLanguage, { timeStyle: 'short' });
    const confirmations: EmptyingConfirmation[] = emptyings.map(emptying => {
        const container = containers.find(c => c.id === emptying.containerId);
        const confirmation: EmptyingConfirmation = {
            id: emptying.id,
            statusI18nKey: `pills.${EmptyingStatusI18nKey[emptying.statusReason]}`.toLowerCase(),
            statusPillBackground: emptying.statusReason === EmptyingStatusReason.Completed ? '#E9F6EB': '#DEE9FC',
            date: emptying.awmResponse ? dateFormat.format(emptying.awmResponse) : '-',
            time: emptying.awmResponse ? timeFormat.format(emptying.awmResponse) : '-',
            containerCode: container ? container.shortName : '-',
            awmContainerId: container ? container.awmId: '-',
            containerName: container ? container.displayName : emptying.containerId,
            downloadUrl: container ? getEmptyingDownloadUrl(emptying, container) : undefined,
            downloadFileName: `emptying-${emptying.id}`,
            price: emptying.price === parseInt(`${emptying.price}`) ? `${emptying.price}.—`: emptying.price
        }
        return confirmation;
    });
    return confirmations;
}

export const EmptyingConfirmations: React.FC = () => {
    const windowWidth = useWindowWidth();
    const isTabletView = windowWidth <= 834 && windowWidth > 480;
    const isMobileView = windowWidth <= 480;
    const isDesktopView = !isTabletView && !isMobileView;

    const [selectedContainer, setSelectedContainer] = useState<ContainerRef>();
    const userContext = useContext(UserContext);
    const containersQuery = useContainersQuery(userContext.accessPermission?.accountRef);
    const emptyingConfirmationsQuery = useEmptyingConfirmationsQuery(new Date(new Date().getTime() - ONE_YEAR), new Date(), selectedContainer);

    const { t, i18n } = useTranslation();

    const columns: GridColDef[] = [];
    columns.push({ field: 'date', headerName: t("pages.balances.date"), disableColumnMenu: true, sortable: false, flex: 1 });
    columns.push({ field: 'time', headerName: t("pages.balances.time"), disableColumnMenu: true, sortable: false, flex: 1 });
    columns.push({ field: 'containerName', headerName: t("pages.balances.name"), disableColumnMenu: true, sortable: false, flex: 2 });
    if (isDesktopView) {
        columns.push({ field: 'containerCode', headerName: t("pages.balances.code"), disableColumnMenu: true, sortable: false, flex: 0.6 });
        columns.push({ field: 'awmContainerId', headerName: t("pages.balances.id"), disableColumnMenu: true, sortable: false, flex: 1.6 });
    }

    columns.push({
        field: 'statusI18nKey',
        headerName: t("pages.balances.status"),
        renderCell: (params: GridRenderCellParams<EmptyingConfirmation>) => {
            return <StatusPill bg={params.row.statusPillBackground} aria-details={params.row.id}>{(t as any)(params.row.statusI18nKey)}</StatusPill>;
        },
        disableColumnMenu: true, sortable: false,
        flex: 1.3
    });
    if (isDesktopView) {
        columns.push({ field: 'price', headerName: t("pages.balances.amountChf"), disableColumnMenu: true, sortable: false, align: 'left', flex: 1 });
    }
    columns.push({
        field: 'downloadUrl',
        headerName: t("pages.balances.file"),
        renderCell: (params: GridRenderCellParams<EmptyingConfirmation>) => (
            (params.row.downloadUrl && params.row.downloadFileName) ? <IconsWrapper pdfUrl={params.row.downloadUrl} fileName={params.row.downloadFileName} /> : <></>
        ),
        disableColumnMenu: true, sortable: false,
        flex: 1
    });

    const confirmations = convertEmptyingsToConfirmations(i18n.language, emptyingConfirmationsQuery.data || [], containersQuery.data || []);

    const renderMobileView = () => {
        return (
            <div>
                <MobileHeaderRow>
                    <MobileHeader>{t("pages.balances.date")}</MobileHeader>
                    <MobileHeader>{t("pages.balances.time")}</MobileHeader>
                    <MobileHeader>{t("pages.balances.name")}</MobileHeader>
                    <MobileHeader style={{ maxWidth: 86 }}>{t("pages.balances.status")}</MobileHeader>
                </MobileHeaderRow>
                {confirmations ? confirmations.map(confirmation => {
                    return (
                        <>
                            <MobileRow key={confirmation.id}>
                                <MobileData>{confirmation.date}</MobileData>
                                <MobileData>{confirmation.date}</MobileData>
                                <MobileData>{confirmation.containerName}</MobileData>
                                <MobileData style={{ maxWidth: 86 }}><StatusPill bg={confirmation.statusPillBackground}>{(t as any)(confirmation.statusI18nKey)}</StatusPill></MobileData>
                            </MobileRow>
                            {confirmation.downloadUrl && confirmation.downloadFileName &&
                                <IconsRow><IconsWrapper pdfUrl={confirmation.downloadUrl} fileName={confirmation.downloadFileName} /></IconsRow>
                            }
                        </>
                    )
                }) : <MessageWrapper messageText={t("components.tables.noEntriesAvailable") as string} messageType="textOnly" height={"300px"} />}
            </div>
        )
    }

    const renderTabletAndDesktopView = () => {
        return (
            <DataGrid
                sx={{
                    '& .MuiDataGrid-row': {
                        borderBottom: `1px solid ${styles.neutral200}`,
                    },
                    '& .MuiDataGrid-cell': {
                        border: 'none'
                    },
                    '& .MuiDataGrid-withBorderColor': {
                        borderColor: `${styles.neutral300}`
                    },
                }}
                initialState={{
                    pagination: {
                        paginationModel: { pageSize: 25, page: 0 },
                    },
                }}
                localeText={(i18n.language === 'de' ? deDE : enUS).components.MuiDataGrid.defaultProps.localeText}
                className="table-grid"
                style={{ border: 'none' }}
                rows={confirmations}
                columns={columns}
                disableColumnSelector
                hideFooterPagination={false}
                pageSizeOptions={[10, 25, 100]}
                paginationMode='client'
                hideFooterSelectedRowCount={true}
                rowHeight={72}
            />
        )
    }
    return (
        <Wrapper>
            <StyledSection>
                <StyledH2>{t("pages.balances.overview")}</StyledH2>

                <div style={{marginBottom: '16px'}}>
                    <ContainerDropdownSelector initialContainer={undefined} onChange={setSelectedContainer} />
                </div>
                
                {(emptyingConfirmationsQuery.isLoading || containersQuery.isLoading) &&
                    // RESOLVED: make loader nice :)
                    <Loader height={"300px"} messageText={t("components.loaders.loading")} />
                }
                {(emptyingConfirmationsQuery.isError) &&
                    // RESOLVED: display error message
                    <MessageWrapper height={"300px"} messageText={emptyingConfirmationsQuery.error?.message} />
                }
                {(containersQuery.isError) &&
                    // RESOLVED: display error message
                    <MessageWrapper height={"300px"} messageText={containersQuery.error?.message} />
                }

                {emptyingConfirmationsQuery.data && containersQuery.data &&
                    <>{isMobileView ? renderMobileView() : renderTabletAndDesktopView()}</>
                }
            </StyledSection>
        </Wrapper>
    );
};