import { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from "react-i18next";
import { IconChevronLeft, IconChevronRight } from '../Icons';
import { NonSelectableContainerCard } from './NonSelectableContainerCard';
import { SelectableContainerCard } from './SelectableContainerCard';
import { Container } from '../../types/Container';
import { styles } from '../../styles';
import { ContainersLoader } from './ContainersLoader';
import DummyContainer from './DummyContainer';


type CarouselProps = {
    containers: Container[];
    selectionMode: 'none' | 'single' | 'multi';
    cardWidth: number;
    selectedByDefault: Container[];
    onChange?: (selectedContainers: Container[]) => void;
    header?: string;
    isLoading?: boolean
};

const StyledCarousel = styled.div`
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
`;

const TopRow = styled.div`
    display: flex;
    align-items: baseline;
`;

const CarouselControls = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
    gap: 8px;
    margin-bottom: 24px;

    @media (max-width: 480px) {
        margin-bottom: 12px;
    }
`;

const ControlButton = styled.button<{ disabled?: boolean }>`
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${props => props.disabled ? styles.neutral200 : '#fff'};
    border: 1px solid ${styles.neutral200};
    border-radius: 100px;
    cursor: pointer;

    svg {
        path {
            fill: ${props => props.disabled ? '#fff' : 'initial'};
        }
    }
`;

const CarouselWrapper = styled.div<{ left: number; }>`
    display: flex;
    position: relative;
    left: ${props => -(props.left)}px;
    transition: left 0.5s;
    margin-bottom: 8px;
    margin-left: 4px;
`;

const StyledH3 = styled.h3`
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 8px;
    width: 100%;
`;

const SelectedContainerInfo = styled.p`
    color: ${styles.textColorSecondary700};
    margin-top: 12px;
    margin-left: 4px;
`;

const ContainerCarousel = ({ containers, cardWidth, selectionMode, selectedByDefault, onChange, header, isLoading}: CarouselProps) => {
    const [startX, setStartX] = useState(0);
    const [endX, setEndX] = useState(0);
    const containersCardRightMargin = 24;
    const itemWidth = cardWidth + containersCardRightMargin; 
    const [currentIndex, setCurrentIndex] = useState(0);
    const [selectedContainers, setSelectedContainers] = useState<Container[]>(selectedByDefault);
    const carouselRef = useRef<HTMLDivElement>(null);
    const [nofFullyVisibleContainers, setNofFullyVisibleContainers] = useState<number>(0);
    const { t } = useTranslation();

    const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
        setStartX(e.touches[0].clientX);
    };
    
    const handleTouchEnd = () => {
        if (startX - endX > 50) {
            // Swiped left
            handleNext();
        } else if (endX - startX > 50) {
            // Swiped right
            handlePrev();
        }
    };

    const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
        setEndX(e.touches[0].clientX);
    };

    const handleContainerClick = (container: Container) => {
        if (selectionMode === 'none') return;
        if (selectionMode === 'single') {
            setSelectedContainers([container]);
        } 
        if (selectionMode === 'multi') {
            setSelectedContainers(prevContainers => {
                const isAlreadySelected = prevContainers.find(prevContainer => prevContainer.id === container.id);
                if (isAlreadySelected) {
                    return prevContainers.filter(prevContainer => prevContainer.id !== container.id);
                } else {
                    return [...prevContainers, container];
                }
            });
        }
    };

    useEffect(() => {
        const calcVisibleContainers = () => {
            const nofFullyVisibleContainersNew =
                Math.min(
                    containers.length,
                    Math.floor(((carouselRef.current?.clientWidth || 0) + containersCardRightMargin) / itemWidth)
                );
            setNofFullyVisibleContainers(nofFullyVisibleContainersNew);
            if (currentIndex + nofFullyVisibleContainersNew > containers.length) {
                setCurrentIndex(containers.length - nofFullyVisibleContainersNew);
            }
        };
        const resizeHandler = calcVisibleContainers;
        window.addEventListener('resize', resizeHandler);
        calcVisibleContainers();
        return () => {
            window.removeEventListener('resize', resizeHandler);
        }
    }, [containers, currentIndex, containersCardRightMargin, itemWidth]);

    useEffect(() => {
        if (onChange) {
            onChange(selectedContainers);
        }
    }, [onChange, selectedContainers]);

    const handleNext = (event?: React.MouseEvent<HTMLButtonElement>) => {
        event?.preventDefault();
        if (containers && containers.length > 0) {
            if ((currentIndex + nofFullyVisibleContainers) < containers.length) {
                setCurrentIndex((currentIndex + 1) % containers.length);
            }
        }
    };

    const handlePrev = (event?: React.MouseEvent<HTMLButtonElement>) => {
        event?.preventDefault();
        if (containers && containers.length > 0) {
            if (currentIndex > 0) {
                setCurrentIndex((currentIndex - 1 + containers.length) % containers.length);
            }
        }
    };

    return (
        <StyledCarousel>
            <TopRow>
                {header ? <StyledH3>{header}</StyledH3> : <h2>{t("components.containers.header")}</h2>}
                <CarouselControls>
                    { nofFullyVisibleContainers < containers.length && 
                        <>
                        <ControlButton  disabled={currentIndex === 0} onClick={handlePrev}><IconChevronLeft /></ControlButton>
                        <ControlButton disabled={(currentIndex + nofFullyVisibleContainers) >= containers.length} onClick={handleNext}><IconChevronRight /></ControlButton>
                        </>
                    }
                </CarouselControls>
            </TopRow>

            {isLoading && <ContainersLoader cardWidth={cardWidth} />}

            {!isLoading && containers.length === 0 &&
                // RESOLVED: show nice content when no container is available.
                // I am showing the DummyContainer in its original form.
                <DummyContainer />
            }

            {!isLoading && containers.length > 0 && 
            <CarouselWrapper ref={carouselRef} left={currentIndex * itemWidth} onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd}>
                {containers.map((container: Container, index: number) => (
                    <div style={{width: itemWidth}} key={index}>
                        {selectionMode === 'none' ? 
                            <NonSelectableContainerCard item={container} cardWidth={cardWidth} />
                            :
                            <SelectableContainerCard 
                                item={container} 
                                onClick={() => handleContainerClick(container)} 
                                selected={selectedContainers.some(selectedContainer => selectedContainer.id === container.id)}
                            /> 
                        }
                    </div>
                ))}
            </CarouselWrapper>
            }
            {selectedContainers.length > 0 && <SelectedContainerInfo>{t("pages.emptying.selection")} {selectedContainers.map(container => container.displayName).join(', ')}</SelectedContainerInfo>}
        </StyledCarousel>
    );
};

export default ContainerCarousel;