import { useContext, useState, useEffect } from 'react';
import { UserContext } from '../contexts/UserContext';
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
import styled from '@emotion/styled';
import KVALogo from '../components/KVALogo';
import { LoginLogout } from "../components/LoginLogout";
import { IconHome, IconHomeActive, IconBalances, IconBalancesActive, IconUser, IconOneTimeEmptying, IconOneTimeEmptyingActive, IconSeriesEmptying, IconSeriesEmptyingActive, IconUsers, IconContainerEmpty, MenuBar } from '../components/Icons';
import NotificationsToggle from '../components/notifications/NotificationsToggle';
import { styles } from '../styles';
import { ROUTES } from './Routes';
import AccountChooser from '../components/AccountChooser';
import { HomeButton } from '../components/HomeButton';

const StyledPageHeader = styled.div`
    height: 66px;
    background-color: #EEF6EF;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 12px 24px;
    box-shadow: ${styles.boxShadowBottomLight};
    margin-bottom: 48px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;

    @media (min-width: 963px) {
        display: none;
    }
`;

const LogoContainer = styled.div`
    svg {
        height: 36px;
        width: auto;
    }
`;

const PageHeaderItems = styled.div`
    display: flex;
    align-items: center;
    overflow: hidden;
`;

const ToggleMenu = styled.button`
    position: relative;
    padding: 0;    
    display: flex;
    align-items: center;
    margin-left: 24px;
    cursor: pointer;
    background: transparent;
    border: none;
    width: 18px;
    height: 20px;
    top: 0;
    float: right;
    z-index: 3;

    svg {
        position: absolute;
        left: 0px;
        -webkit-transition: all .3s;
        transition: all .3s;
    }

    svg:nth-of-type(1) {
        top: 4px;
    }

    svg:nth-of-type(2) {
        top: 9px;
    }

    svg:nth-of-type(3) {
        top: 14px;
    }

    &.active svg:nth-of-type(1) {
        top: 9px;
        -webkit-transform: rotateZ(45deg);
        transform: rotateZ(45deg);
    }

    &.active svg:nth-of-type(2) path {
        stroke: transparent;
    }

    &.active svg:nth-of-type(3) {
        top: 9px;
        -webkit-transform: rotateZ(-45deg);
        transform: rotateZ(-45deg);
    }
`;

type OverlayProps = {
    show: boolean;
}

const Overlay = styled.div<OverlayProps>`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #EEF6EF;
    opacity: 0.5;
    z-index: 2;
    display: ${props => props.show ? 'block' : 'none'};
`;

const MenuDrawer = styled.div`
    display: none;
    background-color: #fff;
    position: fixed;
    top: 0;
    bottom: 0;
    transition: right linear .2s;
    padding: 56px 0px;
    box-shadow: 0px 6px 20px -4px rgba(0, 0, 0, .12);
    right: 0;
    transform: translateX(100%);
    z-index: 2;
    min-width: 260px;
    overflow-y: auto;

    &.open {
        transform: translateX(0);
        display: block;
    }

    ul {
        list-style: none;
    }

    li {
        a {
            text-decoration: none;
        }
    }
`;

const NavList = styled.ul`
    list-style: none;
    margin: 0;

    button {
        padding-left: 32px;
    }
`;

const NavListItem = styled.li`
    color: ${styles.textColorSecondary900};
    margin-bottom: 45px;
    white-space: nowrap;
    
    a {
        display: flex;
        align-items: center;
        text-decoration: none;
        line-height: 22px;
        padding: 0 32px;

        svg {
            margin-right: 8px;
        }

        span {
            font-family: 'Satoshi Bold';
        }
    }

    a:visited {
        color: currentColor;
    }
`;

const DividerContainer = styled.div`
    padding: 16px 0;
`;

const Divider = styled.div`
    height: 1px;
    width: 100%;
    background-color: ${styles.neutral200};
`;

const NavListCategoryTitle = styled.div`
    font-family: 'Satoshi Bold';
    font-size: 12px;
    font-weight: 700;
    color: #979B98;
    margin-bottom: 22px;
    padding: 0 32px;
`;

export const PageHeaderMobile = () => {
    const { accessPermission } = useContext(UserContext);
    const location = useLocation();
    const [isToggled, setIsToggled] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        if (isToggled) {
            document.body.classList.add('no-scroll');
        } else {
            document.body.classList.remove('no-scroll');
        }
    }, [isToggled]);

    const pathname = location.pathname.toLowerCase();
    return (
        <>
            <Overlay show={isToggled} onClick={() => setIsToggled(false)} />
            <StyledPageHeader>
                {location.pathname === ROUTES.Home ?
                    <NavLink to={ROUTES.Home}>
                        <LogoContainer>
                            <KVALogo />
                        </LogoContainer>
                    </NavLink>
                    :
                    <HomeButton />
                }
                <PageHeaderItems>
                    <div style={{width: 'calc(100% - 82px)', overflow: 'hidden'}}>
                        <AccountChooser/>
                    </div>
                    <NotificationsToggle />
                    <ToggleMenu className={`toggle-menu ${isToggled && 'active'}`} onClick={() => setIsToggled(!isToggled)}>
                        <MenuBar />
                        <MenuBar />
                        <MenuBar />
                    </ToggleMenu>
                    <MenuDrawer className={`menu-drawer ${isToggled && 'open'}`}>
                        <NavList>
                            <NavListItem onClick={() => setIsToggled(false)}>
                                <NavLink to={ROUTES.Home}>
                                    {pathname === ROUTES.Home ? <IconHomeActive /> : <IconHome />}
                                    {t("navigation.left.home")}
                                </NavLink>
                            </NavListItem>

                            { accessPermission && accessPermission.containerRefs.length > 0 && accessPermission?.roles?.payment && 
                                <>
                                    <NavListItem onClick={() => setIsToggled(false)}>
                                        <NavLink reloadDocument={pathname === ROUTES.SingleEmptying} to={ROUTES.SingleEmptying}>
                                            {pathname === ROUTES.SingleEmptying ? <IconOneTimeEmptyingActive /> : <IconOneTimeEmptying />}
                                            {pathname === ROUTES.SingleEmptying ? <span>{t("navigation.left.onetimeEmptying")}</span> : t("navigation.left.onetimeEmptying")}
                                        </NavLink>
                                    </NavListItem>
                                    <NavListItem onClick={() => setIsToggled(false)}>
                                        <NavLink reloadDocument={pathname === ROUTES.MultiEmptying} to={ROUTES.MultiEmptying}>
                                            {pathname === ROUTES.MultiEmptying ? <IconSeriesEmptyingActive /> : <IconSeriesEmptying />}
                                            {pathname === ROUTES.MultiEmptying ? <span>{t("navigation.left.seriesEmptying")}</span> : t("navigation.left.seriesEmptying")}
                                        </NavLink>
                                    </NavListItem>
                                    <NavListItem onClick={() => setIsToggled(false)}>
                                        <NavLink  to={ROUTES.CreditOverview}>
                                            {[ROUTES.CreditOverview, ROUTES.Payments, ROUTES.EmptyingConfirmations].indexOf(pathname) >= 0 ?
                                                <>
                                                    <IconBalancesActive />
                                                    <span>{t("navigation.left.balancesAndInvoices")}</span>
                                                </> :
                                                <>
                                                    <IconBalances />
                                                    {t("navigation.left.balancesAndInvoices")}
                                                </>
                                            }
                                        </NavLink>
                                    </NavListItem>
                                </>
                            }
                            <DividerContainer><Divider /></DividerContainer>
                            <NavListCategoryTitle>{t("navigation.top.administration")}</NavListCategoryTitle>
                            <NavListItem onClick={() => setIsToggled(false)}><NavLink to={ROUTES.UserProfile}><IconUser />{t("navigation.top.myData")}</NavLink></NavListItem>
                            <NavListItem onClick={() => setIsToggled(false)}><NavLink to={ROUTES.ContainerOverview}><IconContainerEmpty />{t("navigation.top.myContainers")}</NavLink></NavListItem>
                            <NavListItem onClick={() => setIsToggled(false)}><NavLink to={ROUTES.Organisation}><IconUsers />{t("navigation.top.myOrganisation")}</NavLink></NavListItem>
                            <DividerContainer style={{ marginBottom: 28 }}><Divider /></DividerContainer>
                            <LoginLogout />
                        </NavList>
                    </MenuDrawer>
                </PageHeaderItems>
            </StyledPageHeader>
        </>
    )
}