import { UserContextProvider } from './contexts/UserContext';
import { createTheme, ThemeProvider } from '@mui/material';
import { ErrorBoundary } from './skeleton/ErrorBoundary';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import React from 'react';
import { PageSkeleton } from './skeleton/PageSkeleton';
import GlobalStyles from '@mui/material/GlobalStyles';
import SatoshiRegularTtf from './fonts/Satoshi-Regular.ttf';
import SatoshiBoldTtf from './fonts/Satoshi-Bold.ttf';
import { styles } from './styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { deDE } from '@mui/x-date-pickers/locales';
import { useTranslation } from 'react-i18next';

const queryClient = new QueryClient();
const ReactQueryDevtoolsProduction = React.lazy(() =>
	import('@tanstack/react-query-devtools/build/lib/index.prod.js').then(
		(d) => ({
			default: d.ReactQueryDevtools,
		}),
	),
);

export const App = () => {
	const [showDevtools, setShowDevtools] = React.useState(false)

	React.useEffect(() => {
		// @ts-ignore
		window.toggleReactQueryDevtools = () => setShowDevtools((old) => !old)
	}, [])

	const { t } = useTranslation();
	const theme = createTheme({
        components: {
            MuiDataGrid: {
                defaultProps: {
                    localeText: {
                        noRowsLabel: t("components.tables.noEntriesAvailable"),
                    },
                },
            },
        },
    });

	return (
		<ErrorBoundary>
			<ThemeProvider theme={theme}>
				<QueryClientProvider client={queryClient}>
					<UserContextProvider>
						<LocalizationProvider dateAdapter={AdapterDayjs} localeText={deDE.components.MuiLocalizationProvider.defaultProps.localeText}>
							<GlobalStyles styles={`
							@font-face {
								font-family: 'Satoshi Regular';
								src: url(${SatoshiRegularTtf}) format('truetype');
								font-weight: 400;
								font-style: normal;
							}
							
							@font-face {
								font-family: 'Satoshi Bold';
								src: url(${SatoshiBoldTtf}) format('truetype');
								font-weight: 700;
								font-style: normal;
							}
							
							*, *::before, *::after {
								box-sizing: border-box;
								margin: 0;
								padding: 0;
							}
							
							html {
								font-size: 16px;
							}
							
							body {
								overflow-x: hidden;
								margin: 0;
								padding: 0;
								-webkit-font-smoothing: antialiased;
								-moz-osx-font-smoothing: grayscale;
								font-family: 'Satoshi Regular', sans-serif;
								font-size: 0.875rem;
								font-weight: 400;
								line-height: 22px;
							}
							
							body.no-scroll {
								overflow: hidden;
							}
							
							::-webkit-scrollbar {
								width: 8px;
								height: 8px;
							}
							
							::-webkit-scrollbar-track {
								background: transparent;
							}
							
							::-webkit-scrollbar-thumb {
								background-color: rgba(0, 0, 0, 0.2);
								border-radius: 4px;
								border: 2px solid transparent;
								background-clip: content-box;
							}
							
							::-webkit-scrollbar-thumb:hover {
								background-color: rgba(0, 0, 0, 0.4);
							}
							
							/* For Firefox */
							scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
							scrollbar-width: thin;
							
							section {
								margin-bottom: 64px;
							}
							
							h1, h2, h3, h4 {
								font-family: 'Satoshi Bold', sans-serif;
								font-weight: 700;
								color: ${styles.textColorSecondary700};
							}
							
							h1 {
								font-size: 1.75rem;
								line-height: 2rem;
								margin-bottom: 24px;
							}
							
							h2 {
								font-size: 20px;
								line-height: 24px;

								@media (max-width: 480px) {
									font-size: 16px;
									line-height: 20px;
								}
							}
							
							a {
								text-decoration: none;
								color: currentColor;
							}
							
							.list-view-grid .MuiDataGrid-cell,
							.list-view-grid .MuiDataGrid-columnHeader,
							.table-grid .MuiDataGrid-cell,
							.table-grid .MuiDataGrid-columnHeader {
								font-size: 14px;
								font-weight: 400;
								font-family: "Satoshi Regular";
								color: ${styles.textColorSecondary700};
							}
							
							.list-view-grid .MuiDataGrid-footerContainer,
							.table-grid .MuiDataGrid-footerContainer {
								border-top: none;
							}
							
							.MuiDataGrid-root .MuiTablePagination-root,
							.MuiDataGrid-root .MuiTablePagination-selectLabel,
							.MuiDataGrid-root .MuiTablePagination-root .MuiInputBase-root.MuiInputBase-colorPrimary,
							.MuiTablePagination-displayedRows.MuiTablePagination-displayedRows,
							.MuiDataGrid-root .MuiPaginationItem-root,
							.MuiDataGrid-root .MuiDataGrid-selectedRowCount,
							.MuiInputBase-root.MuiInputBase-colorPrimary.css-16c50h-MuiInputBase-root-MuiTablePagination-select svg {
								color: ${styles.textColorSecondary700} !important;
								font-size: 14px !important;
								line-height: 22px !important;
								font-family: 'Satoshi Regular' !important;
							}
							
							.MuiDataGrid-root .MuiDataGrid-cell:focus-within,
							.MuiDataGrid-root .MuiDataGrid-colCell-checkbox.MuiDataGrid-columnHeader:focus-within .MuiCheckbox-root,
							.MuiDataGrid-root .MuiDataGrid-header .PrivateSwitchBase-input:focus-visible,
							.MuiDataGrid-root .MuiCheckbox-root:focus,
							.MuiDataGrid-root .MuiDataGrid-columnHeader.MuiDataGrid-columnHeader:focus,
							.MuiDataGrid-root .MuiDataGrid-cell:focus,
							.MuiDataGrid-root .MuiButtonBase-root.MuiCheckbox-root.MuiDataGrid-checkboxInput:focus,
							.MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
							.MuiDataGrid-root .MuiDataGrid-columnHeader.MuiDataGrid-columnHeaderCheckbox:focus::before,
							.MuiDataGrid-root .MuiDataGrid-columnHeader.MuiDataGrid-columnHeaderCheckbox:focus::after,
							.MuiDataGrid-root .MuiDataGrid-header .PrivateSwitchBase-input:focus-visible::before,
							.MuiDataGrid-root .MuiDataGrid-header .PrivateSwitchBase-input:focus-visible::after,
							.MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer .PrivateSwitchBase-input:focus-visible,
							.MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainerContent .PrivateSwitchBase-input:focus-visible {
								outline: none !important;
								border: none !important;
								box-shadow: none !important;
							}

							.MuiDataGrid-cell.MuiDataGrid-cellCheckbox:hover > * {
								background-color: #f5f5f5;
							}
							
							.list-view-grid.MuiDataGrid-root .MuiDataGrid-columnHeader:first-of-type:hover div div div > * {
								background-color: #f5f5f5 !important;
							}

							.MuiDataGrid-root .MuiDataGrid-columnHeader:first-of-type:hover {
								outline: none !important;
								border: none !important;
							}
							
							.MuiInputBase-root.MuiOutlinedInput-root.Mui-disabled,
							.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.Mui-error.MuiInputBase-formControl {
								height: 44px;
							}

							.MuiInputBase-input.MuiOutlinedInput-input.Mui-readOnly.MuiInputBase-readOnly {
								font-family: 'Satoshi Regular', sans-serif;
								color: ${styles.textColorSecondary700};
								font-size: 14px;
								max-width: 312px;
							}

							.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium {
								height: 44px;
							}

							.MuiDataGrid-virtualScroller {
								min-height: 200px !important;	
								font-family: 'Satoshi Regular';
								color: ${styles.textColorSecondary700};
							},

							.MuiDataGrid-virtualScroller .MuiDataGrid-overlayWrapper{
								z-index: 0;
							},

							@media (max-width: 834px) {
								.MuiDataGrid-columnHeaderTitle, .MuiDataGrid-cell {
									font-size: 12px !important;
								}
							}
							
							@media (max-width: 480px) {
								h1 {
									font-size: 1.5rem;
								}

								section {
									margin-bottom: 48px;
								}

								.one-click-banner .MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium {
									height: 32px;
								}
							}

							::selection {
								background-color: #f5f5f5;
								color: inherit;
							}
							
							::-moz-selection {
								background-color: #f5f5f5;
								color: inherit;
							}
						`} />
							<PageSkeleton />
						</LocalizationProvider>
					</UserContextProvider>
					<ReactQueryDevtools initialIsOpen={false} />
					{showDevtools && (
						<React.Suspense fallback={null}>
							<ReactQueryDevtoolsProduction />
						</React.Suspense>
					)}
				</QueryClientProvider>
			</ThemeProvider>
		</ErrorBoundary>
	);
}