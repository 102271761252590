import { Container } from "../types/Container";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { convertToErrorMsg, jsonReviverFunction } from "./_base";
import { AccountRef } from "../types/Account";
import { useContext } from "react";
import { UserContext } from "../contexts/UserContext";

export const CONTAINERS_QUERY_KEY = 'containersByAccount';

export const useContainersQuery = (accountRef: AccountRef | undefined) => {
    const query = useQuery<Container[] | undefined, Error>({
        queryKey: [CONTAINERS_QUERY_KEY, accountRef?.id],
        refetchOnWindowFocus: true,
        staleTime: 30000,
        queryFn: () => getContainers(accountRef?.id)
    })
    return query;
} 


export const getContainers = async (accountId: string | undefined): Promise<Container[] | undefined> => {
    if (!accountId) { return [];}
    const response = await fetch(`/api/me/accounts/${accountId}/containers`);
    const responseBody = await response.text();
    if (!response.ok) {
        const errorMsg = convertToErrorMsg(response.status, responseBody);
        throw errorMsg;
    } else {
        const responseJson = JSON.parse(responseBody, jsonReviverFunction);
        const containers = responseJson as Container[];
        return containers;
    }
}


type RegisterContainerInfo = {
    displayName: string;
    shortName: string;
    color: string;
    street: string;
    postalCode: string;
    city: string;
    pictureBase64Encoded: string;
    remarks: string;
}

export const useRegisterContainertMutation = () => {
    const queryClient = useQueryClient()
    const userContext = useContext(UserContext);
    const mutation = useMutation<void, Error, RegisterContainerInfo>({
        mutationFn: (containerInfo: RegisterContainerInfo) => registerConainer(containerInfo, userContext.accessPermission?.accountRef),
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({queryKey: [CONTAINERS_QUERY_KEY]});
        }
    })
    return mutation;
}

const registerConainer = async (containerInfo: RegisterContainerInfo, accountRef?: AccountRef): Promise<void> => {
    if (!accountRef) return;
    const response = await fetch(`/api/me/accounts/${accountRef.id}/containers`, {
        method: 'post',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(containerInfo)
    });
    const responseBody = await response.text();
    if (!response.ok) {
        const errorMsg = convertToErrorMsg(response.status, responseBody);
        throw errorMsg;
    } 
}

type UpdateContainerInfo = {
    id: string;
    displayName: string;
    shortName: string;
    color: string;
}
export const useUpdateContainertMutation = () => {
    const queryClient = useQueryClient()
    const userContext = useContext(UserContext);
    const mutation = useMutation<void, Error, UpdateContainerInfo>({
        mutationFn: (containerInfo: UpdateContainerInfo) => updateContainer(containerInfo, userContext.accessPermission?.accountRef),
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({queryKey: [CONTAINERS_QUERY_KEY]});
        }
    })
    return mutation;
}

const updateContainer = async (containerInfo: UpdateContainerInfo, accountRef?: AccountRef): Promise<void> => {
    if (!accountRef) return;
    const response = await fetch(`/api/me/accounts/${accountRef.id}/containers/${containerInfo.id}`, {
        method: 'post',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(containerInfo)
    });
    const responseBody = await response.text();
    if (!response.ok) {
        const errorMsg = convertToErrorMsg(response.status, responseBody);
        throw errorMsg;
    } 
}

export const useDeleteContainertMutation = () => {
    const queryClient = useQueryClient()
    const userContext = useContext(UserContext);
    const mutation = useMutation<void, Error, Container>({
        mutationFn: (container: Container) => deleteContainer(container, userContext.accessPermission?.accountRef),
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({queryKey: [CONTAINERS_QUERY_KEY]});
        }
    })
    return mutation;
}

const deleteContainer = async (container: Container, accountRef?: AccountRef): Promise<void> => {
    if (!accountRef) return;
    const response = await fetch(`/api/me/accounts/${accountRef.id}/containers/${container.id}`, {
        method: 'delete'
    });
    const responseBody = await response.text();
    if (!response.ok) {
        const errorMsg = convertToErrorMsg(response.status, responseBody);
        throw errorMsg;
    } 
}