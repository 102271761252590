import { useContext } from "react";
import styled from "@emotion/styled";
import { UserContext } from "../contexts/UserContext";
import { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { IconCompany } from "./Icons";
import { styles } from '../styles';

const StyledAccountChooser = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  max-width: 100%;
`;

const SelectWrapper = styled.div`
	display: flex;
	align-items: center;

	& > svg {
	margin-right: -4px;
	width: 24px;
	height: 24px;
	flex-shrink: 0;
	}
`;


const AccountChooser = () => {
	const { updateSelectedAccount, accessPermission, loggedInUser } = useContext(UserContext);

	const options = loggedInUser && loggedInUser.accessPermissions.map((a) => ({
		id: a.accountRef.id,
		name: a.accountRef.name,
		icon: IconCompany
	}));

	if (!loggedInUser || !accessPermission || !options || options.length === 1) {
		// do not show account switcher - user not logged in or no account available or only one account"
		return <></>;
	}

	const handleChange = (event: SelectChangeEvent<string>) => {
		updateSelectedAccount(event.target.value);
	};

	return (
		<StyledAccountChooser>
			<FormControl sx={{ m: 1, }} size="small">
				<InputLabel id="select-small-label"></InputLabel>
				<SelectWrapper className="select-wrapper">
					<IconCompany />
					<Select
						sx={{
							boxShadow: 'none',
							position: 'relative',
							fontFamily: 'Satoshi Regular, sans-serif',
							fontSize: '14px',
							maxWidth: '100%',
							color: styles.textColorSecondary700,
							'.MuiOutlinedInput-notchedOutline': { border: 'none' },
							'.MuiSelect-select': { display: 'flex' },
							'.MuiSelect-outlined': { border: 'none' },
							'.MuiSelect-icon': { color: '#26374A' },
						}}
						SelectDisplayProps={{style:{overflow: 'hidden', paddingRight: 0, marginRight: '28px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', display: 'block'}}}
						labelId="select-small-label"
						id="select-small"
						value={accessPermission.accountRef.id}
						label=""
						onChange={handleChange}
						MenuProps={{
							disableScrollLock: true,
							PaperProps: {
								style: {
									boxShadow: styles.boxShadowBottomLight,
									border: '1px solid #E0E1E0'
								},
							},
						}}
					>
						{options.map((option, index) => {
							return (
								<MenuItem
									key={option.id}
									value={option.id}
									style={index === options.length - 1 ? { marginBottom: '0'}: undefined}

									sx={{
										color: styles.textColorSecondary700,
										display: 'block',
										width: '100%',
										padding: '10px 20px',
										marginBottom: '8px',
										fontSize: '14px',
										fontFamily: 'Satoshi Regular, sans-serif',
									}}>

									{option.name}
								</MenuItem>
							)
						})}
					</Select>
				</SelectWrapper>
			</FormControl>
		</StyledAccountChooser>
	);
};

export default AccountChooser;