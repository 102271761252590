export const GenericErrorImage = () => (
    <svg width="475" height="320" viewBox="0 0 475 320" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_48_113" maskUnits="userSpaceOnUse" x="0" y="0" width="475" height="320">
            <path d="M475 0H0V320H475V0Z" fill="white" />
        </mask>
        <g mask="url(#mask0_48_113)">
            <path d="M475 0H0V320H475V0Z" fill="white" />
            <path d="M232.207 304.367C340.017 304.367 427.414 291.229 427.414 275.023C427.414 258.816 340.017 245.678 232.207 245.678C124.397 245.678 37 258.816 37 275.023C37 291.229 124.397 304.367 232.207 304.367Z" fill="#F5F5F5" />
            <path d="M357.16 266.798L351.89 265.326L353.896 252.729L359.166 254.201L357.16 266.798Z" fill="#FBC8C1" />
            <path d="M399.959 274.836H394.487L393.311 262.163H398.783L399.959 274.836Z" fill="#FBC8C1" />
            <path d="M394.141 274.201H400.808C400.917 274.2 401.023 274.237 401.108 274.306C401.193 274.375 401.251 274.471 401.273 274.578L402.355 279.44C402.381 279.558 402.379 279.681 402.351 279.799C402.322 279.916 402.268 280.026 402.191 280.12C402.114 280.214 402.017 280.289 401.907 280.34C401.797 280.391 401.677 280.416 401.556 280.415C399.412 280.377 397.846 280.251 395.141 280.251C393.475 280.251 388.462 280.421 386.166 280.421C383.871 280.421 383.569 278.15 384.506 277.949C388.726 277.025 391.902 275.754 393.248 274.54C393.494 274.321 393.812 274.2 394.141 274.201Z" fill="#263238" />
            <path d="M352.097 264.723L357.858 266.333C357.963 266.361 358.054 266.425 358.117 266.513C358.18 266.602 358.211 266.709 358.204 266.817L357.94 271.792C357.934 271.912 357.9 272.03 357.842 272.136C357.783 272.241 357.702 272.333 357.603 272.402C357.504 272.472 357.391 272.518 357.272 272.538C357.153 272.557 357.031 272.55 356.915 272.515C354.852 271.886 351.884 270.943 349.273 270.213C346.223 269.364 344.921 269.081 341.343 268.087C339.179 267.459 339.185 265.125 340.148 265.182C344.55 265.427 346.538 265.981 350.714 264.779C351.161 264.631 351.64 264.612 352.097 264.723Z" fill="#263238" />
            <path opacity="0.2" d="M353.896 252.729L352.865 259.226L358.135 260.697L359.173 254.201L353.896 252.729Z" fill="black" />
            <path opacity="0.2" d="M398.783 262.169H393.311L393.921 268.698H399.393L398.783 262.169Z" fill="black" />
            <path d="M379.877 114.638C379.877 114.638 366.779 122.275 364.165 122.99C361.551 123.705 343.921 120.463 343.921 120.463L341.258 121.197L333.003 117.381L333.728 110.541C333.728 110.541 343.749 114.257 344.614 114.818C345.478 115.38 344.954 116.759 344.954 116.759C344.954 116.759 360.759 117.63 362.134 117.302C363.508 116.974 373.536 108.877 373.536 108.877L379.877 114.638Z" fill="#FBC8C1" />
            <path d="M388.887 102.639C392.478 107.643 380.825 117.087 375.381 118.826L370.326 110.437C370.326 110.437 383.242 94.7751 388.887 102.639Z" fill="#52575A" />
            <path d="M376.89 105.094C374.418 111.956 373.386 123.836 371.959 146.792L400.927 150.987C401.663 147.113 402.022 129.654 407.04 111.711C407.344 110.648 407.424 109.535 407.277 108.44C407.13 107.345 406.759 106.293 406.186 105.348C405.613 104.403 404.852 103.587 403.949 102.95C403.046 102.314 402.022 101.87 400.94 101.648C395.562 100.603 390.108 99.9975 384.632 99.8364C382.952 99.7861 381.299 100.267 379.909 101.211C378.519 102.156 377.462 103.514 376.89 105.094Z" fill="#85CC91" />
            <path d="M400.311 88.1508C399.122 91.547 397.563 97.5093 398.846 100.956C398.995 101.357 399.004 101.797 398.87 102.204C398.737 102.611 398.468 102.961 398.11 103.195C395.521 105.063 392.334 105.907 389.16 105.566C385.091 105.126 385.122 102.912 386.16 101.333C386.298 101.124 386.477 100.944 386.687 100.807C386.897 100.67 387.133 100.577 387.38 100.534C391.38 99.8552 392.129 96.5407 391.82 93.5093L400.311 88.1508Z" fill="#FBC8C1" />
            <path opacity="0.2" d="M396.852 90.3521L391.82 93.4967C391.883 94.2396 391.883 94.9865 391.82 95.7294C393.544 95.7294 395.921 94.6225 396.852 92.8678C397.443 91.7986 397.267 90.547 396.852 90.3521Z" fill="black" />
            <path d="M392.059 82.8365C395.921 80.5346 398.041 75.7736 397.72 74.717C397.399 73.6604 391.33 71.7988 390.5 72.239C386.355 74.3271 388.959 80.0566 388.959 80.0566L387.519 81.9434L392.059 82.8365Z" fill="#263238" />
            <path d="M402.625 82.6477C401.16 87.8804 400.563 91.0125 397.298 93.1509C392.393 96.3773 386.374 92.5219 386.254 86.9748C386.148 81.9433 388.569 74.2578 394.21 73.2704C395.453 73.0462 396.732 73.1436 397.927 73.5533C399.121 73.963 400.191 74.6714 401.034 75.6112C401.878 76.551 402.467 77.6909 402.745 78.9225C403.024 80.1541 402.982 81.4365 402.625 82.6477Z" fill="#FBC8C1" />
            <path d="M402.915 86.3899C406.531 83.7232 409.355 79.4717 408.883 78.4843C407.625 75.8679 403.16 73.9497 400.311 75.3396C396.129 77.3459 399.544 83.956 399.544 83.956L398.286 85.9937L402.915 86.3899Z" fill="#263238" />
            <path d="M401.588 77.2579C396.625 77.6855 389.443 73.3145 388.864 69.5787C390.657 68.5535 393.537 69.8302 393.537 69.8302C393.537 69.8302 392.475 67.3145 392.909 66C394.871 66.0818 398.915 68.3271 399.487 69.3522C399.444 68.7273 399.575 68.1027 399.864 67.5472C401.55 67.5472 412.89 73.5157 406.978 77.6101C402.418 78.4214 401.588 77.2579 401.588 77.2579Z" fill="#263238" />
            <path d="M404.147 88.1509C403.19 89.1228 401.889 89.6786 400.525 89.6981C398.713 89.6981 398.286 87.9685 399.191 86.5094C400.003 85.2013 401.927 83.6164 403.55 84.3082C405.173 85 405.311 86.9371 404.147 88.1509Z" fill="#FBC8C1" />
            <path d="M350.928 257.264L359.852 259.748C359.852 259.748 368.657 228.301 371.538 211.49C372.405 206.377 374.299 199.308 376.569 191.761C379.481 182.251 382.953 172 385.77 163.975C388.751 155.522 390.99 149.553 390.99 149.553L371.934 146.798C371.934 146.798 363.041 184.226 359.984 200.641C356.783 217.71 350.928 257.264 350.928 257.264Z" fill="#263238" />
            <path opacity="0.2" d="M376.582 191.761C379.481 182.251 382.953 172 385.77 163.975L384.097 159.572C378.946 164.836 376.336 182.207 376.582 191.761Z" fill="black" />
            <path d="M381.047 148.113C381.047 148.113 382.833 195.547 383.959 211.54C385.217 229.05 391.286 266.723 391.286 266.723H401.305C401.305 266.723 399.607 231.855 397.726 214.842C395.581 195.459 400.921 150.993 400.921 150.993L381.047 148.113Z" fill="#263238" />
            <path d="M390.481 267.496H402.28V263.968L389.085 263.741L390.481 267.496Z" fill="#85CC91" />
            <path d="M350.217 257.358L360.852 260.327L361.808 256.893L350.569 253.572L350.217 257.358Z" fill="#85CC91" />
            <path d="M394.003 82.3898C393.909 82.8238 393.607 83.1257 393.324 83.0628C393.041 82.9999 392.89 82.6036 392.984 82.1697C393.078 81.7357 393.38 81.4401 393.663 81.503C393.946 81.5659 394.097 81.9621 394.003 82.3898Z" fill="#263238" />
            <path d="M390.99 88.1509C390.902 88.5849 390.594 88.8868 390.311 88.8239C390.028 88.761 389.877 88.3648 389.971 87.9308C390.066 87.4968 390.368 87.2012 390.651 87.2641C390.934 87.327 391.085 87.7421 390.99 88.1509Z" fill="#263238" />
            <path d="M389.129 81.3396C389.034 81.7673 388.732 82.0691 388.449 82.0063C388.166 81.9434 388.015 81.5471 388.11 81.1195C388.204 80.6918 388.506 80.3836 388.789 80.4465C389.072 80.5094 389.223 80.9056 389.129 81.3396Z" fill="#263238" />
            <path d="M390.902 81.893C390.102 83.0992 389.139 84.1892 388.041 85.132C388.67 86.0125 390.022 85.9684 390.022 85.9684L390.902 81.893Z" fill="#D58745" />
            <path d="M396.116 80.7295C396.16 80.7385 396.205 80.7385 396.248 80.7295C396.309 80.6984 396.356 80.6454 396.379 80.5813C396.402 80.5171 396.4 80.4465 396.374 80.3836C396.207 80.0078 395.954 79.6768 395.635 79.4178C395.316 79.1587 394.94 78.9789 394.537 78.893C394.504 78.8881 394.469 78.8898 394.436 78.8982C394.403 78.9066 394.372 78.9214 394.344 78.9419C394.317 78.9623 394.293 78.988 394.276 79.0174C394.258 79.0467 394.247 79.0793 394.242 79.1132C394.237 79.147 394.239 79.1815 394.247 79.2147C394.255 79.2479 394.27 79.2791 394.291 79.3065C394.311 79.334 394.337 79.3572 394.366 79.3747C394.396 79.3922 394.428 79.4038 394.462 79.4088C394.779 79.4798 395.076 79.6252 395.326 79.8329C395.576 80.0407 395.774 80.3048 395.902 80.6037C395.924 80.641 395.956 80.672 395.993 80.694C396.03 80.716 396.073 80.7282 396.116 80.7295Z" fill="#263238" />
            <path d="M387.657 78.9622C387.7 78.9664 387.744 78.9594 387.783 78.9417C387.823 78.9241 387.857 78.8965 387.883 78.8616C388.075 78.602 388.326 78.391 388.614 78.2457C388.902 78.1004 389.221 78.0249 389.544 78.0251C389.612 78.031 389.68 78.0094 389.732 77.9652C389.785 77.9209 389.818 77.8577 389.824 77.7893C389.829 77.7209 389.808 77.653 389.764 77.6005C389.719 77.548 389.656 77.5153 389.588 77.5094C389.175 77.4997 388.767 77.5888 388.396 77.7694C388.025 77.9499 387.702 78.2166 387.456 78.5471C387.434 78.5734 387.419 78.6036 387.41 78.636C387.4 78.6684 387.398 78.7023 387.402 78.7357C387.406 78.7691 387.417 78.8014 387.433 78.8306C387.45 78.8598 387.473 78.8853 387.5 78.9056C387.544 78.9423 387.599 78.9624 387.657 78.9622Z" fill="#263238" />
            <path d="M406.613 77.8553C406.683 77.8849 406.759 77.8969 406.835 77.8903C406.91 77.8837 406.983 77.8588 407.047 77.8176C407.516 77.4847 408.058 77.2683 408.628 77.1863C409.198 77.1043 409.779 77.159 410.324 77.3459C410.38 77.3748 410.442 77.3916 410.505 77.3951C410.569 77.3987 410.632 77.3888 410.691 77.3663C410.75 77.3438 410.804 77.309 410.849 77.2643C410.894 77.2196 410.929 77.166 410.952 77.1069C410.98 77.0503 410.996 76.9885 410.999 76.9254C411.003 76.8623 410.992 76.7993 410.97 76.7403C410.948 76.6813 410.913 76.6275 410.869 76.5824C410.825 76.5372 410.772 76.5017 410.713 76.4779C410.029 76.2325 409.296 76.1541 408.575 76.2493C407.854 76.3445 407.166 76.6105 406.569 77.0251C406.519 77.0581 406.477 77.1005 406.443 77.15C406.41 77.1995 406.387 77.255 406.375 77.3135C406.364 77.3719 406.364 77.432 406.375 77.4905C406.387 77.5489 406.41 77.6045 406.443 77.654C406.478 77.7369 406.537 77.8071 406.613 77.8553Z" fill="#263238" />
            <path d="M392.33 275.157C392.812 275.146 393.291 275.081 393.758 274.962C393.783 274.956 393.807 274.943 393.825 274.924C393.843 274.905 393.855 274.881 393.858 274.855C393.862 274.83 393.859 274.806 393.849 274.783C393.839 274.761 393.822 274.742 393.802 274.729C393.6 274.603 391.833 273.515 391.229 273.829C391.162 273.864 391.106 273.916 391.067 273.98C391.028 274.045 391.008 274.119 391.009 274.194C390.993 274.321 391.009 274.451 391.055 274.57C391.101 274.69 391.176 274.796 391.273 274.88C391.585 275.087 391.957 275.185 392.33 275.157ZM393.386 274.779C392.456 274.968 391.739 274.93 391.443 274.679C391.377 274.622 391.327 274.549 391.296 274.468C391.265 274.387 391.255 274.299 391.267 274.213C391.267 274.112 391.311 274.075 391.349 274.056C391.663 273.899 392.688 274.364 393.386 274.779Z" fill="#85CC91" />
            <path d="M393.732 274.943C393.755 274.948 393.779 274.948 393.802 274.943C393.821 274.93 393.837 274.912 393.847 274.891C393.857 274.87 393.861 274.846 393.858 274.823C393.858 274.76 393.726 273.182 393.085 272.66C393.01 272.596 392.923 272.548 392.828 272.52C392.734 272.492 392.635 272.484 392.537 272.496C392.439 272.495 392.344 272.528 392.268 272.59C392.192 272.652 392.14 272.739 392.122 272.836C392.009 273.465 393.104 274.641 393.67 274.949C393.691 274.953 393.713 274.951 393.732 274.943ZM392.626 272.723C392.735 272.723 392.84 272.763 392.921 272.836C393.299 273.332 393.518 273.93 393.55 274.553C392.99 274.125 392.292 273.219 392.361 272.855C392.361 272.823 392.361 272.748 392.55 272.723H392.607H392.626Z" fill="#85CC91" />
            <path d="M351.38 265.308C351.404 265.307 351.426 265.299 351.445 265.286C351.464 265.272 351.479 265.254 351.487 265.232C351.499 265.207 351.503 265.179 351.497 265.151C351.492 265.124 351.477 265.099 351.456 265.081C351.255 264.893 349.437 263.226 348.695 263.408C348.626 263.422 348.562 263.455 348.51 263.503C348.458 263.55 348.419 263.611 348.399 263.679C348.354 263.788 348.336 263.906 348.347 264.023C348.358 264.14 348.398 264.253 348.462 264.352C348.896 265.025 350.462 265.238 351.361 265.308H351.38ZM348.808 263.647C349.242 263.647 350.267 264.383 350.997 265.012C349.777 264.88 348.921 264.591 348.676 264.213C348.636 264.147 348.611 264.072 348.605 263.994C348.598 263.917 348.61 263.839 348.638 263.767C348.646 263.74 348.661 263.715 348.683 263.696C348.704 263.677 348.73 263.665 348.758 263.66L348.808 263.647Z" fill="#85CC91" />
            <path d="M351.38 265.308H351.412C351.433 265.298 351.452 265.285 351.467 265.267C351.482 265.25 351.493 265.229 351.5 265.207C351.5 265.144 351.764 263.685 351.248 262.949C351.177 262.841 351.085 262.749 350.976 262.679C350.868 262.609 350.747 262.561 350.619 262.54C350.267 262.484 350.122 262.628 350.06 262.76C349.795 263.333 350.739 264.823 351.317 265.276C351.335 265.292 351.357 265.303 351.38 265.308ZM350.437 262.792C350.481 262.787 350.525 262.787 350.569 262.792C350.663 262.807 350.754 262.841 350.834 262.893C350.914 262.945 350.982 263.014 351.034 263.094C351.301 263.66 351.385 264.295 351.273 264.911C350.739 264.339 350.129 263.207 350.286 262.861C350.301 262.834 350.323 262.812 350.349 262.798C350.376 262.783 350.407 262.777 350.437 262.779V262.792Z" fill="#85CC91" />
            <path d="M401.604 118.015C401.604 118.015 391.93 129.688 389.72 131.258C387.511 132.829 369.841 135.843 369.841 135.843L367.593 137.448L358.529 136.702L356.86 130.029C356.86 130.029 367.547 130.075 368.552 130.305C369.556 130.534 369.538 132.01 369.538 132.01C369.538 132.01 384.68 127.396 385.858 126.615C387.036 125.834 393.67 114.784 393.67 114.784L401.604 118.015Z" fill="#FBC8C1" />
            <path d="M405.941 103.65C411.034 107.115 403.337 119.989 398.822 123.492L391.191 117.352C391.191 117.352 397.938 98.2053 405.941 103.65Z" fill="#52575A" />
            <path d="M316.952 254.935L310.779 254.982L309.325 62.8723L302.691 62.9225L304.144 255.032L297.971 255.079L293.812 259.252L304.176 259.173L310.81 259.123L321.174 259.045L316.952 254.935Z" fill="#263238" />
            <path d="M353.785 71.0357C359.804 44.6396 343.286 18.3614 316.89 12.3418C290.493 6.32225 264.215 22.8407 258.196 49.2369C252.176 75.633 268.695 101.911 295.091 107.931C321.487 113.95 347.765 97.4318 353.785 71.0357Z" fill="#85CC91" />
            <path opacity="0.8" d="M305.849 104.038C329.883 103.856 349.22 84.2249 349.038 60.1904C348.856 36.1558 329.225 16.8194 305.19 17.0013C281.156 17.1831 261.819 36.8144 262.001 60.8489C262.183 84.8835 281.814 104.22 305.849 104.038Z" fill="white" />
            <path opacity="0.3" d="M316.957 254.853L310.783 254.9L309.762 119.963L303.112 117.922L304.149 254.95L297.975 254.997L293.812 258.584L304.176 258.506L310.81 258.456L321.174 258.377L316.957 254.853Z" fill="white" />
            <path d="M277.61 61.2737H272.435V67H270V53H278.599V55.2599H272.435V59.0711H277.61V61.2737ZM290.001 67H281.365V53H290.001V55.2599H283.8V58.8604H289.354V61.0246H283.8V64.7401H290.001V67ZM295.648 67H293.212V53H295.648V58.7838H301.811V53H304.246V67H301.811V61.063H295.648V67ZM310.336 53V64.7401H316.158V67H307.901V53H310.336ZM327.346 67H318.709V53H327.346V55.2599H321.143V58.8604H326.699V61.0246H321.143V64.7401H327.346V67ZM332.991 67H330.557V53H335.864C339.022 53 340.867 54.6087 340.867 57.3666C340.867 59.2435 339.992 60.5841 338.356 61.2545L341 67H338.337L335.978 61.7716H332.991V67ZM332.991 55.1834V59.6073H335.864C337.405 59.6073 338.318 58.7838 338.318 57.3666C338.318 55.9686 337.405 55.1834 335.864 55.1834H332.991Z" fill="#85CC91" />
            <path d="M56.0367 260.277C56.4677 259.557 57.4188 258.672 58.0875 258.167C58.7563 257.662 59.6703 257.409 60.4505 257.729C61.4388 258.13 61.8921 259.349 61.7583 260.404C61.6171 261.466 61.1118 262.804 60.51 263.688C57.9389 267.455 55.5611 270.903 51.5857 273.705C51.5857 269.031 53.6588 264.268 56.0367 260.277Z" fill="#ADDDB5" />
            <path d="M42.9444 260.923C43.836 260.44 45.4857 261.124 46.2956 261.733C47.9006 262.937 48.8815 264.2 49.8623 265.954C51.1033 268.175 51.2519 271.304 51.4079 273.288C48.2647 270.739 45.0621 268.116 42.9072 264.69C42.5282 264.089 42.1864 263.45 42.1121 262.744C42.0452 262.038 42.3202 261.265 42.9444 260.923Z" fill="#ADDDB5" />
            <path d="M51.6668 275.731C51.756 275.731 51.8303 275.672 51.8451 275.583C52.5882 272.142 53.9109 268.88 55.7686 265.886C55.8206 265.797 55.7983 265.685 55.7091 265.633C55.62 265.581 55.5085 265.603 55.4565 265.693C53.5765 268.724 52.2464 272.024 51.4885 275.509C51.4662 275.605 51.5331 275.709 51.6297 275.731C51.6445 275.731 51.6594 275.731 51.6668 275.731Z" fill="#26374A" />
            <path d="M51.5294 273.78C51.5294 273.78 51.574 273.78 51.5963 273.772C51.6929 273.735 51.7449 273.631 51.7077 273.534C50.8681 271.209 49.6346 269.069 48.0518 267.166C47.985 267.085 47.8661 267.077 47.7918 267.144C47.71 267.211 47.7026 267.33 47.7695 267.404C49.3225 269.269 50.5337 271.372 51.3659 273.661C51.3956 273.735 51.4625 273.787 51.5368 273.787L51.5294 273.78Z" fill="#26374A" />
            <path d="M322.567 269.197L267.282 278.949L265.356 278.858L243.5 278L264.976 254.13L327.059 264.003L322.567 269.197Z" fill="#78B883" />
            <path d="M216.574 280.51L308.661 264.584C308.661 264.584 308.864 264.23 304.227 262.473C299.59 260.716 299.566 258.115 295.731 257.088C291.896 256.06 290.426 252.827 289.063 252.499C287.699 252.171 283.865 246.224 283.865 246.224C281.792 245.958 280.48 248.058 276.741 244.867L268.472 237.904L265.052 236.729C262.526 237.242 259.113 237.208 258.135 237.925C257.158 238.643 256.874 240.398 254.094 241.229C251.315 242.059 254.02 239.185 251.303 242.077C248.587 244.968 250.605 244.833 246.903 245.595C243.201 246.357 247.016 245.225 243.204 246.371L239.376 247.506C236.375 249.35 237.497 243.947 232.765 251.238C228.033 258.53 223.764 255.841 222.821 260.406C221.877 264.971 216.214 265.098 216.214 265.098L214.019 269.392C213.127 276.629 216.168 275.615 213.127 276.629L210.085 277.642L205.222 282.474L216.574 280.51Z" fill="#10242E" />
            <path opacity="0.4" d="M216.574 280.51L308.661 264.584C308.661 264.584 308.864 264.23 304.227 262.473C299.59 260.716 299.566 258.115 295.731 257.088C291.896 256.06 290.426 252.827 289.063 252.499C287.699 252.171 283.865 246.224 283.865 246.224C281.792 245.958 280.48 248.058 276.741 244.867L268.472 237.904L265.052 236.729C262.526 237.242 259.113 237.208 258.135 237.925C257.158 238.643 256.874 240.398 254.094 241.229C251.315 242.059 254.02 239.185 251.303 242.077C248.587 244.968 250.605 244.833 246.903 245.595C243.201 246.357 247.016 245.225 243.204 246.371L239.376 247.506C236.375 249.35 237.497 243.947 232.765 251.238C228.033 258.53 223.764 255.841 222.821 260.406C221.877 264.971 216.214 265.098 216.214 265.098L214.019 269.392C213.127 276.629 216.168 275.615 213.127 276.629L210.085 277.642L205.222 282.474L216.574 280.51Z" fill="white" />
            <path opacity="0.3" d="M216.572 280.508C216.572 280.508 216.214 280.478 218.941 277.22C221.667 273.961 220.29 273.468 222.867 273.251C225.443 273.034 226.25 272.346 228.97 272.195C231.691 272.044 233.79 271.864 234.802 271.095C235.815 270.326 236.9 268.448 237.884 268.277L239.838 267.939C239.838 267.939 237.603 267.839 240.808 267.772C242.228 267.674 243.655 267.749 245.062 267.996L248.781 269.287C251.754 269.489 253.79 269.715 253.79 269.715C253.79 269.715 249.238 265.644 248.259 266.27C247.281 266.896 246.398 265.358 245.735 266.706C245.072 268.055 242.625 264.35 242.625 264.35C242.625 264.35 249.576 261.397 249.424 259.412C249.271 257.428 248.099 254.981 249.502 254.007C250.905 253.033 255.365 251.47 255.553 250.523C255.742 249.577 258.302 246.88 258.302 246.88L264.623 247.843C265.465 248.138 266.329 248.361 267.205 248.508C268.718 248.764 269.708 247.862 270.839 248.626C271.97 249.39 273.486 249.92 273.611 250.645C274.22 251.975 274.965 253.235 275.833 254.403C275.833 254.403 276.389 252.434 273.079 256.144C269.77 259.854 269.866 256.928 269.754 259.765C269.675 261.278 269.736 262.798 269.937 264.303L271.537 260.645C271.941 260.708 272.353 260.664 272.734 260.515C273.115 260.367 273.451 260.119 273.711 259.797L274.788 258.636C275.984 258.165 277.079 257.458 278.012 256.555C278.676 255.557 281.05 256.029 281.05 256.029C281.05 256.029 281.305 254.706 282.476 255.783C283.646 256.86 288.991 257.87 288.991 257.87L291.22 259.038C292.552 260.155 293.999 261.116 295.534 261.902C296.726 261.874 297.913 261.75 299.083 261.532L302.943 265.586L216.572 280.508Z" fill="white" />
            <path opacity="0.2" d="M228.953 262.497C228.821 262.879 228.765 263.286 228.79 263.693C228.815 264.1 228.92 264.498 229.098 264.864L229.679 265.418L232.531 264.925C232.547 264.394 232.452 263.864 232.25 263.373C232.048 262.881 231.746 262.44 231.363 262.081C231.187 261.918 230.977 261.797 230.75 261.729C230.524 261.661 230.286 261.646 230.054 261.686C229.822 261.726 229.603 261.82 229.412 261.961C229.222 262.101 229.065 262.284 228.953 262.497Z" fill="white" />
            <path opacity="0.2" d="M239.485 251.553C239.504 252.239 239.701 252.91 240.056 253.495L241.393 252.137C241.678 251.828 242.003 251.331 241.757 250.931L239.485 251.553Z" fill="white" />
            <path opacity="0.2" d="M265.321 264.708L264.575 264.471C264.368 264.888 263.905 265.014 263.546 265.228C263.186 265.443 262.833 265.945 263.057 266.394C263.037 266.276 262.798 267.018 262.842 267.101C263.143 267.487 263.54 267.782 263.989 267.954C264.438 268.126 264.922 268.169 265.39 268.077C265.676 268.028 265.945 267.903 266.169 267.715C266.394 267.527 266.567 267.281 266.673 267.002C266.835 266.411 265.79 264.87 265.321 264.708Z" fill="white" />
            <path opacity="0.2" d="M280.187 251.718C280.077 251.415 279.896 251.143 279.662 250.928C279.427 250.712 279.145 250.559 278.842 250.483C278.538 250.406 278.222 250.407 277.92 250.487C277.619 250.566 277.342 250.722 277.115 250.939C276.983 251.084 276.892 251.263 276.852 251.457C276.768 251.99 277.18 252.588 276.985 253.079L276.709 253.69C276.942 253.899 277.212 254.057 277.505 254.155C277.797 254.253 278.105 254.288 278.409 254.258C278.713 254.228 279.008 254.134 279.274 253.982C279.541 253.829 279.774 253.622 279.959 253.372C280.141 253.14 280.25 252.855 280.273 252.556C280.312 252.274 280.283 251.986 280.187 251.718Z" fill="white" />
            <path opacity="0.2" d="M261.153 245.018C261.318 244.831 261.416 244.59 261.431 244.336C261.446 244.081 261.376 243.828 261.234 243.618C261.059 243.412 260.84 243.252 260.595 243.15C260.046 242.864 259.381 242.583 258.921 242.982C258.461 243.382 258.53 244.375 258.72 245.134L259.969 244.796C260.03 244.895 260.11 244.981 260.205 245.046C260.299 245.112 260.405 245.157 260.517 245.178C260.628 245.198 260.743 245.195 260.852 245.167C260.962 245.14 261.064 245.089 261.153 245.018Z" fill="white" />
            <path opacity="0.2" d="M227.068 274.703C226.835 274.822 226.656 275.029 226.569 275.283C226.481 275.536 226.492 275.816 226.599 276.064L226.983 276.165C227.395 276.147 227.802 276.077 228.196 275.955C228.424 275.916 228.669 275.721 228.637 275.452C228.604 275.324 228.532 275.208 228.433 275.122C228.269 274.922 228.052 274.776 227.809 274.702C227.566 274.627 227.309 274.628 227.068 274.703Z" fill="white" />
            <path d="M250.627 260.942C249.143 260.209 247.509 259.873 245.876 259.966C243.65 260.26 242.239 260.595 242.239 260.595L243.1 258.436C245.09 257.882 247.19 257.865 249.201 258.386C250.726 258.762 252.083 259.675 253.04 260.966L251.87 262.692L250.627 260.942Z" fill="#10242E" />
            <path d="M225.386 271.25C225.386 271.25 223.359 266.833 222.136 266.725C220.913 266.616 218.798 269.845 218.798 269.845L221.051 272.852C221.051 272.852 223.235 270.19 225.386 271.25Z" fill="#10242E" />
            <path d="M233.958 257.81C233.958 257.81 232.972 255.421 233.416 254.674C234.111 253.981 234.938 253.444 235.846 253.096L237.783 254.955L237.935 257.365C237.301 257.016 236.582 256.871 235.873 256.951C235.163 257.03 234.496 257.329 233.958 257.81Z" fill="#10242E" />
            <path d="M276.711 265.082C276.711 265.082 276.4 260.825 277.838 260.729C279.275 260.632 281.602 260.839 281.413 261.359C281.224 261.879 281.364 266.592 281.364 266.592L276.711 265.082Z" fill="#10242E" />
            <path d="M248.716 272.483C248.716 272.483 247.085 269.597 249.566 268.909C252.047 268.221 252.434 268.504 253.077 269.078C253.976 269.723 254.92 270.298 255.901 270.799L256.103 271.967L248.716 272.483Z" fill="#10242E" />
            <path opacity="0.1" d="M225.386 271.25C225.386 271.25 223.359 266.833 222.136 266.725C220.913 266.616 218.798 269.845 218.798 269.845L221.051 272.852C221.051 272.852 223.235 270.19 225.386 271.25Z" fill="white" />
            <path opacity="0.1" d="M276.711 265.082C276.711 265.082 276.4 260.825 277.838 260.729C279.275 260.632 281.602 260.839 281.413 261.359C281.224 261.879 281.364 266.592 281.364 266.592L276.711 265.082Z" fill="white" />
            <path d="M263.24 239.007C263.24 239.007 235.459 240.674 238.086 229.451C240.713 218.228 258.664 222.571 254.694 225.939C250.723 229.306 231.478 226.314 237.808 217.816C242.506 211.521 245.819 212.334 245.819 212.334" stroke="#10242E" strokeWidth="0.768111" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="3.07 3.07" />
            <path fillRule="evenodd" clipRule="evenodd" d="M267.627 279.281L322.5 269.5L327 264L267.863 274.273L267.627 279.281Z" fill="#6AA374" />
            <path d="M267.627 279.281L267.863 274.273L252.5 273.887L242.5 278L267.627 279.281Z" fill="#5E9067" />
            <path d="M255.336 163.078L128.727 155.447L81.3149 187.725L72.1581 195.515L77.0546 276.45L201.873 294.903L214.951 292.072L261.365 272.154L255.336 163.078Z" fill="#85CC91" />
            <path d="M195.346 207.047L72.5441 195.511L77.0549 276.45L201.488 294.909L195.346 207.047Z" fill="#6AA374" />
            <path d="M181.425 228.699C181.693 233.524 180.699 237.857 178.958 240.539C177.86 242.232 176.464 243.267 174.901 243.354C170.86 243.579 167.23 237.382 166.792 229.513C166.355 221.644 169.276 215.083 173.316 214.858C174.742 214.779 176.116 215.499 177.317 216.809C179.521 219.213 181.142 223.606 181.425 228.699Z" fill="#1E2B39" />
            <path d="M173.316 214.858C174.742 214.779 176.116 215.499 177.317 216.809L177.167 214.644L173.316 214.858Z" fill="#1E2B39" />
            <path d="M178.958 240.539C177.86 242.232 176.464 243.267 174.901 243.354L179.137 243.118L178.958 240.539Z" fill="#1E2B39" />
            <path d="M179.137 243.119C182.965 242.906 185.714 236.354 185.276 228.486C184.839 220.617 181.381 214.41 177.553 214.623C173.725 214.836 170.976 221.388 171.414 229.256C171.851 237.125 175.309 243.332 179.137 243.119Z" fill="#2B3B4D" />
            <path opacity="0.1" d="M178.923 239.268C181.475 239.126 183.285 234.356 182.966 228.614C182.647 222.872 180.319 218.332 177.767 218.474C175.215 218.616 173.405 223.386 173.724 229.128C174.043 234.87 176.371 239.41 178.923 239.268Z" fill="white" />
            <path d="M180.439 231.844L179.027 227.288L183.91 217.607L183.485 214.526L178.271 214.593L175.24 214.751L178.211 209.966L189.295 210.493L190.066 224.356L186.985 224.527L180.439 231.844Z" fill="#536B85" />
            <path d="M204.48 197.673L195.348 207.432L72.1563 195.515L81.3126 187.683L204.48 197.673Z" fill="#9DD6A7" />
            <path d="M255.344 163.849L240.047 174.074L241.169 171.36L243.463 169.788L244.981 167.84L256.075 160.754L255.344 163.849Z" fill="#6AA374" />
            <path d="M128.864 155.485L124.708 158.313L124.997 151.678L258.249 160.532L265.094 275.579L252.208 275.783L260.557 271.819L253.815 163.137L128.864 155.485Z" fill="#6AA374" />
            <path d="M258.244 160.866L124.465 152.171L127.137 150.208L260.525 158.523L268.174 274.135L265.112 275.717L258.244 160.866Z" fill="#85CC91" />
            <path d="M124.827 152.026L129.113 155.442L110.38 168.411L108.808 166.117L113.015 163.363L112.976 160.278L124.827 152.026Z" fill="#78B883" />
            <path d="M215.225 163.804L222.262 161.543L222.48 158.079L215.225 163.804Z" fill="#9DD6A7" />
            <path d="M132.674 158.714L139.711 156.453L139.93 152.989L132.674 158.714Z" fill="#9DD6A7" />
            <path d="M153.312 159.987L160.349 157.726L160.568 154.262L153.312 159.987Z" fill="#9DD6A7" />
            <path d="M173.949 161.259L180.985 158.998L181.204 155.534L173.949 161.259Z" fill="#9DD6A7" />
            <path d="M194.587 162.531L201.623 160.271L201.842 156.806L194.587 162.531Z" fill="#9DD6A7" />
            <path d="M235.863 165.076L242.9 162.815L243.119 159.351L235.863 165.076Z" fill="#9DD6A7" />
            <path d="M260.98 272.16L254.96 163.855L204.864 197.422L195.736 207.428L201.493 295.294L214.565 292.026L260.98 272.16Z" fill="#78B883" />
            <path d="M67.1251 209.353C67.0115 210.511 66.5589 213.294 66.5629 213.603L94.0938 215.949L94.5693 210.929L67.1251 209.353Z" fill="#1E2B39" />
            <path d="M66.5511 213.539C66.3155 216.431 72.294 219.278 79.9044 219.898C87.5148 220.518 93.8752 218.676 94.1108 215.785C94.3464 212.893 88.368 210.046 80.7576 209.426C73.1472 208.806 66.7867 210.647 66.5511 213.539Z" fill="#1E2B39" />
            <path d="M67.1641 209.377C66.8792 212.164 72.7825 214.794 80.3495 215.251C87.9165 215.708 94.2817 213.819 94.5666 211.032C94.8515 208.244 88.9481 205.615 81.3811 205.158C73.8141 204.701 67.4489 206.59 67.1641 209.377Z" fill="#2B3B4D" />
            <path opacity="0.1" d="M71.2378 209.623C71.0397 211.562 75.163 213.392 80.4474 213.711C85.7318 214.03 90.1762 212.717 90.3744 210.779C90.5725 208.84 86.4492 207.01 81.1648 206.691C75.8804 206.372 71.436 207.685 71.2378 209.623Z" fill="white" />
            <path d="M83.1776 209.919L78.5251 208.05L78.4954 205.736L76.9529 205.756L76.8984 201.514L91.1868 202.873L91.2512 207.887L88.5418 207.15L88.522 205.607L83.1776 209.919Z" fill="#536B85" />
            <path d="M167.613 285.201C167.499 286.36 167.046 289.143 167.05 289.451L194.581 291.798L195.057 286.777L167.613 285.201Z" fill="#1E2B39" />
            <path d="M167.037 289.388C166.802 292.28 172.78 295.127 180.39 295.747C188.001 296.367 194.361 294.526 194.597 291.634C194.832 288.742 188.854 285.895 181.244 285.275C173.633 284.655 167.273 286.497 167.037 289.388Z" fill="#1E2B39" />
            <path d="M167.65 285.226C167.365 288.013 173.268 290.643 180.835 291.1C188.402 291.557 194.767 289.668 195.052 286.881C195.337 284.093 189.434 281.464 181.867 281.007C174.3 280.55 167.935 282.439 167.65 285.226Z" fill="#2B3B4D" />
            <path opacity="0.1" d="M171.724 285.472C171.526 287.411 175.649 289.241 180.933 289.56C186.218 289.879 190.662 288.566 190.86 286.628C191.058 284.689 186.935 282.859 181.651 282.54C176.366 282.221 171.922 283.534 171.724 285.472Z" fill="white" />
            <path d="M183.663 285.767L179.011 283.899L178.981 281.585L177.438 281.605L177.384 277.363L191.672 278.722L191.737 283.735L189.027 282.999L189.008 281.456L183.663 285.767Z" fill="#536B85" />
            <path d="M69.1246 267.064C68.6965 268.147 67.4958 270.698 67.4148 270.996L93.2376 280.826L95.076 276.13L69.1246 267.064Z" fill="#1E2B39" />
            <path d="M67.4221 270.93C66.4 273.645 71.3645 278.027 78.5106 280.717C85.6567 283.407 92.2784 283.386 93.3005 280.67C94.3226 277.955 89.3582 273.573 82.212 270.883C75.0659 268.193 68.4443 268.214 67.4221 270.93Z" fill="#1E2B39" />
            <path d="M69.1536 267.098C68.1129 269.7 73.0649 273.852 80.2142 276.373C87.3634 278.894 94.0027 278.829 95.0434 276.228C96.0841 273.627 91.1322 269.474 83.9829 266.953C76.8336 264.432 70.1943 264.497 69.1536 267.098Z" fill="#2B3B4D" />
            <path opacity="0.1" d="M73.0039 268.455C72.2801 270.264 75.7408 273.158 80.7335 274.918C85.7262 276.679 90.3603 276.64 91.0841 274.831C91.8079 273.022 88.3472 270.128 83.3545 268.367C78.3618 266.607 73.7277 266.646 73.0039 268.455Z" fill="white" />
            <path d="M84.403 272.025L80.4443 268.948L81.0523 266.716L79.5639 266.31L80.6786 262.217L94.0416 267.455L92.7242 272.293L90.3221 270.839L90.7274 269.351L84.403 272.025Z" fill="#536B85" />
            <path d="M294.875 242.238C296.304 239.965 295.968 242.354 297.579 243.095C299.191 243.837 300.15 240.975 300.505 243.793C300.86 246.61 300.551 247.791 301.257 248.141C301.964 248.491 301.664 250.492 298.169 249.649C298.002 251.169 298.269 252.711 298.937 254.086C299.963 255.432 308.425 257.989 308.785 263.045C309.145 268.1 300.637 266.891 299.109 266.546C297.58 266.201 296.656 270.199 291.946 268.531C287.237 266.863 286.54 265.552 287.837 259.296C289.135 253.04 294.671 248.701 294.671 248.701C294.671 248.701 293.934 243.756 294.875 242.238Z" fill="#10242E" />
            <path opacity="0.1" d="M294.875 242.238C296.304 239.965 295.968 242.354 297.579 243.095C299.191 243.837 300.15 240.975 300.505 243.793C300.86 246.61 300.551 247.791 301.257 248.141C301.964 248.491 301.664 250.492 298.169 249.649C298.002 251.169 298.269 252.711 298.937 254.086C299.963 255.432 308.425 257.989 308.785 263.045C309.145 268.1 300.637 266.891 299.109 266.546C297.58 266.201 296.656 270.199 291.946 268.531C287.237 266.863 286.54 265.552 287.837 259.296C289.135 253.04 294.671 248.701 294.671 248.701C294.671 248.701 293.934 243.756 294.875 242.238Z" fill="white" />
            <path d="M275.702 239.311C285.795 235.574 286.902 228.027 278.969 226.462C271.036 224.896 268.082 228.069 274.126 229.739C280.169 231.409 289.856 223.341 278.969 217.61" stroke="#10242E" strokeWidth="0.521904" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="2.09 2.09" />
        </g>
    </svg>
);