import { useRemovalDatesQuery } from "../../../data/dates";
import { Container } from "../../../types/Container";
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { ONE_DAY } from '../../../data/_base';
import { useEmptyingsOverviewQuery } from '../../../data/emptyings';
import { deDE, enUS } from '@mui/x-date-pickers/locales';
import { useTranslation } from "react-i18next";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/de';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import styled from '@emotion/styled';
import { IconCalendar } from '../../../components/Icons';
import { MessageWrapper } from '../../../components/MessageWrapper';
import { TextField } from "@mui/material";

type RemovalDateChooserProps = {
    container?: Container;
    defaultValue?: Date;
    minDate?: Date;
    onChange: (date?: Date) => void;
    onPossibleDatesChanged?: (dates: Date[]) => void;
    dateFormat: 'short' | 'long',
    disabled?: boolean;
    limitToSchedulableDates: boolean;
    autoSelectFirstSchedulableDate?: boolean;
    singleRemovalDateChooser?: boolean;
}

type StyledDivProps = {
    singleRemovalDateChooser?: boolean;
}

const StyledDiv = styled.div<StyledDivProps>`
    > div {
        ${({ singleRemovalDateChooser }) => singleRemovalDateChooser && `
            width: 312px;
        `}

        @media (max-width: 480px) {
            width: 100%;
        }
    }
    fieldset { border: none; }
`;

export const RemovalDateChooser = ({ dateFormat, container, defaultValue, minDate, onChange, onPossibleDatesChanged, disabled, limitToSchedulableDates, autoSelectFirstSchedulableDate, singleRemovalDateChooser }: RemovalDateChooserProps) => {
    const { i18n, t } = useTranslation();

    const fromDate = useMemo(() => minDate ? minDate : new Date(new Date().getTime() + ONE_DAY), [minDate]);
    const toDate = useMemo(() => {
        const date = new Date();
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);
        date.setFullYear(date.getFullYear() + 1);
        date.setMonth(11);
        date.setDate(31);
        return date;
    }, []);

    const removalDatesQuery = useRemovalDatesQuery(fromDate, toDate, container?.removalArea);

    const scheduledEmptyingsQuery = useEmptyingsOverviewQuery(fromDate, toDate, container);
    const [possibleDates, setPossibleDates] = useState<Date[]>([]);
    const [selectedDate, setSelectedDate] = useState<Date | undefined>(defaultValue);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        dayjs.locale(i18n.language);
    }, [i18n.language]);

    useEffect(() => {
        onChange(selectedDate)
    }, [onChange, selectedDate]);

    useEffect(() => {
        if (defaultValue && container) {
            setSelectedDate(defaultValue);
        }
    }, [container, defaultValue]);

    useEffect(() => {
        if (removalDatesQuery.data && scheduledEmptyingsQuery.data) {
            const scheduledEmptyingDates = scheduledEmptyingsQuery.data.map(e => e.removalDate.date.toDateString());
            const updatedPossibleDates = removalDatesQuery.data.filter(removalDate => scheduledEmptyingDates.indexOf(removalDate.toDateString()) < 0);
            setPossibleDates(updatedPossibleDates);
            if (onPossibleDatesChanged) {
                onPossibleDatesChanged(updatedPossibleDates);
            }
            const currentSelectedDateIsValid = !limitToSchedulableDates || updatedPossibleDates.filter(d => d.toDateString() === selectedDate?.toDateString()).length > 0;
            if (!currentSelectedDateIsValid && selectedDate) {
                setSelectedDate(undefined);
            }
            if (!selectedDate && autoSelectFirstSchedulableDate && updatedPossibleDates.length > 0 ) {
                setSelectedDate(updatedPossibleDates[0]);
            }
        }
    }, [onPossibleDatesChanged, removalDatesQuery.data, scheduledEmptyingsQuery.data, selectedDate, limitToSchedulableDates, autoSelectFirstSchedulableDate]);

    useEffect(() => {
        if (selectedDate) {
            const currentValue = selectedDate;
            const minValue = minDate || fromDate;
            minValue.setHours(0);
            minValue.setMinutes(0);
            minValue.setSeconds(0);
            minValue.setMilliseconds(0);
            currentValue.setHours(0);
            currentValue.setMinutes(0);
            currentValue.setSeconds(0);
            currentValue.setMilliseconds(0);
            if (currentValue.getTime() < minValue.getTime()) {
                setSelectedDate(minValue)
            }
        }
    }, [selectedDate, minDate, fromDate]);

    useEffect(() => {
        if (defaultValue) {
            setSelectedDate(defaultValue);
        }
    }, [defaultValue]);

    const possibleDatesAsString = possibleDates.map(d => d.toLocaleDateString());
    const datePickerDisabled = disabled || !container || !removalDatesQuery.isSuccess || removalDatesQuery.isLoading || scheduledEmptyingsQuery.isLoading;
    const dateFormatTemplate = i18n.language === 'de' ?
        (dateFormat === 'short' ? "DD. MMMM YYYY" : "dddd, DD. MMMM YYYY") :
        (dateFormat === 'short' ? "MMMM DD, YYYY" : "dddd, MMMM DD YYYY");

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.language}>
            <StyledDiv singleRemovalDateChooser={singleRemovalDateChooser} style={{display: 'inline'}} >
                <DatePicker
                    sx={{
                        '& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl': {
                            border: '1px solid #E0E1E0 !important',
                            height: '44px',
                            boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, 0.08) inset',
                            fontFamily: 'Satoshi Regular, sans-serif !important',
                        },
                        "& .MuiOutlinedInput-root": {
                            "& > fieldset": {
                                borderColor: 'transparent',
                            },
                            "&:hover > fieldset": { borderColor: "#3371EA" },
                        },
                    }}
                    open={open}
                    onOpen={() => setOpen(true)}
                    onClose={() => setOpen(false)}
                    disabled={datePickerDisabled}
                    value={selectedDate && !datePickerDisabled ? dayjs(selectedDate) : null}
                    minDate={dayjs(minDate || fromDate)}
                    maxDate={dayjs(toDate)}
                    localeText={(i18n.language === 'de' ? deDE : enUS).components.MuiLocalizationProvider.defaultProps.localeText}
                    format={dateFormatTemplate}
                    shouldDisableDate={(day) => limitToSchedulableDates && possibleDatesAsString.indexOf(day.toDate().toLocaleDateString()) < 0}
                    onChange={d => { setSelectedDate(d ? d.toDate() : undefined)}}
                    slotProps={
                        {
                            textField: {
                                onClick:() => setOpen(true) ,
                                placeholder: selectedDate ? dayjs(selectedDate).format(dateFormatTemplate): (i18n.language === 'de' ? 'TT / MM / JJJJ': 'DD / MM / YYYY')
                            }
                        }
                    }
                    slots={{
                        openPickerIcon: () => <IconCalendar disabled={datePickerDisabled} />,
                    }}
                    disablePast={true}
                />
                {/* RESOLVED: style this message if no date is possible. and clarify message text with Dani */}
                {container && limitToSchedulableDates && removalDatesQuery.data && possibleDates.length === 0 && (
                    <MessageWrapper
                        messageText={t("pages.emptying.series.modalPlanSerialEmptying.noPossibleDatesFound")}
                        fontSize="10px"
                        lineHeight="18px"
                        textMargin={"14px 0 0"}
                        justifyContent={"flex-start"}
                    />
                )}
                {removalDatesQuery.isError && (
                    <MessageWrapper messageText={removalDatesQuery.error.message} 
                        fontSize="10px"
                        lineHeight="18px"
                        textMargin={"12px 0 0"}
                        justifyContent={"flex-start"} />
                )}
                {scheduledEmptyingsQuery.isError && (
                    <MessageWrapper messageText={scheduledEmptyingsQuery.error.message} 
                        fontSize="10px"
                        lineHeight="18px"
                        textMargin={"14px 0 0"}
                        justifyContent={"flex-start"} />
                )}
            </StyledDiv>
        </LocalizationProvider>
    )
}
