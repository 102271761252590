import styled from "@emotion/styled";
import { Select, MenuItem, SelectChangeEvent } from "@mui/material";
import { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../contexts/UserContext";
import { useContainersQuery } from "../../data/containers";
import { styles } from "../../styles";
import { ContainerRef } from "../../types/Container";

const StyledContainerSelector = styled(Select)`
  border: 1px solid ${styles.neutral200};
  height: 40px;
  width: 50%;
  box-shadow: ${styles.boxShadowInset};
`;

const StyledMenuItem = styled(MenuItem)({
  color: styles.textColorSecondary700,
  fontFamily: 'Satoshi Regular',
  fontSize: '14px',
  lineHeight: '22px',
});

export const ContainerDropdownSelector = ({ initialContainer, onChange }: { initialContainer: ContainerRef | undefined, onChange: (container?: ContainerRef) => void }) => {
    const [selectedContainer, setSelectedContainer] = useState<ContainerRef | undefined>(initialContainer);
    const userContext = useContext(UserContext);
    const { t } = useTranslation();
    const containersQuery = useContainersQuery(userContext.accessPermission?.accountRef);
  
    const handleChange = (event: SelectChangeEvent<unknown>) => {
      const containerId = event.target.value as string;
      const containers = containersQuery.data?.filter(c => c.id === containerId)
      setSelectedContainer(containers && containers.length > 0 ? containers[0] : undefined);
    };
  
    useEffect(() => {
      onChange(selectedContainer);
    }, [onChange, selectedContainer]);
  
  
    return (
      <StyledContainerSelector 
        value={selectedContainer && containersQuery.data && containersQuery.data.find(c => c.id === selectedContainer.id) ? selectedContainer.id : 'all'} 
        onChange={handleChange} 
        sx={{
          boxShadow: 'none',
          position: 'relative',
          color: styles.textColorSecondary700,
          fontFamily: 'Satoshi Regular',
          fontSize: '14px',
          lineHeight: '22px',
          '.MuiOutlinedInput-notchedOutline': { border: 'none' },
          '.MuiSelect-select': { display: 'flex' },
          '.MuiSelect-outlined': { border: 'none' },
          '.MuiSelect-icon': { color: '#26374A' },
        }}
        MenuProps={{
          disableScrollLock: true,
        }}
      >
        <StyledMenuItem value={'all'}>{ t("pages.home.emptyingsOverviewSection.allContainersOption") }</StyledMenuItem>
        {containersQuery.data?.map(container =>
          <StyledMenuItem key={container.id} value={container.id}>{container.displayName}</StyledMenuItem>
        )}
      </StyledContainerSelector>
    );
  };
