import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { styles } from "../../../../styles";
import { ContactForm } from "./components/ContactForm";
import { Toast } from "../../../../components/Toast";
import { UserContext } from "../../../../contexts/UserContext";
import { useAccountQuery } from "../../../../data/accounts";
import { Loader } from "../../../../components/Loader";

const PageWrapper = styled.div`
    color: ${styles.textColorSecondary700};
`;

const Header = styled.h2`
    margin-bottom: 24px;
`;

export const Contact = () => {
    const { t } = useTranslation();
    const userContext = useContext(UserContext);
    const accountQuery = useAccountQuery(userContext.accessPermission?.accountRef.id);

    const [isSent, setIsSent] = useState(false);
    const [showToast, setShowToast] = useState(false);

    const handleToastCloseComplete = () => {
        setShowToast(false);
    };

    const handleSentEvent = () => {
        setIsSent(true);
        setShowToast(true);
    };

    if (accountQuery.isLoading) return <Loader height={"430px"} width={"536px"} />;
    if (!accountQuery.data) return null;
    if (!userContext.loggedInUser) return null;

    const loggedInContact = userContext.loggedInUser.contact;
    return (
        <PageWrapper>
            <section>
                {
                    isSent ? (
                        <p>{t("pages.help.contact.messageSentText")}</p>
                    ) : (
                        <>
                            <Header>{t("pages.help.contact.header")}</Header>
                            <ContactForm onSent={handleSentEvent} accountName={accountQuery.data.name} defaultValues={{
                                email: loggedInContact.email || '',
                                firstName: loggedInContact.firstName || '',
                                lastName: loggedInContact.lastName || '',
                                companyName: accountQuery.data.name || '',
                                phone: loggedInContact.phone || accountQuery.data.phone || '',
                                street: `${[accountQuery.data.street, accountQuery.data.houseNumber].filter(s => s).join(' ')}`,
                                postalCodeAndCity: `${[accountQuery.data.postalCode, accountQuery.data.city].filter(s => s).join(' ')}`,
                                message: '',
                                category: ''
                            }}/>
                        </>
                    )
                }
            </section>
            
            {showToast && <Toast message={t("toasts.requestSent")} type="success" onCloseComplete={handleToastCloseComplete} />}
        </PageWrapper>
    )
}