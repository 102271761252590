import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { OverviewSection } from "./components/OverviewSection";

const StyledH1 = styled.h1`
    margin-bottom: 48px;
`;

export const ContainerOverviewPage = () => {
    const { t } = useTranslation();

    return (
        <>
            <StyledH1>{t("pages.myContainers.header")}</StyledH1>
            <OverviewSection />
        </>
    )
}