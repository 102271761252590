import styled from "@emotion/styled";
import { useContext, useState, useEffect } from "react";
import { UserContext } from "../contexts/UserContext";
import { NavLink } from "react-router-dom";
import { LoginLogout } from "../components/LoginLogout";
import { EnvironmentBatch } from "../components/EnvironmentBatch"
import AccountChooser from "../components/AccountChooser";
import { UserSettingsChooser } from "../components/UserSettingsChooser";
import { IconQuestion } from "../components/Icons";
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import Container from "./Container";
import NotificationsToggle from "../components/notifications/NotificationsToggle";
import { ROUTES } from "./Routes";
import { styles } from "../styles";

const PageHeaderWrapper = styled.div`  
    display: flex;
    position: fixed;
    z-index: 1;
    background-color: #fff;
    top: 0px;
    width: calc(100% - 344px);
    max-width: 1052px;

    @media (max-width: 1919px) {
        width: calc(100% - 320px);
    }

    @media (max-width: 1640px) {
        width: calc(100% - 296px);
    }

	@media (max-width: 1348px) {
        padding: 0 24px 0 0;
    }

    @media (max-width: 1279px) {
        width: calc(100% - 272px);
    }

	@media (max-width: 962px) {
		display: none;
	}
`;

type StyledHeaderProps = {
	showShadow: boolean;
  };

const StyledHeader = styled.div<StyledHeaderProps>`
	display: flex;
	justify-content: flex-end;
	height: 100px;
	padding: 36px 0 20px;
	text-align: right;
	position: relative;
	box-sizing: border-box;
	width: 100%;
	box-shadow: ${props => props.showShadow ? '0px 4px 4px -4px rgba(0, 0, 0, .12)' : 'none'};


	@media (max-width: 1372px) {
        padding: 36px 24px 20px 0;
    }
`;

const RightSideContentWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
`;

type RoundIconWrapperProps = {
	isActive: boolean;
};

const RoundIconWrapper = styled.div<RoundIconWrapperProps>`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 44px;
	height: 44px;
	margin-left: 12px;
	background-color: ${props => props.isActive ? '#26374A' : styles.neutral200};
	border-radius: 100px;
	transition: .2s background-color ease;

	&:hover {
		background-color: ${styles.neutral300};
	}

	${props => props.isActive && `
		svg path {
			stroke: #fff;
		}
	`}
`;

export const PageHeader = () => {
	const userContext = useContext(UserContext);
	const currentUser = userContext.loggedInUser;
	const location = useLocation();
	const pathname = location.pathname.toLowerCase()
	const isOnHelpAndContact = pathname === ROUTES.Manuals || pathname === ROUTES.Faq || pathname === ROUTES.Contact;
	const { t } = useTranslation();
	const [showShadow, setShowShadow] = useState(false);

	useEffect(() => {
		const handleScroll = () => {
			if (window.scrollY > 10) {
				setShowShadow(true);
			} else {
				setShowShadow(false);
			}
		};
	
		window.addEventListener('scroll', handleScroll);
	
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<PageHeaderWrapper>
			<Container>
				<StyledHeader showShadow={showShadow}>
					<RightSideContentWrapper>
						<AccountChooser />
						<UserSettingsChooser />
						{!currentUser && <LoginLogout />}
						{currentUser && <NotificationsToggle />}
						{currentUser &&
							<NavLink to={ROUTES.Manuals}>
								<Tooltip arrow componentsProps={{
									tooltip: {
										sx: {
											bgcolor: '#26374A',
											'& .MuiTooltip-arrow': {
												color: '#26374A',
											},
										},
									},
								}} title={t("navigation.top.helpAndContact")}>
								<RoundIconWrapper isActive={isOnHelpAndContact}><IconQuestion /></RoundIconWrapper></Tooltip>
							</NavLink>
						}
						<EnvironmentBatch /> 
					</RightSideContentWrapper>
				</StyledHeader>
			</Container>
		</PageHeaderWrapper>
	)
}