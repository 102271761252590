import { useState, useEffect, useContext } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { GridRenderCellParams, deDE, enUS } from '@mui/x-data-grid';
import { useTranslation } from "react-i18next";
import { useContactsQuery } from "../../../../data/contacts"
import { useActiveInvitationsQuery, useInvitationReactivationMutation } from "../../../../data/invitations";
import { EmployeeListItem, convertContactAssociationsToListItems, convertInvitationsToListItems } from "./Helpers";
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import styled from "@emotion/styled";
import { styles } from "../../../../styles";
import { IconCircleCheckSelected, IconDeleteSmall, IconEditPen, IconQuestionCircleLineLarge, IconReactivate } from '../../../../components/Icons';
import Modal from '../../../../components/Modal';
import { DeleteEmployeeModalContent } from './DeleteEmployeeModalContent';
import { EditEmployeeModalContent } from './EditEmployeeModalContent';
import { useContainersQuery } from '../../../../data/containers';
import { UserContext } from '../../../../contexts/UserContext';
import { Container, ContainerRef } from '../../../../types/Container';
import { Invitation } from '../../../../types/Invitation';
import { StateCode } from '../../../../types/StateCode';
import { Loader } from '../../../../components/Loader';
import { MessageWrapper } from '../../../../components/MessageWrapper';


type Column = {
    field: string;
    headerName: string;
    sortable?: boolean;
    flex?: number;
    renderCell?: (params: GridRenderCellParams) => JSX.Element;
}

const StyledH2 = styled.h2`
    margin-bottom: 12px;
`;

const StatusPill = styled.div<{ bg: string }>`
    display: flex;
    align-items: center;
    padding: 2px 8px;
    height: 18px;
    background: ${(props) => props.bg};
    color: ${styles.textColorSecondary900};
    text-align: center;
    border-radius: 100px;
    font-size: 10px;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;

  @media (max-width: 568px) {
      width: initial;
  }
`;

const Separator = styled.div`
  height: 20px;
  width: 1px;
  background-color: ${styles.neutral300};
`;

const MobileRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 12px;
    word-break: break-all;

    div {
        width: 25%;
    }
`;

const MobileHeaderRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    border-bottom: 1px solid ${styles.neutral300};

    div {
        width: 25%;
    }
`;

const MobileHeader = styled.div`
    color: ${styles.textColorSecondary700};
    margin-bottom: 8px;
`;

const MobileData = styled.div`
    margin-bottom: 8px;
`;

const IconsRow = styled.div`
    display: flex;
    justify-content: flex-end;
    border-bottom: 1px solid ${styles.neutral200};
    margin-bottom: 24px;
`;



const useWindowWidth = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowWidth(window.innerWidth);
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        }
    }, []);

    return windowWidth;
}

const renderContainerCell = (sharedContainers: ContainerRef[], allContainers: Container[]) => {
    if (sharedContainers && sharedContainers.length > 1) {
        return (
            <Tooltip arrow componentsProps={{
                tooltip: {
                    sx: {
                        bgcolor: '#26374A',
                        '& .MuiTooltip-arrow': {
                            color: '#26374A',
                        },
                    },
                },
            }} title={sharedContainers.map(container => <p key={container.id}>{allContainers.find(c => c.id === container.id)?.displayName || container.id}</p>)}>
                <IconButton>
                    <MoreHorizIcon />
                </IconButton>
            </Tooltip>
        )
    } else if (sharedContainers && sharedContainers.length === 1) {
        return <span>{allContainers.find(c => c.id === sharedContainers[0].id)?.displayName || sharedContainers[0].id}</span>;
    } else {
        return <span>"N/A"</span>;
    }
}

export const EmployeesList = () => {
    const [employeeToDelete, setEmployeeToDelete] = useState<EmployeeListItem>();
    const onDeleteEmployeeModalClosed = () => setEmployeeToDelete(undefined);
    const [employeeToEdit, setEmployeeToEdit] = useState<EmployeeListItem>();
    const onEditEmployeeModalClosed = () => setEmployeeToEdit(undefined);

    const windowWidth = useWindowWidth();
    const viewType: 'mobile' | 'tablet' | 'desktop' = windowWidth <= 480 ? 'mobile' : windowWidth > 834 ? 'desktop' : 'tablet';

    const userContext = useContext(UserContext);
    const accessPermission = userContext.accessPermission;
    const contactsQuery = useContactsQuery();
    const invitationsQuery = useActiveInvitationsQuery();
    const containersQuery = useContainersQuery(accessPermission?.accountRef);
    const { t, i18n } = useTranslation();

    if (contactsQuery.isError) return (
        <MessageWrapper messageText={contactsQuery.error?.message} height={"420px"} margin={"0 0 48px 0"} />
    );

    if (invitationsQuery.isError) return (
        <MessageWrapper messageText={invitationsQuery.error?.message} height={"420px"} margin={"0 0 48px 0"} />
    );

    const contacts = contactsQuery.data && convertContactAssociationsToListItems(contactsQuery.data, t);
    const invitations = invitationsQuery.data && convertInvitationsToListItems(invitationsQuery.data, t);
    const employees = contacts && invitations && contacts.concat(invitations).sort((a, b) => `${a.lastName} ${a.firstName}`.localeCompare(`${b.lastName} ${b.firstName}`));

    const IconsWrapper = ({ employee, curContactId }: { employee: EmployeeListItem, curContactId?: string }) => {
        const invationReactivationMutation = useInvitationReactivationMutation();
        const isSelf = curContactId === employee.contactId;

        const handleEdit = () => {
            setEmployeeToEdit(employee);
        }

        const handleDelete = () => {
            setEmployeeToDelete(employee);
        }

        const handleReactivation = () => {
            invationReactivationMutation.mutate((employee.typedObject as Invitation));
        }

        if (isSelf) return null;
        if (employee.type === 'invitation' && (employee.typedObject as Invitation).stateCode === StateCode.Inactive) {
            return (
                <IconWrapper>
                    {invationReactivationMutation.isLoading && !invationReactivationMutation.isIdle &&
                        <div>...</div>
                    }
                    {!invationReactivationMutation.isLoading && invationReactivationMutation.isIdle &&
                        <IconButton onClick={handleReactivation} title='Aktualisieren'>
                            <IconReactivate />
                        </IconButton>
                    }
                </IconWrapper>
            )
        }

        return (
            <IconWrapper>
                <IconButton onClick={handleEdit}>
                    <IconEditPen />
                </IconButton>

                <Separator />

                <IconButton onClick={handleDelete}>
                    <IconDeleteSmall />
                </IconButton>
            </IconWrapper>
        );
    };

    const renderMobileView = () => {

        if (contactsQuery.isLoading || invitationsQuery.isLoading || containersQuery.isLoading) {
            return (
                <Loader height={"420px"} margin={"0 0 48px 0"} />
            )
        }

        return (
            <div>
                <MobileHeaderRow>
                    <MobileHeader>{t("pages.myOrganization.firstName")}</MobileHeader>
                    <MobileHeader>{t("pages.myOrganization.surname")}</MobileHeader>
                    <MobileHeader>{t("pages.myOrganization.rights")}</MobileHeader>
                    <MobileHeader>{t("pages.myOrganization.container")}</MobileHeader>
                </MobileHeaderRow>
                {employees ? employees.map((employee, index) => (
                    <>
                        <MobileRow key={index}>
                            <MobileData>{employee.firstName}</MobileData>
                            <MobileData>{employee.lastName}</MobileData>
                            <MobileData>{employee.roleName}</MobileData>
                            <MobileData>
                                {employee.containers && employee.containers.length > 1 ? (
                                    <Tooltip arrow componentsProps={{
                                        tooltip: {
                                            sx: {
                                                bgcolor: '#26374A',
                                                '& .MuiTooltip-arrow': {
                                                    color: '#26374A',
                                                },
                                            },
                                        },
                                    }} title={employee.containers.map((container: any) => <p key={container.id}>{containersQuery.data?.find(c => c.id === container.id)?.displayName || container.id}</p>)}>
                                        <IconButton>
                                            <MoreHorizIcon />
                                        </IconButton>
                                    </Tooltip>
                                ) : employee.containers && employee.containers.length === 1 ? (
                                    employee.containers[0].id
                                ) : 'N/A'}
                            </MobileData>
                        </MobileRow>
                        {accessPermission?.roles?.permission &&
                            <IconsRow>
                                <IconsWrapper employee={employee} curContactId={accessPermission?.contactId} />
                            </IconsRow>
                        }
                    </>
                )) : <MessageWrapper messageText={t("components.tables.noEntriesAvailable") as string} messageType="textOnly" height={"360px"} />}
            </div>
        )
    }

    const renderTabletAndDesktopView = () => {
        const columns: Column[] = [];
        columns.push({ field: 'lastName', headerName: t("pages.myOrganization.surname"), flex: 2 });
        columns.push({ field: 'firstName', headerName: t("pages.myOrganization.firstName"), flex: 2 });
        if (viewType === 'desktop') {
            columns.push({ field: 'email', headerName: t("pages.myOrganization.email"), flex: 3 });
        }
        columns.push({ field: 'roleName', headerName: t("pages.myOrganization.rights"), flex: 2 })
        columns.push({
            field: 'containers', headerName: t("pages.myOrganization.container"), flex: 1.6,
            renderCell: (params: GridRenderCellParams<EmployeeListItem>) => {
                return renderContainerCell(params.row.containers, containersQuery.data || []);
            }
        });
        if (viewType === 'desktop') {
            columns.push({
                field: 'status', headerName: t("pages.myContainers.status"), flex: 1,
                renderCell: (params: GridRenderCellParams<EmployeeListItem>) => {
                    let background = '#E9F6EB';
                    if (params.row.type === 'invitation') {
                        background = (params.row.typedObject as Invitation).stateCode === StateCode.Active ? '#DEE9FC' : '#FBD5D5';
                    }
                    return (
                        <StatusPill bg={background}>
                            {params.row.type === 'contact'
                                ? t('pills.active')
                                : (params.row.typedObject as Invitation).stateCode === StateCode.Active ? t('pills.invited') : t('pills.expired')
                            }
                        </StatusPill>
                    )
                },
            });
        }
        if (accessPermission?.roles?.permission) {
            columns.push({
                field: 'file', headerName: '', flex: 2,
                renderCell: (params: GridRenderCellParams<EmployeeListItem>) => {
                    return <IconsWrapper employee={params.row} curContactId={accessPermission.contactId} />
                },
            });
        }

        if (contactsQuery.isLoading || invitationsQuery.isLoading || containersQuery.isLoading) {
            return (
                <Loader height={"420px"} margin={"0 0 48px 0"} />
            )
        }

        if (employees) return (
            <DataGrid
                sx={{
                    '& .MuiDataGrid-row': {
                        borderBottom: `1px solid ${styles.neutral200}`,
                    },
                    '& .MuiDataGrid-cell': {
                        border: 'none'
                    },
                    '& .MuiDataGrid-withBorderColor': {
                        borderColor: `${styles.neutral300}`
                    },
                }}
                initialState={{
                    pagination: {
                        paginationModel: { pageSize: 10, page: 0 },
                    },
                }}
                localeText={(i18n.language === 'de' ? deDE : enUS).components.MuiDataGrid.defaultProps.localeText}
                rows={employees}
                columns={columns}
                className="table-grid"
                style={{ border: 'none' }}
                disableColumnSelector
                hideFooterSelectedRowCount={true}
                rowHeight={72}
            />
        )
    }

    return (
        <div>
            <StyledH2>{t("pages.myOrganization.employees")}</StyledH2>
            {/* RESOLVED: Handle error and loading state */}
            {/* RESOLVED: introduce colors for status pills */}
            {viewType === 'mobile' ? renderMobileView() : renderTabletAndDesktopView()}
            {employeeToDelete &&
                <Modal onClose={onDeleteEmployeeModalClosed}>
                    <DeleteEmployeeModalContent employeeToDelete={employeeToDelete} onClose={onDeleteEmployeeModalClosed} />
                </Modal>
            }
            {employeeToEdit &&
                <Modal onClose={onEditEmployeeModalClosed}>
                    <EditEmployeeModalContent employeeToEdit={employeeToEdit} onClose={onEditEmployeeModalClosed} />
                </Modal>
            }
        </div>
    )
}