import React, { ReactNode } from 'react';
import styled from '@emotion/styled';

const StyledContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

type ContainerProps = {
	children: ReactNode;
}

// TODO: consider renaming this component because Container is kinda reserved word at KVA

const Container: React.FC<ContainerProps> = ({ children }) => (
	<StyledContainer>
		{children}
	</StyledContainer>
);

export default Container;