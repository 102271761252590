import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { styles } from "../../../../styles";
import { IconClose } from "../../../../components/Icons";
import Button from '../../../../components/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { TranslationWithBoldedText } from "../TranslationWithBoldedText";
import { Container } from "../../../../types/Container";
import { Emptying } from "../../../../types/Emptying";
import { useState } from "react";
import { useCancelEmptyingMutation } from "../../../../data/emptyings";
import { Loader } from "../../../../components/Loader";

export type EmptyingCancellingInfo = {
    allAtThisSingleDate?: Date;
    scheduledEmptyings: {
        container?: Container;
        emptying: Emptying
    }[]
}
type CancelModalProps = {
    data: EmptyingCancellingInfo;
    onClose: (dataChanged: boolean) => void;
}

const ModalContentWrapper = styled.div`
    padding-right: 24px;
    padding-top: 24px;
    
    h2 {
        margin-bottom: 36px;
    }

    p {
        color: ${styles.textColorSecondary700};
    }

    @media (max-width: 480px) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }
`;

const ModalHeading = styled.h2`
    margin-bottom: 48px;
    color: ${styles.textColorSecondary700};
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg {
        cursor: pointer;
    }
`;

const BottomRow = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    margin-top: 48px;

    button {
        width: unset;
        margin-bottom: 0;
    }

    @media (max-width: 480px) {
        margin-top: auto;
        flex-direction: column;

        button {
            width: 100%;
            margin-bottom: 0;
        }
    }
`;

const StyledH3 = styled.h3`
    font-size: 12px;
    line-height: 16px;
    margin-top: 24px;
    margin-bottom: 4px;
    width: 100%;
`;

const StyledSelect = styled(Select)(() => ({
    boxShadow: styles.boxShadowInset,
    height: '44px',
    backgroundColor: '#fff',
    borderRadius: '4px',
    width: '100%',
    boxSizing: 'border-box',
    padding: '0',
    fontFamily: 'Satoshi Regular',
    color: styles.textColorSecondary700,

    '.MuiSelect-icon': {
        color: '#26374A',
    },

    '& .MuiSelect-select': {
        paddingTop: '11px',
        paddingBottom: '11px',
        lineHeight: '22px',
        fontSize: '14px',
        boxShadow: styles.boxShadowInset,
    },
    '& .MuiOutlinedInput-root': {
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#e0e1e0',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#e0e1e0',
        },
    },
    '.MuiOutlinedInput-notchedOutline': {
        borderWidth: '1px',
    },
}));

export const CancelEmptyingModalContent: React.FC<CancelModalProps> = ({ onClose, data }) => {
    const { t, i18n } = useTranslation();
    const cancelEmptyingMutation = useCancelEmptyingMutation();

    const dateFormat = new Intl.DateTimeFormat(i18n.language, { dateStyle: 'full' });
    const [selectedEmptyingId, setSelectedEmptyingId] = useState<string>('all');

    const onCancelEmptyings = async () => {
        const emptyingsToCancel = data.scheduledEmptyings.filter(e => selectedEmptyingId === 'all' || e.emptying.id === selectedEmptyingId).map(e => e.emptying);
        for (let i = 0; i < emptyingsToCancel.length; i++) {
            await cancelEmptyingMutation.mutateAsync(emptyingsToCancel[i]);
        }
        onClose(true);
    }
    return (
        <ModalContentWrapper>
            <ModalHeading>{t("pages.home.cancelEmptyingModal.header")} <div onClick={() => { if (!cancelEmptyingMutation.isLoading) { onClose(false) } }}><IconClose /></div></ModalHeading>
            {/* RESOLVED: Make nice :-) */}
            {cancelEmptyingMutation.isLoading && (
                <Loader height={"114px"} />
            )}
            {!cancelEmptyingMutation.isLoading &&
                <>
                    {(data.allAtThisSingleDate || data.scheduledEmptyings.length === 1) &&
                        <p>
                            <TranslationWithBoldedText
                                textValue={dateFormat.format(data.scheduledEmptyings.length === 1 ? data.scheduledEmptyings[0].emptying.removalDate.date : data.allAtThisSingleDate)}
                                i18nKey="pages.home.cancelEmptyingModal.cancelationTextOnSingleDate" />
                        </p>
                    }
                    {!data.allAtThisSingleDate && data.scheduledEmptyings.length > 1 &&
                        <p>
                            <TranslationWithBoldedText
                                textValue={`${data.scheduledEmptyings.length}`}
                                i18nKey="pages.home.cancelEmptyingModal.cancelationTextOnMultipleDates" />
                        </p>
                    }
                    {data.scheduledEmptyings.length > 1 && data.allAtThisSingleDate && (
                        <>
                            <StyledH3>Container</StyledH3>
                            <StyledSelect
                                sx={{
                                    '.MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#e0e1e0',
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#3371EA',
                                        borderWidth: '1px'
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#e0e1e0',
                                    },
                                }}
                                value={selectedEmptyingId}
                                onChange={(e) => { setSelectedEmptyingId(e.target.value as string); console.log(e.target.value) }}
                            >
                                <MenuItem
                                    value="all"
                                    key="all"
                                    sx={{
                                        color: styles.textColorSecondary700,
                                        display: 'block',
                                        width: '100%',
                                        padding: '10px 20px',
                                        marginBottom: '8px',
                                        fontSize: '14px',
                                        fontFamily: 'Satoshi Regular, sans-serif',
                                    }}
                                >
                                    {t('pages.home.cancelEmptyingModal.allContainersOption')}
                                </MenuItem>
                                {data.scheduledEmptyings.map((scheduledEmptying) => {
                                    return (
                                        <MenuItem
                                            key={scheduledEmptying.emptying.id}
                                            value={scheduledEmptying.emptying.id}
                                            sx={{
                                                color: styles.textColorSecondary700,
                                                display: 'block',
                                                width: '100%',
                                                padding: '10px 20px',
                                                marginBottom: '8px',
                                                fontSize: '14px',
                                                fontFamily: 'Satoshi Regular, sans-serif',
                                            }}
                                        >
                                            {scheduledEmptying.container ? scheduledEmptying.container.displayName : scheduledEmptying.emptying.containerId}
                                        </MenuItem>
                                    )
                                })}
                            </StyledSelect>
                        </>
                    )}

                    <BottomRow>
                        <Button buttonVariant="ghost" onClick={() => onClose(false)}>
                            {t("buttons.abort")}
                        </Button>
                        <Button onClick={onCancelEmptyings}>
                            {t("buttons.cancel")}
                        </Button>
                    </BottomRow>
                </>
            }
        </ModalContentWrapper>
    );
};
