import { t } from "i18next"
import { env } from "../Environment";

export const ONE_MINUTE = 60 * 1000;
export const ONE_HOUR = 60 * ONE_MINUTE;
export const ONE_DAY = 24 * ONE_HOUR;
export const ONE_YEAR = 365 * ONE_DAY;

enum HttpStatusCode {
    Unauthorized = 401,
    NotFound = 404,
    Conflict = 409,
    Expired = 410,
    Accepted = 202,
    NotAcceptable = 406,
    BadRequest = 400
}


export const convertToErrorMsg = (status: HttpStatusCode, responseText: string): Error => {
    const errorMsg = new Error();
    errorMsg.message = t('errors.http.unknown') + `: ${responseText} (Status=${status})`;

    if (status === HttpStatusCode.Unauthorized && responseText === 'User not logged in') {
        errorMsg.message = t('errors.http.userNotLoggedIn');
    }
    if (status === HttpStatusCode.NotFound && responseText === 'Account Assignment Not Found in User Context') {
        errorMsg.message = t('errors.http.accountAssignmentNotFound');
    }
    if (status === HttpStatusCode.Unauthorized && responseText === 'Unsatisfied Permission Role Requirement detected') {
        errorMsg.message = t('errors.http.unsatisfiedPermissionRole');
    }
    if (status === HttpStatusCode.Unauthorized && responseText === 'Unsatisfied Container Role Requirement detected') {
        errorMsg.message = t('errors.http.unsatisfiedContainerRole');
    }
    if (status === HttpStatusCode.NotFound && responseText === 'Invitation not found') {
        errorMsg.message = t('errors.http.invitationNotFound');
    }
    if (status === HttpStatusCode.Expired && responseText === 'Invitation expired') {
        errorMsg.name = t('errors.http.invitationExpired');
        errorMsg.message = t('errors.http.invitationExpiredDetail');
    }
    if (status === HttpStatusCode.Conflict && responseText === 'Invitation already redeemed') {
        errorMsg.name = t('errors.http.invitationAlreadyRedeemed');
        errorMsg.message = t('errors.http.invitationAlreadyRedeemedDetail');
    }
    if (status === HttpStatusCode.NotAcceptable && responseText === 'Schedule Emptyings: Dates must be in the future') {
        errorMsg.message = t('errors.http.scheduleEmptyings.datesMustBeInFuture');
    }
    if (status === HttpStatusCode.NotAcceptable && responseText === 'Schedule Emptyings: Disallowed dates provided') {
        errorMsg.message = t('errors.http.scheduleEmptyings.disallowedDatesProvided');
    }
    if (status === HttpStatusCode.NotAcceptable && responseText === 'Schedule Emptyings: Contains already scheduled dates') {
        errorMsg.message = t('errors.http.scheduleEmptyings.containsAlreadyScheduledDates');
    }
    if (status === HttpStatusCode.NotAcceptable && responseText === 'Schedule Emptyings: Not enough bookable credit available') {
        errorMsg.message = t('errors.http.scheduleEmptyings.unsufficientBookableCredit');
    }
    if (status === HttpStatusCode.NotAcceptable && responseText === 'Schedule Emptyings: Credit processing is still on-going') {
        errorMsg.message = t('errors.http.scheduleEmptyings.creditProcessingStillOnGoing');
    }
    return errorMsg;
}

const regexDateFormat = /^(\d{4})-(\d{2})-(\d{2})/;
    
export const jsonReviverFunction = (key: string, value: any) => {
    const isDateValue = (typeof value === 'string' && regexDateFormat.exec(value));
    return isDateValue ? new Date(value): value;
}