import styled from "@emotion/styled";
import { styles } from '../styles';

type CalendarTextProps = {
  margin?: string;
};

export const CalendarText = styled.p<CalendarTextProps>`
  margin:  ${props => props.margin || 0};
  font-family: "Satoshi Bold";
  font-weight: 700;
  color:  ${styles.textColorSecondary700};
  text-align: center;
`;

type TextIconButtonProps = {
  bold?: boolean,
  svgMargin?: string,
}

export const TextIconButton = styled.button<TextIconButtonProps>`
  font-family: ${props => (props.bold ? "Satoshi Bold" : "Satoshi Regular")};
  font-weight: ${props => (props.bold ? 700 : 400)};
  font-size: ${props => (props.bold ? '14px' : '10px')};
  color: ${props => (props.disabled ? styles.neutral200 : (props.bold ? styles.textColorSecondary900: styles.textColorSecondary700))};
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};;
  
  svg {
    margin: ${props => (props.svgMargin || '0')};
  }
`;