import { useEffect, useState } from "react";
import { InitiatePaymentTransactionInputParam, InitiatePaymentTransactionResult, useInitPaymentTransactionMutation } from "../../data/payments";
import { NonExternalPaymentMethod } from "../../types/PaymentMethod";
import { UseMutationResult } from "@tanstack/react-query";
import { useSessionStorage } from "usehooks-ts";

export type PaymentManager = {
    initialized: boolean;
    initPaymentTransactionMutation: UseMutationResult<InitiatePaymentTransactionResult | null, Error, InitiatePaymentTransactionInputParam, unknown>
}

const completedPaymentIds: string[] = [];
const isNotYetCompletedPayment = (paymentTransactionId: string) => {
    if (paymentTransactionId && completedPaymentIds.indexOf(paymentTransactionId) < 0) {
        completedPaymentIds.push(paymentTransactionId);
        return true;
    }
    return false;
}
export const usePaymentManager = (onBeforeLeavingToExternalPayment: (paymentId: string) => void, onAfterExternalPayment: (paymentId: string, outcome: 'success' | 'failure') => Promise<void>, onAfterNonExternalPaymentMethod: (paymentTransactionId: string, paymentMethod: NonExternalPaymentMethod) => void): PaymentManager => {
    const [externalPaymentRunning, setExternalPaymentRunning] = useSessionStorage('active-external-payment', '');
    const [initialized, setInitialized] = useState(false);
    const initPaymentTransactionMutation = useInitPaymentTransactionMutation();

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const paymentParam = urlParams.get('payment');
        const outcomeParam = urlParams.get('outcome') as ('success' | 'failure');
        const throttler = setTimeout(async () => {
            if (paymentParam && outcomeParam && isNotYetCompletedPayment(paymentParam) && externalPaymentRunning) {
                setExternalPaymentRunning('');
                await onAfterExternalPayment(paymentParam, outcomeParam);
            } 
            setInitialized(true);
        }, 0);
        return () => clearTimeout(throttler);
    }, [onAfterExternalPayment, externalPaymentRunning]);

    useEffect(() => {
        if (initPaymentTransactionMutation.data) {
            if (initPaymentTransactionMutation.data.externalPaymentPageUrl) {
                setExternalPaymentRunning(initPaymentTransactionMutation.data.paymentTransactionId);
                onBeforeLeavingToExternalPayment(initPaymentTransactionMutation.data.paymentTransactionId);
                window.location.href = initPaymentTransactionMutation.data.externalPaymentPageUrl;
            } else {
                if (isNotYetCompletedPayment(initPaymentTransactionMutation.data.paymentTransactionId)) {
                    onAfterNonExternalPaymentMethod(initPaymentTransactionMutation.data.paymentTransactionId, initPaymentTransactionMutation.data.paymentMethod as NonExternalPaymentMethod);
                }
            }
        }
    }, [initPaymentTransactionMutation.data, onAfterNonExternalPaymentMethod, onBeforeLeavingToExternalPayment]);

    return {
        initialized,
        initPaymentTransactionMutation
    }
}