import styled from "@emotion/styled";

const ImprintContainer = styled.div`
    h2 {
        margin-top: 60px;
    }
    p {
        margin-top: 30px;
    }
	a:hover {
        text-decoration: underline;
    }
}`
 

export const ImprintInfosPage = () => {
    
    return <ImprintContainer>
            <h1>Impressum</h1>
            <h2>Domaininhaber/Inhalte</h2>
            <p>
                Verband KVA Thurgau<br/>
                Rüteliholzstrasse 5<br/>
                8570 Weinfelden<br/>
                Tel. +41 (0)71 626 96 00<br/>
                Fax +41 (0)71 626 96 10<br/>
                E-Mail <a href="mailto:info@kvatg.ch">info@kvatg.ch</a><br/>
            </p>
        </ImprintContainer>
}