export type GuideItem = {
    id: string;
    title: string;
    description?: string;
    videoUrl?: string;
    pdfUrl?: string;
    section?: GuideSection;
}

export enum GuideSection {
    StartedSuccessfully = 125160000,
    OrganizedEfficiently = 125160001
}