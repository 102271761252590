
import styled from "@emotion/styled";
import { Trans } from "react-i18next";
import { IconDeleteSmall, IconFileUpload } from "../../../components/Icons";
import { styles } from "../../../styles";
import { DragEvent, useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { IconButton } from "@mui/material";
type StyledFileUploadProps = {
    hasError?: boolean;
};
const StyledFileUpload = styled.label<StyledFileUploadProps>`
    flex: 1;
    box-shadow: ${styles.boxShadowInset};
    background-color: #fff;
    border: 1px solid ${props => props.hasError ? '#ed2b2b' : styles.neutral200};
    border-radius: 4px;
    box-sizing: border-box;
    line-height: 22px;
    font-size: 14px;
    color: ${styles.textColorSecondary700};
    font-family: 'Satoshi Regular';
    min-height: 124px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:focus {
        border: 1px solid #3371EA;
        outline: none;
    }

    &:disabled {
        background-color: #f5f5f5;
    }

    input[type="file"] {
        display: none;
    }

    svg {
        margin-bottom: 4px;
    }
`;

const UnderlinedText = styled.span`
    border-bottom: 1px solid ${styles.textColorSecondary700};
`;

type ImageUploadProps = {
    defaultValue?: string;
    onChange: (imageBase64Encoded: string) => void;
}

export const ImageUpload = ({ defaultValue, onChange }: ImageUploadProps) => {
    const [fileContent, setFileContent] = useState<string | undefined>(defaultValue);
    const [fileName, setFileName] = useState<string | undefined>(undefined);

    const loadFile = useCallback((file: File) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            let data = e.target?.result as string;
            if (data.indexOf('data:image') !== 0) {
                data = '';
            }
            setFileContent(data);
        }
        setFileName(file.name);
        reader.readAsDataURL(file);
    }, [setFileContent, setFileName]);

    const onDrop = useCallback((acceptedFiles: File[]) => {
        if (acceptedFiles && acceptedFiles.length > 0) {
            loadFile(acceptedFiles[0]);
        }
    }, [])

    const { getRootProps, isDragActive } = useDropzone({
        onDrop: onDrop,
        accept: {
            'image/png': ['.png'],
            'image/jpg': ['.jpg', '.jpeg'],
        }
    });

    useEffect(() => {
        let base64str = (fileContent || '').replace(/^data:image\/?[A-z]*;base64,/, '');
        onChange(base64str);
    }, [fileContent]);

    if (fileContent) {
        return (
            <div style={{display: "flex", gap:"8px", alignItems: 'center'}}>
                <span>{fileName}</span>
                <span>
                    <IconButton onClick={() => setFileContent(undefined)}>
                        <IconDeleteSmall />
                    </IconButton>
                    {/* RESOLVED: delete button should be on the right hand side of the file name */}
                </span>
            </div>
        );
    }

    return (
        <StyledFileUpload {...getRootProps()} style={{ border: isDragActive ? '2px solid red' : undefined }}>
            <IconFileUpload />
            <div >
                <Trans i18nKey="pages.myContainers.containerAddOrEditForm.dropFileOrBrowseFolder">
                    <UnderlinedText></UnderlinedText>
                </Trans>
            </div>
        </StyledFileUpload>
    )
}