import { useSessionStorage } from 'usehooks-ts'
import React, { useCallback, useEffect, useState } from "react";
import { env } from "../Environment";
import { LoggedInUserInfo } from "../types/LoggedInUserInfo";
import { useLoggedInContactQuery } from "../data/contacts";
import { AccessPermission } from "../types/AccessPermission";
import { useTranslation } from 'react-i18next';
import { ROUTES } from '../skeleton/Routes';

type UserContextData = {
    loggedInUser: LoggedInUserInfo | null;
    login: (cfg?: {fromInvitationLink: boolean}) => void;
    logout: () => void;
    changePwd: () => void;
    accessPermission?: AccessPermission;
    loading: boolean;
    updateSelectedAccount: (accountId: string) => void;
}
export const UserContext = React.createContext<UserContextData>({ loggedInUser: null, loading: true, login: () => { }, logout: () => { }, changePwd: () => {}, updateSelectedAccount: (accountId: string) => { } });

const storageKey = 'selected-account-id';
const additionalLoginHintSessionKey = 'additional-login-hint';

export const UserContextProvider = ({ children }: { children: any }) => {
    const [selectedAccountId, setSelectedAccountId] = useSessionStorage<string | undefined>(storageKey, undefined);
    const [accessPermission, setAccessPermission] = useState<AccessPermission>();
    const { i18n } = useTranslation();
    const loggedInUser = useLoggedInContactQuery();
    const [initialized, setInitialized] = useState(false);

    const updateSelectedAccount = useCallback(async (accountId: string) => {
        if (loggedInUser.data) {
            const accessPermissions = loggedInUser.data.accessPermissions.filter(a => a.accountRef.id === accountId);
            if (accessPermissions.length > 0) {
                setAccessPermission(accessPermissions[0]);
            }
        }
    }, [loggedInUser.data]);

    const login = useCallback((cfg?: {fromInvitationLink: boolean}) => {
        const indexOfDashInLanguage = i18n.language ? i18n.language.indexOf('-'): -1;
        const languageCode = !i18n.language ? 'de': (indexOfDashInLanguage > 0 ? i18n.language.substring(0, indexOfDashInLanguage): i18n.language);
        if (cfg && cfg.fromInvitationLink) {
            sessionStorage.setItem(additionalLoginHintSessionKey, 'from_invitation');
        }
        let loginConfig = `aadb2c-${env.name}-${languageCode}`;
        const additionalLoginHint = sessionStorage.getItem(additionalLoginHintSessionKey);
        if (additionalLoginHint) {
            loginConfig = `${loginConfig}-${additionalLoginHint}`;
        }
        let redirectUriParam = window.location.pathname.toLowerCase().indexOf("/errorpage/") < 0 ? `post_login_redirect_uri=${encodeURIComponent(window.location.pathname)}` : '';
        window.location.href = `/.auth/login/${loginConfig}?${redirectUriParam}`;
    }, []);

    useEffect(() => {
        if (!accessPermission && !loggedInUser.isLoading) {
            if (loggedInUser.data) {
                if (loggedInUser.data.accessPermissions.length > 0) {
                    const storedAccountIdInLocalStorage = localStorage.getItem(storageKey);
                    const storedAccountIdIsValid = storedAccountIdInLocalStorage && loggedInUser.data.accessPermissions.filter(a => a.id === storedAccountIdInLocalStorage).length > 0;
                    setAccessPermission(storedAccountIdIsValid ? storedAccountIdInLocalStorage: loggedInUser.data.accessPermissions[0])
                }
            } else {
                if (window.location.href.toLocaleLowerCase().indexOf(ROUTES.TermsAndConditions) < 0) {
                    login();
                }
            }
            setInitialized(true);
        }
    }, [login, loggedInUser.isLoading, loggedInUser.data, accessPermission]);

    useEffect(() => {
        if (!loggedInUser.isLoading) {
            sessionStorage.removeItem(additionalLoginHintSessionKey);
        }
    }, [loggedInUser.isLoading]);

    useEffect(() => {
        if (accessPermission && selectedAccountId !== accessPermission.accountRef.id) {
            const existingSelectedAccountId = selectedAccountId;
            setSelectedAccountId(accessPermission.accountRef.id);
            if (existingSelectedAccountId) {
                window.location.reload();
            }
        }
    }, [accessPermission, selectedAccountId, setSelectedAccountId]);

    useEffect(() => {
        if (selectedAccountId) {
            localStorage.setItem(storageKey, selectedAccountId);
            updateSelectedAccount(selectedAccountId);
        }
    }, [selectedAccountId, updateSelectedAccount])

    const logout = useCallback(() => {
        window.location.href = '/.auth/logout';
    }, []);

    const changePwd = useCallback(() => {
        sessionStorage.setItem(additionalLoginHintSessionKey, 'change_pwd');
        logout();
    }, []);

    if (loggedInUser.error) {
        return <div>{loggedInUser.error.toString()}</div>
    }

    return (
        <UserContext.Provider value={{
            loggedInUser: loggedInUser.data ? loggedInUser.data : null,
            accessPermission,
            login, logout, changePwd,
            updateSelectedAccount,
            loading: loggedInUser.isLoading || !initialized
        }}>
            {children}
        </UserContext.Provider>
    );
};