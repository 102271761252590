import styled from "@emotion/styled";
import { t } from "i18next";
import { IconSerialEmptying } from "../../../components/Icons";
import { TextIconButton } from "../../../components/StyledComponents";

const PlanEmptyingButtonWrapper = styled.div<{ isDisabled: boolean }>`
    margin-bottom: 64px;

    button {
        color: ${({ isDisabled }) => (isDisabled ? "#E0E1E0" : "#3371EA")};
    }    

    rect {
        fill: ${({ isDisabled }) => (isDisabled ? "#E0E1E0" : "#F1F5FE")};
    }

    svg {
        path, circle {
            stroke: ${({ isDisabled }) => (isDisabled ? "#FFF" : "#3371EA")};
        }
    }
`;

type SeriesPlannerStartButtonProps = {
    disabled: boolean;
    onClick: () => void;
}

export const SeriesPlannerStartButton = ({ disabled, onClick }: SeriesPlannerStartButtonProps) => {
    
    const onBtnClicked = (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        evt.preventDefault();
        onClick();
    }

    return (
        <PlanEmptyingButtonWrapper isDisabled={disabled}>
            <TextIconButton disabled={disabled} onClick={onBtnClicked} bold svgMargin='0 8px 0 0'>
                <IconSerialEmptying />
                {t("pages.emptying.series.buttonPlanEmptying")}
            </TextIconButton>
        </PlanEmptyingButtonWrapper>
    )
}