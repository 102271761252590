import { Checkbox, FormControlLabel, Tooltip } from "@mui/material";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { IconQuestionCircleLineSmall, IconSwissFlag, IconInfo } from "../Icons";
import styled from "@emotion/styled";
import { styles } from "../../styles";

const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-right: 12px;

    &:last-child {
        margin-right: 0;
    }
`;

const TwoColumnsContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    gap: 12px;
`;

type StyledInputProps = {
    hasError?: boolean;
};

const StyledInput = styled.input<StyledInputProps>`
    padding: 10px 16px;
    flex: 1;
    box-shadow: ${styles.boxShadowInset};
    background-color: #fff;
    border: 1px solid ${props => props.hasError ? '#ed2b2b' : styles.neutral200};
    border-radius: 4px;
    box-sizing: border-box;
    line-height: 22px;
    font-size: 14px;
    color: ${styles.textColorSecondary700};
    font-family: 'Satoshi Regular';
    max-height: 44px;

    &:focus {
        border: 1px solid #3371EA;
        outline: none;
    }

    &:disabled {
        background-color: #f5f5f5;
    }
`;


const StyledFormControlLabel = styled(FormControlLabel)`
    height: 32px;
    input {
        height: 28px;
        width: 28px;
        box-sizing: border-box;
    }

    span {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        font-family: 'Satoshi Regular';
        font-size: 14px;
    }
`;

const FlagInputContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
    padding: 10px 12px;
    box-shadow: ${styles.boxShadowInset};
    background-color: #f5f5f5;
    border: 1px solid ${styles.neutral200};
    border-radius: 4px;
    box-sizing: border-box;
    line-height: 22px;
    font-size: 14px;
    color: ${styles.textColorNeutral600};
    font-family: 'Satoshi Regular';
    max-height: 44px;
    width: 92px;
    pointer-events: none;
`;

const IconWrapper = styled.div`
    display: flex;
`;

const LabelWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

const InfoTextWrapper = styled.div`
    display: flex;
    gap: 12px;
    margin-top: 12px;
    p {
        max-width: 292px;
        font-size: 10px;
        line-height: 18px;
    }
`;

const StyledCheckbox = styled(Checkbox)`
  &.MuiCheckbox-root {
    color: #E0E1E0;
    
    &.Mui-checked {
      color: #33AA47;
    }
  }
`;

export type NotificationTypes = {
    orderConfirmation: boolean;
    emptyingReminder: boolean;
    emptyingConfirmation: boolean;
}

type NotificationTypesChooserProps = {
    emptyingReminderOnly: boolean;
    onChange: (notificationTypes: NotificationTypes) => void;
    value: NotificationTypes;
}
export const NotificationTypesChooser = ({ value, emptyingReminderOnly, onChange }: NotificationTypesChooserProps) => {
    const { t } = useTranslation();

    return (
        <>
            {!emptyingReminderOnly &&
                <StyledFormControlLabel
                    control={<StyledCheckbox checked={value.orderConfirmation} onChange={() => onChange({ ...value, orderConfirmation: !value.orderConfirmation })} />}
                    label={<LabelWrapper>{t("components.notificationsForm.orderConfirmation")}<Tooltip arrow componentsProps={{
                        tooltip: {
                            sx: {
                                bgcolor: '#26374A',
                                '& .MuiTooltip-arrow': {
                                    color: '#26374A',
                                },
                            },
                        },
                    }} title={t("components.notificationsForm.orderConfirmationTooltip")}>
                        <IconWrapper>
                            <IconQuestionCircleLineSmall />
                        </IconWrapper>
                    </Tooltip></LabelWrapper>} />
            }

            <StyledFormControlLabel
                control={<StyledCheckbox checked={value.emptyingReminder} onChange={() => onChange({ ...value, emptyingReminder: !value.emptyingReminder })} />}
                label={<LabelWrapper>{t("components.notificationsForm.emptyingReminder")}<Tooltip arrow componentsProps={{
                    tooltip: {
                        sx: {
                            bgcolor: '#26374A',
                            '& .MuiTooltip-arrow': {
                                color: '#26374A',
                            },
                        },
                    },
                }} title={t("components.notificationsForm.emptyingReminderEmailTooltip")}>
                    <IconWrapper>
                        <IconQuestionCircleLineSmall />
                    </IconWrapper>
                </Tooltip></LabelWrapper>} />

            {!emptyingReminderOnly &&
                <StyledFormControlLabel
                    control={<StyledCheckbox checked={value.emptyingConfirmation} onChange={() => onChange({ ...value, emptyingConfirmation: !value.emptyingConfirmation })} />}
                    label={<LabelWrapper>{t("components.notificationsForm.emptyingConfirmation")}<Tooltip arrow componentsProps={{
                        tooltip: {
                            sx: {
                                bgcolor: '#26374A',
                                '& .MuiTooltip-arrow': {
                                    color: '#26374A',
                                },
                            },
                        },
                    }} title={t("components.notificationsForm.emptyingConfirmationTooltip")}>
                        <IconWrapper>
                            <IconQuestionCircleLineSmall />
                        </IconWrapper>
                    </Tooltip></LabelWrapper>} />
            }
        </>
    );
}

export const NotificationPhoneNumberChooser = ({ value, onChange }: { value: string, onChange: (phone: string) => void }) => {
    const { t } = useTranslation();

    const onChangeValue = (newValue: string) => {
        newValue = newValue.startsWith('0') && newValue.length > 1 ? newValue.substring(1) : newValue;
        onChange(`+41 ${newValue}`)
    }
    const onBlur = useCallback((newValue: string) => {
        newValue = !newValue ? '' : newValue.trim();
        if (newValue.startsWith('+')) {
            newValue = newValue.substring(1).trim();
            if (newValue.startsWith('41')) {
                newValue = newValue.substring(2).trim();
            }
        }
        newValue = newValue.replace(/\D/g, '');
        
        if (newValue.length > 0 && !newValue.startsWith('0')) {
            newValue = '0' + newValue;
        }
        if (newValue.length >= 3) {
            newValue = newValue.substring(0, 3) + ' ' + newValue.substring(3)
        }
        if (newValue.length >= 7) {
            newValue = newValue.substring(0, 7) + ' ' + newValue.substring(7)
        }
        if (newValue.length >= 10) {
            newValue = newValue.substring(0, 10) + ' ' + newValue.substring(10)
        }
        if (newValue.length >= 13) {
            newValue = newValue.substring(0, 13);
        }
        newValue = newValue.trim();
        onChange(newValue);
    }, [onChange]);

    useEffect(() => {
        const handler = setTimeout(() => {
            onBlur(value);
        }, 400);
        return () => {
            clearTimeout(handler);
        }
    }, [value, onBlur]);

    value = !value ? '' : value;
        if (value.startsWith('+')) {
            value = value.substring(1).trimStart();
        }
        if (value.startsWith('41')) {
            value = value.substring(2).trimStart();
        }
        if (value && !value.startsWith('0')) {
            value = '0' + value;
        }

    return (
        <>
            <TwoColumnsContainer>
                <FlagInputContainer>
                    <IconSwissFlag />
                    <span>+41</span>
                </FlagInputContainer>

                <InputContainer style={{ width: '223px' }}>
                    <StyledInput
                        type="text"
                        value={value}
                        onChange={evt => onChangeValue(evt.target.value)}
                        onBlur={evt => onBlur(evt.target.value)}
                    />
                </InputContainer>
            </TwoColumnsContainer>
            <InfoTextWrapper>
                <IconInfo />
                <p>{t("components.notificationsForm.differentMobileText")}</p>
            </InfoTextWrapper>
        </>
    )
}

export const NotificationEmailAddressChooser = ({ value, onChange }: { value: string, onChange: (email: string) => void }) => {
    const { t } = useTranslation();

    return (
        <>
            <InputContainer style={{ width: '323px' }}>
                <StyledInput value={value} onChange={(evt) => onChange(evt.target.value)} />
            </InputContainer>
            <InfoTextWrapper>
                <IconInfo />
                <p>{t("components.notificationsForm.differentEmailText")}</p>
            </InfoTextWrapper>
        </>
    )
}