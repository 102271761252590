import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { TextIconButton } from "../../../../../components/StyledComponents";
import { IconPdfDownload } from "../../../../../components/Icons";
import { GuideItem } from "../../../../../types/GuideItem";
import ReactPlayer from "react-player";
import { useState } from "react";

type GuideProps = {
    item: GuideItem
}

const StyledGuide = styled.div`
    flex: 1 0 calc(25% - 24px);
    max-width: calc(25% - 24px);

    @media (max-width: 768px) {
        flex: 1 0 calc(50% - 24px);
        max-width: calc(50% - 24px);
    }

    @media (max-width: 480px) {
        flex: 1 0 100%;
        max-width: 100%;
    }
`;

const Thumbnail = styled.div`
    position: relative;
    width: 100%;
    height: 140px;
    background: #E0E1E0;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 12px;

    iframe {
        border-radius: 8px;
    }
`;

const Duration = styled.span`
    position: absolute;
    bottom: 8px;
    right: 8px;
    align-self: flex-end;
    height: 12px;
    line-height: 12px;
    vertical-align: center;
    padding-left: 4px;
    padding-right: 4px;
    background: #fff;
    border-radius: 100px;
    font-size: 8px;
`;

const Title = styled.h3`
    margin-bottom: 12px;
    font-size: 12px;
    line-height: 16px;
`;

const Subtitle = styled.p`
    margin-bottom: 8px;
    font-size: 10px;
    line-height: 18px;
    margin-bottom: 32px;
`;

const StyledTextIconButton = styled(TextIconButton)`
    font-family: 'Satoshi Bold';
    font-size: 14px;
    line-height: 22px;
    gap: 4px;
`;

export const Guide: React.FC<GuideProps> = ({ item }) => {
    const { t } = useTranslation();
    const [duration, setDuration] = useState<string>("");
    
    const onDurationLoaded = (durationInSeconds: number) => {
        setDuration(`${Math.floor(durationInSeconds / 60)}:${durationInSeconds % 60 < 10 ? '0' : ''}${durationInSeconds % 60}`)
    }


    return (
        <StyledGuide>

            <Thumbnail>
                <ReactPlayer url={item.videoUrl} width="100%" height="140px" controls={true} onDuration={onDurationLoaded} />
                {duration && <Duration>{duration}</Duration>}
            </Thumbnail>

            <Title>{item.title || ''}</Title>
            <Subtitle>{item.description || ''}</Subtitle>
            {item.pdfUrl &&
                <a href={item.pdfUrl} target="_blank">
                    <StyledTextIconButton>
                        <IconPdfDownload />
                        {t("pages.help.guides.downloadPdf")}
                    </StyledTextIconButton>
                </a>
            }
        </StyledGuide>
    )
}