import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { CreditAccount } from "./components/credit-overview/CreditAccount";
import { ROUTES } from "../../skeleton/Routes";
import { TabbedNavigation } from "../../components/TabbedNavigation";

const Header = styled.div`
    h1 {
        margin-bottom: 24px;

        @media (max-width: 480px) {
            margin-bottom: 12px;
        }
    }
`;

export const CreditOverviewPage = () => {
    const { t } = useTranslation();
    return (
        <>
            <Header>
                <h1>{t("pages.balances.header")}</h1>
            </Header>

            <div>
                <TabbedNavigation nodes={[
                    { label: t("pages.balances.tabs.creditAccount"), route: ROUTES.CreditOverview },
                    { label: t("pages.balances.tabs.bills"), route: ROUTES.Payments },
                    { label: t("pages.balances.tabs.confirmationOfEmptying"), route: ROUTES.EmptyingConfirmations }
                ]} />
            </div>

            <CreditAccount />
        </>
    );
};