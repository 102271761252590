import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { styles } from "../../styles";
import { IconClose } from "../Icons";
import Button from '../Button';
import { FormControl } from '@mui/material';
import { useLoggedInContactQuery, useUpdateMeMutation } from "../../data/contacts";
import { Controller, useForm } from "react-hook-form";
import { Contact, NotificationType } from "../../types/Contact";
import { NotificationEmailAddressChooser, NotificationPhoneNumberChooser, NotificationTypes, NotificationTypesChooser } from "./NotificationFormFields";
import { Loader } from "../Loader";
import { MessageWrapper } from "../MessageWrapper";
import { useState } from "react";

export type NotificationFormResultInfo = {
    type: "success" | "error" | "cancel",
    message: string;
}

type NotificationsFormProps = {
    onClose: (info: NotificationFormResultInfo) => void;
}

const Wrapper = styled.div`
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    max-height: 80vh;
    
    @media (max-width: 480px) {
        max-height: 100vh;
        height: 100%;
    }
`;

const ContentWrapper = styled.div`
    overflow-y: auto;
    position: relative;
    max-height: 80vh;
    padding-right: 24px;

    @media (max-width: 480px) {
        max-height: 100vh;
        height: 100%;
    }
`;

const Heading = styled.h2`
    color: ${styles.textColorSecondary700};
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #fff;
    padding-top: 24px;
    padding-bottom: 24px;
    padding-right: 24px;
    border-radius: 8px;
    margin-bottom: 0;

    svg {
        cursor: pointer;
    }
`;

const FormRow = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    color: ${styles.textColorSecondary700};
`;

const BottomRow = styled.div`
    display: flex;
    justify-content: flex-start;
    gap: 12px;
    margin-top: 48px;
    // margin-bottom: 68px;

    @media (max-width: 480px) {
        // margin-bottom: 0;
        flex-direction: column;
        align-items: flex-end;
        gap: 12px;
        width: 100%;

        button {
            width: 100%;
            margin-bottom: 0;
        }
    }
`;

const StyledH3 = styled.h3`
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    gap: 8px;

    svg {
        cursor: pointer;
    }
`;

const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`;

type NotificationsFormData = {
    emailTypes: NotificationTypes;
    smsTypes: NotificationTypes;
    emailAddress: string;
    smsNumber: string;
}

const getFormDefaultValues = (contact: Contact): NotificationsFormData => {
    const selectedNotificationTypes = contact.notificationTypes || [];
    return {
        emailTypes: {
            orderConfirmation: selectedNotificationTypes.indexOf(NotificationType.EmailOrderConfirmation) >= 0,
            emptyingReminder: selectedNotificationTypes.indexOf(NotificationType.EmailEmptyingReminder) >= 0,
            emptyingConfirmation: selectedNotificationTypes.indexOf(NotificationType.EmailEmptyingConfirmation) >= 0
        },
        smsTypes: {
            orderConfirmation: false,
            emptyingReminder: selectedNotificationTypes.indexOf(NotificationType.SmsEmptyingReminder) >= 0,
            emptyingConfirmation: false
        },
        emailAddress: contact.email,
        smsNumber: contact.phone
    }
}

type NotificationsFormWithLoadedContactProps = {
    contact: Contact;
    onClose: () => void;
    onSave: (contact: Contact) => void;
}
const NotificationFormWithLoadedContact = ({ contact, onClose, onSave }: NotificationsFormWithLoadedContactProps) => {
    const { t } = useTranslation();
    const { handleSubmit, watch, control, formState: { errors }, getValues } = useForm({
        defaultValues: getFormDefaultValues(contact)
    });
    const [saving, setSaving] = useState(false);
    const onSubmit = async (formData: NotificationsFormData) => {
        setSaving(true);
        contact.email = formData.emailAddress;
        contact.phone = formData.smsNumber;
        contact.notificationTypes = [];
        if (formData.smsTypes.emptyingReminder) {
            contact.notificationTypes.push(NotificationType.SmsEmptyingReminder)
        }
        if (formData.emailTypes.emptyingConfirmation) {
            contact.notificationTypes.push(NotificationType.EmailEmptyingConfirmation)
        }
        if (formData.emailTypes.orderConfirmation) {
            contact.notificationTypes.push(NotificationType.EmailOrderConfirmation)
        }
        if (formData.emailTypes.emptyingReminder) {
            contact.notificationTypes.push(NotificationType.EmailEmptyingReminder)
        }
        onSave(contact);
    }

    const selectedEmailTypes = watch('emailTypes');
    const hasSelectedEmailTypes = selectedEmailTypes.emptyingReminder || selectedEmailTypes.emptyingConfirmation || selectedEmailTypes.orderConfirmation;
    const emailAddress = watch('emailAddress');
    const selectedSmsTypes = watch('smsTypes');
    const hasSelectedSmsTypes = selectedSmsTypes.emptyingReminder || selectedSmsTypes.emptyingConfirmation || selectedSmsTypes.orderConfirmation;
    const smsNumber = watch('smsNumber');

    const enableSave = (!hasSelectedSmsTypes || smsNumber) && (!hasSelectedEmailTypes || emailAddress);
    return (
        <StyledForm>
            <div>

                <FormRow style={{ marginTop: '24px' }}>
                    <FormControl component="fieldset">
                        <StyledH3>{t("components.notificationsForm.emailNotifications")}</StyledH3>
                        <Controller
                            control={control}
                            name="emailTypes"
                            render={({ field: { onChange } }) => (
                                <NotificationTypesChooser emptyingReminderOnly={false} onChange={onChange} value={getValues()["emailTypes"]} />
                            )} />
                        {errors.emailTypes && <span>{errors.emailTypes.message}</span>}
                    </FormControl>
                </FormRow>
                <FormRow>
                    <Controller
                        control={control}
                        name="emailAddress"
                        render={({ field: { onChange } }) => (
                            <NotificationEmailAddressChooser onChange={onChange} value={getValues()["emailAddress"]} />
                        )} />
                    {errors.emailAddress && <span>{errors.emailAddress.message}</span>}
                </FormRow>
                <FormRow>
                    <StyledH3>{t("components.notificationsForm.smsNotifications")}</StyledH3>
                    <Controller
                        control={control}
                        name="smsTypes"
                        render={({ field: { onChange } }) => (
                            <NotificationTypesChooser emptyingReminderOnly={true} onChange={onChange} value={getValues()["smsTypes"]} />
                        )} />
                    {errors.smsTypes && <span>{errors.smsTypes.message}</span>}

                </FormRow>
                <FormRow>
                    <Controller
                        control={control}
                        name="smsNumber"
                        render={({ field: { onChange } }) => (
                            <NotificationPhoneNumberChooser value={getValues()["smsNumber"]} onChange={onChange} />
                        )} />
                    {errors.smsNumber && <span>{errors.smsNumber.message}</span>}
                </FormRow>
            </div>
            <BottomRow>
                {!saving &&
                    <Button buttonVariant="ghost" onClick={() => onClose()}>
                        {t("buttons.abort")}
                    </Button>
                }
                <Button disabled={!enableSave || saving} onClick={handleSubmit(onSubmit)}>
                    {saving && <Loader />}
                    {!saving && t("buttons.save")}
                </Button>
            </BottomRow>
        </StyledForm>
    )
}



export const NotificationsForm: React.FC<NotificationsFormProps> = ({ onClose }) => {
    const contactQuery = useLoggedInContactQuery();
    const updateMeMutation = useUpdateMeMutation();
    const { t } = useTranslation();

    const onSave = async (updatedContact: Contact) => {
        try {
            await updateMeMutation.mutateAsync(updatedContact);
            onClose({ type: 'success', message: t("toasts.saved") });
        } catch (e) {
            onClose({ type: 'error', message: t("toasts.error") + ` - ${updateMeMutation.error} | ${JSON.stringify(e)}` });
        }
    }

    const onCancel = () => {
        onClose({ type: 'cancel', message: '' });
    }

    const isSaving = updateMeMutation.isLoading
    const isLoading = contactQuery.isLoading;
    const error = contactQuery.error || updateMeMutation.error;

    return (
        <>
            <Wrapper>
                {/* RESOLVED: style loading, saving and error */}
                <Heading>{t("components.notificationsForm.header")} <div onClick={onCancel}><IconClose /></div></Heading>
                <ContentWrapper>
                    {error && <MessageWrapper messageText={error.message} height={"592px"} />}
                    {isLoading && <Loader height={"592px"} />}
                    {isSaving && <Loader height={"592px"} />}
                    {contactQuery.data && !isLoading && !isSaving && !error &&
                        <NotificationFormWithLoadedContact contact={contactQuery.data.contact} onClose={onCancel} onSave={onSave} />
                    }
                </ContentWrapper>
            </Wrapper>
        </>
    );
};