import { NoContainerImage} from './NoContainerImage';
import { useTranslation } from "react-i18next";
import { TextIconButton } from '../StyledComponents';
import { IconArrowRight } from "../Icons";
import styled from "@emotion/styled";
import { NavLink } from 'react-router-dom';
import { ROUTES } from '../../skeleton/Routes';
import { ContainerCard } from './ContainerCard';
import  { styles } from '../../styles'
import { Todo } from '../Todo';


const ContainerText = styled.div`
    margin: 0 auto;

    p {
        margin: 20px 0 11px;
    }
`;

const ContainerContent = styled.div`
    padding: 24px 24px 36px 24px;
    border: 1px solid ${styles.neutral200};
    border-radius: 8px;
    box-shadow: ${styles.boxShadowBottomLight};
    display: flex;
    flex-direction: column;
    align-items: center;

    svg {
        max-width: 100%;
    }

    button {
        svg {
            transition: .1s ease-in;
        }
    }

    button:hover {
        span {
            border-bottom: 1px solid ${styles.textColorSecondary900};
        }
        
        svg {
            transform: translateX(50%);
        }
    }
`;

const DummyContainer = () => {
    const { t } = useTranslation();

    return (
        <ContainerCard width={379.5} marginRight={'0'} resizable>
            <ContainerContent>
                <NoContainerImage />
                <ContainerText>
                    <p>{t("components.containers.dummy.infoText")}</p>
                    <NavLink to={ROUTES.ContainerOverview} state="auto-open-new-dlg">
                        <TextIconButton bold svgMargin='0 0 0 8px'><span>{t("components.containers.dummy.registerContainerButtonText")}</span><IconArrowRight /></TextIconButton>
                    </NavLink>
                </ContainerText>
            </ContainerContent>
        </ContainerCard>
    )
}

export default DummyContainer;