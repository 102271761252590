import { useContext, useEffect, useState } from 'react';
import { IconCalendarAddBig, IconCalendarAddSmall, IconContainerMedium, IconContainersMedium, IconDeleteBig } from '../../../../components/Icons';
import styled from "@emotion/styled";
import { styles } from "../../../../styles"
import { CalendarText } from '../../../../components/StyledComponents';
import { useEmptyingsOverviewQuery } from '../../../../data/emptyings';
import { ContainerRef } from '../../../../types/Container';
import { useTranslation } from 'react-i18next';
import { Namespace, TFunction } from 'i18next';
import { useRemovalDatesQuery } from '../../../../data/dates';
import { useContainersQuery } from '../../../../data/containers';
import { UserContext } from '../../../../contexts/UserContext';
import { RemovalArea } from '../../../../types/RemovalArea';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../../skeleton/Routes';
import { Emptying } from '../../../../types/Emptying';
import Tooltip from '@mui/material/Tooltip';
import Modal from '../../../../components/Modal';
import { CancelEmptyingModalContent, EmptyingCancellingInfo } from './CancelEmptyingModalContent';
import { Loader } from '../../../../components/Loader';
import { Toast } from '../../../../components/Toast';
// import { MessageWrapper } from '../../../../components/MessageWrapper';

type CalendarProps = {
    from: Date;
    to: Date;
    visibleMonth: number;
    container?: ContainerRef;
};

type WeekDayToDateMapping = {
    [key: string]: Date[];
}

const WeekdayColumn = styled.div`
  &:first-of-type > div {
    border-left: 1px solid ${styles.neutral200};
  }
`;

const customShouldForwardProp = (prop: string) => !['overflow'].includes(prop);

type CalendarDayProps = {
    overflow: boolean;
};
const CalendarDay = styled('div', { shouldForwardProp: customShouldForwardProp })<CalendarDayProps>(({ overflow: shouldOverflow }) => ({
    height: '108px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
    margin: '0',
    backgroundColor: shouldOverflow ? '#f5f5f5' : '#fff',
    borderRight: `1px solid ${styles.neutral200}`,
    borderBottom: `1px solid ${styles.neutral200}`,
    fontFamily: 'Satoshi Bold',
    fontWeight: 700,

    ':first-of-type': {
        borderTop: `1px solid ${styles.neutral200}`,
    },

    'span': {
        display: 'block'
    }
}));

const DayLabel = styled.div`
  color: #979B98;
  display: flex;
  width: 100%;
  padding: 6px 8px 0;
`;

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 3px;

  div {
    width: 33.33333%;
    display: flex;
    justify-content: center;
  }

  svg {
    cursor: pointer;
  }
`;

const ContainerIconWrapper = styled.div<{ color: string }>`
    position: relative;
    height: 48px;
    cursor: default;

    svg {
        path {
            fill: ${props => props.color};;
        }
        path:last-child {
            stroke: ${props => props.color};;
        }
    }
`;

const ContainerShortName = styled.span`
    position: absolute;
    top: 14px;
    left: 17px;
    font-family: 'Satoshi Bold';
    color: ${styles.textColorSecondary700};
    font-size: 11px;
    min-width: 14px;
    text-align: center;
`;

const ONE_DAY = 24 * 60 * 60 * 1000;

const calcWorkingDays = (t: TFunction<Namespace, undefined, Namespace>) => {
    return [undefined, t('dates.workingDays.short.0'), t('dates.workingDays.short.1'), t('dates.workingDays.short.2'), t('dates.workingDays.short.3'), t('dates.workingDays.short.4'), undefined];
}
export const EmptyingsCalendar = ({ from, to, visibleMonth, container }: CalendarProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [workingDays, setWorkingDays] = useState(() => calcWorkingDays(t))
    const userContext = useContext(UserContext);
    const containersQuery = useContainersQuery(userContext.accessPermission?.accountRef);
    const [selectedRemovalArea, setSelectedRemovalArea] = useState<RemovalArea>();
    const emptyingsQuery = useEmptyingsOverviewQuery(from, to, container);
    const removalDatesQuery = useRemovalDatesQuery(from, to, selectedRemovalArea);
    const [days, setDays] = useState<WeekDayToDateMapping>({});
    const [showCancelledToast, setShowCancelledToast] = useState(false);

    const [cancelEmptyingsInfo, setCancelEmptyingsInfo] = useState<EmptyingCancellingInfo | undefined>();
    const onCancelEmptyingModalClosed = (dataChanged: boolean) => {
        if (dataChanged) {
            setShowCancelledToast(true);
            emptyingsQuery.refetch();
        }
        setCancelEmptyingsInfo(undefined);
    }

    useEffect(() => {
        setWorkingDays(calcWorkingDays(t));
    }, [t]);

    useEffect(() => {
        if (containersQuery.data) {
            const containers = containersQuery.data.filter(c => !container || c.id === container.id);
            if (containers.length > 0) {
                setSelectedRemovalArea(containers[0].removalArea);
            }
        }
    }, [container, containersQuery.data]);

    useEffect(() => {
        const days: WeekDayToDateMapping = {};
        let date = from;
        while (date <= to) {
            const weekday = workingDays[date.getDay()]
            if (weekday) {
                const dates = days[weekday] || [];
                dates.push(date);
                days[weekday] = dates;
            }
            date = new Date(date.getTime() + ONE_DAY);
        }
        setDays(days);
    }, [from, to, workingDays]);

    const onScheduleNewEmptying = (day: Date) => {
        let selectedContainer = containersQuery.data?.find(c => c.id === container?.id);
        navigate(ROUTES.SingleEmptying, { state: { preselectedContainer: selectedContainer, preselectedRemovalDate: day } });
    };

    const onScheduledEmptyingsCancellation = async (date: Date, emptyings: Emptying[]) => {
        const data: EmptyingCancellingInfo = {
            allAtThisSingleDate: date,
            scheduledEmptyings: emptyings.map(emptying => {
                return {
                    emptying: emptying,
                    container: containersQuery.data?.find(c => c.id === emptying.containerId)
                }})
        }
        setCancelEmptyingsInfo(data);
    }


    // RESOLVED: Handle error and loading state for emptyings (the code is prepared a few line above)
    if (emptyingsQuery.isLoading) return <Loader messageText={t("components.loaders.loading")} height={"600px"} />;

    // if (emptyingsQuery.isError) { return <MessageWrapper messageText={emptyingsQuery.error?.message} height={"600px"} svgMargin='0 12px 0 0'/> };    
    
    // TODO: check if this kind of error handling is what's desired and if necessary modify it ;) It was made like this as a temporary measure
    const hasNoContainers = containersQuery.data && containersQuery.data.length === 0;
    if (hasNoContainers || emptyingsQuery.isError) {
        // Returna an empty calendar
        return (
            <>
                {workingDays.map(weekday =>
                    weekday &&
                    <WeekdayColumn key={weekday} style={{ width: '20%' }}>
                        <CalendarText margin="24px 0 10px">{weekday}</CalendarText>
                        {days[weekday] && days[weekday].map(day => (
                            <CalendarDay key={day.getTime()} overflow={day.getMonth() !== visibleMonth}>
                                <DayLabel>{day.getDate()}</DayLabel>
                            </CalendarDay>
                        ))}
                    </WeekdayColumn>
                )}
            </>
        );
    }

    const emptyings = emptyingsQuery.data || [];

    const editingAllowed = userContext.accessPermission?.roles?.payment === true;
    return (
        <>
            {workingDays.map(weekday =>
                weekday &&
                <WeekdayColumn key={weekday} style={{ width: '20%' }}>
                    <CalendarText margin="24px 0 10px">{weekday}</CalendarText>
                    {days[weekday] && days[weekday].map(day => {
                        const emptyingsOnThisDays = emptyings.filter(e => e.removalDate.date.toDateString() === day.toDateString());
                        const isRemovalDay = day > new Date() && removalDatesQuery.data && removalDatesQuery.data.filter(removalDate => removalDate.toDateString() === day.toDateString()).length > 0;
                        const scheduledContainers = emptyingsOnThisDays
                            ?.map(emptying => containersQuery.data?.find(c => c.id === emptying.containerId))
                            .filter(container => container !== undefined);

                        const isAddingAvailable = () => {
                            const hasEmptyingsOnThisDate = emptyingsOnThisDays.length > 0;
                            const noAllContainersScheduledYet = containersQuery.data && emptyingsOnThisDays.length < containersQuery.data.filter(c => c.removalArea && c.removalArea.weekdays && c.removalArea.weekdays.length > 0).length;
                            return hasEmptyingsOnThisDate && isRemovalDay && noAllContainersScheduledYet && editingAllowed && (!container || container.id !== emptyingsOnThisDays[0].containerId)
                        }
                        
                        return <CalendarDay key={day.getTime()} overflow={day.getMonth() !== visibleMonth}>
                            <DayLabel>{day.getDate()}</DayLabel>
                            {emptyingsOnThisDays.length > 0 && (
                                <Tooltip arrow componentsProps={{
                                    tooltip: {
                                        sx: {
                                            top: '-7px',
                                            bgcolor: '#26374A',
                                            '& .MuiTooltip-arrow': {
                                                color: '#26374A',
                                            },
                                        },
                                    },
                                }} title={scheduledContainers.map(container => container?.displayName).join(', ')}>
                                    <ContainerIconWrapper color={(scheduledContainers.length === 1 && scheduledContainers[0]?.color) || ''}>
                                        {emptyingsOnThisDays.length === 1 ? <IconContainerMedium /> : <IconContainersMedium />}
                                        {emptyingsOnThisDays.length === 1 ? <ContainerShortName>{scheduledContainers[0]?.shortName || ''}</ContainerShortName> : null}
                                    </ContainerIconWrapper>
                                </Tooltip>
                            )}
                            { emptyingsOnThisDays.length === 0 && isRemovalDay && editingAllowed &&
                                <div style={{ cursor: 'pointer' }} onClick={() => onScheduleNewEmptying(day)}><IconCalendarAddBig /></div>
                            }
                            <ActionsWrapper>
                                {isAddingAvailable() &&
                                    <Tooltip arrow componentsProps={{
                                        tooltip: {
                                            sx: {
                                                top: '-6px',
                                                bgcolor: '#26374A',
                                                '& .MuiTooltip-arrow': {
                                                    color: '#26374A',
                                                },
                                            },
                                        },
                                    }} title={t("pages.home.emptyingsOverviewSection.tooltipAddEmptying")}>
                                        <div onClick={() => onScheduleNewEmptying(day)}><IconCalendarAddSmall /></div>
                                    </Tooltip>
                                }
                                { emptyingsOnThisDays.length > 0 && day.getTime() > new Date().getTime() && editingAllowed &&
                                    <Tooltip arrow componentsProps={{
                                        tooltip: {
                                            sx: {
                                                top: '-6px',
                                                bgcolor: '#26374A',
                                                '& .MuiTooltip-arrow': {
                                                    color: '#26374A',
                                                },
                                            },
                                        },
                                    }} title={t("pages.home.emptyingsOverviewSection.tooltipCancelEmptying")}>
                                        <div onClick={() => onScheduledEmptyingsCancellation(day, emptyingsOnThisDays)}>
                                            <IconDeleteBig />
                                        </div>
                                    </Tooltip>
                                }
                            </ActionsWrapper>
                        </CalendarDay>
                    })}
                </WeekdayColumn>
            )}
            {cancelEmptyingsInfo &&
                <Modal onClose={() => onCancelEmptyingModalClosed(false)}>
                    <CancelEmptyingModalContent onClose={onCancelEmptyingModalClosed} data={cancelEmptyingsInfo} />
                </Modal>
            }
            {showCancelledToast && <Toast message={t("toasts.emptyingCancelled")} type="success" onCloseComplete={() => setShowCancelledToast(false)} />}
        </>
    )
};

