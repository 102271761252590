import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useContext } from "react";
import { UserContext } from "../contexts/UserContext";
import { AccessPermission } from "../types/AccessPermission";
import { convertToErrorMsg, jsonReviverFunction } from "./_base";
import { Invitation } from "../types/Invitation";
import { UserRolesInfo } from "../types/UserRolesInfo";
import { AccountRef } from "../types/Account";
import { CURRENT_USER_QUERY_KEY } from "./contacts";
import { CONTAINERS_QUERY_KEY } from "./containers";
import { Salutation } from "../types/Contact";

export const INVITATIONS_QUERY_KEY = "invitations";

export const useActiveInvitationsQuery = () => {
    const userContext = useContext(UserContext);
    const accessPermission = userContext.accessPermission;
    const activeInvitationsQuery = useQuery<Invitation[] | null, Error>({
        queryKey: [INVITATIONS_QUERY_KEY, accessPermission?.accountRef.id],
        refetchOnWindowFocus: true,
        staleTime: 30000,
        retry: false,
        queryFn: () => getActiveInvitations(accessPermission)
    })
    return activeInvitationsQuery;
}

const getActiveInvitations = async (accessPermission?: AccessPermission): Promise<Invitation[]> => {
    if (!accessPermission || !accessPermission.roles || !accessPermission.roles.permission) return [];
    const response = await fetch(`/api/me/accounts/${accessPermission.accountRef.id}/invitations`);
    const responseBody = await response.text();
    if (!response.ok) {
        const errorMsg = convertToErrorMsg(response.status, responseBody);
        throw errorMsg;
    } else {
        const responseJson = JSON.parse(responseBody, jsonReviverFunction);
        const invitations = responseJson as Invitation[];
        return invitations;
    }
}

export type CreateOrUpdateInvitationInfo = {
    salutation: Salutation;
    firstName: string;
    lastName: string;
    email: string;
    sendInvitationRedeemedNotification: boolean;
    roles: UserRolesInfo;
    containerIds: string[];
}
export const useInvitationCreationMutation = () => {
    const userContext = useContext(UserContext);
    const queryClient = useQueryClient()
    const mutation = useMutation<boolean, Error, CreateOrUpdateInvitationInfo>({
        mutationFn: (invitationInfo: CreateOrUpdateInvitationInfo) => addInvitation(invitationInfo, userContext.accessPermission?.accountRef),
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: [INVITATIONS_QUERY_KEY]});
        }
    })
    return mutation;
}

const addInvitation = async (invitationInfo: CreateOrUpdateInvitationInfo, accountRef?: AccountRef): Promise<boolean> => {
    if (!accountRef) return false;
    invitationInfo.sendInvitationRedeemedNotification = true; // alway set to true, user does not have the choice anymore
    const response = await fetch(`/api/me/accounts/${accountRef.id}/invitations`, {
        method: 'post',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(invitationInfo)
    });
    const responseBody = await response.text();
    if (!response.ok) {
        const errorMsg = convertToErrorMsg(response.status, responseBody);
        throw errorMsg;
    } else {
        return true;
    }
}

export const useInvitationUpdateMutation = () => {
    const userContext = useContext(UserContext);
    const queryClient = useQueryClient()
    const mutation = useMutation<boolean, Error, Invitation>({
        mutationFn: (invitation: Invitation) => updateInvitation(invitation, userContext.accessPermission?.accountRef),
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: [INVITATIONS_QUERY_KEY]});
        }
    })
    return mutation;
}

const updateInvitation = async (invitation: Invitation, accountRef?: AccountRef): Promise<boolean> => {
    if (!accountRef) return false;
    const invitationInfo: CreateOrUpdateInvitationInfo = {
        containerIds: invitation.containers.map(c => c.id),
        email: invitation.email,
        salutation: invitation.salutation,
        firstName: invitation.firstName,
        lastName: invitation.lastName,
        roles: invitation.roles,
        sendInvitationRedeemedNotification: true // alway set to true, user does not have the choice anymore
    };
    const response = await fetch(`/api/me/accounts/${accountRef.id}/invitations/${invitation.id}`, {
        method: 'post',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(invitationInfo)
    });
    const responseBody = await response.text();
    if (!response.ok) {
        const errorMsg = convertToErrorMsg(response.status, responseBody);
        throw errorMsg;
    } else {
        return true;
    }
}

export const useInvitationReactivationMutation = () => useInvitationActionMutation('reactivate');
export const useInvitationCancellationMutation = () => useInvitationActionMutation('cancel');
const useInvitationActionMutation = (action: 'cancel' | 'reactivate') => {
    const userContext = useContext(UserContext);
    const queryClient = useQueryClient()
    const mutation = useMutation<boolean, Error, Invitation>({
        mutationFn: (invitation: Invitation) => excelInvitationAction(action, invitation, userContext.accessPermission?.accountRef),
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: [INVITATIONS_QUERY_KEY]});
        }
    })
    return mutation;
}

const excelInvitationAction = async (action: 'cancel' | 'reactivate', invitation: Invitation, accountRef?: AccountRef): Promise<boolean> => {
    if (!accountRef) return false;
    const response = await fetch(`/api/me/accounts/${accountRef.id}/invitations/${invitation.id}`, {
        method: action === 'cancel' ? 'delete': 'put'
    });
    const responseBody = await response.text();
    if (!response.ok) {
        const errorMsg = convertToErrorMsg(response.status, responseBody);
        throw errorMsg;
    } else {
        return true;
    }
}

export const useInvitationRedemptionMutation = () => {
    const queryClient = useQueryClient()
    const mutation = useMutation<AccountRef, Error, string>({
        mutationFn: (code: string) => redeemInvitation(code),
        onSuccess: (accountRef) => {
            queryClient.invalidateQueries({queryKey: [CURRENT_USER_QUERY_KEY]});
            queryClient.invalidateQueries({queryKey: [CONTAINERS_QUERY_KEY, accountRef.id]});
        },
        retry: false
    })
    return mutation;
}

const redeemInvitation = async (code: string) => {
    const response = await fetch(`/api/me/invitations/redeem//${code}`, {
        method: 'post',
        headers: {
            "Content-Type": "application/json",
        }
    });
    const responseBody = await response.text();
    if (!response.ok) {
        const errorMsg = convertToErrorMsg(response.status, responseBody);
        throw errorMsg;
    } else {
        return JSON.parse(responseBody) as AccountRef;
    }
}