import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { styles } from '../../../../styles';
import { Guide } from "./components/Guide";
import { useGuidesQuery } from "../../../../data/guides";
import { GuideSection } from "../../../../types/GuideItem";
import { Loader } from "../../../../components/Loader";

const PageWrapper = styled.div`
    color: ${styles.textColorSecondary700};
`;

const GuidesList = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 24px;

    justify-content: space-between;
`;

const ListHeader = styled.h2`
    margin-bottom: 24px;
`; 

const ListSubheader = styled.p`
    margin-bottom: 24px;
`;

export const Guides = () => {
    const { t } = useTranslation();
    const guidesQuery = useGuidesQuery();

    if (guidesQuery.isLoading) return (
        // RESOLVED: insert loading spinner
        <Loader height={"420px"} />
    )
    if (guidesQuery.isError) {
        return <>ERROR: {guidesQuery.error.toString()}</>
    }

    if (!guidesQuery.data) return null;

    return (
        <PageWrapper>
            <section>
                <ListHeader>{t("pages.help.guides.header1")}</ListHeader>
                <ListSubheader>{t("pages.help.guides.subheader1")}</ListSubheader>
                <GuidesList>
                    {
                        guidesQuery.data
                            .filter(item => item.section === GuideSection.StartedSuccessfully)
                            .map(item => <Guide key={item.id} item={item} />)
                    }
                </GuidesList>
            </section>

            <section>
                <ListHeader>{t("pages.help.guides.header2")}</ListHeader>
                <ListSubheader>{t("pages.help.guides.subheader2")}</ListSubheader>
                <GuidesList>
                    {
                        guidesQuery.data
                        .filter(item => item.section === GuideSection.OrganizedEfficiently)
                        .map(item => <Guide key={item.id} item={item} />)
                    }
                </GuidesList>
            </section>

        </PageWrapper>
    )
}