import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { styles } from "../../../../styles";
import { IconClose } from "../../../../components/Icons";
import Button from '../../../../components/Button';
import { TranslationWithBoldedText } from "../../../home/components/TranslationWithBoldedText";
import { EmployeeListItem } from "./Helpers";
import { useInvitationCancellationMutation } from "../../../../data/invitations";
import { Invitation } from "../../../../types/Invitation";
import { ContactAssociation } from "../../../../types/ContactAssociation";
import { useContactAssociationRemovalMutation } from "../../../../data/contacts";
import { Loader } from "../../../../components/Loader";


type CancelModalProps = {
    employeeToDelete: EmployeeListItem;
    onClose: (deletedEmployee?: EmployeeListItem) => void;
}

const ModalContentWrapper = styled.div`
    padding-top: 24px;
    padding-right: 24px;
    h2 {
        margin-bottom: 36px;
    }

    p {
        color: ${styles.textColorSecondary700};
    }
`;

const ModalHeading = styled.h2`
    margin-bottom: 48px;
    color: ${styles.textColorSecondary700};
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg {
        cursor: pointer;
    }
`;

const BottomRow = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    margin-top: 48px;

    button {
        width: 156px;
    }
`;

export const DeleteEmployeeModalContent: React.FC<CancelModalProps> = ({ employeeToDelete, onClose }) => {
    const cancelInvitationMutation = useInvitationCancellationMutation();
    const removeContactAssociationMutation = useContactAssociationRemovalMutation();
    const { t } = useTranslation();

    const onDelete = async () => {
        if (employeeToDelete.type === 'invitation') {
            await cancelInvitationMutation.mutateAsync(employeeToDelete.typedObject as Invitation)
        }
        if (employeeToDelete.type === 'contact') {
            await removeContactAssociationMutation.mutateAsync(employeeToDelete.typedObject as ContactAssociation);
        }
        _onClose(employeeToDelete)
    }

    const isMutating = cancelInvitationMutation.isLoading || removeContactAssociationMutation.isLoading;
    const _onClose = (deletedEmployee?: EmployeeListItem) => {
        if (!isMutating) {
            onClose(deletedEmployee);
        }
    }


    return (
        <ModalContentWrapper>
            <ModalHeading>{t("pages.myOrganization.header")} <div onClick={() => _onClose()}><IconClose /></div></ModalHeading>
            { !isMutating && 
                <>
                    <TranslationWithBoldedText textValue={`${employeeToDelete.firstName} ${employeeToDelete.lastName}`} i18nKey="pages.myOrganization.deleteEmployeeModal.confirmDeleteText" />
                </>
            }
            { isMutating && 
                <div>
                    {/* RESOLVED: make waiting content nice... */}
                    <Loader messageText={t("pages.myOrganization.deleteEmployeeModal.removing")} height={"126px"} justifyContent={"flexStart"}/>
                </div>
            }
            
            
            {!isMutating && <BottomRow>
                <Button buttonVariant="ghost" onClick={() => _onClose()} disabled={isMutating}>
                    {t("buttons.no")}
                </Button>
                <Button onClick={onDelete} disabled={isMutating}>
                    {t("buttons.yes")}
                </Button>
            </BottomRow>}
        </ModalContentWrapper>
    );
};
