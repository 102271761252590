export const NoContainerImage = () => (
    <svg width="224" height="150" viewBox="0 0 224 150" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_2011_254457)">
            <rect width="222.656" height="150" transform="translate(0.671875)" fill="white" />
            <rect width="212.344" height="178.919" transform="translate(5.26562 -2.66931)" fill="white" />
            <path d="M111.16 137.476C164.326 137.476 207.426 134.68 207.426 131.232C207.426 127.783 164.326 124.987 111.16 124.987C57.9931 124.987 14.8931 127.783 14.8931 131.232C14.8931 134.68 57.9931 137.476 111.16 137.476Z" fill="#F5F5F5" />
            <path d="M176.034 128.32H174.012V20.0895H171.839V128.32H169.817L168.444 130.653H171.839H174.012H177.406L176.034 128.32Z" fill="#26374A" />
            <path d="M188.789 22.9386C190.858 14.175 185.43 5.394 176.667 3.32561C167.903 1.25721 159.122 6.68474 157.054 15.4483C154.985 24.2119 160.413 32.9929 169.176 35.0613C177.94 37.1297 186.721 31.7022 188.789 22.9386Z" fill="#85CC91" />
            <path d="M174.81 16.3154C174.81 15.2815 173.927 14.5816 172.522 14.5816C171.938 14.5441 171.353 14.6549 170.823 14.9036C170.293 15.1523 169.834 15.5309 169.489 16.0043L167.701 14.8424C168.735 13.438 170.395 12.5917 172.701 12.5917C175.409 12.5917 177.22 13.8268 177.22 15.9494C177.22 18.9549 174.064 19.2294 174.064 21.4069H171.731C171.731 18.6759 174.81 18.159 174.81 16.3154ZM171.438 24.3758C171.432 24.0843 171.512 23.7975 171.67 23.5519C171.827 23.3064 172.054 23.1132 172.321 22.997C172.588 22.8808 172.884 22.8468 173.171 22.8995C173.458 22.9521 173.723 23.0889 173.931 23.2925C174.14 23.4961 174.284 23.7572 174.343 24.0425C174.403 24.3279 174.377 24.6247 174.267 24.8949C174.158 25.1652 173.97 25.3968 173.729 25.5601C173.487 25.7235 173.203 25.8113 172.911 25.8122C172.719 25.8191 172.527 25.7868 172.348 25.7175C172.169 25.6482 172.005 25.5433 171.867 25.409C171.73 25.2748 171.621 25.114 171.547 24.9364C171.473 24.7588 171.436 24.5681 171.438 24.3758Z" fill="white" />
            <path opacity="0.3" d="M176.034 128.32H174.012V39.774L171.839 38.4016V128.32H169.817L168.444 130.653H171.839H174.012H177.406L176.034 128.32Z" fill="white" />
            <path d="M189.186 123.783C189.451 123.34 190.036 122.795 190.448 122.484C190.86 122.173 191.423 122.018 191.903 122.214C192.511 122.461 192.79 123.211 192.708 123.861C192.621 124.515 192.31 125.339 191.94 125.883C190.357 128.202 188.893 130.325 186.445 132.05C186.445 129.172 187.722 126.24 189.186 123.783Z" fill="#ADDDB5" />
            <path d="M181.125 124.181C181.674 123.884 182.689 124.305 183.188 124.68C184.176 125.421 184.78 126.199 185.384 127.278C186.148 128.646 186.239 130.572 186.335 131.794C184.4 130.225 182.429 128.61 181.102 126.501C180.869 126.13 180.658 125.737 180.613 125.302C180.571 124.868 180.741 124.392 181.125 124.181Z" fill="#ADDDB5" />
            <path d="M186.493 133.297C186.548 133.297 186.594 133.261 186.603 133.206C187.061 131.088 187.875 129.079 189.019 127.236C189.051 127.181 189.037 127.112 188.982 127.08C188.927 127.048 188.859 127.062 188.826 127.117C187.669 128.983 186.85 131.015 186.384 133.16C186.37 133.219 186.411 133.284 186.471 133.297C186.48 133.297 186.489 133.297 186.493 133.297Z" fill="#26374A" />
            <path d="M186.41 132.095C186.41 132.095 186.438 132.095 186.451 132.091C186.511 132.068 186.543 132.004 186.52 131.944C186.003 130.513 185.244 129.195 184.269 128.024C184.228 127.974 184.155 127.969 184.109 128.01C184.059 128.052 184.054 128.125 184.095 128.17C185.051 129.319 185.797 130.613 186.309 132.022C186.328 132.068 186.369 132.1 186.415 132.1L186.41 132.095Z" fill="#26374A" />
            <path d="M69.3819 108.658C64.5946 115.319 59.2349 123.489 60.796 125.57C63.3978 129.993 81.6104 130.34 90.7167 129.733C103.726 127.652 130.524 129.733 132.346 129.733H156.802C162.839 128.9 162.787 124.182 162.006 121.928C161.226 117.505 154.103 111.52 152.64 106.837C150.558 100.177 151.772 91.0529 152.64 87.3236C155.345 71.7128 154.287 59.6578 153.42 55.5816C151.339 42.6767 138.806 36.3475 135.208 35.5478C125.841 33.4663 117.342 30.4309 114.133 28.7831C112.676 28.1587 113.055 27.2219 114.393 26.4415C117.515 24.6202 120.637 20.2839 122.459 17.8555C125.165 15.1497 122.545 12.7386 120.898 11.8713C112.988 7.29211 111.011 9.09603 111.011 10.5704C109.346 15.5658 103.466 14.7658 100.604 16.5545C98.9384 17.5952 99.9098 19.0696 100.604 19.6767C101.644 20.5873 105.634 24.7068 107.628 27.2219C108.669 28.4708 107.576 28.9961 106.328 29.5635C103.466 30.8645 93.0583 34.8539 88.1149 36.3282C78.4882 39.1993 73.6076 47.7762 72.2439 52.7196C70.5788 58.7557 71.5501 75.3552 72.2439 82.9005C73.2846 88.7285 72.2439 96.1698 72.2439 99.8122C72.2439 104.183 70.3359 107.531 69.3819 108.658Z" fill="#10242E" />
            <path d="M111.531 17.8558C109.45 18.2721 101.889 17.6656 106.327 16.5549C113.857 14.6709 112.711 11.8091 114.377 11.809C115.418 11.8089 114.133 17.3355 111.531 17.8558Z" fill="white" fillOpacity="0.4" />
            <path d="M83.9519 55.5817C84.7845 50.5862 107.873 33.4038 106.327 32.1655C104.782 30.9272 79.0085 49.5976 80.8297 55.5817C82.2868 60.369 83.5183 57.5764 83.9519 55.5817Z" fill="white" fillOpacity="0.4" />
            <path d="M105.547 57.6631C105.755 51.2107 108.409 37.9762 109.71 32.1655C109.71 28.7831 107.108 36.0682 102.165 46.7356C97.2211 57.403 100.864 70.412 104.506 78.9979C108.149 87.5839 116.214 94.8689 111.011 86.283C105.807 77.697 105.287 65.7287 105.547 57.6631Z" fill="white" fillOpacity="0.4" />
            <path d="M96.4406 60.525C95.1397 56.8825 96.7008 55.5817 98.522 46.7356C100.394 39.1904 90.9768 51.4187 88.375 54.2807C85.7732 57.1427 87.0741 61.5658 89.9361 62.3463C92.7981 63.1268 97.7415 64.1676 96.4406 60.525Z" fill="white" fillOpacity="0.4" />
            <path d="M76.6669 55.5817C83.9519 38.3472 93.3026 38.6074 92.0017 37.8269C90.961 36.7861 83.9519 41.0115 78.7483 46.7356C73.5448 52.4596 74.7184 60.1914 76.6669 55.5817Z" fill="white" fillOpacity="0.4" />
            <path d="M139.37 46.7356C134.167 39.1904 114.393 32.1655 113.873 32.1655C106.327 29.2777 128.183 34.7672 135.468 39.7106C142.753 44.654 146.395 48.8169 149.257 54.2807C152.119 59.7445 148.477 67.5499 148.216 59.4843C147.956 51.4187 144.574 54.2807 139.37 46.7356Z" fill="white" fillOpacity="0.4" />
            <path d="M137.029 59.7445C133.907 56.4142 130.351 46.562 128.963 42.0522C129.988 39.7106 133.386 49.3373 138.85 52.9799C144.314 56.6225 147.696 63.9074 143.533 60.525C139.37 57.1427 140.931 63.9074 137.029 59.7445Z" fill="white" fillOpacity="0.4" />
            <path d="M113.873 84.982C111.999 78.5295 111.342 49.0146 111.775 35.4852C111.775 30.0215 113.873 35.5477 115.434 40.7513C116.683 44.9142 114.117 45.6323 115.434 46.7356C122.183 54.9987 116.979 50.5757 115.434 52.1993C113.165 54.5831 115.434 63.1268 116.474 74.5748C117.515 86.0227 116.214 93.0476 113.873 84.982Z" fill="white" fillOpacity="0.4" />
            <path d="M127.402 49.0771C127.818 45.7468 120.984 39.7106 117.515 37.1088C115.434 33.4663 115.114 39.7106 122.979 51.4187C130.844 63.1268 126.882 53.24 127.402 49.0771Z" fill="white" fillOpacity="0.4" />
            <path d="M73.2688 109.699C72.9928 106.514 74.8831 113.799 80.814 116.401C91.44 121.063 73.2688 118.233 73.2688 109.699Z" fill="white" fillOpacity="0.4" />
            <path d="M92.0175 115.163C82.0266 111 79.5479 101.458 75.09 99.2294C73.0086 98.1887 76.6669 102.154 78.7483 109.699C80.8298 117.244 104.506 120.366 92.0175 115.163Z" fill="white" fillOpacity="0.4" />
            <path d="M147.176 110.74C142.477 107.216 134.931 119.523 131.809 119.523C130.248 119.523 135.4 116.13 137.661 114.299C139.986 112.415 143.533 106.577 147.696 108.658C151.859 110.74 149.257 112.301 147.176 110.74Z" fill="white" fillOpacity="0.4" />
            <path d="M76.6669 64.4277C77.0034 66.4467 76.4588 70.3598 78.7483 72.2331C81.6103 74.5748 75.366 56.6223 76.6669 64.4277Z" fill="white" fillOpacity="0.4" />
            <path d="M150.802 74.2521C149.97 79.872 147.76 88.4608 135.452 97.9285C132.069 100.53 144.558 94.0258 146.64 90.1231C148.212 87.1744 152.364 76.1601 152.364 74.2521C152.19 71.9105 151.635 68.6322 150.802 74.2521Z" fill="white" fillOpacity="0.4" />
            <path d="M83.9519 95.3892C77.4994 90.6019 75.7254 80.8878 74.5854 76.396C73.5289 72.2331 73.5744 76.396 73.5447 82.6404C73.5447 87.6358 78.4014 93.221 80.8297 95.3892C84.559 97.3839 90.4044 100.176 83.9519 95.3892Z" fill="white" fillOpacity="0.4" />
            <path d="M65.9995 122.448C65.2189 123.749 65.3751 126.142 72.2438 125.31C80.8297 124.269 65.9995 124.79 65.9995 122.448Z" fill="white" fillOpacity="0.4" />
            <path d="M156.802 122.646C158.363 125.247 147.18 127.452 147.696 126.548C148.737 124.727 149.647 127.02 153.68 123.946C155.501 122.559 153.988 117.955 156.802 122.646Z" fill="white" fillOpacity="0.4" />
            <path d="M107.353 28.2002H114.117" stroke="#10242E" strokeWidth="3.12216" strokeLinecap="round" />
            <path d="M52.0191 39.6286C54.0228 39.0614 56.1363 38.4026 58.0667 37.6661C59.0188 37.311 59.9504 36.9033 60.8572 36.4447C61.264 36.239 61.6567 36.0068 62.0329 35.7494C62.2876 35.5815 62.5197 35.3816 62.7237 35.1547C62.774 35.1044 62.7511 35.1227 62.7511 35.2233C62.7511 35.2462 62.774 35.1913 62.7511 35.1044C62.6938 34.791 62.5773 34.4913 62.408 34.2215C61.9013 33.4097 61.3027 32.659 60.6239 31.9845C59.1141 30.4782 57.5114 29.068 55.8252 27.7621L55.7886 27.7347C55.5732 27.5648 55.4276 27.3219 55.3793 27.0519C55.3311 26.7819 55.3835 26.5035 55.5267 26.2696C55.6699 26.0356 55.8939 25.8623 56.1563 25.7824C56.4187 25.7026 56.7013 25.7217 56.9505 25.8362C59.0864 26.7855 61.1038 27.9815 62.9615 29.3998C63.9724 30.1679 64.8742 31.0697 65.6422 32.0806C66.1241 32.7128 66.4798 33.4319 66.6898 34.1986C66.8226 34.6979 66.8629 35.2173 66.8088 35.7311C66.7406 36.3024 66.5486 36.8519 66.2461 37.3413C65.8114 38.0224 65.262 38.6229 64.6221 39.1163C64.1039 39.5189 63.5599 39.8872 62.9936 40.2188C61.951 40.8216 60.8689 41.3534 59.7548 41.8107C58.6706 42.2682 57.5864 42.6616 56.4885 43.0184C55.3906 43.3752 54.3156 43.7 53.149 43.9791C52.5814 44.1003 51.9888 43.9979 51.4946 43.6933C51.0005 43.3887 50.6429 42.9053 50.4961 42.3436C50.3494 41.782 50.4248 41.1854 50.7068 40.678C50.9888 40.1706 51.4555 39.7915 52.01 39.6195L52.0191 39.6286Z" fill="#FBC8C1" />
            <path d="M49.97 38.3889C49.97 38.3889 55.0752 38.1739 58.6617 36.9708L60.3817 43.1237C60.3817 43.1237 52.7193 47.8858 50.6241 45.8684C48.529 43.851 49.97 38.3889 49.97 38.3889Z" fill="#33AA47" />
            <path opacity="0.7" d="M60.3817 43.1236C60.3817 43.1236 56.9462 45.26 54.0505 46.0651C52.7056 46.4402 51.4842 46.5226 50.7659 45.9919C50.7156 45.9578 50.6682 45.9195 50.6241 45.8775C48.529 43.8693 49.97 38.3706 49.97 38.3706C49.97 38.3706 55.0752 38.1556 58.6617 36.9525L60.3817 43.1236Z" fill="white" />
            <path d="M58.0308 36.9708L59.9018 43.8235L60.8075 43.3981L58.9365 36.5133L58.0308 36.9708Z" fill="#33AA47" />
            <path opacity="0.5" d="M58.0308 36.9708L59.9018 43.8235L60.8075 43.3981L58.9365 36.5133L58.0308 36.9708Z" fill="white" />
            <path opacity="0.1" d="M54.0506 46.0834C52.7057 46.4586 51.4843 46.5409 50.7661 46.0102C50.6454 44.5471 50.8164 43.0746 51.2693 41.6781C51.2693 41.6781 53.0305 42.3277 54.0506 46.0834Z" fill="#10242E" />
            <path d="M37.5184 41.4905C35.4964 42.2591 33.4012 43.1374 31.4616 44.0843C30.5167 44.5411 29.6001 45.0544 28.7169 45.6214C28.3409 45.868 27.9846 46.1433 27.651 46.4448C27.3582 46.6964 27.1935 46.9846 27.1935 46.9023C27.1935 46.8199 27.1935 46.8748 27.1935 46.8611C27.1935 46.8474 27.1935 46.7879 27.1935 46.8245C27.1888 46.8732 27.1888 46.9222 27.1935 46.9709V47.0669V47.1905C27.2087 47.2818 27.2301 47.372 27.2576 47.4604C27.3828 47.9 27.5482 48.3273 27.7516 48.7367C28.2274 49.687 28.7669 50.604 29.3664 51.4814C30.5833 53.3113 31.9694 55.1411 33.3646 56.884L33.3875 56.9115C33.5386 57.1248 33.6103 57.3842 33.5903 57.6449C33.5703 57.9055 33.4599 58.151 33.278 58.3388C33.0962 58.5266 32.8545 58.6449 32.5946 58.6733C32.3347 58.7017 32.0731 58.6384 31.855 58.4943C29.998 57.0066 28.2745 55.3596 26.704 53.572C25.8817 52.6399 25.1366 51.6424 24.4762 50.5894C24.1152 49.9995 23.809 49.3778 23.5613 48.7321C23.4894 48.5498 23.4283 48.3635 23.3783 48.174L23.3005 47.8767L23.2365 47.5519C23.1983 47.3142 23.1754 47.0743 23.1679 46.8336C23.1576 46.6813 23.1576 46.5285 23.1679 46.3762C23.1835 46.2218 23.211 46.0689 23.2502 45.9187C23.3755 45.4076 23.5874 44.9218 23.8769 44.4823C24.1023 44.137 24.358 43.8125 24.6409 43.5125C25.1242 43.0098 25.6508 42.5506 26.2146 42.1401C27.2298 41.3936 28.3002 40.7253 29.4168 40.141C31.5416 39.0119 33.7518 38.0513 36.0271 37.2682C36.3049 37.1666 36.6 37.1208 36.8956 37.1334C37.1911 37.1459 37.4813 37.2165 37.7496 37.3412C38.0178 37.4659 38.2589 37.6422 38.459 37.86C38.6591 38.0778 38.8144 38.333 38.9159 38.6108C39.0174 38.8887 39.0632 39.1838 39.0507 39.4793C39.0382 39.7749 38.9676 40.0651 38.8429 40.3333C38.7182 40.6016 38.5419 40.8426 38.3241 41.0428C38.1062 41.2429 37.8511 41.3981 37.5733 41.4997L37.5184 41.4905Z" fill="#FBC8C1" />
            <path d="M57.6004 26.1381L55.8804 23.5992L53.895 26.9341C53.895 26.9341 55.8392 28.764 56.9188 27.8262L57.6004 26.1381Z" fill="#FBC8C1" />
            <path d="M53.4883 22.8262L51.9741 25.6624L53.8954 26.9342L55.8808 23.5993L53.4883 22.8262Z" fill="#FBC8C1" />
            <path d="M56.3427 129.949L53.3646 130.796L52.6556 126.157L52.2759 123.678L55.2539 122.836L55.7617 126.157L56.3427 129.949Z" fill="#FBC8C1" />
            <path d="M39.2165 130.192L36.147 130.599L36.1196 126.157L36.0967 123.399L39.1708 122.996L39.1937 126.157L39.2165 130.192Z" fill="#FBC8C1" />
            <path opacity="0.2" d="M39.1708 122.996L39.1937 126.157H36.1196L36.0967 123.399L39.1708 122.996Z" fill="#10242E" />
            <path opacity="0.2" d="M55.7617 126.157H52.6556L52.2759 123.678L55.2539 122.836L55.7617 126.157Z" fill="#10242E" />
            <path d="M56.3052 129.487L52.9245 129.396C52.8632 129.393 52.8028 129.412 52.7536 129.448C52.7044 129.485 52.6694 129.538 52.6546 129.597L51.941 132.342C51.9262 132.407 51.9259 132.475 51.9401 132.54C51.9544 132.606 51.9828 132.667 52.0233 132.72C52.0639 132.774 52.1157 132.817 52.1749 132.848C52.2342 132.879 52.2996 132.897 52.3664 132.9C53.5879 132.9 55.3536 132.9 56.8907 132.932C58.684 132.978 59.823 132.891 61.9228 132.932C63.1945 132.964 63.5925 131.692 63.0664 131.56C60.6602 130.965 59.0865 131.102 57.0417 129.73C56.8237 129.582 56.5685 129.498 56.3052 129.487Z" fill="#26374A" />
            <path d="M56.557 129.858C56.5555 129.843 56.5587 129.828 56.566 129.815C56.5734 129.802 56.5846 129.791 56.5982 129.785C56.74 129.716 58.0071 129.117 58.3777 129.327C58.412 129.348 58.4405 129.376 58.4605 129.411C58.4805 129.445 58.4914 129.484 58.492 129.524C58.4969 129.591 58.4858 129.657 58.4595 129.719C58.4332 129.78 58.3926 129.834 58.3411 129.876C57.9888 130.169 57.1059 130.023 56.6119 129.908C56.5991 129.906 56.5872 129.899 56.5776 129.891C56.5679 129.882 56.5608 129.87 56.557 129.858ZM56.8589 129.826C57.5405 129.959 58.0483 129.94 58.2313 129.78C58.2663 129.753 58.2938 129.717 58.3114 129.676C58.3289 129.636 58.336 129.591 58.3319 129.547C58.3316 129.53 58.3273 129.514 58.3193 129.5C58.3113 129.486 58.3 129.473 58.2862 129.465H58.2587C58.0392 129.368 57.353 129.606 56.8543 129.826H56.8589Z" fill="#33AA47" />
            <path d="M56.5802 129.908C56.5694 129.902 56.561 129.892 56.5561 129.881C56.5511 129.869 56.55 129.856 56.5527 129.844C56.5978 129.407 56.7804 128.995 57.0742 128.669C57.1315 128.622 57.1975 128.589 57.2683 128.569C57.3391 128.549 57.4131 128.544 57.4859 128.554C57.6872 128.582 57.7421 128.682 57.7513 128.765C57.797 129.117 57.0331 129.762 56.6534 129.917C56.6357 129.924 56.6161 129.924 56.5985 129.917L56.5802 129.908ZM57.4676 128.701C57.4144 128.691 57.3596 128.694 57.3074 128.708C57.2552 128.722 57.2068 128.748 57.1657 128.783C56.9185 129.04 56.7585 129.368 56.7083 129.721C57.0971 129.506 57.6232 128.993 57.5911 128.783C57.5911 128.783 57.5911 128.737 57.5271 128.714C57.5094 128.708 57.4909 128.703 57.4722 128.701H57.4676Z" fill="#33AA47" />
            <path d="M39.0964 129.487L35.7112 129.396C35.6498 129.393 35.5894 129.412 35.5402 129.448C35.491 129.485 35.456 129.538 35.4413 129.597L34.7276 132.342C34.7128 132.407 34.7125 132.475 34.7268 132.54C34.741 132.606 34.7694 132.667 34.81 132.72C34.8505 132.774 34.9023 132.817 34.9616 132.848C35.0208 132.879 35.0862 132.897 35.1531 132.9C36.3745 132.9 38.1403 132.9 39.6773 132.932C41.4706 132.978 42.6097 132.891 44.7094 132.932C45.9811 132.964 46.3791 131.692 45.853 131.56C43.4468 130.965 41.8731 131.102 39.8283 129.73C39.6116 129.583 39.3581 129.499 39.0964 129.487Z" fill="#26374A" />
            <path d="M39.3387 129.858C39.3373 129.843 39.3404 129.828 39.3478 129.815C39.3551 129.802 39.3664 129.791 39.3799 129.785C39.5217 129.716 40.7889 129.117 41.1594 129.327C41.1937 129.348 41.2222 129.376 41.2422 129.411C41.2622 129.445 41.2731 129.484 41.2738 129.524C41.2787 129.591 41.2675 129.657 41.2413 129.719C41.215 129.78 41.1744 129.834 41.1228 129.876C40.7706 130.169 39.8877 130.023 39.3936 129.908C39.3808 129.906 39.369 129.899 39.3593 129.891C39.3496 129.882 39.3425 129.87 39.3387 129.858ZM39.6407 129.826C40.3223 129.959 40.8346 129.94 41.013 129.78C41.0465 129.752 41.0727 129.716 41.0895 129.675C41.1062 129.635 41.1129 129.591 41.1091 129.547C41.1097 129.53 41.1057 129.514 41.0976 129.499C41.0896 129.485 41.0777 129.473 41.0634 129.465C40.8255 129.368 40.1439 129.606 39.6315 129.826H39.6407Z" fill="#33AA47" />
            <path d="M39.3653 129.908C39.3546 129.902 39.3462 129.892 39.3412 129.881C39.3363 129.869 39.3351 129.856 39.3379 129.844C39.3829 129.407 39.5655 128.995 39.8594 128.669C39.9166 128.622 39.9826 128.589 40.0534 128.569C40.1242 128.549 40.1983 128.544 40.2711 128.554C40.4724 128.582 40.5273 128.682 40.5364 128.765C40.5822 129.117 39.8182 129.762 39.4385 129.917C39.4208 129.924 39.4013 129.924 39.3836 129.917L39.3653 129.908ZM40.2528 128.701C40.1995 128.691 40.1448 128.694 40.0926 128.708C40.0404 128.722 39.992 128.748 39.9509 128.783C39.7037 129.04 39.5436 129.368 39.4934 129.721C39.8822 129.506 40.4083 128.993 40.3763 128.783C40.3763 128.783 40.3763 128.737 40.3123 128.714C40.293 128.707 40.2731 128.703 40.2528 128.701Z" fill="#33AA47" />
            <path d="M32.8789 62.6664C31.2549 69.7204 34.0454 125.21 34.0454 125.21H39.4434L41.7307 78.9657L50.2303 125.21L55.9989 124.789C52.2249 88.9657 48.8946 67.5795 48.8946 67.5795V64.8759L32.8789 62.6664Z" fill="#26374A" />
            <path opacity="0.2" d="M56.0172 124.789L50.2486 125.21L43.5606 88.8056L41.7307 78.9657L39.4434 125.21H34.0454C34.0454 125.21 31.2549 69.7204 32.8789 62.6664L48.922 64.8759V67.5795C48.922 67.5795 52.2432 88.9657 56.0172 124.789Z" fill="white" />
            <path d="M35.5657 36.7552C35.7305 40.1284 35.6448 43.5092 35.3095 46.8697C35.0854 49.2164 34.7697 51.4443 34.4403 53.4388C33.6627 58.1369 32.7843 61.6731 32.6562 62.8853L48.6993 65.0949C54.5457 48.7407 52.359 39.2209 52.359 39.2209C51.1585 38.7265 49.9286 38.3063 48.6765 37.9629C48.4157 37.8897 48.1458 37.8257 47.8668 37.7616C45.6148 37.3162 43.339 37.0016 41.0506 36.8193C40.616 36.7827 40.1769 36.7552 39.7423 36.7324C37.5373 36.6317 35.5657 36.7552 35.5657 36.7552Z" fill="#33AA47" />
            <path d="M35.5657 36.7552C35.7305 40.1284 35.6448 43.5092 35.3095 46.8697C35.0854 49.2164 34.7697 51.4443 34.4403 53.4388C33.6627 58.1369 32.7843 61.6731 32.6562 62.8853L48.6993 65.0949C54.5457 48.7407 52.359 39.2209 52.359 39.2209C51.1585 38.7265 49.9286 38.3063 48.6765 37.9629C48.4157 37.8897 48.1458 37.8257 47.8668 37.7616C45.6148 37.3162 43.339 37.0016 41.0506 36.8193C40.616 36.7827 40.1769 36.7552 39.7423 36.7324C37.5373 36.6317 35.5657 36.7552 35.5657 36.7552Z" fill="white" fillOpacity="0.7" />
            <path d="M44.3754 29.1803C44.2564 31.4264 43.6892 35.4566 41.8228 36.4997C41.8228 36.4997 45.5053 39.4045 46.6124 39.3954C47.39 39.3954 48.5154 38.1145 48.5154 38.1145C46.2555 36.9663 46.7633 35.205 47.6005 33.6405L44.3754 29.1803Z" fill="#FBC8C1" />
            <path opacity="0.2" d="M45.6897 31.0103L47.6018 33.6589C47.4002 34.0211 47.2318 34.4009 47.0986 34.7934C46.2157 34.4412 45.2048 33.1329 45.3511 32.1539C45.3925 31.7551 45.5073 31.3673 45.6897 31.0103Z" fill="#10242E" />
            <path d="M52.7837 25.1088C53.049 26.2982 52.6419 28.6633 51.0911 27.7484C49.5403 26.8334 52.3308 23.0594 52.7837 25.1088Z" fill="#26374A" />
            <path d="M44.2885 25.9506C43.831 28.9195 43.4468 30.635 44.6179 32.4374C46.3745 35.1455 50.2858 34.528 51.6033 31.742C52.7881 29.2397 53.305 24.807 50.6883 23.0503C50.1049 22.6539 49.4295 22.4141 48.7267 22.3537C48.024 22.2934 47.3175 22.4146 46.6751 22.7057C46.0326 22.9969 45.4758 23.4482 45.0579 24.0164C44.6399 24.5846 44.375 25.2506 44.2885 25.9506Z" fill="#FBC8C1" />
            <path d="M43.3276 21.7053C41.2782 22.5105 41.2599 27.5883 43.7851 29.3129C45.3313 28.279 46.0724 24.2808 46.0724 24.2808L43.3276 21.7053Z" fill="#26374A" />
            <path d="M42.6457 28.1556C42.6839 28.5258 42.7953 28.8848 42.9736 29.2115C43.1519 29.5382 43.3935 29.8262 43.6842 30.0586C44.4664 30.6807 45.2487 30.0586 45.363 29.1437C45.4591 28.2974 45.1755 26.957 44.2331 26.7054C44.0267 26.655 43.8111 26.6562 43.6052 26.7088C43.3994 26.7615 43.2096 26.8639 43.0528 27.0073C42.8959 27.1506 42.7767 27.3303 42.7057 27.5305C42.6348 27.7308 42.6141 27.9455 42.6457 28.1556Z" fill="#FBC8C1" />
            <path d="M48.5792 27.6662C48.5289 27.9041 48.6158 28.1236 48.7714 28.1511C48.9269 28.1785 49.0962 28.0138 49.1465 27.776C49.1968 27.5381 49.1099 27.3185 48.9544 27.2865C48.7988 27.2545 48.6296 27.4237 48.5792 27.6662Z" fill="#26374A" />
            <path d="M51.2824 28.206C51.232 28.4439 51.319 28.6635 51.4745 28.6955C51.63 28.7275 51.7993 28.5583 51.8496 28.3204C51.8999 28.0825 51.813 27.8629 51.6575 27.8309C51.5019 27.7989 51.3327 27.9682 51.2824 28.206Z" fill="#26374A" />
            <path d="M50.4002 28.1509C50.5466 28.9157 50.7991 29.6563 51.1505 30.3513C50.9626 30.4296 50.7603 30.4672 50.5569 30.4617C50.3535 30.4561 50.1535 30.4076 49.9702 30.3192L50.4002 28.1509Z" fill="#DA968B" />
            <path d="M47.6966 29.9214C47.6022 29.893 47.5059 29.8716 47.4084 29.8574C47.3896 29.8533 47.3699 29.8567 47.3536 29.867C47.3374 29.8773 47.3258 29.8936 47.3214 29.9123C47.3194 29.9213 47.3193 29.9307 47.321 29.9397C47.3227 29.9488 47.3262 29.9575 47.3314 29.9652C47.3365 29.9729 47.3431 29.9794 47.3508 29.9845C47.3586 29.9896 47.3672 29.993 47.3763 29.9946C47.6731 30.0528 47.9519 30.18 48.1903 30.366C48.4288 30.552 48.62 30.7915 48.7487 31.0651C48.758 31.0784 48.7716 31.0883 48.7871 31.0931C48.8027 31.0978 48.8194 31.0973 48.8346 31.0915C48.8498 31.0858 48.8627 31.0751 48.8712 31.0612C48.8796 31.0473 48.8832 31.0309 48.8814 31.0148C48.7736 30.7584 48.612 30.5281 48.4076 30.3395C48.2032 30.1509 47.9608 30.0083 47.6966 29.9214Z" fill="#26374A" />
            <path d="M48.3232 26.3121C48.3473 26.3189 48.3727 26.32 48.3974 26.3152C48.422 26.3105 48.4452 26.3 48.465 26.2846C48.5961 26.1604 48.755 26.0694 48.9286 26.0192C49.1021 25.9691 49.2851 25.9613 49.4623 25.9964C49.4992 26.0051 49.5381 25.9996 49.5711 25.981C49.6042 25.9623 49.629 25.9319 49.6407 25.8958C49.6462 25.8775 49.648 25.8582 49.6459 25.8392C49.6438 25.8202 49.6378 25.8018 49.6284 25.7851C49.619 25.7684 49.6063 25.7538 49.5911 25.7422C49.576 25.7306 49.5586 25.7221 49.54 25.7174C49.316 25.6721 49.0844 25.6804 48.8642 25.7416C48.644 25.8028 48.4414 25.9152 48.2729 26.0696C48.2596 26.0828 48.249 26.0985 48.2419 26.1157C48.2347 26.133 48.231 26.1515 48.231 26.1703C48.231 26.189 48.2347 26.2075 48.2419 26.2248C48.249 26.242 48.2596 26.2577 48.2729 26.2709C48.2872 26.2874 48.3042 26.3013 48.3232 26.3121Z" fill="#26374A" />
            <path d="M52.6467 26.8978C52.6734 26.9068 52.7024 26.9068 52.7291 26.8978C52.7475 26.8935 52.7649 26.8856 52.7803 26.8745C52.7956 26.8633 52.8086 26.8493 52.8184 26.8331C52.8282 26.8169 52.8346 26.7989 52.8374 26.7801C52.8401 26.7614 52.8391 26.7423 52.8343 26.7239C52.7819 26.5037 52.6772 26.2993 52.5291 26.1281C52.381 25.9569 52.1938 25.8239 51.9834 25.7404C51.947 25.7299 51.908 25.7335 51.8741 25.7504C51.8402 25.7674 51.8139 25.7965 51.8004 25.8319C51.7888 25.8683 51.7918 25.9078 51.8089 25.942C51.826 25.9762 51.8558 26.0024 51.8919 26.0149C52.0583 26.0826 52.2054 26.1902 52.3203 26.3282C52.4352 26.4662 52.5143 26.6304 52.5507 26.8063C52.5576 26.8284 52.5701 26.8484 52.5869 26.8644C52.6037 26.8804 52.6243 26.8919 52.6467 26.8978Z" fill="#26374A" />
            <path d="M45.6657 24.098C46.0839 24.7503 46.7406 25.2133 47.4955 25.388C47.3414 25.0907 47.2718 24.7569 47.2943 24.4228C47.2943 24.4228 48.4471 25.6213 51.5578 25.388L50.9631 24.7567C50.9631 24.7567 53.2504 24.8802 53.8862 24.098C54.5221 23.3157 54.5221 21.7421 54.5221 21.7421L53.9183 22.3688C53.8761 22.1492 53.8786 21.9234 53.9258 21.7049C53.9729 21.4864 54.0637 21.2796 54.1927 21.0971C54.1927 21.0971 52.9896 20.6899 52.308 21.3212C52.308 21.3212 52.4498 20.1776 51.8506 19.6606C51.8506 19.6606 50.6291 20.6579 49.3299 20.3239L50.0848 19.8116C50.0848 19.8116 46.8825 18.7869 45.1533 19.8116V19.3541C45.1533 19.3541 42.7242 20.5344 43.4196 22.7668C44.1149 24.9992 45.6657 24.098 45.6657 24.098Z" fill="#26374A" />
            <path d="M46.6124 39.4182C46.6124 39.4182 45.6974 40.2325 44.691 41.0193C43.4605 40.2005 40.6013 38.0595 40.949 36.4447C41.1869 35.9873 42.4724 35.8363 42.4724 35.8363C42.4724 35.8363 43.6206 37.7576 46.6124 39.4182Z" fill="#33AA47" />
            <path d="M46.6123 39.4182C47.1972 40.0135 47.6933 40.6898 48.0853 41.4265C49.1283 40.9187 49.0002 37.808 48.6892 37.4283C48.2145 37.0851 47.6604 36.8678 47.0789 36.797C47.2216 37.2387 47.2537 37.7087 47.1723 38.1658C47.0909 38.6228 46.8987 39.0529 46.6123 39.4182Z" fill="#33AA47" />
            <g opacity="0.5">
                <path d="M46.6124 39.4182C46.6124 39.4182 45.6974 40.2325 44.691 41.0193C43.4605 40.2005 40.6013 38.0595 40.949 36.4447C41.1869 35.9873 42.4724 35.8363 42.4724 35.8363C42.4724 35.8363 43.6206 37.7576 46.6124 39.4182Z" fill="white" />
                <path d="M46.6123 39.4182C47.1972 40.0135 47.6933 40.6898 48.0853 41.4265C49.1283 40.9187 49.0002 37.808 48.6892 37.4283C48.2145 37.0851 47.6604 36.8678 47.0789 36.797C47.2216 37.2387 47.2537 37.7087 47.1723 38.1658C47.0909 38.6228 46.8987 39.0529 46.6123 39.4182Z" fill="white" />
            </g>
            <path d="M33.8992 58.8558L33.4967 60.6856L30.418 58.3251C30.418 58.3251 31.5433 55.9051 32.8974 56.3718L33.3228 56.8292C33.5743 57.0961 33.758 57.4195 33.8583 57.7722C33.9586 58.1249 33.9726 58.4965 33.8992 58.8558Z" fill="#FBC8C1" />
            <path d="M31.562 62.2775L29.1055 60.2098L30.4184 58.3159L33.4971 60.6764L31.562 62.2775Z" fill="#FBC8C1" />
            <path opacity="0.2" d="M43.5613 88.8056L41.7314 78.9656L42.102 73.7734C42.102 73.7734 44.5814 82.79 43.5613 88.8056Z" fill="#10242E" />
            <path opacity="0.1" d="M37.4905 41.0193C37.4905 41.0193 37.0331 45.1364 35.4731 47.2636C35.4731 47.0623 35.5143 46.8565 35.5326 46.6506C35.6836 45.077 35.7934 43.4484 35.8437 41.7833C36.2836 41.3399 36.8679 41.0689 37.4905 41.0193Z" fill="#10242E" />
            <path d="M35.7889 36.5362C32.3579 36.1062 26.4521 41.0193 26.4521 41.0193L30.4778 46.4265L37.6691 43.3386C37.6691 43.3386 39.9655 37.0668 35.7889 36.5362Z" fill="#33AA47" />
            <path opacity="0.7" d="M35.7889 36.5362C32.3579 36.1062 26.4521 41.0193 26.4521 41.0193L30.4778 46.4265L37.6691 43.3386C37.6691 43.3386 39.9655 37.0668 35.7889 36.5362Z" fill="white" />
            <path d="M26.3203 40.9005L30.4237 46.7011L31.302 46.2391L27.0568 40.2235L26.3203 40.9005Z" fill="#33AA47" />
            <path opacity="0.5" d="M26.3203 40.9005L30.4237 46.7011L31.302 46.2391L27.0568 40.2235L26.3203 40.9005Z" fill="white" />
        </g>
        <defs>
            <clipPath id="clip0_2011_254457">
                <rect width="222.656" height="150" fill="white" transform="translate(0.671875)" />
            </clipPath>
        </defs>
    </svg>
);