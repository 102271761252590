import styled from "@emotion/styled";
import { t } from "i18next";
import { RemovalDateChooser } from "./RemovalDateChooser";
import { IconDeleteBig, IconPlusCircle } from "../../../components/Icons";
import { TextIconButton } from "../../../components/StyledComponents";
import { styles } from "../../../styles";
import { useCallback, useEffect, useState } from "react";
import { Container } from "../../../types/Container";
import { ONE_DAY } from "../../../data/_base";
import { StyledCheckbox, StyledFormControlLabel } from "./SeriesPlannerTool";

type DatePeriodsChooserProps = {
    container: Container;
    initialValue: DatePeriod[];
    onChange: (datePeriods: DatePeriod[]) => void;
    useSettingsFromPreviousContainer: boolean;
}

type DatePeriodChooserProps = {
    index: number;
    container: Container;
    disabled: boolean;
    minDate?: Date;
    value?: DatePeriod;
    onChange: (index: number, datePeriod: DatePeriod) => void;
    onUpdatePossibleDatesWithoutEnd?: (dates: Date[]) => void;
    useSettingsFromPreviousContainer: boolean;
}


const Row = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 12px;
    color: ${styles.textColorSecondary700};
`;


const StyledH3 = styled.h3<{ disabled: boolean}>`
    display: flex;
    color: ${props => props.disabled ? '#E0E1E0': 'inherit'};
    gap: 10px;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 8px;

    svg {
        cursor: pointer;
    }
`;

const DateRow = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 12px;
    margin-top: 12px;
    margin-bottom: 12px;
`;

const DatePeriodChooserWrapper = styled.div`
    display: flex;
    align-items: flex-end;
    gap: 8px;

    svg {
        width: 24px;
        height: 24px;
        min-width: 24px;
        min-height: 24px;
        cursor: pointer;
    }
`;

const TransparentBackgroundDiv = styled.div`
    display: block;
    width: 24px;
    height: 24px;
    background: transparent;
`;

const AddPeriodButton = styled(TextIconButton)`
    align-self: start;

    circle, path {
        stroke:  ${props => (props.disabled ? styles.neutral200 : styles.textColorSecondary700)};
    }
`;

export type DatePeriod = {
    id: number;
    startDate: Date;
    endDate: Date;
    schedulableDates: Date[];
    type: 'picker' | 'all-year';
}

export const END_OF_YEAR = new Date();
END_OF_YEAR.setMonth(11);
END_OF_YEAR.setDate(31);

const DatePeriodChooser = ({ index, container, disabled, value, minDate, onChange, onUpdatePossibleDatesWithoutEnd, useSettingsFromPreviousContainer }: DatePeriodChooserProps) => {

    const [possibleDatesWithoutEnd, setPossibleDatesWithoutEnd] = useState<Date[]>([]);
    const [lastChangedByValueUpdate, setLastChangedByValueUpdate] = useState(false);
    const [startDate, setStartDate] = useState<Date | undefined>(value?.startDate);
    const [endDate, setEndDate] = useState<Date | undefined>(value?.endDate);

    useEffect(() => {
        if (onUpdatePossibleDatesWithoutEnd) {
            onUpdatePossibleDatesWithoutEnd(possibleDatesWithoutEnd);
        }
    }, [onUpdatePossibleDatesWithoutEnd, possibleDatesWithoutEnd]);

    const informParentAboutChangedSelection = useCallback(() => {
        if (disabled) return;
        const start = startDate || minDate;
        if (!lastChangedByValueUpdate && start && endDate) {
            const updatedDatePeriod: DatePeriod = {
                id: value?.id || new Date().getTime(),
                startDate: start,
                endDate: start > endDate ? start : endDate,
                schedulableDates: (possibleDatesWithoutEnd || []).filter(date => date.getTime() <= endDate.getTime() && date.getTime() >= start.getTime()),
                type: 'picker'
            }
            onChange(index, updatedDatePeriod);
        }
    }, [lastChangedByValueUpdate, minDate, startDate, endDate, possibleDatesWithoutEnd, index, onChange, value?.id]);

    useEffect(() => {
        if (lastChangedByValueUpdate) {
            if (value?.startDate.getTime() !== startDate?.getTime()) {
                setStartDate(value?.startDate);
            }
            if (value?.endDate.getTime() !== endDate?.getTime()) {
                setEndDate(value?.endDate);
            }
            setLastChangedByValueUpdate(false);
        }
    }, [lastChangedByValueUpdate, startDate, endDate, value]);


    useEffect(() => {
        setLastChangedByValueUpdate(true);
    }, [value])

    useEffect(() => {
        setLastChangedByValueUpdate(false);
    }, [possibleDatesWithoutEnd])


    const updatePossibleDates = useCallback((possibleDates: Date[]) => {
        if (possibleDates && possibleDates.length > 0) {
            setPossibleDatesWithoutEnd(possibleDates);
        }
    }, []);

    useEffect(() => {
        informParentAboutChangedSelection();
    }, [informParentAboutChangedSelection]);

    const updateStartDate = useCallback((date?: Date) => {
        setLastChangedByValueUpdate(false);
        setStartDate(date);
    }, []);

    const updateEndDate = useCallback((date?: Date) => {
        setLastChangedByValueUpdate(false);
        setEndDate(date);
    }, []);

    const isDisabled = disabled || useSettingsFromPreviousContainer;

    return (
        <DateRow>
            <div>
                <StyledH3 disabled={disabled}>{t("pages.emptying.series.modalPlanSerialEmptying.start")}</StyledH3>
                <RemovalDateChooser
                    dateFormat="short" container={container} minDate={minDate} limitToSchedulableDates={false}
                    onChange={updateStartDate} disabled={isDisabled} onPossibleDatesChanged={updatePossibleDates}
                    defaultValue={startDate} />
            </div>
            <div>
                <StyledH3 disabled={disabled}>{t("pages.emptying.series.modalPlanSerialEmptying.end")}</StyledH3>
                <RemovalDateChooser
                    dateFormat="short" container={container} minDate={startDate || minDate} limitToSchedulableDates={false}
                    onChange={updateEndDate} disabled={isDisabled}
                    defaultValue={endDate} />
            </div>
        </DateRow>
    )
}

export const DatePeriodsChooser = ({ container, initialValue, onChange, useSettingsFromPreviousContainer }: DatePeriodsChooserProps) => {
    const [initialValueInternal] = useState(initialValue);
    const [datePeriods, setDatePeriods] = useState<(DatePeriod | undefined)[]>(initialValue.length === 0 ? [undefined] : initialValue[0].type === 'all-year'? [initialValue[0], undefined]: initialValue);
    const [fullYearDatePeriods, setFullYearDatePeriods] = useState<{ year: number, datePeriod: DatePeriod}[]>();

    const onDatePeriodChanged = useCallback((index: number, datePeriod: DatePeriod) => {
        const newDatePeriods = [...datePeriods];
        datePeriod.schedulableDates = [];
        fullYearDatePeriods?.forEach(fullYearDatePeriod => {
            fullYearDatePeriod.datePeriod.schedulableDates.forEach(date => {
                if (date.getTime() < datePeriod.endDate.getTime() + ONE_DAY && date.getTime() >= datePeriod.startDate.getTime()) {
                    datePeriod.schedulableDates.push(date);
                }
            })
        })
        
        if (JSON.stringify(newDatePeriods[index]) !== JSON.stringify(datePeriod)) {
            newDatePeriods[index] = datePeriod;
            setDatePeriods(newDatePeriods);
        }
    }, [datePeriods, fullYearDatePeriods]);

    useEffect(() => {
        onChange(datePeriods.filter(d => d && d.startDate !== undefined && d.endDate !== undefined) as DatePeriod[]);
    }, [onChange, datePeriods]);

    const onRemoveDatePeriod = (datePeriod: DatePeriod | undefined) => {
        const updatedList = datePeriods.filter(d => d && d !== datePeriod && d?.id !== datePeriod?.id);
        setDatePeriods(updatedList);
        onChange(updatedList as DatePeriod[]);
    }

    const onAddPeriod = () => {
        if (datePeriods.length === 0 || datePeriods[datePeriods.length - 1] !== undefined) {
            setDatePeriods([...datePeriods, undefined]);
        }
    }

    const onUpdatePossibleDatesWithoutEnd = (dates: Date[]) => {
        if (fullYearDatePeriods && fullYearDatePeriods.length > 0) return; 
        
        if (!dates || dates.length === 0) return;

        const _fullYearDatePeriods: { year: number, datePeriod: DatePeriod}[] = [];
        
        dates.forEach(date => {
            let fullYearConfig = _fullYearDatePeriods.find(f => f.year === date.getFullYear());
            if (!fullYearConfig) {
                fullYearConfig = {
                    year: date.getFullYear(),
                    datePeriod: {
                        id: date.getFullYear(),
                        startDate: new Date(`${date.getFullYear()}/01/01`),
                        endDate: new Date(`${date.getFullYear()}/12/31`),
                        type: 'all-year',
                        schedulableDates: []
                    }
                };
                if (date.getFullYear() === new Date().getFullYear()) {
                    fullYearConfig.datePeriod.startDate = new Date(new Date().getTime() + ONE_DAY);
                }
                _fullYearDatePeriods.push(fullYearConfig);
            }
            fullYearConfig.datePeriod.schedulableDates.push(date);
        })
        setFullYearDatePeriods(_fullYearDatePeriods);
    }

    const isFullYearSelectorChecked = (year: number) => {
        return (datePeriods || []).filter(datePeriod => datePeriod?.type === 'all-year' && datePeriod.startDate.getFullYear() === year).length > 0;
    }
    
    const toggleFullYearSelection = (year: number) => {
        if (!fullYearDatePeriods) return;

        const datePeriod = fullYearDatePeriods.find(f => f.year === year)?.datePeriod;

        const allSelectedFullYearPeriods = (datePeriods || []).filter(datePeriod => datePeriod?.type === 'all-year');
        const allSelectedFullYearPeriodsWithoutSelectedYear = allSelectedFullYearPeriods.filter(datePeriod => datePeriod?.startDate.getFullYear() !== year);
        const hasBeenDeselected = allSelectedFullYearPeriodsWithoutSelectedYear.length < allSelectedFullYearPeriods.length;
        if (hasBeenDeselected) {
            if (allSelectedFullYearPeriodsWithoutSelectedYear.length === 0) {
                setDatePeriods([undefined]);
            } else {
                setDatePeriods([...allSelectedFullYearPeriodsWithoutSelectedYear, undefined]);
            }
        } else {
            setDatePeriods([...allSelectedFullYearPeriods, datePeriod, undefined]);
        }
    }
    

    useEffect(() => {
        if (initialValueInternal && initialValueInternal.length > 0 && initialValueInternal.filter(v => v.type === 'picker').length > 0) {
            initialValueInternal.forEach(datePeriod => {
                fullYearDatePeriods?.forEach(fullYearDatePeriod => {
                    fullYearDatePeriod.datePeriod.schedulableDates.forEach(date => {
                        if (date.getTime() < datePeriod.endDate.getTime() + ONE_DAY && date.getTime() >= datePeriod.startDate.getTime()) {
                            datePeriod.schedulableDates.push(date);
                        }
                    })
                })
            })
            setDatePeriods(initialValueInternal);
        } else {
            const selectedYears = (initialValueInternal || []).map(v => v.startDate.getFullYear());
            const datePeriods = fullYearDatePeriods?.filter(f => selectedYears.indexOf(f.year) >= 0)?.map(f => f.datePeriod);
            if (datePeriods && datePeriods.length > 0) {
                setDatePeriods([...datePeriods, undefined]);
            }
        }
    }, [fullYearDatePeriods, initialValueInternal]);
    
    const pickableDatePeriods = datePeriods.filter(datePeriod => datePeriod === undefined  || datePeriod?.type === 'picker');
    const selectedFullYearOptions = datePeriods.filter(datePeriod => datePeriod && datePeriod.type === 'all-year');
    const hasFullYearOptionSelected = selectedFullYearOptions.length > 0;

    return (
        <>
            {t("pages.emptying.series.modalPlanSerialEmptying.entireYearCollection")}
            <div>
            {fullYearDatePeriods && fullYearDatePeriods.map(fullYearDatePeriod =>
                <StyledFormControlLabel key={fullYearDatePeriod.year} checked={isFullYearSelectorChecked(fullYearDatePeriod.year)} disabled={useSettingsFromPreviousContainer}
                    onChange={(evt, value) => toggleFullYearSelection(fullYearDatePeriod.year)} control={<StyledCheckbox />} label={fullYearDatePeriod.year} />
            )}
            </div>

            <div style={{marginTop: '20px', color: hasFullYearOptionSelected ? '#E0E1E0': undefined}}>
                {t("pages.emptying.series.modalPlanSerialEmptying.flexibleEmptyingDesired")}
            </div>
            
            {pickableDatePeriods.map((datePeriod, index) => {
                let minDate = index === 0 ? new Date() : pickableDatePeriods[index - 1] && pickableDatePeriods[index - 1]?.endDate ? pickableDatePeriods[index - 1]?.endDate : undefined;
                if (minDate) {
                    minDate = new Date(minDate.getTime() + ONE_DAY)
                }
                const datePeriodId = datePeriod?.id || `not-set-${new Date().getTime()}`;
                if (hasFullYearOptionSelected && selectedFullYearOptions[0]) {
                    datePeriod = {...selectedFullYearOptions[0]};
                    selectedFullYearOptions.forEach(fullYearOption => {
                        if (fullYearOption && datePeriod && fullYearOption.startDate < datePeriod.startDate) {
                            datePeriod.startDate = fullYearOption.startDate;
                        }
                        if (fullYearOption && datePeriod && fullYearOption.endDate > datePeriod.endDate) {
                            datePeriod.endDate = fullYearOption.endDate;
                        }
                    })
                }
                return (<DatePeriodChooserWrapper key={datePeriodId}>
                    <DatePeriodChooser useSettingsFromPreviousContainer={useSettingsFromPreviousContainer} index={index} container={container} minDate={minDate} value={datePeriod} disabled={hasFullYearOptionSelected} onChange={onDatePeriodChanged} onUpdatePossibleDatesWithoutEnd={index === 0 ? onUpdatePossibleDatesWithoutEnd: undefined} />
                    {pickableDatePeriods.length > 1 && index !== 0 && !useSettingsFromPreviousContainer &&
                        <div style={{ height: "44px", display: "flex", alignItems: "center", marginBottom: '12px' }} onClick={() => onRemoveDatePeriod(datePeriod)}>
                            <IconDeleteBig />
                        </div>}
                    {pickableDatePeriods.length > 1 && index === 0 && !useSettingsFromPreviousContainer && <TransparentBackgroundDiv />}
                </DatePeriodChooserWrapper>)
            })}
            <Row>
                <AddPeriodButton disabled={hasFullYearOptionSelected || (pickableDatePeriods.length === 1 && !pickableDatePeriods[0]) } onClick={!useSettingsFromPreviousContainer ? onAddPeriod : undefined} bold svgMargin='0 8px 0 0'><IconPlusCircle /> {t("pages.emptying.series.modalPlanSerialEmptying.addPeriod")}</AddPeriodButton>
            </Row>
        </>
    )
}