import { styles } from "../../styles";
import { Error404Image } from "./Error404Image";
import styled from "@emotion/styled";
import Button from '../../components/Button';
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { ROUTES } from '../../skeleton/Routes';

const PageWrapper = styled.div`
    svg {
        width: 100%;
        max-width: 475px;
        height: auto;
    }
`;

const MainSection = styled.section`
    h1 {
        margin-top: 64px;

        @media (max-width: 480px) {
            margin-top: 48px;
        }
    }

    p {
        max-width: 500px;
        color: ${styles.textColorSecondary700};
    }
`;

const BottomRow = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    margin-top: 48px;
    max-width: 500px;

    button {
        width: 156px;
    }

    @media (max-width: 480px) {
        flex-direction: column;
        align-items: flex-end;
        width: 100%;

        button {
            width: 100%;
            margin-bottom: 0;
        }
    }
`;

export const ContentNotFoundPage = () => {
    const { t } = useTranslation();

    return (
        <PageWrapper>
            {/* RESOLVED: make it nice */}
            <Error404Image />
            <MainSection>
                <h1>{t("pages.pageNotFound.header")}</h1>
                <p>{t("pages.pageNotFound.paragraph")}</p>
            </MainSection>
            <BottomRow>
                <Button>
                <NavLink to={ROUTES.Home}>
                    {t("buttons.home")}
                </NavLink>
                </Button>
            </BottomRow>
        </PageWrapper>
    )
}