import { useState, useEffect } from 'react';
import { DataGrid, GridColDef, GridRenderCellParams, GridSortModel, deDE, enUS } from '@mui/x-data-grid';
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { styles } from "../../../../styles";
import { SortingInfo, useHistoryLogQuery } from '../../../../data/historylog';
import { HistoryLogItem, HistoryLogItemType } from '../../../../types/HistoryLogItem';
import { usePaging } from '../../../../hooks/usePaging';
import { Loader } from '../../../../components/Loader';
import { MessageWrapper } from '../../../../components/MessageWrapper';


const StyledSection = styled.section`
    grid-column: span 8;
`;

const StyledH2 = styled.h2`
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 24px;
`;

const useWindowWidth = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowWidth(window.innerWidth);
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        }
    }, []);

    return windowWidth;
}

const PAGE_SIZE = 500; // prevent that paging is even visible :)
const defaultSort: SortingInfo = {field: 'created', type: 'desc' };

export const PastTransactionsSection: React.FC = () => {
    const windowWidth = useWindowWidth();
    const isMobileView = windowWidth <= 480;
    const [ sortInfo, setSortInfo ] = useState<SortingInfo>(defaultSort);
    const { skipToken, renderPagingUi, updatePagingData, resetPagingInfos} = usePaging<HistoryLogItem>(PAGE_SIZE);
    const historyLogQuery = useHistoryLogQuery('credit-mutations', PAGE_SIZE, sortInfo, skipToken);
    const { t, i18n } = useTranslation();
    const dateFormat = new Intl.DateTimeFormat(i18n.language, { dateStyle: 'medium' });
    const timeFormat = new Intl.DateTimeFormat(i18n.language, { timeStyle: 'short' });

    const columns: GridColDef<HistoryLogItem>[] = [];
    columns.push({ field: 'date', headerName: t("pages.balances.date"), sortable: true, flex: 0.8, disableColumnMenu: true, 
        renderCell: (params: GridRenderCellParams<HistoryLogItem>) => {
            return dateFormat.format(params.row.created);
        } 
    });
    columns.push({ field: 'time', headerName: t("pages.balances.time"), sortable: false, flex: 0.8, disableColumnMenu: true, 
        renderCell: (params: GridRenderCellParams<HistoryLogItem>) => {
            return timeFormat.format(params.row.created);
        } 
    });
    columns.push({ field: 'type', headerName: t("pages.balances.activity"), flex: 1.5, disableColumnMenu: true, 
        renderCell: (params: GridRenderCellParams<HistoryLogItem>) => {
            let activity = '-';
            switch (params.row.type) {
                case HistoryLogItemType.Deposit: activity = t('pages.balances.pastTransactions.activityTypes.deposit'); break;
                case HistoryLogItemType.EmptyingCancelled: activity = t('pages.balances.pastTransactions.activityTypes.emptyingCancelled'); break;
                case HistoryLogItemType.RequestedEmptying: activity = t('pages.balances.pastTransactions.activityTypes.requestedEmptying'); break;
                case HistoryLogItemType.Payout: activity = t('pages.balances.pastTransactions.activityTypes.payout'); break;
            }
            return activity;
        } 
    });
    columns.push({ field: 'amount', headerName: t("pages.balances.amountChf"), sortable: true, flex: 1, disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<HistoryLogItem>) => {
            const prefix = params.row.type === HistoryLogItemType.Deposit || params.row.type === HistoryLogItemType.EmptyingCancelled ? '+': '-';
            const amount = params.row.amount === parseInt(`${params.row.amount}`) ? `${prefix}${params.row.amount}.—`: params.row.amount; 
            return amount;
        } 
    });
    columns.push({ field: 'issuer', headerName: t("pages.balances.issuer"), flex: 1.5, disableColumnMenu: true, 
        renderCell: (params: GridRenderCellParams<HistoryLogItem>) => {
            const issuer = params.row.issuer ? `${params.row.issuer.firstName} ${params.row.issuer.lastName}`: '-'; 
            return issuer;
        } 
    })  
    if (!isMobileView) {
        columns.push({ field: 'bookableCredit', headerName: t("pages.balances.remainingCreditChf"), flex: 1.2, disableColumnMenu: true, 
        renderCell: (params: GridRenderCellParams<HistoryLogItem>) => {
            const bookableCredit = params.row.bookableCredit === parseInt(`${params.row.bookableCredit}`) ? `${params.row.bookableCredit}.—`: params.row.bookableCredit; 
            return bookableCredit;
        } 
    })}

    const onSortModelChange = (model: GridSortModel) => {
        resetPagingInfos();
        setSortInfo(model.length > 0 && model[0].sort ? {
            field: model[0].field as "created" | "type" | "amount" | "bookableCredit",
            type: model[0].sort
        }: defaultSort);
    }

    useEffect(() => {
        updatePagingData(historyLogQuery.data);
    }, [updatePagingData, historyLogQuery.data]);
    
    //  RESOLVED: make loading nice :-)" />Loading...</div>
    //  RESOLVED: make error handling nice :-)
    //  RESOLVED: define how it must look in case of no data...
    if (historyLogQuery.isError) {
        return <MessageWrapper messageText={historyLogQuery.error.toString()} justifyContent={"flex-start"}/>
    }
    return (
        <StyledSection>
            <StyledH2>{t("pages.balances.pastTransactions.title")}</StyledH2>
            { historyLogQuery.isLoading && <Loader height={"420px"} messageText={t("components.loaders.loading")} />}
            { !historyLogQuery.isLoading && historyLogQuery.data && historyLogQuery.data.rows.length > 0 &&
                <DataGrid
                    sx={{
                        '& .MuiDataGrid-row': {
                            borderBottom: `1px solid ${styles.neutral200}`,
                        },
                        '& .MuiDataGrid-cell': {
                            border: 'none'
                        },
                        '& .MuiDataGrid-withBorderColor': {
                            borderColor: `${styles.neutral300}`
                        },
                    }}
                    initialState={{
                        pagination: {
                            paginationModel: { pageSize: 10, page: 0 },
                        },
                    }}
                    className="table-grid"
                    localeText={(i18n.language === 'de' ? deDE : enUS).components.MuiDataGrid.defaultProps.localeText}
                    style={{ border: 'none' }}
                    rows={historyLogQuery.data.rows}
                    columns={columns}
                    disableColumnSelector
                    hideFooterSelectedRowCount={true}
                    rowHeight={72}
                    onSortModelChange={onSortModelChange}
                />
            }
            { renderPagingUi() }
        </StyledSection>
    );
};