import { Trans } from "react-i18next";

type DateComponentProps = {
    textValue: string;
    i18nKey: string;
  }

export const TranslationWithBoldedText = ({ textValue, i18nKey }: DateComponentProps) => (
    <Trans
        i18nKey={i18nKey as any}
        components={{ 1: <strong /> }}
        values={{ textValue }}
    />
);