import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
import { IconHome, IconHomeActive, IconBalances, IconBalancesActive, IconOneTimeEmptying, IconOneTimeEmptyingActive, IconSeriesEmptying, IconSeriesEmptyingActive } from '../components/Icons';
import OneClickBanner from '../components/OneClickBanner';
import logo from '../images/kva_logo.png';
import styled from '@emotion/styled';
import { styles } from "../styles";
import { ROUTES } from './Routes';
import { useContext } from "react";
import { UserContext } from "../contexts/UserContext";

const LeftNav = styled.div`
    background-color: #EEF6EF;
    display: flex;
    flex-direction: column;
    padding-top: 36px;
    width: 248px;

    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;

    @media (max-width: 962px) {
        display: none;
    }
`;

const LogoContainer = styled.div`
    margin-left: 36px;

    img {
        height: 48px;
    }
`;

const NavList = styled.ul`
    list-style: none;
    margin: 36px 0;
    padding: 0;

    @media (max-width: 1440px) {
        margin: 36px 0 24px;
    }
`;

const NavListItem = styled.li`
    color: ${styles.textColorSecondary900};
    margin-bottom: 24px;
    white-space: nowrap;
    
    a {
        display: flex;
        align-items: center;
        text-decoration: none;
        line-height: 22px;
        padding: 10px 24px;

        svg {
            margin-right: 8px;
        }

        span {
            font-family: 'Satoshi Bold';
        }
    }

    a:visited {
        color: currentColor;
    }

    a:hover {
        background-color: rgba(92, 187, 108, .1);
    }
`;

// TODO: use the KVALOgo component instead of img-tag
// needs some further investigation, as for some reason the svg logo from <KVALogo /> is rendered in a strange way in this component, that's why png version was used here
export const LeftNavigation = () => {
    const { accessPermission } = useContext(UserContext);
    const location = useLocation();
    const { t } = useTranslation();
    
    const pathname = location.pathname.toLowerCase();
    return (
        <LeftNav>
            <NavLink to={ROUTES.Home}>
                <LogoContainer>
                    <img src={logo} alt="KVA Thurgau Logo" />
                </LogoContainer>
            </NavLink>

            <NavList>
                <NavListItem>
                    <NavLink to={ROUTES.Home}>
                        {pathname === '/' ? <IconHomeActive /> : <IconHome />}
                        {pathname === '/' ? <span>{t("navigation.left.home")}</span> : t("navigation.left.home")}
                    </NavLink>
                </NavListItem>
                { accessPermission && accessPermission.containerRefs.length > 0 && accessPermission?.roles?.payment && 
                <>
                <NavListItem>
                    <NavLink reloadDocument={pathname === ROUTES.SingleEmptying} to={ROUTES.SingleEmptying}>
                        {pathname === ROUTES.SingleEmptying ? <IconOneTimeEmptyingActive /> : <IconOneTimeEmptying />}
                        {pathname === ROUTES.SingleEmptying ? <span>{t("navigation.left.onetimeEmptying")}</span> : t("navigation.left.onetimeEmptying")}
                    </NavLink>
                </NavListItem>
                <NavListItem>
                    <NavLink reloadDocument={pathname === ROUTES.MultiEmptying} to={ROUTES.MultiEmptying}>
                        {pathname === ROUTES.MultiEmptying ? <IconSeriesEmptyingActive /> : <IconSeriesEmptying />}
                        {pathname === ROUTES.MultiEmptying ? <span>{t("navigation.left.seriesEmptying")}</span> : t("navigation.left.seriesEmptying")}
                    </NavLink>
                </NavListItem>
                <NavListItem>
                    <NavLink to={ROUTES.CreditOverview}>
                        { [ROUTES.CreditOverview, ROUTES.Payments, ROUTES.EmptyingConfirmations].indexOf(pathname) >= 0  ? 
                            <>
                                <IconBalancesActive />
                                <span>{t("navigation.left.balancesAndInvoices")}</span>
                            </> : 
                            <>
                                <IconBalances />
                                { t("navigation.left.balancesAndInvoices") }
                            </>
                        }
                    </NavLink>
                </NavListItem>
                </>
                }
            </NavList>
            { accessPermission && accessPermission.containerRefs.length > 0 && accessPermission?.roles?.payment &&
                <OneClickBanner disabled={false} />
            }
        </LeftNav>
    )
}