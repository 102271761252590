import { RemovalArea } from "./RemovalArea";

export type ContainerRef = {
    id: string;
}

export type Container = {
    id: string;
    created: Date;
    displayName: string;
    awmId: string;
    shortName: string;
    accountId: string;
    color: string;
    removalArea?: RemovalArea;
    street: string;
    houseNumber: string;
    postalCode: string;
    city: string;
    latitude: string;
    longitude: string;
    pictureBase64Encoded: string;
    statusReason: ContainerStatusReason;
    remarks: string;
}

export enum ContainerStatusReason {
    Active = 1,
    Pending = 125160001
}