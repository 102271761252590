import { useState } from "react";
import { PagedResultSet } from "../types/PagedResultSet";

export const usePaging = <T,>(pageSize: number) => {
    const [prevLinks, setPrevLinks] = useState<string[]>([]);
    const [skipToken, setSkipToken] = useState<string>();
    const [data, setData] = useState<PagedResultSet<T> | undefined | null>();
    
    const onPreviousPage = () => {
        const newPrevLinks = [...prevLinks];
        newPrevLinks.pop();
        const latestPrevLink = newPrevLinks.pop(); //
        setSkipToken(latestPrevLink);
        setPrevLinks(newPrevLinks);
    }

    const onNextPage = () => {
        if (data) {
            const skipToken = data.skipToken;
            if (skipToken) {
                setPrevLinks([...prevLinks, skipToken]);
                setSkipToken(skipToken);
            }
        }
    }

    const renderPagingUi = () => {
        if (!data) return null;
        const from = (prevLinks.length * pageSize) + 1;
        const to = (prevLinks.length + 1) * pageSize;
        if (data.totalCount <= to) return null;
        return (
            <>
            { prevLinks.length > 0 &&
                <input type="button" value="Prev" onClick={onPreviousPage} /> 
            }
            { data && data.totalCount > 0 &&
                <>{from} - { Math.min(data.totalCount, to) } from {data.totalCount}</>
            }
            { data && data.skipToken && 
                <input type="button" value="Next" onClick={onNextPage} />
            }
            </>
        )
    }

    return {
        renderPagingUi,
        skipToken,
        updatePagingData: setData,
        resetPagingInfos: () => {
            setPrevLinks([]);
            setSkipToken(undefined);
            setData(undefined);
         }
    }
}