import { useState } from 'react';
import { Linkbox } from './Linkbox'; 
import styled from '@emotion/styled';
import { LinkboxData } from './LinkboxData';

type CarouselProps = {
  data: LinkboxData[];
};

const StyledCarousel = styled.div`
    display: none;
    @media (max-width: 480px) {
        display: block;
    },
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
`;

const CarouselWrapper = styled.div<{ currentIndex: number; length: number; }>`
    display: flex;
    position: relative;
    left: ${props => -(props.currentIndex * 100)}%;
    transition: left 0.5s;
    width: ${props => props.length * 100}%;
`;

const DotContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin-bottom: 12px;
    margin-top: 16px;
`;

const Dot = styled.div<{ isActive: boolean }>`
    width: 8px;
    height: 8px;
    background-color: ${props => props.isActive ? '#1E2B39' : '#fff'};
    border: ${props => props.isActive ? '2px solid #1E2B39' : '2px solid #979B98'};
    border-radius: 50%;
    cursor: pointer;
`;

export const LinksCarousel = ({ data }: CarouselProps) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [startX, setStartX] = useState(0);
    const [endX, setEndX] = useState(0);

    const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
        setStartX(e.touches[0].clientX);
    };

    const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
        setEndX(e.touches[0].clientX);
    };

    const handleTouchEnd = () => {
        if (startX - endX > 50) {
            handleNext();
        } else if (endX - startX > 50) {
            handlePrev();
        }
    };

    const handleNext = () => {
        if (currentIndex < data.length - 1) {
            setCurrentIndex(currentIndex + 1);
        }
    };

    const handlePrev = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };

    return (
        <StyledCarousel>
            <CarouselWrapper 
                length={data.length} 
                currentIndex={currentIndex} 
                onTouchStart={handleTouchStart} 
                onTouchMove={handleTouchMove} 
                onTouchEnd={handleTouchEnd}
            >
                {data.map((item: LinkboxData, index: number) => (
                    <div style={{width: '100%'}} key={index}>
                        <Linkbox item={item} />
                    </div>
                ))}
            </CarouselWrapper>
            <DotContainer>
                {data.map((_, index) => (
                    <Dot 
                        key={index}
                        isActive={currentIndex === index}
                        onClick={() => setCurrentIndex(index)}
                    />
                ))}
            </DotContainer>
        </StyledCarousel>
    );
};