import { useState, useRef, useEffect, useContext } from "react";
import styled from "@emotion/styled";
import { IconCircleArrowRight, IconCircleCheck, IconContainerLarge, IconMoreVertical } from "../Icons";
import { styles } from '../../styles';
import { Container } from "../../types/Container";
import { useScheduledAndLatestInPastEmptyingsQuery } from "../../data/emptyings";
import { ContainerCard } from "./ContainerCard";
import { useTranslation } from "react-i18next";
import { ContainerSubMenu } from './ContainerSubMenu';
import Tooltip from '@mui/material/Tooltip';
import { UserContext } from "../../contexts/UserContext";


type ContainerInfoCardProps = {
    item: Container,
    cardWidth?: number,
    marginRight?: string,
    resizable?: boolean
}

const StyledContainerInfoCard = styled('div')<ContainerInfoCardProps>(({ item }) => ({
}));

const ContainerContent = styled.div`
    padding: 22px 22px 26px 24px;
    border: 1px solid ${styles.neutral200};
    border-radius: 8px;
    box-shadow: ${styles.boxShadowBottomLight};
    height: 280px;
`;

const TopRow = styled.div`
    position: relative;
    display: flex;
    justify-content: flex-end;
`;

const MiddleRow = styled.div<{ color: string }>`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    svg {
        margin-bottom: 8px;
        path {
            fill: ${props => props.color};;
        }
        path:last-child {
            stroke: ${props => props.color};;
        }
    }
`;

const ContainerName = styled.p`
    font-family: 'Satoshi Bold';
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 22px;
    color: ${styles.textColorSecondary700}
`;

const ContainerId = styled.p`
    font-size: 1rem;
    line-height: 18px;
    color: ${styles.textColorSecondary700};
    margin-bottom: 20px;
    mrgin-top: 5px;
`;

const BottomRow = styled.div`
    padding-left: 16px;
    padding-right: 16px;
    text-align: center;
`;

const PrevEmptying = styled.div`
    display: flex;
    justify-content: center;
    gap: 12px;
    padding: 4px 12px 4px 4px;
    color: #979B98;
`;

const EmptyingsNumber = styled.span`
    position: absolute;
    top: -12px;
    right: -12px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    background-color: #3371EA;
    box-shadow: ${styles.boxShadowBottomLight};
    color: #fff;
    border-radius: 100px;
    font-size: 10px;
    cursor: pointer;
`;

const ContainerShortName = styled.span`
    font-family: 'Satoshi Bold';
    color: ${styles.textColorSecondary700};
    position: absolute;
    top: 23px;
`;

type NextEmptyingProps = {
    nofAdditionalEmptyings: number;
};

const NextEmptying = styled.div<NextEmptyingProps>`
    display: inline-block;
    position: relative;
    background-color: ${props => props.nofAdditionalEmptyings <= 0 ? 'transparent' : '#f5f5f5'};
    margin-bottom: 4px;
    padding: 4px 12px 4px 4px;
    border-radius: 100px;

    div {
        display: flex;
    }

    span {
        margin-left: 12px;
    }
`;

const PendingPill = styled.span`
    display: inline-block;
    height: 32px;
    line-height: 32px;
    background-color: #DEE9FC;
    color: ${styles.textColorSecondary900};
    border-radius: 100px;
    padding: 0 12px;
    margin-bottom: 9px;
`;

const RegistrationInfo = styled.span`
    display: block;
    color: #979B98;
`;

const EmptyingInfo = ({ item }: ContainerInfoCardProps) => {
    const { t, i18n } = useTranslation();
    const scheduledAndLatestInPastEmptyingsQuery = useScheduledAndLatestInPastEmptyingsQuery(item);

    // TODO: Handle loading and error states
    if (scheduledAndLatestInPastEmptyingsQuery.isError) {
        console.error("Error in Container Tile", scheduledAndLatestInPastEmptyingsQuery.error);
        return <>Error</>
    }

    const dateFormat = new Intl.DateTimeFormat(i18n.language, { dateStyle: 'long' });
    const emptyings = scheduledAndLatestInPastEmptyingsQuery.data || { scheduled: [], latest: undefined };
    const nextEmptyingDate = emptyings.scheduled.length > 0 ? dateFormat.format(emptyings.scheduled[0].removalDate.date) : t('components.containers.noEmptyingScheduled');
    const prevEmptyingDate = emptyings.latest ? dateFormat.format(emptyings.latest.removalDate.date) : t('components.containers.noCompletedEmptying');
    const nofAdditionalEmptyings = emptyings.scheduled.length;

    return (
        <>
            <NextEmptying nofAdditionalEmptyings={nofAdditionalEmptyings}>
                <div>
                    {nofAdditionalEmptyings > 1 && nextEmptyingDate && <IconCircleArrowRight />}
                    {nofAdditionalEmptyings >= 0 ? <span>{scheduledAndLatestInPastEmptyingsQuery.isLoading ? '...' : nextEmptyingDate}</span> : t('components.containers.noMoreEmptying')}
                </div>
                {nofAdditionalEmptyings > 0 &&
                    <Tooltip arrow componentsProps={{
                        tooltip: {
                            sx: {
                                top: '-3px',
                                bgcolor: '#26374A',
                                '& .MuiTooltip-arrow': {
                                    color: '#26374A',
                                },
                            },
                        },
                    }} title={t('components.containers.emptyingsNumberTooltip')}>
                        <EmptyingsNumber>{nofAdditionalEmptyings}</EmptyingsNumber>
                    </Tooltip>
                    }
            </NextEmptying>

            <PrevEmptying>
                {emptyings.latest && <IconCircleCheck />}
                <p>{scheduledAndLatestInPastEmptyingsQuery.isLoading ? '...' : prevEmptyingDate}</p>
            </PrevEmptying>
        </>
    )
}

const PendingContainerInfo = ({container}: {container: Container}) => {
    const { t, i18n } = useTranslation();
    const dateFormat = new Intl.DateTimeFormat(i18n.language, { dateStyle: 'long' });
    return (
        <>
            <PendingPill>{t('components.containers.pending')}</PendingPill>
            <RegistrationInfo>{t('components.containers.recordedOn')} {dateFormat.format(container.created) } </RegistrationInfo>
        </>
    )
};
 
// PARTIALLY RESOLVED: implement three vertical dots feature
// the submenu is implemented, but the items are not linked to the actions

// TODO: the id is a GUID, doesn't make much sense to show this info...

// RESOLVED:: why is the width of ContainerCartd hardcoded to 312? there is cardWidth prop on the Carousel
// You were right, it was unnecessary. But now it is used to differentiate between the cards in the carousel and the single cards 
// displayed when there is only one proper container or dummy container
export const NonSelectableContainerCard: React.FC<ContainerInfoCardProps> = ({ item, cardWidth, marginRight, resizable }) => {
    const [isSubMenuOpen, setSubMenuOpen] = useState(false);
    const { accessPermission } = useContext(UserContext);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const isPending = item.statusReason === 125160001;

    useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => {
            if (!containerRef.current?.contains(event.target as Node)) {
                setSubMenuOpen(false);
            }
        };

        if (isSubMenuOpen) {
            document.addEventListener("mousedown", handleOutsideClick);
        }
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, [isSubMenuOpen]);

    const toggleSubMenu = (event: React.MouseEvent) => {
        event.stopPropagation();
        setSubMenuOpen(prevState => !prevState);
    };

    return (
        <StyledContainerInfoCard item={item} ref={containerRef}>
            <ContainerCard width={cardWidth || 312} resizable={resizable} marginRight={marginRight}>
                <ContainerContent>
                    { (accessPermission?.roles?.container || accessPermission?.roles?.payment) && 
                        <TopRow>
                            <div onClick={!isPending ? toggleSubMenu : undefined} style={{ cursor: `${!isPending ? 'pointer' : 'not-allowed'}` }}>
                                <IconMoreVertical />
                            </div>
                            {isSubMenuOpen && <ContainerSubMenu container={item} />}
                        </TopRow>
                    }
                    <MiddleRow color={item.color}>
                        <IconContainerLarge />
                        <ContainerShortName>{item.shortName}</ContainerShortName>
                        <ContainerName>{item.displayName}</ContainerName>
                        <ContainerId>ID-Nr. {item.awmId}</ContainerId>
                    </MiddleRow>
                    <BottomRow>
                        {!isPending ? <EmptyingInfo item={item} /> : <PendingContainerInfo container={item}/>}
                    </BottomRow>
                </ContainerContent>
            </ContainerCard>
        </StyledContainerInfoCard>
    );
};
