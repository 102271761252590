import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import styled from "@emotion/styled";
import { Tabs, Tab } from "@mui/material";
import { styles } from "../styles";


type StyledTabsProps = {
    children?: React.ReactNode;
    value: number;
    onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

const StyledTabs = styled((props: StyledTabsProps) => (
    <Tabs
        {...props}
        TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
))({
    '& .MuiTabs-indicator': {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
    },
    '& .MuiTabs-indicatorSpan': {
        maxWidth: 48,
        width: '100%',
        backgroundColor: '#33AA47',
    },
    marginBottom: '64px',

    '@media (max-width: 480px)': {
        marginBottom: '48px',
    },
});

type StyledTabProps = {
    label: React.ReactNode;
}

const StyledTab = styled((props: StyledTabProps) => (
    <Tab disableRipple {...props} />
))(() => ({
    textTransform: 'none',
    fontWeight: 400,
    fontSize: 14,
    padding: '8px 0',
    height: '44px !important',
    marginRight: '32px',
    lineHeight: '22px',
    minWidth: 'unset',
    color: styles.textColorSecondary700,
    fontFamily: [
        'Satoshi Regular',
        'sans-serif',
      ].join(','),
    '&.Mui-selected': {
        fontFamily: [
            'Satoshi Bold',
            'sans-serif',
          ].join(','),
        fontWeight: 700,
        color: styles.textColorSecondary700,
    },
    '&.Mui-focusVisible': {
        backgroundColor: 'rgba(100, 95, 228, 0.32)',
    },
}));


const fromRouteToId = (route: string, nodes: NavNode[]) => {
    for (let i=0; i<nodes.length; i++) {
        if (nodes[i].route === route) {
            return i;
        }
    }
    return 0;
}
const fromIdToRoute = (id: number, nodes: NavNode[]) => {
    return nodes[id].route;
}

type NavNode = {
    label: string;
    route: string;
} 
export const TabbedNavigation = ({nodes}: {nodes: NavNode[]}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [value] = useState(fromRouteToId(location.pathname, nodes));
    
    const handleChange = (_: React.SyntheticEvent, newValue: number) => {
        const route = fromIdToRoute(newValue, nodes);
        navigate(route);
    };
    
    return (
        <StyledTabs
            value={value}
            onChange={handleChange}
            aria-label="tabs"
        >
            { nodes.map(node => <StyledTab key={node.route} label={node.label} />)}
        </StyledTabs>
    )
}