import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { styles } from "../../../../styles";
import { IconClose, IconExclamationMark, IconInfo } from "../../../../components/Icons";
import Button from '../../../../components/Button';
import { Account, AccountDataChangeRequest } from "../../../../types/Account";
import { useForm } from "react-hook-form";
import { usePostAccountDataChangeRequestMutation } from "../../../../data/accounts";
import { useCallback } from "react";
import { MessageWrapper } from "../../../../components/MessageWrapper";
import { Loader } from "../../../../components/Loader";


type RequestDataChangeModalContentProps = {
    onCancel: () => void;
    onRequestSent: () => void;
    account: Account;
}


const Wrapper = styled.div`
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    max-height: 80vh;

    @media (max-width: 480px) {
        max-height: 100vh;
        height: 100%;
    }
`;

const ContentWrapper = styled.div`
    overflow-y: auto;
    position: relative;
    max-height: 80vh;
    padding-right: 24px;

    @media (max-width: 480px) {
        max-height: 100vh;
        height: 100%;
    }
`;

const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`;

const ModalHeading = styled.h2`
    color: ${styles.textColorSecondary700};
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #fff;
    padding-top: 24px;
    padding-bottom: 24px;
    padding-right: 24px;
    border-radius: 8px;
    margin-bottom: 0;

    svg {
        cursor: pointer;
    }
`;

const StyledH3 = styled.h3`
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 8px;
`;

const BottomRow = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    margin-top: 48px;
    margin-bottom: 68px;

    button {
        width: 156px;
    }

    @media (max-width: 480px) {
        margin-bottom: 0;
        flex-direction: column;
        align-items: flex-end;
        gap: 12px;
        width: 100%;

        button {
            width: 100%;
            margin-bottom: 0;
        }
    }
`;

const StyledH4 = styled.h4`
    font-size: 10px;
    line-height: 18px;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    gap: 8px;

    svg {
        cursor: pointer;
    }
`;

const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    &:last-child {
        margin-right: 0;
    }
`;

const InputContainerBig = styled(InputContainer)`
    flex-grow: 1;
    margin-right: 12px;
`;

const InputContainerFullWidth = styled(InputContainer)`
    flex-grow: 1;
`;

const FormRow = styled.div`
    margin-bottom: 12px;
`;

const TwoColumnsFormRow = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
`;

type StyledInputProps = {
    hasError?: boolean;
};

const StyledInput = styled.input<StyledInputProps>`
    padding: 10px 16px;
    flex: 1;
    box-shadow: ${styles.boxShadowInset};
    background-color: #fff;
    border: 1px solid ${props => props.hasError ? '#ed2b2b' : styles.neutral200};
    border-radius: 4px;
    box-sizing: border-box;
    line-height: 22px;
    font-size: 14px;
    color: ${styles.textColorSecondary700};
    font-family: 'Satoshi Regular';
    max-height: 44px;

    &:focus {
        border: 1px solid #3371EA;
        outline: none;
    }

    &:disabled {
        background-color: #f5f5f5;
    }
`;

const InfoText = styled.span`
    font-size: 10px;
    line-height: 18px;
    display: flex;
    align-items: flex-start;
    gap: 8px;

    svg {
        margin-top: 2px;
    }
`;

const OldCompanyData = styled.div`
    margin-bottom: 24px;

    span {
        display: block;
        font-size: 14px;
        line-height: 22px;
        color: ${styles.textColorSecondary700};
    }
`;

const ErrorWrapper = styled.div`
    margin-top: 12px;
    display: flex;
    align-items: center;
    font-size: 10px;
    line-height: 18px;

    svg {
        margin-right: 8px;
    }
`;

const buildDefaultValues = (account: Account): AccountDataChangeRequest => {
    return {
        city: account.city,
        email: account.email,
        name: account.name,
        phone: account.phone,
        postalCode: account.postalCode,
        streetName: account.street,
        streetNumber: account.houseNumber,
    }
}

export const RequestDataChangeModalContent: React.FC<RequestDataChangeModalContentProps> = ({ onCancel, account, onRequestSent }) => {
    const { t } = useTranslation();

    const { register, handleSubmit, formState: { errors } } = useForm({
        defaultValues: buildDefaultValues(account)
    });
    const accountDataChangeMutation = usePostAccountDataChangeRequestMutation();

    const onSubmit = useCallback(async (formData: AccountDataChangeRequest) => {
        await accountDataChangeMutation.mutateAsync(formData);
        if (accountDataChangeMutation.isSuccess) {
            onRequestSent();
        }
    }, [accountDataChangeMutation, onRequestSent]);

    if (accountDataChangeMutation.isSuccess) {
        onRequestSent();
    }
    return (
        <Wrapper>
            <StyledForm>
                <ModalHeading>{t("pages.myData.companyData.requestDataChangeModal.header")} <div onClick={onCancel}><IconClose /></div></ModalHeading>
                {!accountDataChangeMutation.isError && !accountDataChangeMutation.isLoading && <ContentWrapper>
                    <div style={{ marginTop: '24px' }}>
                        <StyledH3>{t("pages.myData.companyData.requestDataChangeModal.currentCompanyData")}</StyledH3>
                        <OldCompanyData>
                            <span>{account.name}</span>
                            <span>{account.street} {account.houseNumber}</span>
                            <span>{account.postalCode} {account.city}</span>
                        </OldCompanyData>
                        <StyledH3>{t("pages.myData.companyData.requestDataChangeModal.newCompanyData")}</StyledH3>
                        <FormRow>
                            <InputContainerFullWidth>
                                <StyledH4>{t("pages.myData.companyData.company")}</StyledH4>
                                <StyledInput hasError={Boolean(errors.name)} type="text" placeholder={t("pages.myData.companyData.company")} {...register("name", { required: 'Mussfeld' })} />
                                {errors.name && <ErrorWrapper><IconExclamationMark />{errors.name.message}</ErrorWrapper>}
                            </InputContainerFullWidth>
                        </FormRow>
                        <TwoColumnsFormRow>
                            <InputContainerBig>
                                <StyledH4>{t("pages.myData.companyData.street")}</StyledH4>
                                <StyledInput hasError={Boolean(errors.streetName)} type="text" placeholder={t("pages.myData.companyData.street")} {...register("streetName", { required: 'Mussfeld' })} />
                                {errors.streetName && <ErrorWrapper><IconExclamationMark />{errors.streetName.message}</ErrorWrapper>}
                            </InputContainerBig>
                            <InputContainer style={{ width: '156px' }}>
                                <StyledH4>{t("pages.myData.companyData.no")}</StyledH4>
                                <StyledInput hasError={Boolean(errors.streetNumber)} type="text" placeholder={t("pages.myData.companyData.no")} {...register("streetNumber", { required: 'Mussfeld' })} />
                                {errors.streetNumber && <ErrorWrapper><IconExclamationMark />{errors.streetNumber.message}</ErrorWrapper>}
                            </InputContainer>
                        </TwoColumnsFormRow>
                        <TwoColumnsFormRow>
                            <InputContainerBig>
                                <StyledH4>{t("pages.myData.companyData.location")}</StyledH4>
                                <StyledInput hasError={Boolean(errors.city)} type="text" placeholder={t("pages.myData.companyData.location")} {...register("city", { required: 'Mussfeld' })} />
                                {errors.city && <ErrorWrapper><IconExclamationMark />{errors.city.message}</ErrorWrapper>}
                            </InputContainerBig>
                            <InputContainer style={{ width: '156px' }}>
                                <StyledH4>{t("pages.myData.companyData.postcode")}</StyledH4>
                                <StyledInput hasError={Boolean(errors.postalCode)} type="text" placeholder={t("pages.myData.companyData.postcode")} {...register("postalCode", { required: 'Mussfeld' })} />
                                {errors.postalCode && <ErrorWrapper><IconExclamationMark />{errors.postalCode.message}</ErrorWrapper>}
                            </InputContainer>
                        </TwoColumnsFormRow>
                        <TwoColumnsFormRow>
                            <InputContainerBig>
                                <StyledH4>{t("pages.myData.companyData.email")}</StyledH4>
                                <StyledInput hasError={Boolean(errors.email)} type="text" placeholder={t("pages.myData.companyData.email")} {...register("email", { required: 'Mussfeld' })} />
                                {errors.email && <ErrorWrapper><IconExclamationMark />{errors.email.message}</ErrorWrapper>}
                            </InputContainerBig>
                            <InputContainer style={{ width: '156px' }}>
                                <StyledH4>{t("pages.myData.companyData.phone")}</StyledH4>
                                <StyledInput hasError={Boolean(errors.phone)} type="text" placeholder={t("pages.myData.companyData.phone")} {...register("phone", { required: 'Mussfeld' })} />
                                {errors.phone && <ErrorWrapper><IconExclamationMark />{errors.phone.message}</ErrorWrapper>}
                            </InputContainer>
                        </TwoColumnsFormRow>
                        <InfoText><IconInfo /> <span style={{ marginTop: '4px' }}>{t('pages.myData.companyData.requestDataChangeModal.infoText')}</span></InfoText>
                    </div>
                    <BottomRow>
                        {!accountDataChangeMutation.isLoading &&
                            <Button buttonVariant="ghost" onClick={() => onCancel()}>
                                {t("buttons.abort")}
                            </Button>
                        }
                        <Button disabled={accountDataChangeMutation.isLoading} onClick={handleSubmit(onSubmit)}>
                            {accountDataChangeMutation.isLoading && <Loader />}
                            {!accountDataChangeMutation.isLoading && t("buttons.request")}
                        </Button>
                    </BottomRow>
                </ContentWrapper>}
                {accountDataChangeMutation.isError && (
                    <MessageWrapper messageText={accountDataChangeMutation.error.message} height={"140px"} textMargin={"12px 24px 0 0 "} justifyContent="flex-start" />
                )}
                {accountDataChangeMutation.isLoading && (
                    <Loader height="140px" messageText={t("components.loaders.postingAccountDataChangeRequest")} justifyContent="flex-start" margin="24px 0 0 " />
                )}
            </StyledForm>
        </Wrapper>
    );
};