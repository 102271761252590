export const styles = {
    boxShadowBottomLight: "0px 2px 6px 0 rgba(0, 0, 0, .12)",
    boxShadowTopLight: "0px -2px 6px 0 rgba(0, 0, 0, .12)",
    boxShadowInset: "inset 1px 2px 4px 0px rgba(0, 0, 0, 0.08)",
    boxShadowContainerHovered: "0px 6px 20px -4px rgba(0, 0, 0, 0.12)",
    textColorSecondary900: "#10242E",
    textColorSecondary700: "#26374A",
    textColorNeutral600: "#646865",
    neutral300: "#B9BCBA",
    neutral200: "#E0E1E0"
  };