import { Contact } from "./Contact";

export enum HistoryLogItemType {
    Deposit = 125160000,
    RequestedEmptying = 125160001,
    EmptyingCancelled = 125160002,
    EmptyingCompleted = 125160003,
    EmptyingNotPossible = 125160004,
    Payout = 125160005
}
export type HistoryLogItem = {
    id: string;
    accountId: string;
    containerId: string,
    type: HistoryLogItemType;
    amount: number;
    bookableCredit: number;
    created: Date;
    issuer: Contact;
}