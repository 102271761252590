import { Translation } from "./Translation";

export const en: Translation = {
    navigation: {
        left: {
            home: "Home",
            onetimeEmptying: "One-time Collection",
            seriesEmptying: "Serial Collection",
            balancesAndInvoices: "Credit & Invoices",
            bannerText: "One-time collection on the next possible day?",
            bannerButtonText: "1-Click-Collection"
        },
        top: {
            languageChooserLabels: {
                en: 'EN',
                de: 'DE'
            },
            myData: "My Data",
            myContainers: "My Containers",
            myOrganisation: "My Organization",
            administration: "Administration",
            notificationSettings: "Notification settings",
            helpAndContact: "Help & Contact",
            login: "Login",
            logout: "Logout"
        }
    },
    footer: {
        footerText: "© {current-year} KVA Thurgau. All rights reserved.",
        footerLinks: {
            termsConditions: "T&Cs",
            imprint: "Imprint",
            dataProtection: "Data Protection",
            helpAndContact: "Help & Contact"
        }
    },
    payment: {
        transactionNames: {
            payForSingleEmptying: 'One-time collection at {date}',
            payForMultipleEmptyings: 'Serial collection ({nof-emptyings} emptyings)'
        }
    },
    components: {
        containers: {
            header: "Container",
            dummy: {
                infoText: "Dump the trash!",
                registerContainerButtonText: "Add containers",
            },
            noEmptyingScheduled: "No scheduled collections",
            noCompletedEmptying: "No completed collections",
            noMoreEmptying: 'No collection possible',
            pending: 'Pending',
            recordedOn: 'Added on ',
            emptyingsNumberTooltip: 'Number of scheduled collections',
            infoTexts: {
                noRemovalDayAssigned: 'We have not yet assigned a collection day to this container. We will do so as soon as possible. Please try again later.',
                emptyingScheduledOnThisDate: 'A collection has already been scheduled for this date.',
            },
            submenu: {
                changeContainerData: 'Change container data',
                orderOneTimeEmptying: 'Order one-time collection'
            }
        },
        notificationsForm: {
            header: "Notification settings",
            emailNotifications: "E-Mail notifications",
            orderConfirmation: "Order confirmation",
            orderConfirmationTooltip: "Confirmation that a collection has been successfully ordered.",
            emptyingReminder: "Collection reminder",
            emptyingReminderEmailTooltip: "Reminder for upcoming collections. The reminder is sent the day before the collection date.",
            emptyingReminderSmsTooltip: "Reminder for upcoming collections. The reminder is sent the day before the collection date.",
            emptyingConfirmation: "Collection confirmation",
            emptyingConfirmationTooltip: "Confirmation that a collection has been successful.",
            smsNotifications: "SMS notification",
            differentEmailText: "To enter a different email address, please click in the field.",
            differentMobileText: "To enter a different cell phone number, please click in the field.",
        },
        loaders: {
            loading: 'Loading...',
            postingAccountDataChangeRequest: 'Request for data change is being processed...',
            booking: 'Ordering...',
        },
        tables: {
            noEntriesAvailable: 'No entries available'
        }
    },
    pages: {
        home: {
            welcome: {
                header: "e-Container Portal"
            },
            containerInfobox: {
                infoText: "To use the e-container Portal, first add your containers.",
                buttonText: "Add containers"
            },
            helpInfobox: {
                infoText: "To get to know all the functions of the e-Container Portal, watch our instruction videos.",
                buttonText: "Watch instruction videos"
            },
            organizationInfobox: {
                infoText: "Do you like to delegate the collection mangement to your employees or let them view scheduled collections?",
                buttonText: "Invite employees"
            },
            notificationsInfobox: {
                infoText: "Do you like to be reminded of upcoming or successful collections?",
                buttonText: "Set notifications"
            },
            emptyingsOverviewSection: {
                header: "Collection calendar",
                allContainersOption: "All containers",
                tooltipAddEmptying: "Order collection",
                tooltipCancelEmptying: "Cancel collection",
            },
            creditSection: {
                header: "Credit",
                creditInfoCard: {
                    currency: "CHF",
                    emptyings: "Collections",
                    buttonText: "Charge",
                    infoText: "You can use your credit balance or other means of payment to order emptyings.",
                },
                topUpOrderLineText: 'Top Up Credit',
                topUpModal: {
                    header: 'Top up credit',
                    topUpAmount: 'Amount',
                    meansOfPayment: 'Payment methods',
                    maxAmountWarning: 'Warning: Max <1>{{textValue}}</1> CHF allowed.',
                    payByInvoiceMsg: {
                        title: 'Pay by invoice',
                        text1: 'The invoice for topping up the credit account will be sent to you by email in a few minutes and will be available in the «Credit & Transactions» section under «Invoices».',
                        text2: 'In order to complete the top up of your credit account transfer the according amount to our account. The credit will be topped up upon receipt of payment. ',
                        primaryBtn: 'Invoices',
                        secondaryBtn: 'Close'
                    }
                }
            },
            cancelEmptyingModal: {
                header: 'Cancel collection',
                allContainersOption: "All containers",
                cancelationTextOnSingleDate: 'Do you want to cancel the collection on <1>{{textValue}}</1>?',
                cancelationTextOnMultipleDates: 'Do you really want to cancel the selected <1>{{textValue}}</1> emptyings?'
            }
        },
        emptying: {
            selection: "Selection: ",
            emptyings: "collections",
            dirtyFormMessage: {
                title: 'Unsaved Configuration',
                text: 'We have detected unsaved data that will be removed if you leave this page.',
                labels: {
                    proceed: 'Leave page',
                    stay: 'Stay on page'
                }
            },
            oneclick: {
                header: "1-Click-Collection",
                subheader: "Here you can order one-off emptying on the next emptying day.",
            },
            onetime: {
                header: "One-time Collection",
                subheader: "Here you can order an one-time collection for one of your containers.",
                subheaderOrderView: "You can cancel collections at any time up until 10:00 p.m. the night before the collection. The costs will automatically be credited to your credit account.",
                emptyingDateSection: {
                    header: 'Date of collection',
                    currency: 'CHF',
                    costs: 'Costs:',
                },
            },
            series: {
                header: "Serial Collection",
                subheader: "Here you can plan serial collections for your containers.",
                buttonPlanEmptying: 'Plan serial collection',
                serialPlanning: {
                    header: "Serial planning",
                    tooltip: "Here you can edit the planned collection dates or delete a serial collection.",
                    container: 'Container',
                    seriesRule: 'Serial rule',
                    emptyingsNumber: 'Collections',
                },
                modalPlanSerialEmptying: {
                    header: 'Plan serial collection',
                    container: 'Container',
                    weekday: 'Weekday',
                    weekdayTooltip: 'The availibility of the weekdays results from the planning of collection routes by the KVA Thurgau. ',
                    inheritInputFromPreviousContainer: 'Use the same information as for the previous container',
                    rhythm: 'Rhythm',
                    weekly: 'Weekly',
                    biweekly: 'Every 2 weeks',
                    fourweekly: 'Every 4 weeks',
                    period: 'Period',
                    periodTooltip: 'You can still edit the collection dates via the planning overview after clicking on "Plan".',
                    entireYearCollection: 'I would like to plan collections for the entire year.',
                    flexibleEmptyingDesired: 'I would like to plan more flexible',
                    start: 'Start',
                    end: 'End',
                    addPeriod: 'Add period',
                    costs: 'Costs:',
                    currency: 'CHF',
                    noPossibleDatesFound: 'No possible dates found.',
                },
                modalEditSerialEmptying: {
                    header: 'Edit Emptying Dates',
                    container: 'Edit collection dates',
                    weekday: 'Weekday',
                    emptyingDates: 'Collection dates',
                },
                modalCancelSerialEmptying: {
                    header: 'Delete serial collection',
                    confirmDeleteText: 'Do you really want to delete the planned serial collection for <1>{textValue}}</1>?',
                },
            },
            order: {
                header: "Order",
                credit: "Credit",
                creditTooltip: "Orders can be paid with the credit account (if sufficient) or through other payment methods.",
                currency: "CHF",
                container: "Container",
                date: "Date",
                priceWithVat: "(price incl. VAT)",
                total: "Total",
                emptyings: "Collections",
                edit: 'Edit order'
            },
            orderConfirmation: {
                header: 'Order confirmation',
                oneTimeEmptyingFor: 'The collection for ',
                serialEmptyingFor: 'You have successfully ordered the serial emptying for',
                and: 'and',
                nextEmptyingWillTakePlaceOn: 'The next emptying will take place on',
                emptyingOverviewInfo: 'The emptying overview can be found in the calendar.',
                hasBeenOrdered: 'has been ordered. ',
                itWillTakePlaceOn: 'It will take place on ',
                cancelingInformation: 'You can cancel your order until 10:00 PM the day before (including Sundays and public holidays).',
                notificationsSettingsReminder: "Do not forget to activate the desired notification settings.",
                lidOnIt: 'That is it!'
            },
            paymentModal: {
                header: 'Choose payment method',
                payWithCreditBtn: 'Order',
                creditAccount: 'Credit Account',
                invoice: 'Invoice',
                creditCard: 'Credit Card',
                maestro: 'Maestro',
                postFinance: 'PostFinance',
                twint: 'Twint',
            }
        },
        balances: {
            header: "Credit & Invoices",
            tabs: {
                creditAccount: 'Credit account',
                bills: 'Invoices',
                confirmationOfEmptying: 'Collection confirmations',
            },
            credit: 'Credit',
            emptyings: 'Collections',
            pastTransactions: {
                title: 'Past account activities',
                activityTypes: {
                    deposit: 'Top up',
                    emptyingCancelled: 'Cancellation',
                    payout: 'Payout',
                    requestedEmptying: 'Order'
                }
            },
            date: 'Date',
            issuer: 'Employee',
            activity: 'Activity',
            amountChf: 'Amount (CHF)',
            chf: 'CHF',
            status: 'Status',
            remainingCreditChf: 'Balance (CHF)',
            service: 'Service',
            overview: 'Overview',
            name: 'Name',
            code: 'Code',
            id: 'ID',
            paymentMethod: 'Mean of payment',
            invoiceNumber: 'Invoice / Receipt No.',
            file: 'File',
            creditCard: 'Credit Card',
            time: 'Time',
            documentNotReadyYet: 'Your invoice has not been generated yet. This may take a few minutes.'
        },
        myData: {
            header: 'My Data',
            personalDetails: {
                header: 'Personal details',
                salutation: 'Salutation',
                surname: 'Last name',
                firstName: 'First name',
                email: 'E-Mail',
                loginName: 'Login',
                mobilePhone: 'Phone (mobile)',
                salutationOptions: {
                    mr: 'Mr.',
                    mrs: 'Mrs.'
                }
            },
            companyData: {
                header: 'Company data',
                tooltip: 'You can request a  hange of data from KVA Thurgau.',
                customerNumber: 'Customer no. ',
                company: 'Company',
                street: 'Street',
                no: 'No.',
                location: 'Location',
                postcode: 'Postcode',
                email: 'E-Mail',
                phone: 'Phone',
                requestDataChange: "Request a change of data",
                requestDataChangeModal: {
                    header: 'Request a change of data',
                    currentCompanyData: 'Current company data',
                    newCompanyData: 'New company data',
                    infoText: 'Your data will be checked and approved by KVA Thurgau within 24 hours.',
                }
            }
        },
        myOrganization: {
            header: 'My Organization',
            employees: 'Employees',
            surname: 'Last name',
            firstName: 'First name',
            email: 'E-Mail',
            rights: 'Role',
            container: 'Container',
            status: 'Status',
            addEmployees: 'Invite employees',
            editEmployees: 'Edit employees',
            updatingEmployee: 'Updating employee...',
            creatingInvitation: 'An invitation is being created...',
            addingEmployeesNotPossibleDueToNoActiveContainer: 'Adding employees is possible as soon as at least one container is active',
            invitationForm: {
                header: 'Invite employees',
                personalDetails: 'Personal details',
                salutation: 'Salutation',
                mr: 'Mr.',
                mrs: 'Mrs.',
                surname: 'Surname',
                firstName: 'First Name',
                email: 'E-Mail',
                role: 'Role',
                roleTooltip: 'Define the role of your employees. Administrator: Employee can see when collections take place, he can order and cancel collections, charge the credit account and invite new employees. Employee (authorized to order): Employee can view when collections occur and order and cancel collections. Employee (view only): Employee can view portal, but does not have order authorization. ',
                pleaseChoose: 'Please select',
                containerAssignment: 'Container allocation',
                choice: 'Choice: ',
                creatingInvitation: 'Creating invitation...',
                atLeastOneContainer: 'At least one container must be selected.',
            },
            invitationSentModal: {
                header: 'Invitation sent...',
                andOffYouGo: 'So far so good!',
                yourEmployeeWillReceiveEmail: 'Your employee will shortly receive a registration email.',
                validityHint: "The invitation is valid for 3 days. After the expiry date, you can update the invitation manually."
            },
            deleteEmployeeModal: {
                header: 'Delete employee access',
                confirmDeleteText: 'Do you really want to delete the employee access of <1>{textValue}}</1>?',
                removing: 'Deleting...'
            },
        },
        myContainers: {
            header: 'My Containers',
            overview: 'Overview',
            name: 'Name',
            id: 'ID',
            code: 'Code',
            street: 'Street',
            number: 'No.',
            location: 'City',
            postcode: 'ZIP',
            color: 'Color',
            status: 'Status',
            containerAddOrEditForm: {
                registerContainer: 'Add container',
                editContainer: 'Edit container',
                sameLocationText: 'Same location as billing address?',
                yes: 'Yes',
                no: 'No',
                containerName: 'Container name',
                containerNamePlaceholder: 'i.e. Container_1',
                containerId: 'Container ID',
                containerIdTooltip: 'KVA Thurgau will automatically assign you a container ID once they have successfully checked your container.',
                containerCode: 'Container code',
                containerCodeTooltip: 'Two-digit code for individual identification of the container in the e-Container Portal.',
                containerCodePlaceholder: 'i.e. F1',
                color: 'Color',
                colors: {
                    blue: 'Blue',
                    green: 'Green',
                    orange: 'Orange',
                    red: 'Red',
                    purple: 'Purple',
                },
                colorTooltip: 'Color of the container in the e-Container Portal to distinguish multiple containers.',
                street: 'Street',
                number: 'No.',
                location: 'Location',
                postcode: 'Postcode',
                photo: 'Photo (optional)',
                dropFileOrBrowseFolder: 'Drop file or <0>browse</0> folder.',
                comments: 'Comments (optional)',
                addressChangeTooltip: 'If you change your address, you will need to re-register your container.'
            },
            containerCheckedModal: {
                header: 'Checking container',
                weAreAlreadyThere: 'We are right at it!',
                afterSuccessfulCheckText: 'After a successful check you will be notified.',
                infoText1: 'Checking the container usually takes around 10 working day. Collections can only be ordered for active containers.',
                infoText2: 'Until the chip is attached to your registered container, you do not need to do anything else and can dispose of your commercial waste as usual.'
            },
            deleteContainersModal: {
                deleteContainer: "Delete container",
                doYouReallyWantToDeleteContainer: 'Do you really want to delete <1>{{textValue}}</1>?',
                scheduledEmptyingFound: 'There are scheduled collections for this container. Deletion ist not possible.'
            },
        },
        help: {
            headers: {
                help: 'Help',
                helpAndContact: 'Help & Contact',
            },
            tabs: {
                guides: 'Instructions',
                faq: 'FAQ',
                contact: 'Contact',
            },
            guides: {
                header1: 'For a successful start',
                subheader1: 'Learn everything about the first steps in the e-Container Portal.',
                header2: 'For an efficient organization',
                subheader2: 'Learn about functionalities that make the collection management more efficient.',
                downloadPdf: 'Download PDF',
            },
            faq: 'Frequently Asked Questions',
            contact: {
                header: 'Contact Form',
                subheader: 'If you have not found the answers to your questions in the FAQ and instructions section, fill out the contact form below and we will personally take care of your request.',
                firstName: 'First name*',
                lastName: 'Last Name*',
                company: 'Company',
                streetAndNo: 'Street, No.',
                zipAndCity: 'ZIP / City',
                phone: 'Phone',
                email: 'E-Mail*',
                category: 'Topic*',
                pleaseChoose: 'Please select',
                portal: 'Portal',
                emptying: 'Collection',
                credit: 'Credit',
                invoice: 'Invoice',
                feedback: 'Feedback',
                misc: 'Miscellaneous',
                yourMessage: 'Your message*',
                messageSentText: 'We have received your message and will take care of your request as soon as possible!',
                postingContactForm: 'Sending contact form...',
            }
        },
        invitationRedemption: {
            isLoading: "Your invitation is about to be accepted. We need a few moments to set up everything...",
            isSuccess: "Your invitation has been accepted. You will be redirected to the homepage in a few moments..."
        },
        pageNotFound: {
            header: '404: Page not found',
            paragraph: 'Reloading the page may resolve the issue. If the error still persists, close the browser and restart the application.',
        },
        genericAppErrorPage: {
            header: 'Unknown error has occurred!',
            report: 'Report: ',
            reloadApplication: 'Reloading the page may resolve the issue. If the error still persists, close the browser and restart the application.',
        },
        unauthenticatedPage: {
            youAreNotLoggedIn: 'You are not logged in. You will be redirected to the login page.',
            noPermissionsHeader: '401: Unauthorized',
            noPermissionsParagraph: 'You do not have permissions to access this site.',
        }
    },
    errors: {
        http: {
            unknown: 'Unknown data error',
            userNotLoggedIn: 'User must be logged in',
            accountAssignmentNotFound: 'User does not belong to the customer',
            unsatisfiedPaymentRole: 'For this action, the role \'payment\' needs to be assigned',
            unsatisfiedPermissionRole: 'For this action, the role \'permission\' needs to be assigned',
            unsatisfiedContainerRole: 'For this action, the role \'container\' needs to be assigned',
            invitationNotFound: 'Invitation not found',
            invitationExpired: 'Invitation expired',
            invitationExpiredDetail: 'Gerne reaktivieren wir Ihren Registrierungslink. Schreiben Sie uns hierfür eine E-Mail an e-container@kvatg.ch oder rufen Sie uns unter der 071 626 96 00 zu unseren Öffnungszeiten an (Mo - Fr, 07:30 - 12:00 und 13:30 - 17:00 Uhr). ',
            invitationAlreadyRedeemed: 'Invitation has already been redeemed',
            invitationAlreadyRedeemedDetail: 'Sie sind bereits registriert? Dann melden Sie sich über www.e-container.ch direkt im Portal an. Andernfalls wenden Sie sich an den Administrator Ihrer Firma, damit dieser Sie dem Firmenaccount hinzufügen kann. ',
            scheduleEmptyings: {
                containsAlreadyScheduledDates: 'Already scheduled dates cannot be booked again',
                creditProcessingStillOnGoing: 'Credit processing in action. Please wait and try again later.',
                datesMustBeInFuture: 'Dates must be in the future',
                disallowedDatesProvided: 'Dates are not allowed',
                unsufficientBookableCredit: 'Not enough credit avaialable'
            }
        },
        requiredField: 'Required Field'
    },
    buttons: {
        edit: 'Edit',
        delete: 'Delete',
        abort: 'Abort',
        cancel: 'Cancel',
        next: 'Next',
        goToHome: "Go to Home",
        saveSelection: 'Save Selection',
        charge: 'Charge',
        changePassword: 'Change Password',
        save: 'Save',
        invite: 'Invite',
        plan: 'Plan',
        addContainers: 'Add Containers',
        register: 'Register',
        yes: 'Yes',
        no: 'No',
        resetToDefault: 'Reset To Default',
        close: 'Close',
        back: 'Back',
        home: 'Home',
        request: 'Request',
        send: 'Send',
        refreshPage: 'Refresh Page',

    },
    toasts: {
        saved: 'Saved!',
        planned: 'Planned!',
        error: 'Error!',
        dataChangeRequested: 'Data change requested!',
        requestSent: 'Request sent!',
        topUpSucceeded: 'Topping up your credit was succesful.',
        topUpFailed: 'Topping up your credit has been canceled.',
        emptyingCancelled: 'Emptying Cancelled!'
    },
    pills: {
        active: 'Active',
        pending: 'Pending',
        cancelled: 'Cancelled',
        completed: 'Completed',
        uncompleted: 'Uncompleted',
        emptied: 'Collected',
        notemptied: 'Not collected',
        booked: 'Booked',
        expired: 'Expired',
        invited: 'Invited'
    },
    dates: {
        workingDays: {
            short: ['Mo', 'Tu', 'We', 'Th', 'Fr'],
            long: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday']
        },
        months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    },
    containerStatus: {
        active: 'Active',
        pending: 'Pending'
    },
    roles: {
        admin: 'Administrator',
        staff: 'Employee (authorized to order)',
        viewer: 'Employee (view only)'
    }
}