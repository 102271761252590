import { DataGatheringView } from "./01_DataGatheringView";
import { OrderView } from "./02_OrderView";
import { ConfirmationView } from "./04_ConfirmationView";
import { useCallback, useContext, useEffect, useState } from "react";
import { Container } from "../../types/Container";
import { usePaymentManager } from "../../components/payment/usePaymentManager";
import { jsonReviverFunction } from "../../data/_base";
import { BookingView } from "./03_BookingView";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../skeleton/Routes";
import { RecurrenceDefinition } from "../../types/Emptying";
import { NonExternalPaymentMethod } from "../../types/PaymentMethod";
import type {
    unstable_BlockerFunction as BlockerFunction,
} from "react-router-dom";
import {
    unstable_useBlocker as useBlocker,
} from "react-router-dom";

import { LeavingConfirmation } from "./LeavingConfirmation";
import { useContainersQuery } from "../../data/containers";
import { UserContext } from "../../contexts/UserContext";

export type EmptyingSchedulingMode = 'single' | 'multi';

export type EmptyingsSchedule = {
    container: Container;
    emptyings: {
        price: number;
        date: Date;
    }[];
    recurrence?: RecurrenceDefinition;
}
const EmptyingSchedulingPage = ({ mode }: { mode: EmptyingSchedulingMode }) => {
    const navigate = useNavigate();
    const [paymentTransactionId, setPaymentTransactionId] = useState<string>();
    const [emptyingSchedules, setEmptyingSchedules] = useState<EmptyingsSchedule[]>();
    const [status, setStatus] = useState<'new' | 'order' | 'auto-order' | 'booking' | 'confirm'>('new');
    const userContext = useContext(UserContext);
    const containersQuery = useContainersQuery(userContext.accessPermission?.accountRef);
    const [orderWasAutosubmitted, setOrderWasAutoSubmitted] = useState(false);

    const shouldConfirmLeaving = useCallback<BlockerFunction>(
        ({ currentLocation, nextLocation }) =>
            emptyingSchedules !== undefined && emptyingSchedules.length > 0 && currentLocation.pathname !== nextLocation.pathname && status !== 'confirm'
        ,
        [emptyingSchedules, status]
    );
    const navigationBlocker = useBlocker(shouldConfirmLeaving);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [status]);

    const onCancelProcess = () => {
        setEmptyingSchedules(undefined);
        if (status === 'auto-order' && (!containersQuery.data || containersQuery.data.length <= 1)) {
            // if we are in one-click-emptying and the user only sees one container, we must move over to single-emptying, otherwise cancelling would trigger the 1-click-emptying again and we would be stuck in there.
            navigate(ROUTES.SingleEmptying);
        } else {
            setStatus('new')
        }
    }

    const onDataGatheringUpdate = (schedules: EmptyingsSchedule[]) => {
        if (status === 'new') {
            setEmptyingSchedules(schedules);
        }
    }

    const onDataGatheringCompleted = (schedules: EmptyingsSchedule[], autoSubmitted?: boolean) => {
        if (status === 'new') {
            setEmptyingSchedules(schedules);
            setOrderWasAutoSubmitted(autoSubmitted || false);
            setStatus(autoSubmitted ? 'auto-order' : 'order');
        }
    }

    const onOrderCompleted = useCallback((force?: boolean) => {
        if (force || status === 'order' || status === 'auto-order') {
            setStatus('booking');
        }
    }, [status, setStatus]);

    const onBookingCompleted = (totalPrice: number) => {
        if (status === 'booking') {
            setStatus('confirm');
        }
    }

    const onConfirmationCompleted = useCallback(() => {
        setOrderWasAutoSubmitted(false);
        navigate(ROUTES.Home);
    }, [navigate]);

    const onExternalPaymentFailure = useCallback(() => {
        setOrderWasAutoSubmitted(false);
        setStatus('new');
    }, []);

    const onBeforeLeavingToExternalPayment = useCallback((paymentTransactionId: string) => {
        sessionStorage.setItem(paymentTransactionId, JSON.stringify(emptyingSchedules));
        setPaymentTransactionId(paymentTransactionId);
    }, [emptyingSchedules]);

    const onAfterExternalPayment = useCallback(async (paymentTransactionId: string, outcome: 'success' | 'failure'): Promise<void> => {
        setPaymentTransactionId(paymentTransactionId);
        const storedStateStr = sessionStorage.getItem(paymentTransactionId);
        sessionStorage.removeItem(paymentTransactionId);
        if (storedStateStr) {
            const storedState = JSON.parse(storedStateStr, jsonReviverFunction) as EmptyingsSchedule[];
            if (storedState) {
                setEmptyingSchedules(storedState);
                if (outcome === 'success') {
                    onOrderCompleted(true);
                }
                if (outcome === 'failure') {
                    onExternalPaymentFailure();
                }
            }
        } else {
            onExternalPaymentFailure();
        }
    }, [onOrderCompleted, onExternalPaymentFailure]);

    const onAfterNonExternalPayment = (paymentTransactionId: string, paymentMethod: NonExternalPaymentMethod) => {
        console.log("Non-External Payment", { paymentTransactionId, paymentMethod });
        setPaymentTransactionId(paymentTransactionId);
        if (paymentMethod === "Guthaben") {
            onOrderCompleted();
        }
        if (paymentMethod === "Rechnung") {
            alert("Rechnung!!! Wird an dierser Stelle nicht unterstützt.")
        }
    }

    const externalPaymentMgr = usePaymentManager(onBeforeLeavingToExternalPayment, onAfterExternalPayment, onAfterNonExternalPayment);

    if (!externalPaymentMgr.initialized) return <></>;
    return (
        <>
            {status === 'new' && <DataGatheringView defaultValue={emptyingSchedules} mode={mode} onCompleted={onDataGatheringCompleted} onUpdate={onDataGatheringUpdate} />}
            {emptyingSchedules && emptyingSchedules.length > 0 &&
                <>
                    {(status === 'order' || status === 'auto-order') &&
                        <OrderView paymentMgr={externalPaymentMgr} mode={mode} autoOpenPayment={status === 'auto-order'} emptyingSchedules={emptyingSchedules} onCancel={onCancelProcess} onBack={() => setStatus('new')} />
                    }
                    {status === 'booking' && paymentTransactionId &&
                        <BookingView emptyingSchedules={emptyingSchedules} paymentTransactionId={paymentTransactionId} onCompleted={onBookingCompleted} />
                    }
                    {status === 'confirm' &&
                        <ConfirmationView mode={mode} emptyingSchedules={emptyingSchedules} autoOpenPayment={orderWasAutosubmitted} onCompleted={onConfirmationCompleted} />
                    }
                </>
            }
            {navigationBlocker ? <LeavingConfirmation blocker={navigationBlocker} /> : null}
        </>
    )
};



export const OneClickmpytingSchedulingPage = () => <EmptyingSchedulingPage mode="single" />
export const OneTimeEmpytingSchedulingPage = () => <EmptyingSchedulingPage mode="single" />
export const SeriesEmpytingSchedulingPage = () => <EmptyingSchedulingPage mode="multi" />