import { useState, useContext } from "react";
import styled from "@emotion/styled";
import { IconNotificationsOff, IconNotificationsOffFilled, IconNotificationsOn, IconNotificationsOnFilled } from "../Icons";
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from "react-i18next";
import Modal from "../Modal";
import { NotificationFormResultInfo, NotificationsForm } from "./NotificationsForm";
import { UserContext } from "../../contexts/UserContext";
import { styles } from "../../styles";
import { Toast } from "../Toast";


type RoundIconButtonProps = {
  isOpen: boolean;
  isNotificationsModalOpened: boolean;
};

const RoundIconButton = styled.div<RoundIconButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  margin-left: 12px;
  background-color: ${props => props.isOpen ? '#26374A' : styles.neutral200};
  border-radius: 100px;
  transition: .2s background-color ease;
  cursor: pointer;

  svg {
    path {
      stroke: ${props => props.isOpen ? '#fff' : '#26374A'};
    }
  }

  @media (max-width: 962px) {
    width: 28px;
    height: 28px;

    svg {
        height: 16px;
    }
  }

  &:hover {
    background-color: ${styles.neutral300};
  }
`;

const TooltipIconButton = ({ isOpen, onClick, isNotificationsModalOpened }: RoundIconButtonProps & { onClick: () => void }) => {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);
  const currentUser = userContext.loggedInUser;
  const notificationsAreNotSet = currentUser?.contact.notificationTypes ? currentUser?.contact.notificationTypes.length === 0 : true;

  return (
    <Tooltip
      arrow
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: '#26374A',
            '& .MuiTooltip-arrow': {
              color: '#26374A',
            },
          },
        },
      }}
      title={t("navigation.top.notificationSettings")}
    >
      <RoundIconButton isOpen={isOpen} onClick={onClick} isNotificationsModalOpened={isNotificationsModalOpened}>
        {(() => {
          if (!isNotificationsModalOpened && notificationsAreNotSet) return <IconNotificationsOff />;
          if (isNotificationsModalOpened && notificationsAreNotSet) return <IconNotificationsOffFilled />;
          if (!isNotificationsModalOpened) return <IconNotificationsOn />;
          return <IconNotificationsOnFilled />;
        })()}
      </RoundIconButton>
    </Tooltip>
  );
}

const NotificationsToggle = () => {
  const [isNotificationsModalOpened, setIsNotificationsModalOpened] = useState(false);
  const [toastInfo, setToastInfo] = useState<NotificationFormResultInfo>();
  const onNotificationsFormClosed = (resultInfo?: NotificationFormResultInfo) => {
    setIsNotificationsModalOpened(false);
    if (resultInfo && resultInfo.type !== 'cancel') {
      setToastInfo(resultInfo);
    }
  }

  const handleToastCloseComplete = () => {
    setToastInfo(undefined);
  };
  
  return (
    <>
      <TooltipIconButton
        isOpen={isNotificationsModalOpened}
        onClick={() => setIsNotificationsModalOpened(!isNotificationsModalOpened)}
        isNotificationsModalOpened={isNotificationsModalOpened}
      />
      {isNotificationsModalOpened &&
        <Modal onClose={onNotificationsFormClosed}>
          <NotificationsForm onClose={onNotificationsFormClosed} />
        </Modal>
      }
      {toastInfo && toastInfo.type !== 'cancel' && <Toast message={toastInfo.message} type={toastInfo.type} onCloseComplete={handleToastCloseComplete} />}
    </>
  )
}

export default NotificationsToggle;