import styled from '@emotion/styled';
import { Checkbox } from "@mui/material";
import { DayOfWeek, RemovalArea } from "../../../types/RemovalArea";
import { Namespace, TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { StyledFormControlLabel } from "./SeriesPlannerTool";

const StyledCheckbox = styled(Checkbox)`
  &.MuiCheckbox-root {
    color: #E0E1E0;
    
    &.Mui-checked {
      color: #33AA47;
    }
  }
`;

type WeekdayChooserProps = {
    removalArea: RemovalArea;
    values: DayOfWeek[];
    onChange: (weekdays: DayOfWeek[]) => void;
    useSettingsFromPreviousContainer: boolean; 
}

type WeekdayIndexInI18nList = 0 | 1 | 2 | 3 | 4;

const calcDayName = (t: TFunction<Namespace, undefined, Namespace>, weekday: number) => {
    return (weekday <= 0 || weekday > 5) ? '-' : t(`dates.workingDays.long.${weekday - 1 as WeekdayIndexInI18nList}`);
}

export const WeekdayChooser = ({ removalArea, onChange, values, useSettingsFromPreviousContainer }: WeekdayChooserProps) => {
    const { t } = useTranslation();
    const [selectedWeekdays, setSelectedWeekdays] = useState<DayOfWeek[]>([]);

    const onToggleDaySelection = (day: DayOfWeek) => {
        const updatedSelection =
            (selectedWeekdays.indexOf(day) >= 0)
                ? selectedWeekdays.filter(d => d !== day)
                : [...selectedWeekdays, day];
        setSelectedWeekdays(updatedSelection);
        onChange(updatedSelection);
    }

    useEffect(() => {
        if (values.length > 0) {
            setSelectedWeekdays(values.filter(weekday => removalArea.weekdays.indexOf(weekday) >= 0));
        } else {
            onChange(selectedWeekdays)
        }
    }, [values, removalArea.weekdays]);

    if (removalArea.weekdays.length === 1 && values.length === 1) {
        return <>{calcDayName(t, values[0] as WeekdayIndexInI18nList)}</>;
    }
    return <div>
        {removalArea.weekdays.map(day =>
            <StyledFormControlLabel
                key={day}
                control={<StyledCheckbox disabled={useSettingsFromPreviousContainer} onChange={() => onToggleDaySelection(day)} 
                checked={selectedWeekdays.indexOf(day) >= 0} />}
                label={calcDayName(t, day as WeekdayIndexInI18nList)}
            />
        )}
    </div>
}