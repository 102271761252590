import styled from "@emotion/styled";
import { CircularProgress } from '@mui/material';

type ContainersLoaderProps = {
    cardWidth?: number,
}

type CircularProgressWrapperProps = {
    cardWidth?: number,
}

const CircularProgressWrapper = styled.div<CircularProgressWrapperProps>`
    padding: 24px 24px 36px 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    height:  ${(props) => props.cardWidth === 200 ? 167 : props.cardWidth}px;
`;

export const ContainersLoader: React.FC<ContainersLoaderProps> = ({ cardWidth = 200 }) => (
    <CircularProgressWrapper cardWidth={cardWidth}>
        <CircularProgress sx={{ color: '#33AA47' }} />
    </CircularProgressWrapper>
);