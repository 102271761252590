import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { ROUTES } from "../../skeleton/Routes";
import { PersonalDetails } from "./components/personal-details/PersonalDetails";
import { TabbedNavigation } from "../../components/TabbedNavigation";
import { useLoggedInContactQuery } from "../../data/contacts";
import { Loader } from "../../components/Loader";
import { MessageWrapper } from "../../components/MessageWrapper";
import { useContext } from "react";
import { UserContext } from "../../contexts/UserContext";

const StyledH1 = styled.h1`
    margin-bottom: 48px;

    @media (max-width: 480px) {
        margin-bottom: 12px;
    }
`;

export const UserProfilePage = () => {
    const contactQuery = useLoggedInContactQuery();
    const { accessPermission } = useContext(UserContext);
    const { t } = useTranslation();

    const nodes = [
        { label: t("pages.myData.personalDetails.header"), route: ROUTES.UserProfile }
    ]
    if (accessPermission) {
        nodes.push({ label: t("pages.myData.companyData.header"), route: ROUTES.AccountProfile });
    }


    return (
        <>
            <StyledH1>{t("pages.myData.header")}</StyledH1>
            <div>
                <TabbedNavigation nodes={nodes} />
                { contactQuery.isLoading && 
                    <Loader messageText={t("components.loaders.loading")} height={"360px"} />
                }
                { contactQuery.isError && 
                    <MessageWrapper messageText={contactQuery.error.toString()}  height={"360px"} svgMargin="0 12px 12px 0" />
                }
                { !contactQuery.isError && !contactQuery.isLoading && contactQuery.data && 
                    <PersonalDetails contact={contactQuery.data.contact}/>
                }
            </div>
        </>
    )
};