import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const HashLink = ({ name }: { name: string }) => {
    let location = useLocation();

    useEffect(() => {
        const hash = location.hash;

        if (hash) {
            const hashLink = location.hash.substring(1);
            if (hashLink === name) {
                const hashElement = document.getElementById(hashLink);
                hashElement?.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                    inline: "start"
                });
            }
        }
    }, [location.hash, name]);

    return <span style={{display:'inline'}} id={name} />;
};