import { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from '@emotion/styled';
import { IconCircleCheckSelected, IconClose, IconContainerLarge } from "../../../components/Icons";
import Button from '../../../components/Button';
import { styles } from '../../../styles';
import { EmptyingsSchedule } from "../EmptyingSchedulingPage";
import { Namespace, TFunction } from "i18next";

type EditSerialEmptyingModalProps = {
    onClose: (originalSchedule: EmptyingsSchedule, updatedSchedule?: EmptyingsSchedule) => void;
    originalPlannedSchedule: EmptyingsSchedule;
    editedPlannedSchedule: EmptyingsSchedule;
}

const ModalHeading = styled.h2`
    color: ${styles.textColorSecondary700};
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #fff;
    padding-top: 24px;
    padding-bottom: 24px;
    padding-right: 24px;
    border-radius: 8px;
    margin-bottom: 0;

    svg {
        cursor: pointer;
    }
`;

const ContainerInfo = styled.div<{ color: string }>`
    position: relative;
    max-width: 208px;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: 12px;
    padding: 16px 24px;
    border: 1px solid ${styles.neutral200};
    border-radius: 8px;

    svg {
        width: 48px;
        height: 48px;
        path {
            fill: ${props => props.color};
        }
        path:last-child {
            stroke: ${props => props.color};
        }
    }

    h4 {
        font-size: 14px;
        line-height: 22px;
    }

    p {
        font-size: 10px;
        line-height: 18px;
    }

    @media (max-width: 480px) {
        h4, p {
            font-size: 12px;
        }
    }
`;

const RowContainer = styled.div`
    display: flex;
    gap: 48px;

    @media (max-width: 480px) {
        flex-direction: column;
        gap: unset;
    }
`;

const Row = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    color: ${styles.textColorSecondary700};
`;

const StyledH3 = styled.h3`
    display: flex;
    gap: 10px;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 8px;

    svg {
        cursor: pointer;
    }
`;

const BottomRow = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    margin-top: 48px;

    button {
        width: 156px;
    }

    @media (max-width: 480px) {
        margin-bottom: 0;
        flex-direction: column;
        margin-bottom: 0;
        gap: 12px;

        button {
            width: 100%;
            margin-top: 0;
            margin-bottom: 0;
        }
    }

`;

const EmptyingDates = styled.div`
    column-count: 2;
    column-gap: 8px;

    @media (max-width: 480px) {
        column-count: 1;
    }
`;

const EmptyingDate = styled.div<{ isSelected: boolean }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    margin-bottom: 8px;
    box-sizing: border-box;
    padding: 4px 4px 4px 12px;
    border-radius: 100px;
    border: 1px solid ${props => props.isSelected ? "transparent" : "#E0E1E0"};
    box-shadow: 0px 2px 6px 0px ${props => props.isSelected ? "rgba(0, 0, 0, 0)" : "rgba(0, 0, 0, 0.12)"};
    background-color: ${props => props.isSelected ? "#DEE9FC" : "#fff"};
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid-column;
    height: 34px;
    font-size: 14px;
    line-height: 22px;
    color: ${styles.textColorSecondary900};
    cursor: pointer;
`;

const EmptyingWeek = styled.span`
    font-family: 'Satoshi Bold';
    font-weight: 700;
    font-size: 10px;
    line-height: 18px;

    ::-moz-selection {
        color: initial;
        background: initial;
    }

    ::selection {
        color: initial;
        background: initial;
    }
`;

const EmptyingDateText = styled.span`
    ::-moz-selection {
        color: initial;
        background: initial;
    }

    ::selection {
        color: initial;
        background: initial;
    }
`;

const Wrapper = styled.div`
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    max-height: 80vh;

    @media (max-width: 480px) {
        max-height: 100vh;
        height: 100%;
    }
`;

const ContentWrapper = styled.div`
    overflow-y: auto;
    position: relative;
    max-height: 80vh;
    padding-right: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    @media (max-width: 480px) {
        max-height: 100vh;
        height: 100%;
    }
`;

const DateContainer = styled.div`
    flex-grow: 1;
    overflow-y: scroll;
`;

const SelectedContainerInfo = styled.p`
    color: ${styles.textColorSecondary700};
    margin-top: 12px;
    margin-left: 4px;
`;

const ContainerShortName = styled.span`
    font-family: 'Satoshi Bold';
    color: ${styles.textColorSecondary700};
    position: absolute;
    top: 31px;
    left: 37px;
    width: 22px;
    text-align: center;
`;

const getWeekNumber = (date: Date) => {
    const onejan = new Date(date.getFullYear(), 0, 1);
    const week = Math.ceil((((date.getTime() - onejan.getTime()) / 86400000) + onejan.getDay() + 1) / 7);
    return `${week}`.padStart(2, '0');
};

type WeekdayIndexInI18nList = 0 | 1 | 2 | 3 | 4;

const calcDayName = (t: TFunction<Namespace, undefined, Namespace>, weekday: number) => {
    return (weekday <= 0 || weekday > 5) ? '-' : t(`dates.workingDays.long.${weekday - 1 as WeekdayIndexInI18nList}`);
}

export const EditSerialEmptyingModalContent: React.FC<EditSerialEmptyingModalProps> = ({ originalPlannedSchedule, editedPlannedSchedule, onClose }) => {
    const [selectedDates, setSelectedDates] = useState(editedPlannedSchedule.emptyings.map(e => e.date));
    const { t, i18n } = useTranslation();

    const toggleDateSelection = (date: Date) => {
        const allWithoutThisDate = selectedDates.filter(d => d !== date);
        const isSelected = allWithoutThisDate.length < selectedDates.length;
        if (isSelected) {
            setSelectedDates(allWithoutThisDate);
        } else {
            setSelectedDates([...selectedDates, date]);
        }
    };

    const onSave = () => {
        onClose(originalPlannedSchedule, {
            ...editedPlannedSchedule,
            emptyings: originalPlannedSchedule.emptyings.filter(e => selectedDates.find(d => d === e.date))
        });
    }

    const dateFormat = new Intl.DateTimeFormat(i18n.language, { day: "2-digit", weekday: "short", month: "2-digit", year: "numeric" });

    const shortenTo12Chars = (s: string) => {
        return (s && s.length > 12 ? s.substring(0, 12) + "..." : s);
    }

    return (
        <Wrapper>
            <ModalHeading>{t("pages.emptying.series.modalEditSerialEmptying.header")}<div onClick={() => onClose(originalPlannedSchedule)}><IconClose /></div></ModalHeading>
            <ContentWrapper>
            <div style={{marginTop: '24px'}}>
            <RowContainer>
                <Row >
                    <StyledH3>{t("pages.emptying.series.modalEditSerialEmptying.container")}</StyledH3>
                    <ContainerInfo color={originalPlannedSchedule.container.color}>
                        <IconContainerLarge />
                        <div>
                            <h4>{shortenTo12Chars(originalPlannedSchedule.container.displayName)}</h4>
                            <p>ID-Nr. {originalPlannedSchedule.container.awmId}</p>
                            <ContainerShortName>{originalPlannedSchedule.container.shortName}</ContainerShortName>
                        </div>
                    </ContainerInfo>
                </Row>

                <Row>
                    <StyledH3>
                        {t("pages.emptying.series.modalPlanSerialEmptying.weekday")}
                    </StyledH3>

                    <p>{originalPlannedSchedule.recurrence && originalPlannedSchedule.recurrence.weekdays
                        .map(day => calcDayName(t, day as WeekdayIndexInI18nList)).join(', ')}</p>
                </Row>
            </RowContainer>

            <StyledH3>{t("pages.emptying.series.modalEditSerialEmptying.emptyingDates")}</StyledH3>
            {originalPlannedSchedule.emptyings.length === 0 &&
                <div>keine eingeplanten Leerungen.</div>
            }
            {originalPlannedSchedule.emptyings.length > 0 &&
                <>
                    <DateContainer>
                        <Row style={{ marginBottom: 0 }}>
                            <EmptyingDates>
                                {originalPlannedSchedule.emptyings.map(emptying => {
                                    const isSelected = selectedDates.some(date => date === emptying.date);
                                    return (
                                        <EmptyingDate
                                            key={emptying.date.getTime()}
                                            isSelected={isSelected}
                                            onClick={() => toggleDateSelection(emptying.date)}
                                        >
                                            <EmptyingWeek>KW{getWeekNumber(emptying.date)}</EmptyingWeek>
                                            <EmptyingDateText>{dateFormat.format(emptying.date)}</EmptyingDateText>
                                            {isSelected ? <IconCircleCheckSelected /> : <div style={{ width: '24px', height: '24px', backgroundColor: 'transparent' }}></div>}
                                        </EmptyingDate>
                                    );
                                })}
                            </EmptyingDates>
                        </Row>
                    </DateContainer>
                    <SelectedContainerInfo>{t("pages.emptying.selection")} {selectedDates.length} {t("pages.emptying.emptyings")}</SelectedContainerInfo>
                </>
            }
            </div>
            <BottomRow>
                <Button buttonVariant="ghost" onClick={() => onClose(originalPlannedSchedule)}>
                    {t("buttons.abort")}
                </Button>
                <Button onClick={() => onSave()} disabled={originalPlannedSchedule.emptyings.length === 0}>
                    {t("buttons.save")}
                </Button>
            </BottomRow>
            </ContentWrapper>
        </Wrapper>
    )
}