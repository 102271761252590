import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { styles } from "../../../../styles";
import { IconClose, IconInfo } from "../../../../components/Icons";
import Button from '../../../../components/Button';
import { InvitationWasSentImage } from "./InvitationWasSentImage";


type InvitationSentModalProps = {
    onClose: () => void;
}

const ModalHeading = styled.h2`
    margin-bottom: 48px;
    color: ${styles.textColorSecondary700};
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 24px;

    svg {
        cursor: pointer;
    }
`;

const Wrapper = styled.div`
    padding-right: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`;


const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const ModalContentText = styled.div`
    color: ${styles.textColorSecondary700};
    p {
        margin: 12px 0 0px;
        font-family: 'Satoshi Bold';
        text-align: center;
    }

    p:nth-of-type(2) {
        margin: 0 0 8px;
    }
`;

const InfoTextWrapper = styled.div`
    display: flex;
    gap: 12px;
    margin-top: 12px;

    p {
        
        font-size: 10px;
        line-height: 18px;
        margin-right: 24px;
    }

    svg {
        min-width: 24px;
        min-height: 24px;
        margin-left: 34px;
    }
`;

const BottomRow = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    margin-top: 48px;

    button {
        width: 156px;
    }

    @media (max-width: 480px) {
        margin-bottom: 0;
        flex-direction: column;
        align-items: flex-end;
        gap: 12px;
        width: 100%;

        button {
            width: 100%;
            margin-bottom: 0;
        }
    }
`;

export const InvitationSentModalContent: React.FC<InvitationSentModalProps> = ({ onClose }) => {
    const { t } = useTranslation();

    return (
        <Wrapper>
            <div>
                <ModalHeading>{t("pages.myOrganization.invitationSentModal.header")} <div onClick={onClose}><IconClose /></div></ModalHeading>
                <ModalContent>
                    <InvitationWasSentImage />
                    <ModalContentText>
                        <p>{t('pages.myOrganization.invitationSentModal.andOffYouGo')}</p>
                        <p>{t('pages.myOrganization.invitationSentModal.yourEmployeeWillReceiveEmail')}</p>
                    </ModalContentText>
                    <InfoTextWrapper>
                        <IconInfo />
                        <p>{t('pages.myOrganization.invitationSentModal.validityHint')}</p>
                    </InfoTextWrapper>
                </ModalContent>
            </div>

            <BottomRow>
                <Button onClick={onClose}>
                    {t("buttons.close")}
                </Button>
            </BottomRow>
        </Wrapper>
    );
};