import styled from "@emotion/styled";
import { RadioGroup, Radio } from "@mui/material"
import { t } from "i18next"
import { StyledFormControlLabel } from "./SeriesPlannerTool"
import { RecurrenceInterval } from "../../../types/Emptying"
import { useEffect, useState } from "react";

type RadioOption = 'weekly' | 'bi-weekly' | 'four-weekly';

type IntervalChooserProps = {
    value: RecurrenceInterval | undefined;
    onChange: (interval: RecurrenceInterval) => void;
    useSettingsFromPreviousContainer: boolean; 
}

const GreenRadio = styled(Radio)`
  &.MuiRadio-root {
    color: #E0E1E0;

    &.Mui-checked {
      color: #33AA47;
    }
  }
`;

export const IntervalChooser = ({value, onChange, useSettingsFromPreviousContainer}: IntervalChooserProps) => {
    const [intervalValue, setIntervalValue] = useState<RecurrenceInterval | undefined>(value);

    const updateInterval = (interval: RadioOption) => {
        const newInterval = 
            interval === 'weekly' ? RecurrenceInterval.Weekly : 
            interval === 'bi-weekly' ? RecurrenceInterval.BiWeekly : 
            interval === 'four-weekly' ? RecurrenceInterval.FourWeekly: RecurrenceInterval.Weekly;
            setIntervalValue(newInterval);
        onChange(newInterval);
    }

    useEffect(() => {
        setIntervalValue(value);
    }, [value]);

    return (
        <RadioGroup row  onChange={(evt, value) => updateInterval(value as RadioOption)}>
            <StyledFormControlLabel disabled={useSettingsFromPreviousContainer} value={'weekly'} control={<GreenRadio checked={intervalValue === RecurrenceInterval.Weekly} />} label={t("pages.emptying.series.modalPlanSerialEmptying.weekly")} />
            <StyledFormControlLabel disabled={useSettingsFromPreviousContainer} value={'bi-weekly'} control={<GreenRadio checked={intervalValue === RecurrenceInterval.BiWeekly} />} label={t("pages.emptying.series.modalPlanSerialEmptying.biweekly")} />
            <StyledFormControlLabel disabled={useSettingsFromPreviousContainer} value={'four-weekly'} control={<GreenRadio checked={intervalValue === RecurrenceInterval.FourWeekly} />} label={t("pages.emptying.series.modalPlanSerialEmptying.fourweekly")} />
        </RadioGroup>
    )
}