import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { styles } from "../../../styles";
import { IconClose, IconInfo } from "../../../components/Icons";
import Button from '../../../components/Button';
import { ContainerWasCheckedImage } from "./ContainerWasCheckedImage";


type ContainerCheckedModalContentProps = {
    onClose: () => void;
}

const Wrapper = styled.div`
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    max-height: 80vh;

    @media (max-width: 480px) {
        max-height: 100vh;
        height: 100%;
    }
`;

const ContentWrapper = styled.div`
    overflow-y: auto;
    position: relative;
    max-height: 80vh;
    padding-right: 24px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: 480px) {
        max-height: 100vh;
        height: 100%;
    }
`;

const ModalHeading = styled.h2`
    color: ${styles.textColorSecondary700};
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #fff;
    padding-top: 24px;
    padding-bottom: 24px;
    padding-right: 24px;
    border-radius: 8px;
    margin-bottom: 0;

    svg {
        cursor: pointer;
    }
`;

const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 24px;
`;

const ModalContentText = styled.div`
    color: ${styles.textColorSecondary700};
    max-width: 362px;
    
    p {
        margin: 9px 0 0px;
        font-family: 'Satoshi Bold';
        text-align: center;
    }

    p:nth-of-type(2) {
        margin: 0 0 8px;
    }
`;

const InfoText = styled.span`
    font-size: 10px;
    line-height: 18px;
    display: flex;
    align-items: flex-start;
    gap: 8px;

    svg {
        margin-top: 2px;
    }
`;

const BottomRow = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    margin-top: 48px;

    button {
        width: 156px;
    }

    @media (max-width: 480px) {
        margin-bottom: 0;
        flex-direction: column;
        align-items: flex-end;
        gap: 12px;
        width: 100%;

        button {
            width: 100%;
            margin-bottom: 0;
        }
    }
`;

export const ContainerCheckedModalContent: React.FC<ContainerCheckedModalContentProps> = ({ onClose }) => {
    const { t } = useTranslation();

    return (
        <Wrapper>
            <ModalHeading>{t("pages.myContainers.containerCheckedModal.header")} <div onClick={onClose}><IconClose /></div></ModalHeading>
            <ContentWrapper>
            <ModalContent>
                <ContainerWasCheckedImage />
                <ModalContentText>
                    <p>{t('pages.myContainers.containerCheckedModal.weAreAlreadyThere')}</p>
                    <p>{t('pages.myContainers.containerCheckedModal.afterSuccessfulCheckText')}</p>
                    <InfoText><IconInfo /> {t('pages.myContainers.containerCheckedModal.infoText1')}<br/>{t('pages.myContainers.containerCheckedModal.infoText2')}</InfoText>
                </ModalContentText>
            </ModalContent>

            <BottomRow>
                <Button onClick={onClose}>
                    {t("buttons.close")}
                </Button>
            </BottomRow>
            </ContentWrapper>
        </Wrapper>
    );
};