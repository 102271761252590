import { useState } from "react";
import Modal from "../../../components/Modal";
import { Container } from "../../../types/Container";
import { SeriesPlannerStartButton } from "./SeriesPlannerStartButton";
import { SeriesPlannerTool } from "./SeriesPlannerTool";
import { EmptyingsSchedule } from "../EmptyingSchedulingPage";
import { SeriesPlannedSchedulesList } from "./SeriesPlannedSchedulesList";
import { Toast } from "../../../components/Toast";
import { useTranslation } from "react-i18next";


type SeriedFormAreaProps = {
    selectedContainer?: Container;
    defaultValue: EmptyingsSchedule[] | undefined;
    onChange: (schedules: EmptyingsSchedule[]) => void;
}

export const SeriesFormArea = ({ selectedContainer, defaultValue, onChange }: SeriedFormAreaProps) => {
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState(false);
    const [plannedSchedules, setPlannedSchedules] = useState<EmptyingsSchedule[]>(defaultValue ? defaultValue: []);
    const [showToast, setShowToast] = useState(false);

    const handleToastCloseComplete = () => {
        setShowToast(false);
    };
    
    const handleCloseModal = () => {
        setShowModal(false);
    };
    const onSaveSchedules = (newlyPlannedSchedules: EmptyingsSchedule[]) => {
        const updatedPlannedSchedules = plannedSchedules.filter(s => newlyPlannedSchedules.length === 0 || newlyPlannedSchedules[0].container.id !== s.container.id);
        newlyPlannedSchedules.forEach(plannedSchedule => updatedPlannedSchedules.push(plannedSchedule));
        setPlannedSchedules(updatedPlannedSchedules);
        handleCloseModal();
        setShowToast(true);
        onChange(updatedPlannedSchedules);
    }
    const onListChanged = (updatedPlannedSchedules: EmptyingsSchedule[]) => {
        setPlannedSchedules(updatedPlannedSchedules);
        handleCloseModal();
        setShowToast(true);
        onChange(updatedPlannedSchedules);
    }

    return (
        <>
            <SeriesPlannerStartButton disabled={!selectedContainer} onClick={() => setShowModal(true)} />
            {showModal && selectedContainer &&
                <Modal onClose={handleCloseModal}>
                    <SeriesPlannerTool container={selectedContainer} 
                        onCancel={handleCloseModal} onSaveSchedules={onSaveSchedules} 
                        existingSchedules={plannedSchedules }
                    />
                </Modal>
            }
            <SeriesPlannedSchedulesList plannedSchedules={plannedSchedules} onUpdate={onListChanged}/>
            {showToast && <Toast message={t("toasts.planned")} type="success" onCloseComplete={handleToastCloseComplete} />}
        </>
    )
}