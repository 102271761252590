import styled from "@emotion/styled";
import { CreditSection } from "./CreditSection";
import { PastTransactionsSection } from "./PastTransactionsSection";


const Wrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(8, 1fr);
`;

export const CreditAccount = () => (
    <Wrapper>  
        <CreditSection />
        <PastTransactionsSection/>
    </Wrapper>
);