import styled from "@emotion/styled";
import { CardStyledContainersList } from "../../../../components/containers/CardStyledContainersList";

const StyledContainerSection = styled.section`
    h2 {
        margin-bottom: 24px;
    }

    p {
        max-width: 526px;
    }
`;

export const ContainerSection = () => {
    return (
        <StyledContainerSection>
            <CardStyledContainersList selectionMode="none" cardWidth={312} />
        </StyledContainerSection>
    );
}