import React from 'react';
import { GenericAppErrorPage } from '../pages/errors/GenericAppErrorPage';

type Props = { children: any}
type State = {
	hasError: boolean;
	errorName: string;
	errorMsg: string;
	errorInfo: any;
}

export class ErrorBoundary extends React.Component<Props, State> {
	constructor(props: {children: any}) {
		super(props);
		this.state = { hasError: false, errorName: '', errorMsg: '', errorInfo: '' };
	}

	static getDerivedStateFromError() {
		return { hasError: true };
	}

	componentDidCatch(error: Error, errorInfo: any) {
		this.setState({ errorInfo: JSON.stringify(errorInfo, null, 2), errorName: error.name, errorMsg: error.message });
	}

	render() {
		if (this.state.hasError) {
			console.log("Error caught in ErrorBoundary", this.state);
			return (<GenericAppErrorPage message={this.state.errorMsg} />);
		}
		return this.props.children;
	}
}