import { DayOfWeek } from "./RemovalArea";
import { RemovalDate } from "./RemovalDate";

export type Emptying = {
    id: string;
    containerId: string;
    scheduleId: string;
    removalDate: RemovalDate;
    price: number;
    statusReason: EmptyingStatusReason;
    confirmationDocumentLink?: string;
    awmResponse?: Date;
}

export enum EmptyingStatusReason {
    Pending = 1,
    Cancelled = 2,
    Completed = 125160000,
    Uncompleted = 125160001
}

export const EmptyingStatusI18nKey: { [key in EmptyingStatusReason]: string } = {
    [EmptyingStatusReason.Pending]: "Pending",
    [EmptyingStatusReason.Cancelled]: "Cancelled",
    [EmptyingStatusReason.Completed]: "Emptied",
    [EmptyingStatusReason.Uncompleted]: "NotEmptied",
};

export enum RecurrenceInterval {
    Weekly = 125160000,
    BiWeekly = 125160001,
    FourWeekly = 125160002
}

export type RecurrenceDefinition = {
    interval: RecurrenceInterval;
    startDate: Date;
    endDate: Date;
    weekdays: DayOfWeek[];
    type: 'all-year' | 'picker';
}